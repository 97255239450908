import React, { ReactElement, useEffect, useRef } from 'react';

function ScrollToDiv(): ReactElement {
  const scrollToRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (scrollToRef.current) {
      scrollToRef.current.scrollIntoView({ block: 'center' });
    }
  }, [scrollToRef]);

  return (
    <div ref={scrollToRef} />
  );
}

export default ScrollToDiv;
