import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import {
  SegmentationData, SegmentationPossibleOutputEntityTypes, SegmentationRequest, SegmentationWorkflowIds
} from '@deecision/dna-interfaces';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Unstable_Grid2';
import {
  IconCirclesRelation,
  IconFilterSearch,
  IconUsers
} from '@tabler/icons-react';
import {
  SegmentationCriteriaSpec,
  SegmentationFilter
} from '@deecision/dna-interfaces/dist/segmentation/segmentationFilters';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import Skeleton from '@mui/material/Skeleton';
import { useTheme } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import IconBuildings from '@/assets/custom/IconBuildings';
import { makeFindOptions } from '@/utils';
import { CustomSegmentationData } from '../types.segmentations';
import SegmentationsServices from '../services/segmentations.services';
import getDynamicGroupsRequest from '../../../modules/deetect/portfolios/utils/dynamicgroups';
import { SegmentationContext } from '../wrappers/wrapper.segmentations';
import {
  possibleCriteriaTypes,
  possibleOutputEntities,
  possibleWorkflowIds,
  segmentationPossibleWorkflows
} from '../builder/workflows';
import CustomAccordion from '../../../../components/accordion';
import RenderSegmentationCriterias from './criterias/criterias.render.segmentations';
import EntitiesListsSegmentations from './lists/entities.lists.segmentations';
import FiltersCriteriasSegmentations from './criterias/filters.criterias.render.segmentations';
import ConfigBuilderSegmentation from '../builder/config/config.builder.segmentation';
import PreFilteringBuilderSegmentation from '../builder/prefiltering/prefiltering.builder.segmentation';
import { getEntityTypeEquivalent } from '../../../providers/getter';
import { SegmentationDataContext } from './abstract.segmentations';
import SelectorLevelsRenderSegmentations from "./levels/selector.levels.render.segmentations";
import SegmentationTitle from "./title.render.segmentations";

interface RenderSegmentationsProps {
  filters?: SegmentationFilter[],
  removeFilter?: (filterId: string) => void,
  reset?: () => void,
  children?: React.ReactNode
}

function RenderSegmentations(props: RenderSegmentationsProps): ReactElement {
  const segmentationContext = useContext(SegmentationContext);
  const { id } = useParams();
  const theme = useTheme();
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const segmentationService = new SegmentationsServices();
  const [listRef, setListRef] = useState<HTMLDivElement | null>(null);

  const entityType = searchParams.get('entityType');
  const groupId = searchParams.get('groupId');
  const customGroupId = searchParams.get('customGroupId');
  const outputEntitiesFromParams = searchParams.get('outputEntities');
  const workflowIdFromParams = searchParams.get('workflowId');
  const filterIds = searchParams.get('filterIds')?.split(',');
  const filterValues = searchParams.get('filterValues')?.split(',');

  const getOutputEntities = () =>  (outputEntitiesFromParams ? [outputEntitiesFromParams as SegmentationRequest['outputEntities']] : entityType ? possibleOutputEntities([getEntityTypeEquivalent(entityType) as CustomSegmentationData['possibleDataSetIds'][0]]) : segmentationContext?.segmentation?.data.possibleOutputEntities || []);
  const getWorkflowIds = (dataSetIdTmp: CustomSegmentationData['possibleDataSetIds'][0], outputEntitiesTmp?: CustomSegmentationData['possibleOutputEntities'][0]) => (workflowIdFromParams ? [workflowIdFromParams as SegmentationRequest['workflowId']] : possibleWorkflowIds([dataSetIdTmp], outputEntitiesFromParams ? [outputEntitiesFromParams as SegmentationRequest['outputEntities']] : outputEntitiesTmp ? [outputEntitiesTmp] : []));
  const getCriterias = (outputEntitiesTmp?: SegmentationPossibleOutputEntityTypes) => segmentationContext?.segmentation?.data.potentialSegmentationCriterias.filter(criteria => possibleCriteriaTypes(undefined, outputEntitiesTmp ? [outputEntitiesTmp] : undefined).includes(criteria.on)) || [];

  const [isDraft, setIsDraft] = useState<boolean>(false);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [isPrefiltersEnabled, setIsPrefiltersEnabled] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [segmentationData, setSegmentationData] = useState<SegmentationData>();
  const [filters, setFilters] = useState<SegmentationFilter[]>([]);
  const [entryCount, setEntryCount] = useState<number>(0);
  const [dataSetId, setDataSetId] = useState<CustomSegmentationData['possibleDataSetIds'][0]>(entityType ? getEntityTypeEquivalent(entityType) as CustomSegmentationData['possibleDataSetIds'][0] : segmentationContext?.segmentation?.data.possibleDataSetIds[0] || 'deecPersons');
  const [outputEntities, setOutputEntities] = useState<SegmentationPossibleOutputEntityTypes>(getOutputEntities()[0]);
  const [workflowId, setWorkflowId] = useState<SegmentationRequest['workflowId']>(getWorkflowIds(dataSetId, outputEntities)[0]);

  const baseFilterGroupMember: SegmentationFilter[] = id ? (customGroupId === 'true' && groupId) ? getDynamicGroupsRequest(groupId, id)?.filters || [] : [
    {
      id: dataSetId === 'deecPersons' ? 'person_groupMember' : 'company_groupMember',
      filterId: dataSetId === 'deecPersons' ? 'person_groupMember' : 'company_groupMember',
      type: 'filter',
      on: dataSetId === 'deecPersons' ? 'person1' : 'company',
      values: groupId && groupId.split(',').length > 1 ? groupId.split(',').map(uniqGroupId => `${id}/${uniqGroupId}`) : [`${id}/${groupId || (dataSetId === 'deecPersons' ? 'persons' : 'companies')}`]
    }
  ] : [];

  const baseFiltersFromUrlParams: SegmentationFilter[] = filterIds && filterValues ? filterIds.map((filterId, index) => ({
    id: filterId,
    filterId,
    type: 'filter',
    on: dataSetId === 'deecPersons' ? 'person1' : 'company',
    values: [filterValues[index] === "true" ? true : filterValues[index] === "false" ? false : filterValues[index]]
  })) : [];

  const setCustomSegmentationData = (segmentationDataTmp: CustomSegmentationData) => segmentationContext?.setSegmentation(prevState => (prevState ? { ...prevState, data: segmentationDataTmp } : undefined));

  const makeSegmentationCriterias = (criterias: CustomSegmentationData['potentialSegmentationCriterias']) => criterias.map(criteria => ({
    ...criteria,
    type: 'segmentationCriteria',
    on: segmentationPossibleWorkflows
      .find(possibleWorkflow => possibleWorkflow.dataSetId === dataSetId)?.entryPoints
      .find(entryPoint => entryPoint.entryPoint === outputEntities)?.correlationTable?.[criteria.on] || criteria.on
  }))
    .filter(criteria => !filters.find(filter => filter.filterId === criteria.filterId && filter.on === criteria.on));

  const makeFilters = (filtersTmp: SegmentationFilter[]) => filtersTmp.map((filter) => {
    if (filter.on !== outputEntities && filter.on.includes('company') && outputEntities !== 'person2person') {
      return {
        id: `${filter.filterId || ''}-${filter.on || outputEntities}-withAnyCompany`,
        type: 'personWithAnyCompany' as const,
        on: outputEntities,
        subItemSpecs: [
          {
            ...filter,
            id: `${filter.filterId || ''}-${filter.on || outputEntities}`
          }
        ]
      };
    }

    return {
      ...filter,
      id: `${filter.filterId || ''}-${filter.on || outputEntities}`
    };
  });

  const performSegmentation = () => {
    if (dataSetId && outputEntities && workflowId) {
      const request = {
        dataSetId,
        outputEntities,
        workflowId,
        filters: [
          ...baseFilterGroupMember,
          ...baseFiltersFromUrlParams,
          ...(segmentationContext?.segmentation?.data.preFilters.map(preFilter => ({ ...preFilter, id: preFilter.filterId })) as SegmentationRequest['filters'] || []),
          ...makeFilters(filters),
          ...props.filters || []
        ],
        globalFilteringItems: [],
        segmentationCriterias: makeSegmentationCriterias(getCriterias(outputEntities)) as SegmentationCriteriaSpec[],
        entitiesSettings: {
          includeEntities: true,
          findOptions: makeFindOptions({ searchParams, post: true })
        }
      };

      setIsLoading(true);
      segmentationService.perform(request)
        .then((res) => {
          setSegmentationData(res.data ? { ...res.data, request } : undefined);
          setIsLoading(false);
          if (!entryCount && res.data) {
            setEntryCount(res.data.totalCount);
          }
        });
    }
  };

  const addFilter = (filter: SegmentationFilter) => {
    setFilters(prevState => [
      ...prevState,
      {
        ...filter,
        id: `${filter.id || ''}-${makeSegmentationCriterias([filter as unknown as CustomSegmentationData['potentialSegmentationCriterias'][0]])[0].on}`,
        on: makeSegmentationCriterias([filter as unknown as CustomSegmentationData['potentialSegmentationCriterias'][0]])[0].on
      } as SegmentationFilter
    ]);
  };

  const removeFilter = (filterId: string) => {
    if (filters.find(filter => filter.id === filterId)) {
      setFilters(prevState => [
        ...prevState.filter(f => f.id !== filterId)
      ]);
    } else {
      props.removeFilter?.(filterId);
    }
  };

  const reset = () => {
    setFilters([]);
    props.reset?.();
    setSegmentationData(undefined);
  };

  const changeIsEditMode = (isEditModeTmp: boolean) => {
    setIsEditMode(isEditModeTmp);
    if (isEditModeTmp) {
      setIsDraft(true);
    }
  };

  useEffect(() => {
    if (segmentationContext) {
      performSegmentation();
    }
  }, [id, searchParams, workflowId, segmentationContext?.segmentation?._id]);

  useEffect(() => {
    if (segmentationData) {
      if (searchParams.get('page')) {
        searchParams.delete('page');
        setSearchParams(searchParams);
      } else {
        performSegmentation();
      }
    }
  }, [filters, props.filters]);

  useEffect(() => {
    setOutputEntities(getOutputEntities()?.[0]);
  }, [segmentationContext?.segmentation?.data.possibleOutputEntities]);

  useEffect(() => {
    searchParams.delete('page');
    searchParams.delete('pageSize');
    setSearchParams(searchParams);

    if (getWorkflowIds(dataSetId, outputEntities)[0] !== workflowId) {
      setWorkflowId(getWorkflowIds(dataSetId, outputEntities)[0]);
    } else if (segmentationData) {
      performSegmentation();
    }
  }, [outputEntities]);

  useEffect(() => {
    if (segmentationData) {
      setIsDraft(true);
      performSegmentation();
    }
  }, [segmentationContext?.segmentation?.data.potentialSegmentationCriterias.length]);

  return segmentationContext?.segmentation?.data ? (
    <SegmentationDataContext.Provider value={segmentationData || null}>
      <Grid container spacing={4}>
        {!props.children && (
          <SegmentationTitle
            segmentationData={segmentationData}
            isDraft={isDraft}
            setIsDraft={setIsDraft}
            isEditMode={isEditMode}
            setIsEditMode={changeIsEditMode}
            setSegmentationData={setSegmentationData}
          />
        )}
        <Grid
          xs={12}
          alignItems='center'
          sx={
            [...filters, ...(props.filters || [])].length > 0
              ? {
                position: 'sticky',
                top: 53,
                bgcolor: theme.palette.grey['50'],
                zIndex: 3
              }
              : undefined
          }
        >
          <FiltersCriteriasSegmentations
            segmentationData={segmentationData}
            filters={[...filters, ...(props.filters || [])]}
            addFilter={addFilter}
            removeFilter={removeFilter}
            reset={reset}
            listRef={listRef}
          />
        </Grid>
        {outputEntities && getOutputEntities().length > 1 && !props.children &&
          <Grid xs={12}>
            <SelectorLevelsRenderSegmentations
              possibleOutputEntities={getOutputEntities()}
              outputEntities={outputEntities}
              setOutputEntities={setOutputEntities}
              setDataSetId={setDataSetId}
              first
            />
          </Grid>
        }
        <Grid xs={12}>
          <Stack>
            {!props.children &&
              isEditMode &&
              segmentationContext.segmentation && [
              <ConfigBuilderSegmentation
                customSegmentation={segmentationContext.segmentation}
                setCustomSegmentation={segmentationContext.setSegmentation}
                isPrefiltersEnabled={isPrefiltersEnabled}
                setIsPrefiltersEnabled={setIsPrefiltersEnabled}
                access={segmentationContext.segmentation.groups?.[0] || 'me'}
                setAccess={segmentationContext?.updateAccess}
              />,
              isPrefiltersEnabled && (
                <PreFilteringBuilderSegmentation
                  customSegmentationData={
                    segmentationContext.segmentation.data
                  }
                  setCustomSegmentationData={setCustomSegmentationData}
                />
              )
            ]}
            {props.children}
            {props.children && outputEntities && getOutputEntities().length > 1 &&
              <SelectorLevelsRenderSegmentations
                possibleOutputEntities={getOutputEntities()}
                outputEntities={outputEntities}
                setOutputEntities={setOutputEntities}
                setDataSetId={setDataSetId}
                first={!props.children}
              />
            }
            <CustomAccordion
              title={
                <Stack
                  direction='row'
                  spacing={2}
                  alignItems='center'
                  width='100%'
                  pr={4}
                >
                  <Typography variant='h4'>
                    {t('segmentation.render.segmentationCriterias.label')}
                  </Typography>
                  <Chip
                    label={
                      <Stack direction='row' spacing={1} alignItems='center'>
                        <IconFilterSearch size={16} />
                        <Typography variant='body2'>
                          {`${getCriterias(outputEntities)?.length || 0} ${t('segmentation.render.segmentationCriterias.displayed')}`}
                        </Typography>
                      </Stack>
                    }
                    color='primary'
                    size='small'
                  />
                </Stack>
              }
              bgcolor={theme.palette.background.default}
              defaultOpen
            >
              <RenderSegmentationCriterias
                getCriterias={getCriterias}
                setCustomSegmentationData={setCustomSegmentationData}
                updateSegmentation={performSegmentation}
                outputEntities={outputEntities}
                dataSetId={dataSetId}
                segmentationData={
                  isLoading
                    ? ({
                      ...segmentationData,
                      segmentationCriterias: []
                    } as unknown as SegmentationData)
                    : segmentationData
                }
                filters={filters}
                addFilter={addFilter}
                removeFilter={removeFilter}
              />
            </CustomAccordion>
            <CustomAccordion
              title={
                <Stack
                  direction='row'
                  spacing={2}
                  alignItems='center'
                  width='100%'
                  pr={4}
                >
                  <Typography variant='h4'>
                    {t('segmentation.render.entities.label')}
                  </Typography>
                  {segmentationData && !isLoading ? (
                    <Chip
                      label={
                        <Stack direction='row' spacing={1} alignItems='center'>
                          {workflowId === 'person1_link_person2' && (outputEntities === 'person2person' ?
                            <IconCirclesRelation size={16} /> :
                            <IconUsers size={16} />
                          )}
                          {workflowId === 'person1-companies_person2-companies' && <IconBuildings size={16} stroke={theme.palette.primary.dark} />}
                          <Typography variant='body2'>
                            {segmentationData?.totalCount || 0}
                          </Typography>
                        </Stack>
                      }
                      color='primary'
                      size='small'
                    />
                  ) : (
                    <CircularProgress size={20} />
                  )}
                  {workflowId &&
                    getWorkflowIds(dataSetId, outputEntities).length > 1 && (
                    <Select
                      variant='outlined'
                      value={workflowId}
                      onChange={event =>
                        setWorkflowId(
                            event.target.value as SegmentationWorkflowIds
                        )
                      }
                      size='small'
                      onClick={event => event.stopPropagation()}
                      sx={{
                        ml: 'auto !important',
                        bgcolor: theme.palette.grey['50']
                      }}
                    >
                      {getWorkflowIds(dataSetId, outputEntities).map(wi => (
                        <MenuItem key={wi} value={wi}>
                          <Stack
                            direction='row'
                            spacing={2}
                            alignItems='center'
                          >
                            {wi === 'person1_link_person2' && (
                              <IconUsers size={16} />
                            )}
                            {wi === 'person1-companies_person2-companies' && (
                              <IconBuildings size={16} />
                            )}
                            <Typography variant='body1'>
                              {t(
                                `segmentation.render.entities.workflowIds.${wi}`
                              )}
                            </Typography>
                          </Stack>
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                </Stack>
              }
              bgcolor={theme.palette.background.default}
              setRef={setListRef}
              defaultOpen
            >
              {segmentationData ? (
                <EntitiesListsSegmentations
                  data={segmentationData}
                  entityType={segmentationData.objectType}
                />
              ) : (
                <Stack width='100%' p={4} spacing={2}>
                  <Skeleton height={48} />
                  <Skeleton />
                  <Skeleton sx={{ opacity: 0.8 }} />
                  <Skeleton sx={{ opacity: 0.6 }} />
                  <Skeleton sx={{ opacity: 0.4 }} />
                  <Skeleton sx={{ opacity: 0.2 }} />
                </Stack>
              )}
            </CustomAccordion>
          </Stack>
        </Grid>
      </Grid>
    </SegmentationDataContext.Provider>
  ) : (
    <></>
  );
}

export default RenderSegmentations;
