import React, { ReactElement } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { IconDotsVertical } from '@tabler/icons-react';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';

export interface MakeWidgetProps {
  size?: string,
  width?: string,
  height?: string,
  childWidth?: string,
  childHeight?: string,
  title: string,
  logo?: ReactElement,
  icon?: ReactElement,
  link?: string,
  linkLabel?: string,
  children: ReactElement
}

function MakeWidget(props: MakeWidgetProps): ReactElement {
  return (
    <Paper
      variant='hoverElevation1'
      sx={{
        width: props.width,
        height: props.height,
        p: 1
      }}
    >
      <Grid container>
        {props.size !== 'small' &&
          <Grid xs={12}>
            <Grid container spacing={2} direction='row' alignItems='flex-start' flexWrap='nowrap'>
              {props.icon &&
                <Grid p={2} pr={0}>
                  {props.icon}
                </Grid>
              }
              <Grid p={2}>
                <Typography variant='h4' fontWeight={600}>
                  {props.title}
                </Typography>
              </Grid>
              {props.logo ?
                <Grid p={2}>
                  {props.logo}
                </Grid> :
                <Grid xs/>
              }
              <Grid width='min-content' p={1}>
                <IconButton size='small'>
                  <IconDotsVertical size={20}/>
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        }
      </Grid>
      <Grid
        xs={12}
        sx={{
          width: props.childWidth,
          height: props.childHeight,
          overflowY: props.childHeight ? 'auto' : undefined,
          p: 1
        }}
      >
        {props.children}
      </Grid>
      {props.link && props.linkLabel &&
        <Grid xs={12} mt={2}>
          <Link href={props.link}>
            <Typography textAlign='center'>
              {props.linkLabel}
            </Typography>
          </Link>
        </Grid>
      }
    </Paper>
  );
}

export default MakeWidget;
