import React from 'react';
import { BodaccDataBlock, IDataBlock } from '@deecision/dna-interfaces';
import { useTheme } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import { upperFirst } from 'lodash';
import KeyValueWithCard from '@/components/keyvalue/keyvaluewithcard';

function BodaccHeader(props: { datas: IDataBlock<BodaccDataBlock>[], parsedDatas: IDataBlock<BodaccDataBlock>[], selectedCriticity: string, selectCriticity: (c: string) => void }) {
  const theme = useTheme();
  const colorOfCriticity = [
    {
      label: 'neutral',
      color: theme.palette.primary.dark,
      bgColor: theme.palette.primary.light
    },
    {
      label: 'opportunity',
      color: theme.palette.success.dark,
      bgColor: theme.palette.success.light
    },
    {
      label: 'alert',
      color: theme.palette.error.dark,
      bgColor: theme.palette.error.light
    },
    {
      label: 'ambiguous',
      color: theme.palette.warning.dark,
      bgColor: theme.palette.warning.light
    }
  ];

  return (
    <Paper variant='hoverElevation1' sx={{ width: '100%', height: '100px', p: 2 }}>
      <Grid container height='100%' columnSpacing={2}>
        <Grid>
          <KeyValueWithCard
            keyValueCard={{
              bgColor: theme.palette.grey['200'],
              Key: 'Total Elements',
              size: 'big',
              value: props.datas.length
            }}
          />
        </Grid>
        <Grid>
          <KeyValueWithCard
            keyValueCard={{
              bgColor: theme.palette.grey['200'],
              Key: 'Filtered Elements',
              size: 'big',
              value: props.parsedDatas.filter(d => d.data._deecision?.criticity === props.selectedCriticity).length
            }}
          />
        </Grid>
        <Grid xs />
        <Grid container spacing={2}>
          {colorOfCriticity.map(c => (
            <Grid key={c.label} width={96}>
              <KeyValueWithCard
                keyValueCard={{
                  bgColor: c.bgColor,
                  color: c.color,
                  Key: upperFirst(c.label),
                  selected: (props.selectedCriticity !== '' && c.label === props.selectedCriticity) || undefined,
                  disabled: (props.selectedCriticity !== '' && c.label !== props.selectedCriticity) || undefined,
                  action: () => props.selectCriticity(c.label),
                  width: '100%',
                  size: 'big',
                  value: props.parsedDatas.filter(data => data.data._deecision?.criticity === c.label).length.toString()
                }}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Paper>
  );
}

export default BodaccHeader;
