import React, { ReactElement, useMemo, useState } from 'react';
import {
  SegmentationCriteriaInfo,
  ValuesOrRanges,
  SegmentationPossibleDisplayTypes
} from '@deecision/dna-interfaces';
import Paper from '@mui/material/Paper';
import { useTranslation } from 'react-i18next';
import { getElementAtEvent } from 'react-chartjs-2';
import type { Chart } from 'chart.js';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
import { darken, useTheme } from '@mui/material/styles';
import Collapse from '@mui/material/Collapse';
import ListItemIcon from '@mui/material/ListItemIcon';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import {
  IconArrowsSplit2,
  IconChartBar,
  IconChartPie,
  IconChartPpf,
  IconDotsVertical,
  IconTrash
} from '@tabler/icons-react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import { lowerFirst } from 'lodash';
import { SegmentationFilter } from '@deecision/dna-interfaces/dist/segmentation/segmentationFilters';
import { SegmentationData } from '@deecision/dna-interfaces/dist/segmentation/segmentation';
import Chip from '@mui/material/Chip';
import { BaseChartsProps } from '../../../../../components/charts/types';
import PieCharts from '../../../../../components/charts/pie';
import YesNoCharts from '../../../../../components/charts/yesno';
import BarCharts from '../../../../../components/charts/bar';
import {
  BaseSegmentationProps,
  PotentialSegmentationCriteria
} from '../../types.segmentations';

export interface ChartDispatchSegmentationsProps extends BaseSegmentationProps {
  criteria: PotentialSegmentationCriteria & SegmentationData['segmentationCriterias'][0],
  handleDelete?: (segmentationCriteria:  PotentialSegmentationCriteria & SegmentationData['segmentationCriterias'][0]) => void,
  handleChangeDisplayType?: (displayType: SegmentationPossibleDisplayTypes, segmentationCriteria: PotentialSegmentationCriteria & SegmentationData['segmentationCriterias'][0]) => void,
  totalCount: number
}

export const baseChartWidth = 480;

const multiRangesEnable = false;
const multiValuesEnable = false;

function DispatchChartsSegmentations(props: ChartDispatchSegmentationsProps): ReactElement {
  const { t } = useTranslation();
  const theme = useTheme();
  const [selected, setSelected] = useState<ValuesOrRanges>();
  const [halfPieChart, setHalfPieChart] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const chartHeight = {
    pieChart: 1.3,
    barChart: 1.7,
    yesNo: 2.5
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const setValuesOrRanges = (valuesOrRanges: ValuesOrRanges) => {
    setSelected(undefined);
    if (valuesOrRanges && (valuesOrRanges.values && valuesOrRanges.values.length > 0 || valuesOrRanges.ranges && valuesOrRanges.ranges.length > 0)) {
      // if (props.criteria?.valueType === 'countryCode[]' && valuesOrRanges.values && isArray(valuesOrRanges.values) && valuesOrRanges.values.length > 0) {
      //   valuesOrRanges.values = valuesOrRanges.values[0] as unknown as string[];
      // }
      props.addFilter({
        id: props.criteria.id || 'unknown',
        filterId: (props.criteria?.filterSpec ? undefined : props.criteria.filterId) || 'unknown',
        label: props.criteria.displayInfo.label || props.criteria.filterLabel,
        on: (props.criteria?.filterSpec ? props.criteria.filterSpec.on : props.criteria?.on) || 'person1',
        values: undefined,
        ranges: undefined,
        valueType: props.criteria.displayInfo.valueType || props.criteria?.valueType || 'string' as const,
        type: props.criteria?.filterSpec ? props.criteria.filterSpec.type : 'filter' as const,
        subItemSpecs: props.criteria?.filterSpec ? props.criteria.filterSpec.subItemSpecs : undefined,
        ...valuesOrRanges
      } as SegmentationFilter);
    } else {
      props.removeFilter(props.criteria.id);
    }
  };

  const setValues = (baseValue: boolean | string | number, unique?: boolean) => {
    setSelected({
      values: selected?.values?.find(value => value === baseValue) !== undefined ?
        selected?.values?.filter(value => value !== baseValue) :
        selected?.values && !unique && multiValuesEnable ? [...selected.values, baseValue] : [baseValue]
    });
  };

  const setRanges = (baseRange: { min: number, max: number }) => {
    setSelected({
      ranges: selected?.ranges?.find(range => range.max === baseRange.max && range.min === baseRange.min) ?
        selected?.ranges?.filter(range => !(range.max === baseRange.max && range.min === baseRange.min)) :
        selected?.ranges && multiRangesEnable ? [...selected.ranges, baseRange] : [baseRange]
    });
  };

  const onClick = (event?: React.MouseEvent<HTMLCanvasElement, MouseEvent>, chart?: Chart, baseValue?: boolean, echarts?: boolean, echartsData? : {range: string, value: string}) => {
    // REFACTO THIS FUNCTION TO USE ECHARTS //
    if (echarts && echartsData) {
      const selectedRangeTmp = echartsData.range;
      const selectedValueTmp = echartsData.value;

      if (selectedRangeTmp !== undefined) {
        const [min, max] = selectedRangeTmp.split('-').map(Number);
        setRanges({ min, max });
      } else if (selectedValueTmp !== undefined) {
        setValues(selectedValueTmp);
      } else if (baseValue !== undefined) {
        setValues(baseValue, true);
      }
    }

    if (chart && event && props.criteria?.values && getElementAtEvent(chart, event)[0]) {
      const selectedRangeTmp = props.criteria?.values[getElementAtEvent(chart, event)[0].index].range;
      const selectedValueTmp = props.criteria?.values[getElementAtEvent(chart, event)[0].index].value;

      if (selectedRangeTmp !== undefined) {
        setRanges(selectedRangeTmp);
      } else if (selectedValueTmp !== undefined) {
        setValues(selectedValueTmp);
      }
    } else if (baseValue !== undefined) {
      setValues(baseValue, true);
    }
  };

  const Charts: Record<SegmentationCriteriaInfo['displayInfo']['displayType'], (props: BaseChartsProps) => ReactElement> = {
    pieChart: elementProps => PieCharts({ ...elementProps, halfPieChart }),
    yesNo: elementProps => YesNoCharts(elementProps),
    barChart: elementProps => BarCharts({ ...elementProps })
  };

  const Chart = useMemo(() => Charts[props.criteria.displayInfo.displayType], [props.criteria]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await fetch('https://fastly.jsdelivr.net/npm/emoji-flags@1.3.0/data.json');
  //       const data = await response.json();
  //       setFlagsData(data);
  //     } catch (error) {
  //       console.error('Error fetching data:', error);
  //     }
  //   };

  //   fetchData();
  // }, []);

  return (
    <Paper
      sx={{
        // maxWidth: '800px',
        minWidth: baseChartWidth,
        width: '100%',
        bgcolor: theme.palette.grey[50]
      }}
    >
      <Stack spacing={2}>
        <Stack direction='row' spacing={2} alignItems='center' justifyContent='space-between'>
          <Stack direction='row' spacing={2} alignItems='center'>
            {props.criteria?.displayInfo?.displayType === 'barChart' && (halfPieChart ? <IconChartPpf /> : <IconChartBar />)}
            {props.criteria?.displayInfo?.displayType === 'pieChart' && <IconChartPie />}
            {props.criteria?.displayInfo?.displayType === 'yesNo' && <IconArrowsSplit2 />}
            <Typography variant='h5' p={1}>{props.criteria.displayInfo.label || props.criteria.filterLabel}</Typography>
            <Chip
              label={t(`entities.${props.criteria.on}.multiple`)}
              color={props.criteria.on === 'person' ? 'primary' : props.criteria.on === 'company' ? undefined : 'warning'}
              sx={props.criteria.on === 'company' ? {
                bgcolor: theme.palette.secondary.light,
                color: theme.palette.secondary.dark
              } : undefined}
              size='small'
            />
          </Stack>
          <IconButton
            size='small'
            id='chart-menu-button'
            onClick={handleClick}
            aria-controls={open ? 'chart-menu' : undefined}
            aria-haspopup='true'
            aria-expanded={open ? 'true' : undefined}
          >
            <IconDotsVertical size={20} />
          </IconButton>
          <Menu
            id='chart-menu'
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'chart-menu-button'
            }}
          >
            {props.criteria?.displayInfo?.displayType !== 'barChart' && props.criteria?.possibleDisplayTypes?.includes('barChart') &&
              <MenuItem
                onClick={() => {
                  props.handleChangeDisplayType?.('barChart', props.criteria);
                  handleClose();
                }}
              >
                <ListItemIcon>
                  <IconChartBar color={theme.palette.text.primary} />
                </ListItemIcon>
                <ListItemText>
                  {`${t('segmentation.builder.displayTypes.transformTo')} ${lowerFirst(t('segmentation.builder.displayTypes.barChart') || '')}`}
                </ListItemText>
              </MenuItem>
            }
            {(props.criteria?.displayInfo?.displayType !== 'pieChart' || halfPieChart) && props.criteria?.possibleDisplayTypes?.includes('pieChart') &&
              <MenuItem
                onClick={() => {
                  setHalfPieChart(false);
                  props.handleChangeDisplayType?.('pieChart', props.criteria);
                  handleClose();
                }}
              >
                <ListItemIcon>
                  <IconChartPie color={theme.palette.text.primary} />
                </ListItemIcon>
                <ListItemText>
                  {`${t('segmentation.builder.displayTypes.transformTo')} ${lowerFirst(t('segmentation.builder.displayTypes.pieChart') || '')}`}
                </ListItemText>
              </MenuItem>
            }
            {(props.criteria?.displayInfo?.displayType !== 'pieChart' || !halfPieChart) && props.criteria?.possibleDisplayTypes?.includes('pieChart') &&
              <MenuItem
                onClick={() => {
                  setHalfPieChart(true);
                  props.handleChangeDisplayType?.('pieChart', props.criteria);
                  handleClose();
                }}
              >
                <ListItemIcon>
                  <IconChartPpf color={theme.palette.text.primary} />
                </ListItemIcon>
                <ListItemText>
                  {`${t('segmentation.builder.displayTypes.transformTo')} ${lowerFirst(t('segmentation.builder.displayTypes.donut') || '')}`}
                </ListItemText>
              </MenuItem>
            }
            {props.criteria?.displayInfo?.displayType !== 'yesNo' && props.criteria?.possibleDisplayTypes?.includes('yesNo') &&
              <MenuItem
                onClick={() => {
                  props.handleChangeDisplayType?.('yesNo', props.criteria);
                  handleClose();
                }}
              >
                <ListItemIcon>
                  <IconArrowsSplit2 color={theme.palette.text.primary} />
                </ListItemIcon>
                <ListItemText>
                  {`${t('segmentation.builder.displayTypes.transformTo')} ${lowerFirst(t('segmentation.builder.displayTypes.yesNo') || '')}`}
                </ListItemText>
              </MenuItem>
            }
            {props.handleDelete &&
              <MenuItem
                onClick={() => {
                  props.handleDelete?.(props.criteria);
                  handleClose();
                }}
              >
                <ListItemIcon>
                  <IconTrash color={theme.palette.error.main} />
                </ListItemIcon>
                <ListItemText sx={{ pr: 4, color: theme.palette.error.main }}>
                  {t('segmentation.builder.custom.delete')}
                </ListItemText>
              </MenuItem>
            }
          </Menu>
        </Stack>
        <Box
          sx={{
            height: baseChartWidth / chartHeight[props.criteria.displayInfo.displayType],
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          {props.criteria.values ?
            <Chart
              {...props}
              datas={props.criteria?.values || []}
              on={props.criteria.on === 'person' ? t('entities.persons.label') : props.criteria.on === 'company' ? t('entities.companies.label') : props.criteria.on === 'person2person' ? t('entities.links.multiple') : t('common.utils.unknown')}
              selected={selected}
              dataType={props.criteria?.valueType}
              onClick={onClick}
            /> :
            <Stack direction='row' spacing={1} alignItems='center'>
              {props.criteria.displayInfo.displayType === 'pieChart' ? [
                <Skeleton variant='circular' width={baseChartWidth / (chartHeight.pieChart * 1.3)} height={baseChartWidth / (chartHeight.pieChart * 1.3)} />,
                <Stack spacing={2} pl={8}>
                  {[...Array(7)].map((_, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <Skeleton key={index} variant='rounded' width={96} height={16} />
                  ))}
                </Stack>
              ] :
                [...Array(7)].map((_, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Skeleton key={index} variant='rectangular' width={baseChartWidth / 6.5} height='50%' />
                ))
              }
            </Stack>
          }
        </Box>
        <Collapse in={selected && (selected.values || selected.ranges || []).length > 0}>
          <Grid container spacing={2} p={2}>
            <Grid xs>
              <Button
                onClick={() => setSelected(undefined)}
                sx={{
                  '&:hover': {
                    color: theme.palette.error.main
                  }
                }}
                fullWidth
              >
                {t('segmentation.filters.cancel')}
              </Button>
            </Grid>
            <Grid xs>
              <Button
                variant='contained'
                onClick={() => (selected ? setValuesOrRanges(selected) : undefined)}
                sx={{
                  bgcolor: theme.palette.success.main,
                  borderColor: theme.palette.success.dark,
                  '&:hover': {
                    bgcolor: darken(theme.palette.success.main, 0.3),
                    borderColor: theme.palette.success.main
                  }
                }}
                fullWidth
              >
                {t('segmentation.filters.apply')}
              </Button>
            </Grid>
          </Grid>
        </Collapse>
      </Stack>
    </Paper>
  );
}

export default DispatchChartsSegmentations;
