import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { CompanyEntity } from '@deecision/dna-interfaces';
import { useTranslation } from 'react-i18next';
import Tooltip from '@mui/material/Tooltip';
import Chip from '@mui/material/Chip';
import { Stack } from '@mui/material';
import Divider from '@mui/material/Divider';
import { IconDatabase, IconHome, IconRefresh } from '@tabler/icons-react';
import { upperFirst } from 'lodash';
import Typography from '@mui/material/Typography';
import Flags from '@/components/flags';
import { CompanyGetter } from '@/main/providers/getter';

function CompanyEntitiesChips(props: { entity: CompanyEntity, size?: 'small' | 'medium' }): ReactElement[] {
  const { t } = useTranslation();
  const [entity, setEntity] = useState<CompanyEntity>(props.entity as CompanyEntity);
  const companyGetter = useMemo(() => new CompanyGetter({
    data: entity,
    errorMsg: t('common.utils.unknown')
  }), [entity]);

  useEffect(() => {
    setEntity(props.entity as CompanyEntity);
  }, [props.entity]);

  return ([
    <Tooltip
      key='common.identity.country'
      title={`${t('common.identity.country')}: ${companyGetter.getCountry()}`}
      arrow
      placement='top'
    >
      <Chip
        label={
          <Stack direction='row' height='20px' spacing={1} alignItems='center' divider={<Divider orientation='vertical' />} sx={{ mr: 1 }}>
            {Flags({ countries: [companyGetter.getCountryCode(true) || ''], size: props.size })}
          </Stack>
        }
        icon={<IconHome size={props.size === 'small' ? 18 : 22} style={{ marginLeft: props.size === 'small' ? '8px' : '12px', marginRight: '-4px' }} />}
        size={props.size}
      />
    </Tooltip>,
    <Chip
      key='state'
      label={upperFirst(companyGetter.getState())}
      color={companyGetter.getState() === 'open' ? 'success' : 'error'}
      size={props.size}
    />,
    <Tooltip title={t('common.utils.completeness')} arrow placement='top'>
      <Chip
        key='completion'
        label={
          <Stack direction='row' spacing={1} alignItems='center'>
            <IconDatabase size={20} />
            <Typography>{`${Math.floor(Math.random() * (55 - 35) + 35)}%`}</Typography>
          </Stack>
        }
        color='primary'
        size={props.size}
      />
    </Tooltip>,
    ...companyGetter.getTags().filter(tag => tag.family !== 'batchTag').map(tag => (
      <Tooltip key={`${tag.value}-${tag.family}`} title={tag.family === 'companyType' && tag.value === 'listedCompany' ? t('entities.tags.listed.company') : t(`tags.families.${tag.family}`)} arrow placement='top'>
        {tag.family === 'companyType' && tag.value === 'listedCompany' ?
          <Chip sx={{ '& .MuiChip-label': { fontWeight: '600', pl: 4, pr: 4 } }} color='error' size={props.size} label='L' /> :
          <Chip label={t(`tags.values.${tag.value}`)} size={props.size} />
        }
      </Tooltip>
    )),
    <Tooltip
      key='common.dates.lastUpdate'
      title={t('common.dates.lastUpdate')}
      arrow
      placement='top'
    >
      <Chip
        label={companyGetter.getDataDate()}
        icon={<IconRefresh size={props.size === 'small' ? 18 : 22} style={{ marginLeft: props.size === 'small' ? '8px' : '12px', marginRight: '-4px' }} />}
        size={props.size}
      />
    </Tooltip>
  ]);
}

export default CompanyEntitiesChips;
