import { ReactElement } from 'react';
import { AxiosInstance } from 'axios';
import { AxiosInstanceModifier } from '../api';

type CustomHeader = {key: string, value: string};

export interface AxiosWrapperProps {
  baseApiUrl: string,
  apiUrlSuffix?: string,
  customHeaders?: CustomHeader[],
  axiosInstance: AxiosInstance
}

function AxiosWrapper(props: AxiosWrapperProps & { children: ReactElement }): ReactElement {
  const axiosInstanceModifier = new AxiosInstanceModifier(props.axiosInstance);
  const apiUrlSuffix= props.apiUrlSuffix?.charAt(0) === '/' ?
    props.apiUrlSuffix.substring(1) : props.apiUrlSuffix;

  axiosInstanceModifier.changeBaseUrl(apiUrlSuffix ? `${props.baseApiUrl}/${apiUrlSuffix}` : props.baseApiUrl);
  axiosInstanceModifier.addHeader('Content-Type', 'application/json');

  if (props.customHeaders && props.customHeaders.length > 0) {
    props.customHeaders.forEach((customHeader) => {
      axiosInstanceModifier.addHeader(customHeader.key, customHeader.value);
    });
  }

  return props.children;
}

export default AxiosWrapper;
