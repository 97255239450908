import React, { ReactElement, useEffect, useState } from 'react';
import TableFooter from '@mui/material/TableFooter';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import {
  IconChevronLeft,
  IconChevronRight,
  IconChevronsLeft,
  IconChevronsRight
} from '@tabler/icons-react';
import { useSearchParams } from 'react-router-dom';

interface TablePaginationActionsProps {
  count: number,
  page: number,
  rowsPerPage: number,
  onPageChange: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void
}

function TablePaginationActions(props: TablePaginationActionsProps) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 4, mr: 4 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label='first page'
        size='small'
        sx={{ ml: 1, mr: 1 }}
      >
        {theme.direction === 'rtl' ? <IconChevronsRight size={20} /> : <IconChevronsLeft size={20} />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label='previous page'
        size='small'
        sx={{ ml: 1, mr: 1 }}
      >
        {theme.direction === 'rtl' ? <IconChevronRight size={20} /> : <IconChevronLeft size={20} />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='next page'
        size='small'
        sx={{ ml: 1, mr: 1 }}
      >
        {theme.direction === 'rtl' ? <IconChevronLeft size={20} /> : <IconChevronRight size={20} />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='last page'
        size='small'
        sx={{ ml: 1, mr: 1 }}
      >
        {theme.direction === 'rtl' ? <IconChevronsLeft size={20} /> : <IconChevronsRight size={20} />}
      </IconButton>
    </Box>
  );
}

function EvenOddFooter(props: { totalCount: number, basePageSize?: number }): ReactElement {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(props.basePageSize || 20);
  const [searchParams, setSearchParams] = useSearchParams();

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    searchParams.forEach((value, key) => {
      if (key === 'page') {
        setPage(parseInt(value, 10));
      }
      if (key === 'pageSize') {
        setRowsPerPage(parseInt(value, 10));
      }
    });
  }, []);

  useEffect(() => {
    if (page === 0) {
      if (searchParams.get('page')) {
        searchParams.delete('page');
        setSearchParams(searchParams);
      }
    } else {
      searchParams.set('page', page.toString());
      setSearchParams(searchParams);
    }
  }, [page]);

  useEffect(() => {
    if (rowsPerPage === (props.basePageSize || 20)) {
      if (searchParams.get('pageSize')) {
        searchParams.delete('pageSize');
        setSearchParams(searchParams);
      }
    } else {
      searchParams.set('pageSize', rowsPerPage.toString());
      setSearchParams(searchParams);
    }
  }, [rowsPerPage]);

  return (
    <TableFooter>
      <TableRow>
        <TablePagination
          sx={{
            border: 'none',
            '& > *': {
              justifyContent: 'center',
              display: 'flex'
            }
          }}
          rowsPerPageOptions={[5, 10, 20, 50, 100, 200, 500, 1000, { label: 'All', value: props.totalCount }]}
          colSpan={3}
          count={props.totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          slotProps={{
            select: {
              inputProps: { 'aria-label': 'rows per page' },
              native: true
            }
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}

        />
      </TableRow>
    </TableFooter>
  );
}

export default EvenOddFooter;
