import React, { ReactElement } from 'react';
import { AxiosInstance } from 'axios';
import i18n from 'i18next';
import { I18nextProvider, withTranslation, WithTranslation } from 'react-i18next';
import { AxiosInstanceModifier } from '../api';
import { i18nPossibleLanguages } from '../i18n/config';
import FallbackComponent from '@/router/fallback';
import { getLanguageStorage } from '../i18n/localstorage';

export interface I18nProps {
  app: string,
  axiosInstance: AxiosInstance
}

interface IProps extends WithTranslation {
  children: ReactElement
}

interface IState {
  state?: unknown
}

function MyComponent(props: IProps & IState): ReactElement {
  return props.tReady ? props.children : <FallbackComponent />;
}

function I18nWrapper(props: I18nProps & { children: ReactElement }): ReactElement {
  const axiosInstanceModifier = new AxiosInstanceModifier(props.axiosInstance);
  const Extended = withTranslation()(MyComponent);
  const lng = getLanguageStorage();

  i18n.loadNamespaces(props.app).then(() => undefined);
  i18n.changeLanguage(lng?.lang || i18nPossibleLanguages[0].global)
    .then(() => {
      axiosInstanceModifier.addLanguageHeader(
        `${(i18nPossibleLanguages.find(lang => lang.global === (lng?.lang || i18nPossibleLanguages[0].global)) || i18nPossibleLanguages[0]).version},${(i18nPossibleLanguages.find(lang => lang.global === (lng?.lang || i18nPossibleLanguages[0].global)) || i18nPossibleLanguages[0]).global.split('/')[0]};`
      );
    })
    .catch(() => {
      axiosInstanceModifier.addLanguageHeader(
        `${i18nPossibleLanguages[0].version},${i18nPossibleLanguages[0].global};`
      );
    });

  return (
    <I18nextProvider i18n={i18n} defaultNS={props.app}>
      <Extended>
        {props.children}
      </Extended>
    </I18nextProvider>
  );
}

export default I18nWrapper;
