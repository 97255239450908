
import { ApiResponse } from '@deecision/deecision-interfaces';
import { BaseElementsService } from '@/api';
import axiosInstance from '../../../../api';

class VennDiagramServices<T> extends BaseElementsService<T> {
  secondUrlPrefix?: string;

  constructor(props?: { elementPrefixUrl?: string }) {
    super({ elementPrefixUrl: `venn-diagrams`, axiosInstance });

    this.secondUrlPrefix = props?.elementPrefixUrl;
  }

  getGroupDescs<U>(): Promise<ApiResponse<U[]>> {
    return this.axiosInstance.get(this.constructUrl('available-groups'))
      .then(res => this.handleResponse(res));
  }

  execute<U>(id: string, context: unknown): Promise<ApiResponse<U>> {
    return this.axiosInstance.post(this.constructUrl(`${id}/execute`), context)
      .then(res => this.handleResponse(res));
  }

  // getList(): Promise<ApiResponse<T[]>> {
  //   return this.axiosInstance.get(this.constructUrl(''))
  //     .then(res => this.handleResponse(res));
  // }
  // perform(props: SegmentationRequest): Promise<ApiResponse<SegmentationData>> {
  //   const data: SegmentationRequest = {
  //     ...props
  //   };

  //   return this.axiosInstance.post(this.constructUrl('performSegmentation5').replace(`/${this.secondUrlPrefix}` || '', ''), data)
  //     .then(res => this.handleResponse(res));
  // }
}

export default VennDiagramServices;
