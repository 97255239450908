import React, { useState, useEffect } from 'react';
import { IconArrowLeft } from '@tabler/icons-react';
import MenuItem from '@mui/material/MenuItem';
import Switch from '@mui/material/Switch';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { translate } from '../translate';
import { useLayoutStorageContext } from '../hooks/layoutstorage';

interface AppSettingsProps {
  language: string,
  setOpenSettings: React.Dispatch<React.SetStateAction<boolean>>
}

function AppSettings(props: AppSettingsProps) {
  const { layoutStorage, setLayoutStorage } = useLayoutStorageContext();
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [displayBreadcrumbs, setDisplayBreadcrumbs] = useState(true);
  const [dispLanguage, setDispLanguage] = useState(true);

  useEffect(() => {
    if (layoutStorage) {
      setSidebarOpen(layoutStorage.sidebar);
      setDisplayBreadcrumbs(layoutStorage.breadcrumbs);
      setDispLanguage(layoutStorage.dispLanguage);
    }
  }, [layoutStorage]);

  const handleChangeSidebar = () => {
    setLayoutStorage({
      sidebar: !sidebarOpen,
      breadcrumbs: displayBreadcrumbs,
      dispLanguage
    });
  };

  const handleChangeBreadcrumbs = () => {
    setLayoutStorage({
      sidebar: sidebarOpen,
      breadcrumbs: !displayBreadcrumbs,
      dispLanguage
    });
  };

  const handleChangeDispLanguage = () => {
    setLayoutStorage({
      sidebar: sidebarOpen,
      breadcrumbs: displayBreadcrumbs,
      dispLanguage: !dispLanguage
    });
  };

  return (
    <>
      <MenuItem
        sx={{
          mb: 4,
          '&.MuiButtonBase-root:hover': {
            bgcolor: 'transparent',
            cursor: 'default'
          }
        }}
        disableTouchRipple
        disableRipple
      >
        <IconButton
          sx={{
            ml: -2,
            mt: -1
          }}
          onClick={() => props.setOpenSettings(false)}
          size='small'
        >
          <IconArrowLeft size={20} />
        </IconButton>
        <Typography sx={{ mt: -1, ml: 3 }}>
          {translate({ en: 'Application Settings', fr: 'Paramètres de l\'application', language: props.language })}
        </Typography>
      </MenuItem>
      <MenuItem
        sx={{
          mt: 2,
          mb: 2,
          pl: 4,
          pr: 4,
          '&.MuiButtonBase-root:hover': {
            bgcolor: 'transparent',
            cursor: 'default'
          }
        }}
        disableTouchRipple
        disableRipple
      >
        <Typography>
          {translate({ en: 'Sidebar open by default', fr: 'Sidebar ouverte par défaut', language: props.language })}
        </Typography>
        <Switch checked={sidebarOpen} onChange={handleChangeSidebar} sx={{ ml: 'auto' }} size='small' />
      </MenuItem>
      <MenuItem
        sx={{
          mt: 2,
          mb: 2,
          pl: 4,
          pr: 4,
          '&.MuiButtonBase-root:hover': {
            bgcolor: 'transparent',
            cursor: 'default'
          }
        }}
        disableTouchRipple
        disableRipple
      >
        <Typography>
          {translate({ en: 'Display breadcrumbs', fr: 'Afficher les breadcrumbs', language: props.language })}
        </Typography>
        <Switch checked={displayBreadcrumbs} onChange={handleChangeBreadcrumbs} sx={{ ml: 'auto' }} size='small' />
      </MenuItem>
      <MenuItem
        sx={{
          mt: 2,
          mb: 2,
          pl: 4,
          pr: 4,
          '&.MuiButtonBase-root:hover': {
            bgcolor: 'transparent',
            cursor: 'default'
          }
        }}
        disableTouchRipple
        disableRipple
      >
        <Typography>
          {translate({ en: 'Display Languages', fr: 'Afficher les langues', language: props.language })}
        </Typography>
        <Switch checked={dispLanguage} onChange={handleChangeDispLanguage} sx={{ ml: 'auto' }} size='small' />
      </MenuItem>
    </>
  );
}

export default AppSettings;
