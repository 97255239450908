import React, { ReactElement, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import Link from '@mui/material/Link';
import { IconTrash } from '@tabler/icons-react';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import { useNavigate } from 'react-router-dom';
import { CurrencyFormatterProps } from '@/utils';
import dnaConfig from '../../../../config/dna.config.json';
import { Column, Row } from '@/components/tables/types';
import EvenOddTable from '@/components/tables/evenodd/table';
import { VennDiagramConfig } from '../types.vennDiagram';
import VennDiagramServices from './vennDiagram.services';
import CustomDialog from '../../../../components/dialog';

type ListVennDiagramType = {
  totalCount: number,
  actions: ReactElement[] | undefined,
  actionsPadding: string | undefined,
  service: VennDiagramServices<unknown>,
  data: VennDiagramConfig[]
}

function ListVennDiagram(props: ListVennDiagramType): ReactElement {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);
  const [diagramDataDelete, setDiagramDataDelete] = useState<VennDiagramConfig | undefined>();

  const columns: Column<CurrencyFormatterProps>[] = [
    {
      id: 'name',
      label: t('common.identity.name'),
      sortPath: 'props.children'
    },
    {
      id: 'objectType',
      label: 'ObjectType',
      align: 'right'
    },
    {
      id: 'segments',
      label: 'Segments',
      align: 'right'
    },
    {
      id: 'actions',
      label: t('common.utils.actions'),
      align: 'right'
    }
  ];

  const [rows, setRows] = useState<Row<CurrencyFormatterProps>[]>([]);

  const onDelete = () => {
    diagramDataDelete?._id &&
      props.service?.delete?.(diagramDataDelete._id)
        .then(() => {
          setOpenDialog(false);
          setDiagramDataDelete(undefined);
          navigate(0);
        });
  };

  useEffect(() => {
    setRows(props.data.map(vennDiagramData => ({
      id: vennDiagramData._id,
      name: <Link href={`${dnaConfig.routes.admin.factory.vennDiagram.baseUrl}/${vennDiagramData._id as string}`}>{vennDiagramData.label}</Link>,
      objectType: vennDiagramData.objectType,
      segments: vennDiagramData.segments?.length,
      actions: <Stack direction='row' justifyContent='flex-end'>
        <IconButton>
          <IconTrash
            onClick={
              () => {
                setOpenDialog(true);
                setDiagramDataDelete(vennDiagramData);
              }
            }
          />
        </IconButton>
      </Stack>
    })));
  }, [props.data]);

  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <CustomDialog
        open={openDialog}
        handleClose={()=>{
          setOpenDialog(false);
        }}
        title='Are you sure you want to delete this configuration ?'
        content={`You are currently deleting : ${diagramDataDelete?.label}`}
        buttonConfirmationText='Delete'
        buttonConfirmationAction={onDelete}
        buttonCancelText='Cancel'
      />
      <EvenOddTable<CurrencyFormatterProps> label='VennDiagram' {...props} columns={columns} rows={rows} />
    </Box>
  );
}

export default ListVennDiagram;
