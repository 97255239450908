import React from "react";
import { Box, FormControl, FormHelperText, FormLabel, IconButton,  Stack, TextField, Typography, useTheme } from "@mui/material";
import Grid from "@mui/system/Unstable_Grid";
import { IconFilter, IconX } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { SelectFiltersToAddModalProps } from "./types.filter.modal";
import { VennDiagramSegment } from "../../types.vennDiagram";
import ActionBehavior from "../../components/venn.action.behavior";
import ActionColor from "../../components/venn.action.color";

type SelectFiltersToAddModalHeaderProps = {
  onClose: () => void,
  segment: VennDiagramSegment | undefined,
  handleSegmentChange: (propertyLabel: keyof VennDiagramSegment, value: string) => void,
  segmentRefColor: string | undefined
};

function SelectFiltersToAddModalHeader(props: SelectFiltersToAddModalProps & SelectFiltersToAddModalHeaderProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const usedColors = props.segment?.color ? props.usedColorsBySegments.filter(color => color !== props.segmentRefColor) : props.usedColorsBySegments;

  return (
    <>
      <Grid xs={12} display='flex' flexDirection='row' justifyContent='space-between'>
        <Stack display='flex' flexDirection='row' alignItems='center'>
          <IconFilter />
          <Typography ml={2} fontSize={16} fontWeight={600} color='grey.700'>{`${t('segmentation.vennDiagram.builder.modalTitle')}`}</Typography>
        </Stack>
        <IconButton
          size='medium'
          onClick={props.onClose}
          sx={{ borderRadius: 50, height: '35px', width: '35px' }}
        >
          <IconX color='grey'/>
        </IconButton>
      </Grid>
      <Grid container spacing={4} direction='row' alignItems='center' ml={5}>
        <Grid>
          <Box
            component='form'
            sx={{
              '& > :not(style)': { m: 1, width: '25ch' }
            }}
            noValidate
            autoComplete='off'
          >
            <FormControl required>
              <FormLabel id='demo-controlled-radio-buttons-group' sx={{ fontSize: '16px', color: !props.segment?.label ? theme.palette.error.main : theme.palette.text.primary }}>
              Segment Label
              </FormLabel>
              <TextField
                variant='standard'
                hiddenLabel
                value={props.segment?.label}
                onClick={e => e.stopPropagation()}
                autoFocus
                onChange={e => props.handleSegmentChange('label', e.target.value)}
              />
              {/* TODO: Add a condition here based on the new logic */}
              {!props.segment?.label && <FormHelperText>{t('common.utils.required')}</FormHelperText>}
            </FormControl>
          </Box>
        </Grid>

        {props.segment &&
          <Grid>
            <ActionBehavior segment={props.segment} modifyBehavior={props.handleSegmentChange} hideUnion={props.segment.groupIds.length <= 1}/>
          </Grid>
        }
        {props.segment &&
          <Grid container>
            <ActionColor segment={props.segment} modifyColor={props.handleSegmentChange} usedColors={usedColors} />
          </Grid>
        }
      </Grid>
    </>
  );
}

export default SelectFiltersToAddModalHeader;
