import { TabFunction } from "@/layouts/tabs";
import dnaConfig from "../../../../../../config/dna.config.json";
import {
  bodaccFunction,
  financialFunction,
  newsFunction,
  veegilenzFunction
} from "../../../components/tabs/tabsfunctions.tabs";
import { veegilenzCompanyPaths } from "../../../../../containers/veegilenz/veegilenz";

export const tabsFunctions: Record<string, TabFunction> = {
  [dnaConfig.routes.companies.tabs.officialInformations.baseUrl]: bodaccFunction,
  [dnaConfig.routes.companies.tabs.financial.baseUrl]: financialFunction,
  [dnaConfig.routes.companies.tabs.news.baseUrl]: newsFunction,
  [dnaConfig.routes.persons.tabs.veegilenz.baseUrl]: (props: { id: string, data?: unknown }) => veegilenzFunction({ ...props, paths: veegilenzCompanyPaths })
};
