import { ApiArrayResponse } from '@deecision/deecision-interfaces';
import { EntityRef } from '@deecision/dna-interfaces';
import { EntityLinksQueryAggregatorService } from '../../../../../api/services/query';
import { WEBAPP_NAME } from '../../../../../env/env';
import dnaConfig from '../../../../../config/dna.config.json';

class RelationsServices<T> extends EntityLinksQueryAggregatorService<T> {
  constructor(props: { entityType?: string, toEntityType?: string }) {
    super(props);
  }

  get(): Promise<ApiArrayResponse<T>> {
    const { search } = window.location;

    const entity1: EntityRef = {
      entityId: decodeURIComponent(decodeURIComponent(search.replace('step=3&', '').split('&')[0]?.replace('?entity1=', ''))),
      entityType: decodeURIComponent(decodeURIComponent(search.replace('step=3&', '').split('&')[1]?.replace('entity1Type=', '')))
    };
    const entity2: EntityRef = {
      entityId: decodeURIComponent(decodeURIComponent(search.replace('step=3&', '').split('&')[2]?.replace('entity2=', ''))),
      entityType: decodeURIComponent(decodeURIComponent(search.replace('step=3&', '').split('&')[3]?.replace('entity2Type=', '')))
    };

    if (entity1.entityId === 'undefined' || entity1.entityType === 'undefined' || entity2.entityId === 'undefined' || entity2.entityType === 'undefined') {
      window.location.replace(`/${WEBAPP_NAME}${dnaConfig.routes.relations.relativeUrl}`);
    }

    return super.findLinks({
      query: {
        type: undefined
      },
      entity1,
      entity2,
      expandEntity1: true,
      expandEntity2: true
    });
  }

  search(query: string): Promise<ApiArrayResponse<T>> {

    return this.axiosInstance.get(this.constructUrl('search'), { params: { name: query } })
      .then(res => this.handleArrayResponse<T>(res));
  }
}

export default RelationsServices;
