import React, { ReactElement } from 'react';
import {
  Radar,
  RadarChart,
  PolarGrid,
  Legend,
  PolarAngleAxis,
  PolarRadiusAxis,
  ResponsiveContainer,
  Tooltip
} from 'recharts';

export interface RadarData {
  [subject: string]: number
}

export interface RadarChartDatas {
  label: string,
  dataKey: 'A' | 'B' | 'C' | 'D' | 'E' | 'F' | 'G' | 'H' | 'I' | 'J' | 'K' | 'L' | 'M' | 'N' | 'O' | 'P' | 'Q' | 'R' | 'S' | 'T' | 'U' | 'V' | 'W' | 'X' | 'Y' | 'Z',
  color: string,
  datas: RadarData
}

export interface RadarChartsProps {
  subjects: string[],
  datas: RadarChartDatas[],
  maxValue: number
}

function RadarCharts(props: RadarChartsProps): ReactElement {
  const data = props.subjects.map((subject) => {
    let dataTmp = {
      subject,
      fullMark: props.maxValue
    };

    props.datas.forEach((d) => {
      dataTmp = { ...dataTmp, [d.dataKey]: d.datas[subject] };
    });

    return dataTmp;
  });

  return (
    <ResponsiveContainer width='100%' height='100%'>
      <RadarChart cx='50%' cy='50%' outerRadius='80%' data={data}>
        <PolarGrid />
        <PolarAngleAxis dataKey='subject' />
        <PolarRadiusAxis angle={30} domain={[0, props.maxValue]} />
        <Tooltip />
        {props.datas.map(d => (
          <Radar key={d.dataKey} name={d.label} dataKey={d.dataKey} stroke={d.color} fill={d.color} fillOpacity={0.6} />
        ))}
        <Legend />
      </RadarChart>
    </ResponsiveContainer>
  );
}

export default RadarCharts;
