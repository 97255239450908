import React, { Dispatch, SetStateAction } from 'react';
import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';
import { CustomSegmentationData } from '../../types.segmentations';
import Typography from '@mui/material/Typography';
import {
  possibleCriteriaOnTypes,
  possibleCriteriaTypes,
  possibleDataSetIds, possibleFilterTypes,
  possibleOutputEntities,
  possibleWorkflowIds,
  segmentationPossibleWorkflows
} from '../workflows';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Switch from '@mui/material/Switch';

export interface AdvancedConfigBuilderSegmentationProps {
  customSegmentationData: CustomSegmentationData,
  setCustomSegmentationData: (data: CustomSegmentationData) => void,
  isPrefiltersEnabled: boolean,
  setIsPrefiltersEnabled: Dispatch<SetStateAction<boolean>>
}

function AdvancedConfigBuilderSegmentation(props: AdvancedConfigBuilderSegmentationProps)  {
  const { t } = useTranslation();

  const setData = (possibleDataSetIdsToSet = props.customSegmentationData.possibleDataSetIds, possibleOutputEntitiesToSet?: CustomSegmentationData['possibleOutputEntities'], possibleWorkflowIdsToSet?: CustomSegmentationData['possibleWorkflowIds']) => {
    props.setCustomSegmentationData({
      ...props.customSegmentationData,
      possibleDataSetIds: possibleDataSetIdsToSet,
      possibleOutputEntities: possibleOutputEntitiesToSet || possibleOutputEntities(possibleDataSetIdsToSet),
      possibleWorkflowIds: possibleWorkflowIdsToSet || possibleWorkflowIds(possibleDataSetIdsToSet, possibleOutputEntitiesToSet || possibleOutputEntities(possibleDataSetIdsToSet)),
      possibleFilterTypes: possibleFilterTypes(possibleDataSetIdsToSet, possibleOutputEntitiesToSet || possibleOutputEntities(possibleDataSetIdsToSet)),
      possibleCriteriaTypes: possibleCriteriaTypes(possibleDataSetIdsToSet, possibleOutputEntitiesToSet || possibleOutputEntities(possibleDataSetIdsToSet)),
      possibleCriteriaOnTypes: possibleCriteriaOnTypes(possibleDataSetIdsToSet, possibleOutputEntitiesToSet || possibleOutputEntities(possibleDataSetIdsToSet)),
      potentialSegmentationCriterias: props.customSegmentationData.potentialSegmentationCriterias.filter(potentialSegmentationCriteria => possibleCriteriaTypes(possibleDataSetIdsToSet, possibleOutputEntitiesToSet || possibleOutputEntities(possibleDataSetIdsToSet)).includes(potentialSegmentationCriteria.on))
    });
  };

  return (
    <Stack spacing={4} sx={{ width: '100%' }}>
      <Stack>
        <Typography variant='h5' p={2}>
          {t('segmentation.builder.config.advanced.compatibleEntityGroups')}
        </Typography>
        <FormGroup sx={{ flexDirection: 'row', p: 2 }}>
          {possibleDataSetIds().map((dataSetId) => {
            const Icon = segmentationPossibleWorkflows.find(segmentationPossibleWorkflow => segmentationPossibleWorkflow.dataSetId === dataSetId)?.icon;

            return (
              <FormControlLabel
                key={`seg.builder.config.groups.checkbox.${dataSetId}`}
                control={
                  <Checkbox
                    checked={props.customSegmentationData?.possibleDataSetIds.includes(dataSetId)}
                    onChange={(e) => setData([...props.customSegmentationData.possibleDataSetIds.filter(possibleDataSetId => possibleDataSetId !== dataSetId), ...(e.target.checked ? [dataSetId] : [])])}
                    disabled={props.customSegmentationData?.possibleDataSetIds.length === 1 && props.customSegmentationData?.possibleDataSetIds.includes(dataSetId)}
                  />
                }
                label={
                  <Stack direction='row' alignItems='center' spacing={2}>
                    {Icon && <Icon />}
                    <Typography>
                      {t(`segmentation.builder.config.advanced.entityGroups.${dataSetId}`)}
                    </Typography>
                  </Stack>
                }
              />
            );
          })}
        </FormGroup>
      </Stack>
      <Stack>
        <Typography variant='h5' p={2}>
          {t('segmentation.builder.config.advanced.on.label')}
        </Typography>
        <FormGroup sx={{ flexDirection: 'row', p: 2 }}>
          {segmentationPossibleWorkflows.filter(possibleWorkflow => props.customSegmentationData.possibleDataSetIds.includes(possibleWorkflow.dataSetId)).map(possibleWorkflow => possibleWorkflow.entryPoints.map(entryPoint => {
            const Icon = entryPoint?.icon;

            return (
              <FormControlLabel
                key={`seg.builder.config.groups.checkbox.entryPoint.${entryPoint.entryPoint}`}
                control={
                  <Checkbox
                    checked={props.customSegmentationData?.possibleOutputEntities.includes(entryPoint.entryPoint)}
                    onChange={(e) => setData(undefined, [...props.customSegmentationData.possibleOutputEntities.filter(possibleOutputEntity => possibleOutputEntity !== entryPoint.entryPoint), ...(e.target.checked ? [entryPoint.entryPoint] : [])])}
                    disabled={props.customSegmentationData?.possibleOutputEntities.length === 1 && props.customSegmentationData?.possibleOutputEntities.includes(entryPoint.entryPoint)}
                  />
                }
                label={
                  <Stack direction='row' alignItems='center' spacing={2}>
                    {Icon && <Icon />}
                    <Typography>
                      {t(`segmentation.builder.config.advanced.on.${entryPoint.entryPoint}`)}
                    </Typography>
                  </Stack>
                }
              />
            );
          }))}
        </FormGroup>
      </Stack>
      <FormControlLabel
        sx={{ alignSelf: 'flex-start' }}
        control={
          <Switch checked={props.isPrefiltersEnabled} onChange={(e) => props.setIsPrefiltersEnabled(e.target.checked)} name='isPrefiltersEnabled' />
        }
        label={
          <Typography variant='h5' p={2}>
            {t('segmentation.builder.config.advanced.enablePrefilters')}
          </Typography>
        }
        labelPlacement='start'
      />
    </Stack>
  );
}

export default AdvancedConfigBuilderSegmentation;
