interface SirenReturn {
  label: string,
  countryCode: string,
  id: string
}

export function sirenParser(id?: string | null): SirenReturn | undefined {
  if (!id) {
    return undefined;
  }

  const idSplit = id.split(':');

  if (idSplit.length !== 3) {
    return undefined;
  }

  return { label: idSplit[1], countryCode: idSplit[0], id: idSplit[2] };
}
