import React, { ReactElement, useState } from 'react';
import Keycloak from 'keycloak-js';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { AuthClientEvent, AuthClientInitOptions, AuthClientTokens } from '@react-keycloak/core/lib';
import { AxiosInstance } from 'axios';
import AxiosInstanceModifier from '../api/axios/axiosinstancemodifier.api';

export interface KeyCloakWrapperProps {
  keycloakInstance: Keycloak,
  axiosInstance: AxiosInstance,
  initOptions?: AuthClientInitOptions
}

function KeycloakWrapper(props: KeyCloakWrapperProps & { children: ReactElement }): ReactElement {
  const axiosModifier = new AxiosInstanceModifier(props.axiosInstance);
  const [authenticated, setAuthenticated] = useState(false);

  const onTokens = (tokens: AuthClientTokens) => {
    if (tokens.token) {
      axiosModifier.addAuthorizationHeader(tokens.token);
    }
  };

  const onEvent = (e: AuthClientEvent) => {
    if (e === 'onReady') {
      setAuthenticated(true);
    }
  };

  return (
    <ReactKeycloakProvider
      authClient={props.keycloakInstance}
      initOptions={props.initOptions || { onLoad: 'login-required', checkLoginIframe: false }}
      onTokens={onTokens}
      onEvent={onEvent}
    >
      {authenticated && props.children}
    </ReactKeycloakProvider>
  );
}

export default KeycloakWrapper;
