import { z } from 'zod';

const TableStorageSchema = z.record(z.string().array());

export type TableStorage = z.infer<typeof TableStorageSchema>;

export function getTableStorage(): TableStorage | undefined {
  const tableStorage = localStorage.getItem('tableStorage');
  const validatedLayoutStorage = TableStorageSchema.safeParse(tableStorage ? JSON.parse(tableStorage) : '');

  return validatedLayoutStorage.success ? validatedLayoutStorage.data : undefined;
}

export function setTableStorage(TableStorage: TableStorage): void {
  localStorage.setItem('tableStorage', JSON.stringify(TableStorage));
}
