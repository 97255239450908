import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { CompanyEntity, PersonEntity } from '@deecision/dna-interfaces';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import BaseDnaEntitiesService from '../../services/entities.services';

interface SearchAutocompleteItemProps {
  entity: PersonEntity | CompanyEntity,
  select: (entity: string) => void,
  selected: boolean,
  setSelected: (value: number) => void
}

type SearchAutocompleteProps = Pick<SearchAutocompleteItemProps, 'select'> & {
  query: string | undefined,
  focus: boolean,
  service: BaseDnaEntitiesService<PersonEntity | CompanyEntity>,
  anchorEl: HTMLElement | null,
  searchEntityType: 'deecPerson' | 'deecCompany',
  disabled?: boolean,
  popperWidth?: number,
  submitted?: boolean,
  setSelectedFromAutocomplete: (value: string | undefined) => void
}

function SearchAutocompleteItem(props: SearchAutocompleteItemProps): ReactElement {
  const theme = useTheme();
  const scrollToRef = useRef<HTMLLIElement>(null);

  useEffect(() => {
    if (scrollToRef.current && props.selected) {
      scrollToRef.current.scrollIntoView({ block: 'nearest' });
    }
  }, [scrollToRef, props.selected]);

  return (
    <MenuItem
      ref={scrollToRef}
      onMouseEnter={() => props.setSelected(-1)}
      onClick={() => props.select(props.entity.name)}
      sx={{
        p: 2,
        backgroundColor: props.selected ? theme.palette.grey['200'] : undefined
      }}
    >
      {props.entity.name}
    </MenuItem>
  );
}

function SearchAutocomplete(props: SearchAutocompleteProps): ReactElement {
  const { t } = useTranslation();
  const theme = useTheme();
  const [results, setResults] = useState<(PersonEntity | CompanyEntity)[]>([]);
  const [open, setOpen] = useState(false);
  const [querySelected, setQuerySelected] = useState<string>();
  const [selected, setSelected] = useState(-1);

  const onKeyDown = (event: KeyboardEvent) => {
    if (results.length > 0 && open) {
      switch (event.key) {
      case 'ArrowDown':
        if (selected < results.length - 1) {
          setSelected(prevState => prevState + 1);
        } else {
          setSelected(0);
        }
        break;
      case 'ArrowUp':
        if (selected <= 0) {
          setSelected(results.length - 1);
        } else {
          setSelected(prevState => prevState - 1);
        }
        break;
      case 'Enter':
      case 'NumpadEnter':
        setOpen(false);
        break;
      default:
        break;
      }
    }
  };

  const select = (value: string) => {
    setQuerySelected(value);
    props.select(value);
  };

  useEffect(() => {
    if (props.query !== querySelected) {
      setQuerySelected(undefined);
    }
    if (props.query && props.focus && props.query !== '' && props.query.length > 2) {
      props.service.getAll({ filters: [{ scope: 'fe_name', id: props.query, value: props.query }, { scope: 'autocomplete', id: 'true', value: 'true' }] })
        .then((res) => {
          if (res.data) {
            setResults(res.data);
            setOpen(true);
          }
        })
        .catch(() => {
          setResults([]);
          setOpen(true);
        });
    } else {
      setOpen(false);
    }
  }, [props.query, props.service]);

  useEffect(() => {
    if (props.submitted) {
      setOpen(false);
    }
  }, [props.submitted]);

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown);

    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, [onKeyDown]);

  useEffect(() => {
    if (results.length > 0 && selected >= 0) {
      props.setSelectedFromAutocomplete(results[selected]?.name);
    } else {
      props.setSelectedFromAutocomplete(undefined);
    }
  }, [selected, results]);

  useEffect(() => {
    setSelected(-1);
  }, [open]);

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <Popper
        open={open && !props.disabled && props.query !== querySelected}
        anchorEl={props.anchorEl}
        sx={{
          zIndex: 100
        }}
      >
        <Paper
          variant='hoverElevation3'
          sx={{
            p: 0,
            boxShadow: 2,
            mt: 3,
            width: props.popperWidth || 0,
            bgcolor: 'grey.50'
          }}
        >
          <Stack>
            <Stack
              spacing={2}
              pt={2}
              sx={{
                maxHeight: 400,
                overflow: 'auto'
              }}
            >
              {results.map((res, index) => (
                <SearchAutocompleteItem
                  key={res._id}
                  entity={res}
                  select={select}
                  selected={index === selected}
                  setSelected={setSelected}
                />
              ))}
            </Stack>
            <Divider />
            <Typography p={2} variant='body2' color={theme.palette.grey['600']} textAlign='center'>
              {`${results.length} ${t('search.results')}`}
            </Typography>
          </Stack>
        </Paper>
      </Popper>
    </ClickAwayListener>
  );
}

export default SearchAutocomplete;
