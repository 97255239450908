import React, { ReactElement } from 'react';
import KeycloakWrapper, { KeyCloakWrapperProps } from './keycloak';
import MuiWrapper, { ThemeWrapperProps } from './mui';
import MaintenanceWrapper, { MaintenanceWrapperProps } from './maintenance';
import I18nWrapper, { I18nProps } from '@/wrappers/i18n';
import DndWrapper from './dnd';
import AxiosWrapper, { AxiosWrapperProps } from './axios';
import ErrorWrapper, { ErrorWrapperProps } from '@/wrappers/error';
import '@/assets/css/basecss.css';

const modules = [
  { keycloak: {} as KeyCloakWrapperProps },
  { axios: {} as AxiosWrapperProps },
  { dnd: true as boolean },
  { mui: true as ThemeWrapperProps },
  { maintenance: {} as MaintenanceWrapperProps },
  { i18n: {} as I18nProps },
  { error: {} as ErrorWrapperProps },
  { children: <></> as ReactElement }
] as const;

export type ImplementModulesProps = typeof modules[number];

function ParserModules(props: ImplementModulesProps): ReactElement {
  const value = Object.keys(props)[0] as keyof ImplementModulesProps;
  const other: ImplementModulesProps = { ...props };

  delete other[value];

  switch (value) {
  case 'keycloak':
    return (
      <KeycloakWrapper {...(props[value] as KeyCloakWrapperProps)}>
        {ParserModules({ ...other })}
      </KeycloakWrapper>
    );
  case 'axios':
    return (
      <AxiosWrapper {...(props[value] as AxiosWrapperProps)}>
        {ParserModules({ ...other })}
      </AxiosWrapper>
    );
  case 'dnd':
    return (
      <DndWrapper>
        {ParserModules({ ...other })}
      </DndWrapper>
    );
  case 'mui':
    return (
      <MuiWrapper>
        {ParserModules({ ...other })}
      </MuiWrapper>
    );
  case 'maintenance':
    return (
      <MaintenanceWrapper {...(props[value] as MaintenanceWrapperProps)}>
        {ParserModules({ ...other })}
      </MaintenanceWrapper>
    );
  case 'i18n':
    return (
      <I18nWrapper {...(props[value] as I18nProps)}>
        {ParserModules({ ...other })}
      </I18nWrapper>
    );
  case 'error':
    return (
      <ErrorWrapper {...(props[value] as ErrorWrapperProps)}>
        {ParserModules({ ...other })}
      </ErrorWrapper>
    );
  case 'children':
    return props[value];
  default:
    return <></>;
  }
}

export default ParserModules;
