import React, { ReactElement, useEffect, useState } from 'react';
import {
  BingNewsData,
  GoogleCseDataBlock,
  NewsApiOrgData
} from '@deecision/dna-interfaces/dist/dataBlocks/newsDataBlock';
import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import { useTheme } from '@mui/material/styles';
import { IDataBlock } from '@deecision/dna-interfaces';
import Tooltip from '@mui/material/Tooltip';
import { IconCirclesRelation } from '@tabler/icons-react';
import Chip from '@mui/material/Chip';

interface NewsCardsProps {
  news: IDataBlock<BingNewsData | NewsApiOrgData | GoogleCseDataBlock>
}

function NewsCards(props: NewsCardsProps): ReactElement {
  const theme = useTheme();
  const [hideImg, setHideImg] = useState(false);
  const [imgUrl, setImgUrl] = useState<string | undefined>(undefined);

  useEffect(() => {
    setImgUrl((props.news.data as NewsApiOrgData)?.thumbnailUrl || (props.news.data as BingNewsData)?.provider?.[0]?.image?.thumbnail?.contentUrl || (props.news.data as GoogleCseDataBlock)?.pagemap?.cse_thumbnail?.[0]?.src || (props.news.data as GoogleCseDataBlock)?.pagemap?.cse_image?.[0]?.src);
    setHideImg(false);
  }, [props.news.data]);

  return (
    <Box sx={{ display: 'flex', p: 0, width: '100%' }}>
      <Link
        href={(props.news.data as NewsApiOrgData).url || (props.news.data as GoogleCseDataBlock).link}
        target='_blank'
        sx={{
          width: '100%',
          textDecoration: 'none',
          textDecorationColor: `${theme.palette.text.primary} !important`,
          '&:hover': {
            textDecoration: 'underline'
          }
        }}
      >
        <Stack direction='row' width='100%'>
          <CardContent sx={{ p: 2, pr: 4 }}>
            <Stack direction='column' spacing={2}>
              <Typography
                variant='body2'
                sx={{ color: 'primary.main' }}
              >
                {((props.news.data as NewsApiOrgData).url || (props.news.data as GoogleCseDataBlock).link)?.replace('www.', '')?.replace('fr.', '')}
              </Typography>
              <Stack spacing={2} direction='row'>
                {props.news.data.tags && props.news.data.tags.find(tag => (tag.family === 'general' && tag.value === 'related')) &&
                  <Tooltip title='Related' arrow placement='top'>
                    <Chip
                      size='small'
                      sx={{
                        '&>.MuiChip-labelSmall': {
                          pl: '4px !important',
                          pr: '4px !important'
                        }
                      }}
                      color={
                        (props.news._source?.query?.bias || (props.news.data as BingNewsData).query?.bias) === 'positive' && 'success' ||
                        (props.news._source?.query?.bias || (props.news.data as BingNewsData).query?.bias) === 'neutral' && 'warning' ||
                        (props.news._source?.query?.bias || (props.news.data as BingNewsData).query?.bias) === 'negative' && 'error' ||
                        undefined
                      }
                      label={
                        <IconCirclesRelation
                          style={{
                            marginTop: 6
                          }}
                          color={
                            (props.news._source?.query?.bias || (props.news.data as BingNewsData).query?.bias) === 'positive' && theme.palette.success.dark ||
                            (props.news._source?.query?.bias || (props.news.data as BingNewsData).query?.bias) === 'neutral' && theme.palette.warning.dark ||
                            (props.news._source?.query?.bias || (props.news.data as BingNewsData).query?.bias) === 'negative' && theme.palette.error.dark ||
                            'text.primary'
                          }
                        />
                      }
                    />
                  </Tooltip>
                }
                <Typography
                  variant='h4'
                  sx={{
                    color: (
                      (props.news._source?.query?.bias || (props.news.data as BingNewsData).query?.bias) === 'positive' && theme.palette.success.main ||
                      (props.news._source?.query?.bias || (props.news.data as BingNewsData).query?.bias) === 'neutral' && theme.palette.warning.main ||
                      (props.news._source?.query?.bias || (props.news.data as BingNewsData).query?.bias) === 'negative' && theme.palette.error.main ||
                      'text.primary'
                    )
                  }}
                >
                  {(props.news.data as NewsApiOrgData).title || (props.news.data as BingNewsData).name}
                </Typography>
              </Stack>
              <Typography
                variant='body1'
                sx={{ color: 'text.primary' }}
              >
                {(props.news.data as NewsApiOrgData).shortDescription || (props.news.data as BingNewsData).description || (props.news.data as GoogleCseDataBlock).snippet}
              </Typography>
              <Typography variant='caption'>
                {}
              </Typography>
            </Stack>
          </CardContent>
          {imgUrl && !hideImg &&
            <CardMedia
              component='img'
              sx={{
                p: 2,
                marginLeft: 'auto !important',
                width: '100px',
                height: '100px',
                borderRadius: 1
              }}
              image={imgUrl}
              alt={imgUrl}
              onError={() => setHideImg(true)}
            />
          }
        </Stack>
      </Link>
    </Box>
  );
}

export default NewsCards;
