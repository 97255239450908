import React, { ReactElement, ReactNode } from 'react';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2';
import { Icon, IconCircleX, IconDotsVertical, IconProps } from '@tabler/icons-react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import IconBuildings from '@/assets/custom/IconBuildings';
import { KeyValue, KeyValueListRow } from '@/components/keyvalue/keyvaluelist';
import { CustomFilter } from "@/components/filters/types";

interface CardPartProps {
  mainValue?: KeyValue | KeyValue[],
  values: KeyValue[],
  selectKey?: (key: string) => void,
  link?: ReactElement
}

interface KeyValueCardComponentProps {
  id?: string,
  grid?: true,
  icon?: React.ForwardRefExoticComponent<Omit<IconProps, "ref"> & React.RefAttributes<Icon>>,
  title: string | ReactNode,
  hideFilters?: true,
  filters?: CustomFilter[],
  setFilters?: (filters: CustomFilter[]) => void,
  cardParts?: CardPartProps | Array<CardPartProps | undefined>,
  cardList?: ReactElement[],
  cardElements?: ReactElement[],
  actions?: Array<() => void>,
  disableElevation?: true,
  bgColor?: string,
  border?: string,
  selected?: string,
  select?: () => void,
  link?: ReactElement,
  width?: string,
  height?: string,
  noDivider?: boolean,
  overflow?: string
}

export function CardPart(props: CardPartProps): ReactElement {
  const theme = useTheme();
  const values = props.values.filter(value => (
    value.value && value.value !== '' && value.value !== ' ' && !value.value.toString().includes('undefined')
  ));

  return (
    <Stack spacing={4} key={`cardPart-${Array.isArray(props.mainValue) ? props.mainValue[0].key : props.mainValue?.key}`}>
      {props.mainValue && !Array.isArray(props.mainValue) && props.mainValue?.key === '' ?
        <Typography variant='h4' sx={{ ml: '-4px', mb: 2 }}>{props.mainValue.value}</Typography>
        :
        props.mainValue &&
          <Paper
            elevation={0}
            sx={{
              bgcolor: !Array.isArray(props.mainValue) ? props.mainValue.bgcolor || theme.primaryColors?.lighter : theme.primaryColors?.lighter,
              mb: 4,
              width: '100%'
            }}
          >
            <Grid
              container
              spacing={2}
              p={2}
              sx={{
                minWidth: '120px',
                width: 'max-content',
                maxWidth: '360px'
              }}
            >
              {Array.isArray(props.mainValue) ?
                props.mainValue.map(value => (
                  <React.Fragment key={`value-${value.key}-${value.value?.toString()}`}>
                    <Grid xs={12}>
                      <Typography variant='body2'>
                        {value.key}
                      </Typography>
                    </Grid>
                    {value.icon &&
                      <Grid mt='1px'>
                        <value.icon
                          stroke={value.icon === IconBuildings ? theme.primaryColors?.main : undefined}
                          color={value.color || theme.primaryColors?.main}
                        />
                      </Grid>
                    }
                    <Grid xs>
                      <Typography
                        variant='h2'
                        color={value.color || 'primary'}
                        sx={{
                          display: '-webkit-box',
                          overflow: 'hidden',
                          WebkitBoxOrient: 'vertical',
                          WebkitLineClamp: 2
                        }}
                      >
                        {value.value || ''}
                      </Typography>
                    </Grid>
                    <div/>
                  </React.Fragment>
                )) : props.mainValue &&
                <>
                  <Grid xs={12}>
                    <Typography variant='body2'>
                      {props.mainValue.key}
                    </Typography>
                  </Grid>
                  {props.mainValue.icon &&
                    <Grid mt='1px'>
                      <props.mainValue.icon
                        stroke={props.mainValue.icon === IconBuildings ? theme.primaryColors?.main : undefined}
                        color={theme.primaryColors?.main}
                      />
                    </Grid>
                  }
                  <Grid xs>
                    <Typography
                      variant='h2'
                      color={props.mainValue.color || 'primary'}
                      pr={2}
                      sx={{
                        display: '-webkit-box',
                        overflow: 'hidden',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 2
                      }}
                    >
                      {props.mainValue.value || ''}
                    </Typography>
                  </Grid>
                </>
              }
            </Grid>
          </Paper>
      }
      <KeyValueListRow values={values} selectKey={props.selectKey} />
      {props.link &&
        <Box textAlign='center'>
          {props.link}
        </Box>
      }
    </Stack>
  );
}

function KeyValueCard(props: Omit<KeyValueCardComponentProps, 'grid'>): ReactElement {

  return (
    <Paper
      elevation={0}
      variant={props.disableElevation ? undefined : 'hoverElevation2'}
      sx={{
        height: props.height || '100%',
        maxWidth: '100%',
        width: props.width,
        p: 1,
        overflowX: 'auto',
        bgcolor: !props.border && props.bgColor || undefined,
        border: props.border ? 'solid 1px' : undefined,
        borderRadius: 1,
        borderColor: props.border || undefined,
        overflow: props.overflow,
        transition: 'background-color 200ms, border-color 200ms',
        '&:hover': {
          bgcolor: props.bgColor,
          borderColor: props.border ? props.bgColor : undefined,
          transition: 'background-color 200ms, border-color 200ms'
        }
      }}
    >
      <Stack spacing={1}>
        <Box sx={{ cursor: props.select ? 'pointer' : undefined }} onClick={props.select}>
          <Grid
            container
            spacing={2}
            direction='row'
            alignItems='flex-start'
          >
            {props.icon &&
              <Grid mt='-3px' p={2} pr={0}>
                <props.icon />
              </Grid>
            }
            {props.select &&
              <Grid p={0} mr='-8px'>
                <Checkbox disableRipple checked={props.selected === props.id} size='small' />
              </Grid>
            }
            <Grid p={2}>
              <Typography variant='h4' fontWeight={600}>
                {props.title}
              </Typography>
            </Grid>
            {!props.hideFilters && props.filters?.filter(f => f.value && f.value !== '')
              .map(filter => (
                filter.value &&
                <Grid p={2}>
                  <Chip
                    key={filter.id}
                    label={`${filter.label}: ${filter.valueParsed || filter.value}`}
                    size='small'
                    onDelete={() => (
                      props.setFilters ?
                        props.setFilters(props.filters?.filter(f => f.id !== filter.id) || []) :
                        undefined
                    )}
                    deleteIcon={<IconCircleX size={18} />}
                  />
                </Grid>
              ))
            }
            {props.actions && [
              <Grid xs />,
              <Grid>
                <IconButton size='small'>
                  <IconDotsVertical size={20} />
                </IconButton>
              </Grid>
            ]}
          </Grid>
        </Box>
        <Stack direction='row' spacing={2} overflow='auto' p={2} width='100%'>
          {Array.isArray(props.cardParts) ?
            <Stack direction='row'>
              {props.cardParts.map((cardPart, index) => (cardPart &&
                <React.Fragment
                  key={Array.isArray(cardPart.mainValue) ? cardPart.mainValue[0].key as string : cardPart.mainValue?.key as string}
                >
                  <CardPart {...cardPart} />
                  {!props.noDivider && index !== (props.cardParts as CardPartProps[]).length - 1 &&
                    <Box p={4}>
                      <Divider orientation='vertical' />
                    </Box>
                  }
                </React.Fragment>
              ))}
            </Stack> :
            props.cardParts && <CardPart {...props.cardParts} />
          }
          {props.cardList && props.cardParts && !props.noDivider && <Divider orientation='vertical' />}
          {props.cardList &&
            <Stack spacing={2}>
              {props.cardList.map(element => element)}
            </Stack>
          }
          {props.cardElements && props.cardElements.map((cardElement, index) => (
            <Stack key={`element-${cardElement.key}`} spacing={2} direction='row'>
              {!props.noDivider && (props.cardParts || props.cardList || props.cardElements && index > 0 && index < props.cardElements.length) &&
                <Divider orientation='vertical' />
              }
              {cardElement}
            </Stack>
          ))}
        </Stack>
        {props.link &&
          <Box p={2} textAlign='center'>
            {props.link}
          </Box>
        }
      </Stack>
    </Paper>
  );
}

function KeyValueCardComponent(props: KeyValueCardComponentProps): ReactElement {

  return props.grid ? <Grid><KeyValueCard {...props} /></Grid> : <KeyValueCard {...props} />;
}

export default KeyValueCardComponent;
