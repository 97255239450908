import type Keycloak from 'keycloak-js';
import {
  CustomSegmentationData
  , CustomUserData,
  CustomUserDataAccess, customUserDataAccesses
} from '../types.segmentations';
import {
  GroupDataCustomSegmentationService,
  UserDataCustomSegmentationService
} from '../services/segmentations.services';

export function getSegmentationService<T>(accessTmp: CustomUserDataAccess, userId: string) {
  return (accessTmp === 'me' ?
    new UserDataCustomSegmentationService<T>({ userId }) :
    new GroupDataCustomSegmentationService<T>({ groupId: accessTmp }));
}

async function getSegmentations(keycloak: Keycloak): Promise<CustomUserData<CustomSegmentationData>[]> {
  const segmentations: CustomUserData<CustomSegmentationData>[] = [];

  const promises = customUserDataAccesses.map(async (access) => {
    const result = await getSegmentationService<CustomUserData<CustomSegmentationData>>(access, keycloak.tokenParsed?.preferred_username).getAll({
      filters: [
        {
          id: 'type',
          scope: 'type',
          value: 'segmentation'
        }
      ]
    });
    segmentations.push(...(result.data || []));
  });

  await Promise.all(promises);

  return segmentations;
}

export default getSegmentations;
