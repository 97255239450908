import React, { ReactElement, useState } from 'react';
import { SegmentationPossibleFilter } from '@deecision/dna-interfaces';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { IconMinus, IconPlus } from '@tabler/icons-react';
import Modal from '@mui/material/Modal';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { uniq, upperFirst } from 'lodash';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material';

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)'
};

interface AddCriteriasBuilderSegmentationProps {
  possibleCriterias: SegmentationPossibleFilter[],
  addSegmentationCriterias: (possibleCriteria: SegmentationPossibleFilter[]) => void,
  addSegmentationCriteriaCategory: (category?: string) => void,
  fullWidth?: true,
  contained?: true
}

export function PossibleCriteriasTreeView(props: AddCriteriasBuilderSegmentationProps & { handleClose: () => void, disableAddAll?: true }): ReactElement {
  const { t } = useTranslation();
  const [collapsed, setCollapsed] = useState(!props.disableAddAll);

  return (
    <Stack spacing={2}>
      <Stack direction='row' spacing={2} p={2} alignItems='center'>
        {!props.disableAddAll &&
          <Typography variant='h3'>{t('segmentation.builder.segmentationCriterias.add')}</Typography>
        }
        <Button onClick={() => setCollapsed(prevState => !prevState)} endIcon={collapsed ? <IconPlus size={16} /> : <IconMinus size={16} />} size='small'>
          {collapsed ? t('common.utils.expandAll') : t('common.utils.collapseAll')}
        </Button>
      </Stack>
      <Box maxHeight='80vh' overflow='auto'>
        <SimpleTreeView
          key={collapsed ? 'collapsed' : 'expanded'}
          sx={{ overflow: 'auto' }}
          defaultExpandedItems={!collapsed ?
            uniq(props.possibleCriterias.map(possibleCriteria => possibleCriteria.categories).flat(1)) :
            []
          }
        >
          {uniq(props.possibleCriterias.map(possibleCriteria => possibleCriteria.categories).flat(1)).map(category => (
            <TreeItem
              itemId={category}
              key={category}
              label={
                <Stack direction='row' spacing={4} alignItems='center'>
                  <Typography variant='h4'>{`${upperFirst(category.split(/(?=[A-Z])/).join(' '))} (${props.possibleCriterias.filter(possibleCriteria => possibleCriteria.categories.includes(category)).length})`}</Typography>
                  {!props.disableAddAll &&
                    <Button
                      variant='outlined'
                      startIcon={<IconPlus size={16} />}
                      size='small'
                      onClick={(e) => {
                        e.stopPropagation();
                        props.handleClose();
                        props.addSegmentationCriteriaCategory(category);
                      }}
                    >
                      {t('segmentation.builder.segmentationCriterias.selection.addAllCat')}
                    </Button>
                  }
                </Stack>
              }
            >
              {props.possibleCriterias.filter(possibleCriteria => possibleCriteria.categories.includes(category)).map(possibleCriteria => (
                <TreeItem
                  itemId={`${category}-${possibleCriteria.id}`}
                  key={`${category}-${possibleCriteria.id}`}
                  onClick={() => {
                    props.handleClose();
                    props.addSegmentationCriterias([possibleCriteria]);
                  }}
                  label={
                    <Stack direction='row' spacing={2} width='100%' alignItems='center' pr={2}>
                      <Typography variant='body1'>{possibleCriteria.label}</Typography>
                      <Chip
                        label={t(`entities.${possibleCriteria.on}.multiple`)}
                        sx={{
                          bgcolor: possibleCriteria.on === 'person' ? 'primary.light' : possibleCriteria.on === 'company' ? 'secondary.light' : 'warning.light',
                          color: possibleCriteria.on === 'person' ? 'primary.dark' : possibleCriteria.on === 'company' ? 'secondary.dark' : 'warning.dark'
                        }}
                        size='small'
                      />
                      {!props.disableAddAll &&
                        <IconPlus size={16} style={{ marginLeft: 'auto' }} />
                      }
                    </Stack>
                  }
                />
              ))}
            </TreeItem>
          ))}
        </SimpleTreeView>
      </Box>
      {!props.disableAddAll &&
        <Button
          startIcon={<IconPlus size={20} />}
          onClick={() => {
            props.handleClose();
            props.addSegmentationCriteriaCategory();
          }}
        >
          {`${t('segmentation.builder.segmentationCriterias.selection.addAll')} (${props.possibleCriterias.length})`}
        </Button>
      }
    </Stack>
  );
}

function AddCriteriasBuilderSegmentation(props: AddCriteriasBuilderSegmentationProps): ReactElement {
  const { t } = useTranslation();
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  return (
    <Box sx={{ p: 1, pt: 0, pb: 0 }}>
      <Button
        variant={props.contained ? 'contained' : 'outlined'}
        startIcon={<IconPlus size={20} />}
        onClick={handleOpen}
        disabled={props.possibleCriterias.length < 1}
        fullWidth={props.fullWidth}
        sx={{
          bgcolor: !props.contained ? theme.palette.grey[50] : undefined
        }}
      >
        {t('segmentation.builder.segmentationCriterias.add')}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='criterias-selection-modal-title'
        aria-describedby='criterias-selection-modal-description'
      >
        <Paper sx={style}>
          <PossibleCriteriasTreeView {...props} handleClose={handleClose} />
        </Paper>
      </Modal>
    </Box>
  );
}

export default AddCriteriasBuilderSegmentation;
