import React, { useEffect, useState } from 'react';
import { darken, useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { GroupAllValues } from '@deecision/dna-interfaces';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import { FormControl, Select, Skeleton } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import GroupsServices from '../../../modules/deetect/portfolios/services';
import {
  basePaperSx,
  BasePortfolioDashboardProps,
  dashboardSpacing
} from './types';
import ContainerGroupQuickDashboard from './container';

function PortfolioDashboardGroupSelectSubgroup(props: BasePortfolioDashboardProps & { selected: string }) {
  const theme = useTheme();
  const { t } = useTranslation();
  const groupsServices = new GroupsServices();
  const [values, setValues] = useState<GroupAllValues>();

  useEffect(() => {
    if (props.group.subGroupId) {
      groupsServices.getGroupValues(props.baseGroupId, `${props.group.subGroupId}.${props.selected}`)
        .then(res => setValues(res.data));
    }
  }, [props.selected]);

  return (
    <Paper
      variant={values ? 'hoverElevation1' : undefined}
      sx={{
        ...basePaperSx,
        bgcolor: theme.palette.primary.light,
        borderColor: darken(theme.palette.primary.light, 0.2),
        cursor: values ? 'pointer' : 'default'
      }}
      onClick={values ? () => props.callSegmentation(values.groupDesc.objectType, `${props.group.subGroupId}.${props.selected}`) : undefined}
    >
      <Stack direction='row' spacing={dashboardSpacing} p={dashboardSpacing} width='100%' alignItems='center' justifyContent='space-between'>
        <Typography variant='h5' maxWidth={240} color={theme.palette.primary.dark}>
          {t(`portfolios.dashboard.groups.labels.${props.group.subGroupId}.label`)}
        </Typography>
        <Typography variant='h4' whiteSpace='nowrap' color={theme.palette.primary.dark}>
          {values ? values.values.totalCount : <Skeleton variant='text' width={24} />}
        </Typography>
      </Stack>
    </Paper>
  );
}

function PortfolioDashboardGroupSelect(props: BasePortfolioDashboardProps) {
  const { t } = useTranslation();
  const [selected, setSelected] = useState<string>(props.group.options?.[1] || '');

  return (
    <ContainerGroupQuickDashboard
      {...props}
      titleAddon={
        <FormControl sx={{ ml: 'auto !important' }}>
          <Select
            id={`${props.group.subGroupId}-select`}
            value={selected}
            onChange={e => setSelected(e.target.value as string)}
            size='small'
          >
            {props.group.options?.map(option => (
              <MenuItem value={option}>{t(`portfolios.dashboard.options.${option}`)}</MenuItem>
            ))}
          </Select>
        </FormControl>
      }
    >
      <Grid xs={12} container>
        {props.group.subGroups?.map(subgroup => (
          <Grid xs={12} key={subgroup.subGroupId}>
            <PortfolioDashboardGroupSelectSubgroup {...props} group={subgroup} selected={selected} />
          </Grid>
        ))}
      </Grid>
    </ContainerGroupQuickDashboard>
  );
}

export default PortfolioDashboardGroupSelect;
