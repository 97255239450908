import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PersonEntity } from '@deecision/dna-interfaces';
import { useTheme } from '@mui/material/styles';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { IconLayoutList } from '@tabler/icons-react';
import KeyValueCardComponent from '../../../components/cards/keyvalue.cards';
import { getRelations } from '../../providers/getter';
import SmallEntityCards from '@/main/containers/entitiescards/entities';
import { SmallPersonCardEntity } from '@/main/containers/entitiescards/person';
import { CustomFilter } from "@/components/filters/types";

function PersonsForPersonWidgets(props: { data: PersonEntity }): (React.JSX.Element | false)[] {
  const { t } = useTranslation();
  const theme = useTheme();
  const [persons, setPersons] = useState<PersonEntity[]>([]);
  const [selectedPersons, setSelectedPersons] = useState<PersonEntity[]>([]);
  const [entities, setEntities] = useState<Array<SmallPersonCardEntity>>([]);
  const [filters, setFilters] = useState<CustomFilter[]>([]);

  useEffect(() => {
    getRelations(props.data, 'deecPerson')
      .then((res) => {
        res.forEach((augmentedEntity) => {
          setPersons(prevState => ([...prevState, augmentedEntity.entity as PersonEntity]));
          setEntities(prevState => (
            [...prevState, { status: 'unknown', person: augmentedEntity.entity as PersonEntity }]
          ));
        });
      });
  }, [props.data]);

  useEffect(() => {
    if (filters.length === 0) {
      setSelectedPersons(persons);
    }
  }, [filters, persons]);

  return ([
    entities.length > 0 &&
    <KeyValueCardComponent
      key='personsList'
      icon={IconLayoutList}
      title={t('common.utils.list')}
      filters={filters}
      setFilters={f => setFilters(f)}
      cardList={
        entities.filter(entity => selectedPersons.find(person => person.entityId === entity.person.entityId)).map(person => (
          <SmallEntityCards
            key={person.person.entityId}
            entityType={person.person.entityType}
            entity={person}
            sideColor={person.status === 'active' ? theme.palette.success.light : person.status === 'unknown' ? theme.palette.grey['400'] : theme.palette.error.light}
            bgColor={theme.palette.background.paper}
          />
        ))
      }
      link={
        <Link href='../network'>
          <Typography noWrap>
            {t('entities.persons.all')}
          </Typography>
        </Link>
      }
    />
  ]);
}

export default PersonsForPersonWidgets;
