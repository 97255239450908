import React, { ReactElement, useState } from 'react';
import { Button, darken } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import { BaseChartsProps } from './types';

function YesNoCharts(props: BaseChartsProps): ReactElement {
  const { t } = useTranslation();
  const theme = useTheme();
  const [selected, setSelected] = useState<-1 | 0 | 1>(-1);

  const handleOnClick = (value: number) => {
    setSelected(prevSelected =>
      (value === prevSelected ? -1 : value === 0 || value === 1 ? value : -1)
    );
    props.onClick?.(undefined, undefined, value === 0);
  };

  return (
    <Stack width='100%' direction='column' spacing={2} alignItems='center'>
      <Typography variant='body2' width='100%' textAlign='center'>{props.on}</Typography>

      <Grid container spacing={2} p={2}>
        <Grid xs>
          <Stack textAlign='center'>
            <Button
              variant='contained'
              onClick={() => handleOnClick(0)}
              sx={{
                bgcolor: darken(theme.palette.success.main, selected === 0 ? 0.3 : 0),
                borderColor: darken(theme.palette.success.main, selected === 0 ? 0.3 : 0),
                '&:hover': {
                  bgcolor: darken(theme.palette.success.main, 0.2),
                  borderColor: theme.palette.success.main
                }
              }}
              fullWidth
            >
              {t('common.utils.yes')}
            </Button>
            <Typography variant='h5' fontWeight={selected === 0 ? 'bold' : 'normal'} color='primary'>
              {`${(((props.datas[0]?.count || 0) * 100) / ((props.datas[0]?.count || 0) + (props.datas[1]?.count || 0))).toFixed(2)}% (${props.datas[0]?.count || 0})`}
            </Typography>
          </Stack>
        </Grid>
        <Grid xs>
          <Stack textAlign='center'>
            <Button
              variant='contained'
              onClick={() => handleOnClick(1)}
              sx={{
                bgcolor: darken(theme.palette.error.main, selected === 1 ? 0.3 : 0),
                borderColor: darken(theme.palette.error.main, selected === 1 ? 0.3 : 0),
                '&:hover': {
                  bgcolor: darken(theme.palette.error.main, 0.2),
                  borderColor: theme.palette.error.main
                }
              }}
              fullWidth
            >
              {t('common.utils.no')}
            </Button>
            <Typography  variant='h5' fontWeight={selected === 1 ? 'bold' : 'normal'}  color='primary'>
              {`${(((props.datas[1]?.count || 0) * 100) / ((props.datas[0]?.count || 0) + (props.datas[1]?.count || 0))).toFixed(2)}% (${props.datas[1]?.count || 0})`}
            </Typography>
          </Stack>

        </Grid>
      </Grid>
    </Stack>
  );
}

export default YesNoCharts;
