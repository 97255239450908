import React, { ReactElement } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import { CompanyEntity, CompanyInfo } from '@deecision/dna-interfaces';
import { useNavigate } from "react-router-dom";
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';
import { CompanyGetter, getEntityPath } from '../../providers/getter';
import KeyValueList from '@/components/keyvalue/keyvaluelist';

export interface SmallCompanyCardEntity {
  status: string,
  companyInfo?: CompanyInfo,
  company: CompanyEntity
}

interface SmallEntityCardProps {
  entity: SmallCompanyCardEntity
}

function CompanySmallCards(props: SmallEntityCardProps): ReactElement {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const path = getEntityPath({ ...props.entity.company });
  const companyGetter = new CompanyGetter({
    data: props.entity.company,
    errorMsg: t('common.utils.unknown')
  });

  return (
    <Grid
      container
      width='100%'
      sx={{ cursor: 'pointer' }}
      onClick={() => navigate(path)}
      direction='row'
      flexWrap='nowrap'
      spacing={4}
    >
      <Grid
        xs
        width='max-content'
        whiteSpace='nowrap'
        sx={{
          mr: 'auto',
          overflowX: 'auto',
          msOverflowStyle: 'none',
          scrollbarWidth: 'none',
          '& ::-webkit-scrollbar': {
            display: 'none'
          }
        }}
      >
        <Stack spacing={0}>
          <Grid container spacing={2} flexWrap='nowrap' sx={{ width: 'max-content !important' }}>
            <Grid>
              <Typography variant='subtitle2'>
                {companyGetter.getName()}
              </Typography>
            </Grid>
            {props.entity.companyInfo && props.entity.companyInfo.activeRoles.length > 0 &&
              props.entity.companyInfo.activeRoles.map(activeRole => (
                <Grid key={activeRole.text}>
                  <Tooltip title={props.entity.companyInfo?.activePositionTypes.join(' | ')} placement='top' arrow>
                    <Chip
                      label={activeRole.text}
                      size='small'
                      color='success'
                      sx={{
                        opacity: 0.75,
                        mt: '-4px',
                        fontSize: '11px',
                        p: 0,
                        height: '18px',
                        '& .MuiChip-label': {
                          pl: 2,
                          pr: 2
                        }
                      }}
                    />
                  </Tooltip>
                </Grid>
              ))
            }
            {props.entity.companyInfo && props.entity.companyInfo.inactiveRoles.length > 0 &&
              props.entity.companyInfo.inactiveRoles.map(inactiveRole => (
                <Grid key={inactiveRole.text}>
                  <Tooltip title={props.entity.companyInfo?.inactivePositionTypes.join(' | ')} placement='top' arrow>
                    <Chip
                      label={inactiveRole.text}
                      size='small'
                      sx={{
                        opacity: 0.75,
                        mt: '-4px',
                        fontSize: '11px',
                        p: 0,
                        height: '18px',
                        '& .MuiChip-label': {
                          pl: 2,
                          pr: 2
                        }
                      }}
                    />
                  </Tooltip>
                </Grid>
              ))
            }
          </Grid>
          <Typography variant='body2'>
            {companyGetter.getActivitySector()}
          </Typography>
          <Typography variant='body2' sx={{ mt: '-3px' }}>
            {companyGetter.getLegalForm()}
          </Typography>
        </Stack>
      </Grid>
      <Grid>
        <Divider orientation='vertical' />
      </Grid>
      <Grid>
        <KeyValueList values={[[
          {
            key: t('common.financial.turnover.label'),
            value: companyGetter.getTurnover(),
            align: 'right' as const
          },
          {
            key: t('common.financial.capital.label'),
            value: companyGetter.getCapital(),
            align: 'right' as const
          },
          {
            key: t('common.financial.ebitda.label'),
            value: companyGetter.getEbitda(),
            align: 'right' as const
          }
        ]]}
        />
      </Grid>
    </Grid>
  );
}

export default CompanySmallCards;
