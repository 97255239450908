import React, { ReactElement, useEffect, useState } from 'react';
import { useLocation, useMatches, useSearchParams } from 'react-router-dom';
import MuiBreadcrumbs from '@mui/material/Breadcrumbs';
import { IconChevronRight } from '@tabler/icons-react';
import { useTheme } from '@mui/material';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import Tooltip from '@mui/material/Tooltip';
import upperFirst from 'lodash/upperFirst';
import Box from '@mui/material/Box';
import { HandleType } from "@/router/types";

interface TypedMatch {
  handle?: HandleType,
  data?: {
    data?: {
      name?: string,
      label?: string,
      title?: string
    }
  }
}

function Breadcrumbs(): ReactElement {
  const matches = useMatches();
  const theme = useTheme();
  const url = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [hover, setHover] = useState<string | undefined>(undefined);
  const [prevLocation, setPrevLocation] = useState<string>();

  useEffect(() => {
    if (prevLocation?.split('/')[0] === url.pathname?.split('/')[0] && prevLocation !== url.pathname && (searchParams.get('page') || searchParams.get('pageSize'))) {
      searchParams.delete('page');
      searchParams.delete('pageSize');
      setSearchParams(searchParams);
    }
    setPrevLocation(url.pathname);
  }, [url]);

  return (
    <Box p={2}>
      <MuiBreadcrumbs separator={<IconChevronRight size={16} />} aria-label='breadcrumb'>
        {matches.filter(match => match.id === '0' || match.pathname !== '/' && match.pathname[match.pathname.length -1] !== '/')
          .map((match, index) => {
            const typedMatch = match as typeof match & TypedMatch;
            const CustomIcon = typedMatch.handle?.icon;
            const CustomCrumb = typedMatch.handle?.crumb;

            return (
              <Link
                key={match.id}
                href={`${match.pathname}${url.search || ''}`}
                underline='none'
                onMouseEnter={() => setHover(match.pathname)}
                onMouseLeave={() => setHover(undefined)}
                sx={{
                  pointerEvents: index === (matches.length - 1) ? 'none' : undefined,
                  color: 'text.primary',
                  '&:hover': {
                    color: 'primary.main'
                  }
                }}
              >
                {CustomCrumb ?
                  <CustomCrumb size={20} /> :
                  <Grid container>
                    {CustomIcon ?
                      <Grid pt='2px'>
                        <Tooltip arrow placement='top' title={`${upperFirst(match.pathname.replaceAll('_', ' ').split('/').at(-1))}`}>
                          <div>
                            <CustomIcon
                              size={16}
                              stroke={hover === match.pathname ? theme.palette.primary.main : theme.palette.text.primary}
                              strokeWidth='2'
                            />
                          </div>
                        </Tooltip>
                      </Grid> :
                      <Grid>
                        <Typography
                          variant='body1'
                          sx={{
                            pr: (index === (matches.length - 1) && !typedMatch.handle?.crumb) ? 2 : undefined
                          }}
                        >
                          {typedMatch.params.id &&
                            (typedMatch.data?.data?.name || typedMatch.data?.data?.label || typedMatch.data?.data?.title) ||
                            `${upperFirst(match.pathname.replaceAll('_', ' ').split('/').at(-1))}`
                          }
                        </Typography>
                      </Grid>
                    }
                  </Grid>
                }
              </Link>
            );
          })}
      </MuiBreadcrumbs>
    </Box>
  );
}

export default Breadcrumbs;
