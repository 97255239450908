import React from 'react';
import { IconDashboard } from "@tabler/icons-react";
import createElementRoutes from "@/router/create";
import AdminDashboard from "./adminDashboard";
import { CustomRouteObject } from "@/router/types";

const routeDashboard: CustomRouteObject = createElementRoutes({
  id: 'Dashboard',
  path: 'dashboard',
  icon: IconDashboard,
  component: <AdminDashboard />,
  idComponent: <AdminDashboard />,
  displayIndex: true
});

export default routeDashboard;
