import React, { ReactElement, useMemo } from 'react';
import { PersonEntity } from '@deecision/dna-interfaces';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import { RadarChartDatas } from '../../../components/charts/radar';
import RadarWidget from './radar';
import NewsWidget from './news';

interface IdentityWidgetsProps {
  data: PersonEntity
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function PersonRadarWidget(props: Pick<IdentityWidgetsProps, 'data'>): ReactElement {
  const { t } = useTranslation();
  const theme = useTheme();

  const radarSubjects = [
    t('entities.persons.person.charts.radar.completeness'),
    t('entities.persons.person.charts.radar.alerts'),
    t('entities.persons.person.charts.radar.compliance'),
    t('entities.persons.person.charts.radar.opportunities'),
    t('entities.persons.person.charts.radar.trust')
  ];

  const radarDatas: RadarChartDatas[] = [
    {
      label: '2022',
      dataKey: 'A' as const,
      color: theme.palette.secondary.main,
      datas: {}
    },
    {
      label: '2023',
      dataKey: 'B' as const,
      color: theme.palette.primary.main,
      datas: {}
    }
  ];

  radarDatas.forEach((radarData, index) => {
    radarSubjects.forEach((radarSubject) => {
      radarDatas[index].datas = { ...radarData.datas, [radarSubject]: (Math.floor(Math.random() * 10) + 1) };
    });
  });

  return (
    <RadarWidget subjects={radarSubjects} datas={radarDatas} maxValue={10} />
  );
}

function IdentityWidgets(props: IdentityWidgetsProps): (React.JSX.Element | false)[] {
  const TmpRadarWidget = useMemo(() => (<PersonRadarWidget {...props} key='radarWidget' />), []);

  return ([
    TmpRadarWidget,
    <NewsWidget key='newsWidget' data={props.data} />
  ]);
}

export default IdentityWidgets;
