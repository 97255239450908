import React, { ReactElement } from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { BaseItemFormProps, DNABaseFilterValue, DNAFilterPossibleValue } from '../types.form';

function SelectFilterTypeForm(props: BaseItemFormProps): ReactElement {

  return (
    <FormControl fullWidth>
      {props.filter.label && <InputLabel id='form-filter-simple-select-fullwidth-label'>{props.filter.label}</InputLabel>}
      <Select
        labelId='form-filter-simple-select-fullwidth-label'
        id='form-filter-simple-select-fullwidth'
        value={((props.filter.possibleValues as DNAFilterPossibleValue[]).find(possibleValue => (possibleValue as DNABaseFilterValue).id === props.filter.value) as DNABaseFilterValue)?.id || ''}
        onChange={e => props.onChange((props.filter.possibleValues as DNABaseFilterValue[]).find(possibleValue => e.target.value === possibleValue.id)?.id)}
        fullWidth
        label={props.filter.label}
      >
        <MenuItem value=''>
          <div>&nbsp;</div>
        </MenuItem>
        {(props.filter.possibleValues as DNABaseFilterValue[]).map(possibleValue => (
          <MenuItem key={possibleValue.label} value={possibleValue.id}>{possibleValue.label}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default SelectFilterTypeForm;
