import React, { ReactElement } from 'react';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';
import { IconDatabase, IconTargetArrow } from '@tabler/icons-react';

interface CompletenessAndAccuracyProps {
  completeness?: number | null,
  accuracy?: number | null,
  onlyCompleteness?: boolean,
  onlyAccuracy?: boolean
}

function CompletenessAndAccuracy(props: CompletenessAndAccuracyProps): ReactElement {
  const { t } = useTranslation();

  return (
    <Stack direction='row' spacing={1}>
      {!props.onlyAccuracy &&
        <Tooltip title={t('common.utils.completeness')} arrow placement='bottom'>
          <Chip
            icon={props.completeness === 0 ? undefined : <IconDatabase size={16} />}
            label={`${!props.completeness ? t('common.utils.unknown') : `${(props.completeness * 100).toFixed(2)}%`}`}
            color={(props.completeness || 0) * 100 > 70 && 'success' || (props.completeness || 0) * 100 > 30 && 'warning' || (props.completeness || 0) * 100 > 0 && 'error' || undefined}
            size='small'
          />
        </Tooltip>
      }
      {!props.onlyCompleteness &&
        <Tooltip title={t('common.utils.accuracy')} arrow placement='bottom'>
          <Chip
            icon={<IconTargetArrow size={16} />}
            label={`${!props.accuracy ? t('common.utils.unknown') : `${(props.accuracy * 100).toFixed(2)}%`}`}
            color={(props.accuracy || 0) * 100 > 70 && 'success' || (props.accuracy || 0) * 100 > 30 && 'warning' || (props.accuracy || 0) * 100 > 0 && 'error' || undefined}
            size='small'
          />
        </Tooltip>
      }
    </Stack>
  );
}

export default CompletenessAndAccuracy;
