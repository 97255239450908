import React from 'react';
import { IconListDetails } from "@tabler/icons-react";
import createElementRoutes from "@/router/create";
import DataElementsScreen from './dataElementsList';
import DataElementDetailsHeader from './dataElementDetailHeader';
import DataElementSummary from './tabs/dataElement.tabs.summary';
import DependenciesTabs from './tabs/dataElement.tabs.dependencies';
import DataBlocksTabs from './tabs/dataElement.tabs.dataBlocks';
import JsonBrutTabs from './tabs/dataElement.tabs.jsonBrut';
import dnaConfig from '../../../../config/dna.config.json';
import { CustomRouteObject } from "@/router/types";

const routeDataElements: CustomRouteObject = createElementRoutes({
  id: dnaConfig.routes.admin.tabs.dataElements.label,
  path: dnaConfig.routes.admin.tabs.dataElements.baseUrl,
  icon: IconListDetails,
  component: <DataElementsScreen />,
  idComponent: <DataElementDetailsHeader />,
  tabs: [{
    id: dnaConfig.routes.admin.tabs.dataElements.tabs.summary.label,
    i18nKey: dnaConfig.routes.admin.tabs.dataElements.tabs.summary.i18nKey,
    path: dnaConfig.routes.admin.tabs.dataElements.tabs.summary.baseUrl,
    element: <DataElementSummary />
  },
  {
    id: dnaConfig.routes.admin.tabs.dataElements.tabs.dependencies.label,
    i18nKey: dnaConfig.routes.admin.tabs.dataElements.tabs.dependencies.i18nKey,
    path: dnaConfig.routes.admin.tabs.dataElements.tabs.dependencies.baseUrl,
    element: <DependenciesTabs />
  },
  {
    id: dnaConfig.routes.admin.tabs.dataElements.tabs.dataBlocks.label,
    i18nKey: 'DataBlocks',
    path: dnaConfig.routes.admin.tabs.dataElements.tabs.dataBlocks.baseUrl,
    element: <DataBlocksTabs />
  },
  {
    id: dnaConfig.routes.admin.tabs.dataElements.tabs.json.label,
    i18nKey: 'JsonBrut',
    path: dnaConfig.routes.admin.tabs.dataElements.tabs.json.baseUrl,
    element: <JsonBrutTabs />
  }],
  displayIndex: true
});

export default routeDataElements;
