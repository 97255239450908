import React, { ReactElement, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams, useHref, Params } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Unstable_Grid2';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import IconButton from '@mui/material/IconButton';
import { IconExternalLink } from '@tabler/icons-react';
import { upperFirst } from 'lodash';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import { ApiArrayResponse } from '@deecision/deecision-interfaces';
import { CustomRouteObject } from "@/router/types";

abstract class FakeTabsDisplayService {
  abstract get(id: string): Promise<ApiArrayResponse<unknown>>;
}

export type TabFunction = (props: { id: string, data?: unknown }) => Promise<('visible' | 'hidden' | 'disabled')>

interface DispTabProps {
  path: string,
  params: Params<string>,
  tabsDisplayService?: FakeTabsDisplayService,
  tabsFunctions?: Record<string, TabFunction>
}

export interface TabsComponentsProps extends Omit<DispTabProps, 'path'> {
  tabs: CustomRouteObject[],
  idChildrens?: CustomRouteObject[]
}

function CustomTabs(props: TabsComponentsProps): ReactElement {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { pathname, search } = useLocation();
  const { id, tab } = useParams();
  const basename = useHref('/');
  let tabNb = props.tabs.findIndex(tb => (tb ? tb.path === tab : props.tabs[0].path));
  const [value, setValue] = useState(tabNb > 0 ? tabNb : 0);
  const [state, setState] = useState<Record<string, 'visible' | 'hidden' | 'disabled'>>({});

  const handleChange = (event: React.ChangeEvent<unknown>, newValue: number) => {
    setValue(newValue);
    navigate({ pathname: `${props.tabs[newValue]?.path || props.tabs[0].path}`, search: props.tabs[newValue] ? props.tabs[newValue].handle && props.tabs[newValue].handle.keepParams && search || undefined : props.tabs[0]?.handle && props.tabs[0].handle.keepParams ? search : undefined  });
  };

  useEffect(() => {
    tabNb = props.tabs.findIndex(tb => (tb ? tb.path === tab : props.tabs[0].path));

    if (!tab) {
      navigate({ pathname: `${props.tabs[0]?.path}`, search: props.tabs[0]?.handle && props.tabs[0].handle.keepParams ? search : undefined }, { replace: true });
    }

    if (value !== tabNb && tabNb > -1) {
      setValue(tabNb);
    }
  }, [tab]);

  useEffect(() => {
    if (props.tabsDisplayService && id) {
      props.tabsDisplayService.get(id)
        .then((res) => {
          props.tabs.forEach((tb) => {
            if (tb.path) {
              if (props.tabsFunctions?.[tb.path as keyof typeof props.tabsFunctions] && id) {
                setState(prevState => ({ [tb.path as string]: 'hidden', ...prevState }));
                props.tabsFunctions?.[tb.path as keyof typeof props.tabsFunctions]({ id, data: res.data })
                  .then(r => setState(prevState => ({ ...prevState, [tb.path as string]: r })))
                  .catch(() => setState(prevState => ({ ...prevState, [tb.path as string]: 'disabled' })));
              } else {
                setState(prevState => ({ ...prevState, [tb.path as string]: 'visible' }));
              }
            } else {
              setState(prevState => ({ ...prevState, [tb.path as string]: 'visible' }));
            }
          });
        })
        .catch(() => {
          props.tabs.forEach((tb) => {
            setState(prevState => ({ ...prevState, [tb.path as string]: 'disabled' }));
          });
        });
    } else {
      props.tabs.forEach((tb) => {
        if (tb.path) {
          if (props.tabsFunctions?.[tb.path as keyof typeof props.tabsFunctions] && id) {
            setState(prevState => ({ [tb.path as string]: 'hidden', ...prevState }));
            props.tabsFunctions?.[tb.path as keyof typeof props.tabsFunctions]({ id })
              .then(res => setState(prevState => ({ ...prevState, [tb.path as string]: res })))
              .catch(() => setState(prevState => ({ ...prevState, [tb.path as string]: 'disabled' })));
          } else {
            setState(prevState => ({ ...prevState, [tb.path as string]: 'visible' }));
          }
        } else {
          setState(prevState => ({ ...prevState, [tb.path as string]: 'visible' }));
        }
      });
    }
  }, [props.tabs]);

  return (
    <Grid container p={2} style={{ flexWrap: 'nowrap' }}>
      <Grid xs={11} sx={{ borderBottom: 'solid 1px', borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor='primary'
          textColor='primary'
          variant='scrollable'
        >
          {props.tabs.map((tabInfo) => {
            const tabSpec = props.tabs.find(tb => tb.path === tabInfo?.path);

            return (
              tabSpec && tabSpec.path ?
                <Tab
                  key={tabSpec.path}
                  label={
                    <Stack direction='row' spacing={2} alignItems='center'>
                      <Typography variant='h5'>{upperFirst(t(`${tabSpec.i18nKey}`) || tabSpec.id || tabSpec.path)}</Typography>
                      {tabInfo.handle?.tabChips && tabInfo.handle.tabChips.map((tabChip: string | undefined) => (tabChip &&
                        <Chip key={tabChip} label={tabChip} color='secondary' size='small' sx={{ mt: 1, cursor: 'pointer' }} />
                      ))
                      }
                    </Stack>
                  }
                  disabled={state[tabSpec.path] === 'disabled'}
                  sx={{
                    display: state[tabSpec.path] === 'hidden' ? 'none' : undefined
                  }}
                  disableRipple
                />
                : undefined
            );
          }).filter(x => !!x)}
        </Tabs>
      </Grid>
      <Grid xs={1} sx={{ borderBottom: 'solid 1px', borderColor: 'divider' }} style={{ textAlign: 'end', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
        <IconButton
          onClick={() => window.open(`${basename}${pathname}${search}`, '_blank', '')}
          size='small'
        >
          <IconExternalLink size={20} />
        </IconButton>
      </Grid>
    </Grid>
  );
}

export default CustomTabs;
