import React from "react";
import { useTranslation } from "react-i18next";
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  Tooltip,
  Typography,
  useTheme
} from "@mui/material";
import { IconAmpersand, IconArrowsRight } from "@tabler/icons-react";
import { VennDiagramSegment } from "../types.vennDiagram";

type ActionBehaviorProps = {
  segment: VennDiagramSegment,
  modifyBehavior: (propertyLabel: keyof VennDiagramSegment, value: "intersection" | "union", segmentRef: VennDiagramSegment) => void,
  hideUnion?: boolean
}

function ActionBehavior(props: ActionBehaviorProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const behaviorType = [
    { icon: <IconAmpersand />, label: t('common.utils.and'), type: 'intersection', behavior: t('segmentation.vennDiagram.builder.segments.behavior.intersection') },
    ...(props.hideUnion ? [] : [{ icon: <IconArrowsRight />, label: t('common.utils.or'), type: 'union', behavior: t('segmentation.vennDiagram.builder.segments.behavior.union')  }])
  ];

  return (
    <Stack spacing={6} p={4} width='fit-content'>
      <FormControl required>
        <FormLabel id='demo-controlled-radio-buttons-group' sx={{ fontSize: '16px', color: theme.palette.text.primary }}>
          {t('segmentation.vennDiagram.builder.segments.behavior.select')}
        </FormLabel>
        <RadioGroup
          row
          aria-labelledby='demo-controlled-radio-buttons-group'
          name='controlled-radio-buttons-group'
          value={props.segment?.behavior || 'intersection'}
          onChange={(value) => {
            props.modifyBehavior('behavior', value.target.value as "intersection" | "union", props.segment);
          }}
        >
          {behaviorType.map(type => (
            <Tooltip key={type.type} title={type.behavior} placement='top' arrow>
              <FormControlLabel
                value={type.type}
                control={<Radio />}
                sx={{
                  mr: 6
                }}
                label={
                  <Stack direction='row' spacing={2} alignItems='center'>
                    {type.icon}
                    <Typography variant='h5'>{t(`${type.label}`)}</Typography>
                  </Stack>
                }
              />
            </Tooltip>
          ))}
        </RadioGroup>
        {props.segment.behavior && <FormHelperText>{t('common.utils.required')}</FormHelperText>}
      </FormControl>
    </Stack>
  );
}

export default ActionBehavior;
