import React, { ReactElement, useEffect, useState } from 'react';
import { BodaccDataBlock, IDataBlock } from '@deecision/dna-interfaces';
import { useTranslation } from 'react-i18next';
import { get, upperFirst } from 'lodash';
import { LocalDate } from '@js-joda/core';
// eslint-disable-next-line no-restricted-imports
import { deepClone } from '@mui/x-data-grid/utils/utils';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';
import Popper from '@mui/material/Popper';
import { BodaccElementsProps, FilterGroupChoices } from './types';
import { getParsedData } from './utils';

function BodaccFilters(props: { parsedData: IDataBlock<BodaccDataBlock>[], setFilters: (f: FilterGroupChoices<BodaccDataBlock>[]) => void } & Omit<BodaccElementsProps, 'select' | 'selectedId' | 'criticity' | 'years'>): ReactElement {
  const { t } = useTranslation();
  const [open, setOpen] = useState('');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [filters, setFilters] = useState<FilterGroupChoices<BodaccDataBlock>[]>([]);
  const [baseFilters, setBaseFilters] = useState<FilterGroupChoices<BodaccDataBlock>[]>([]);

  const handleOpen = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, value: string) => {
    setAnchorEl(event.currentTarget);
    setOpen(value);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen('');
  };

  const handleChange = (f: typeof filters, simple?: true) => {
    setFilters(f);

    if (!simple) {
      const tmpAnchor = anchorEl;

      setAnchorEl(null);
      setTimeout(() => {
        setAnchorEl(tmpAnchor);
      }, 300);
    }
  };

  useEffect(() => {
    const filterGroups: FilterGroupChoices<BodaccDataBlock>[] = [
      {
        key: 'dateparution',
        value: 'years',
        checker: '',
        subFiltersKey: 'dataInfo.dataTimestamp',
        subFilters: []
      },
      {
        key: 'familleavis_lib',
        value: 'Modifications diverses',
        checker: 'Modification',
        subFiltersKey: '_deecision.json.modificationsgenerales.descriptif',
        subFilters: []
      },
      {
        key: 'familleavis_lib',
        value: 'Dépôts des comptes',
        checker: 'Comptes',
        subFiltersKey: '_deecision.json.depot.typeDepot',
        subFilters: []
      },
      {
        key: 'familleavis_lib',
        value: '',
        checker: '',
        subFiltersKey: 'familleavis_lib',
        subFilters: []
      }
    ];

    props.datas.forEach((data) => {
      filterGroups.forEach((filterGroup) => {
        if (data.data[filterGroup.key] === filterGroup.value) {
          if (!filterGroup.subFilters.find(subFilter => subFilter.value === upperFirst(get(data.data, filterGroup.subFiltersKey).replaceAll('.', '')))) {
            filterGroup.subFilters.push({ key: filterGroup.subFiltersKey, value: upperFirst(get(data.data, filterGroup.subFiltersKey).replaceAll('.', '')), status: true });
          }
        } else if (filterGroup.value === 'years') {
          if (data.dataInfo.dataTimestamp && !filterGroup.subFilters.find(setFilter => data.dataInfo.dataTimestamp && setFilter.value === LocalDate.parse(data.dataInfo.dataTimestamp).year().toString())) {
            filterGroup.subFilters.push({
              key: filterGroup.subFiltersKey,
              value: LocalDate.parse(data.dataInfo.dataTimestamp).year().toString() || '',
              status: true
            });
          }
        } else if (filterGroup.value === '' && !filterGroups.find(fg => data.data[filterGroup.key] === fg.value)) {
          if (!filterGroup.subFilters.find(subFilter => subFilter.value === upperFirst(get(data.data, filterGroup.subFiltersKey)))) {
            filterGroup.subFilters.push({
              key: filterGroup.subFiltersKey,
              value: upperFirst(get(data.data, filterGroup.subFiltersKey)),
              status: true
            });
          }
        }
      });
    });

    filterGroups.find(filterGroup => filterGroup.value === 'years')?.subFilters.sort((a, b) => parseInt(b.value, 10) - parseInt(a.value, 10));

    setFilters(deepClone(filterGroups));
    setBaseFilters(deepClone(filterGroups));
  }, [props.datas]);

  useEffect(() => {
    props.setFilters(filters);
  }, [filters]);

  return (
    <Box width='100%' pl={4} pr={4}>
      <Stack direction='row' alignItems='center' spacing={0}>
        {filters.map((filterGroup, index) => (filterGroup.subFilters.length > 0 &&
          filterGroup.value !== '' ?
          <Stack
            key={filterGroup.value}
            direction='row'
            alignItems='center'
            onMouseEnter={event => handleOpen(event, filterGroup.value)}
            onMouseLeave={handleClose}
            sx={{ ml: filterGroup.value !== 'years' ? 2 : undefined }}
          >
            {filterGroup.value === 'years' ?
              <Typography>{t('filters.years')}</Typography> :
              <FormGroup aria-orientation='horizontal'>
                <FormControlLabel
                  key={`label-${filterGroup.subFilters.every(subFilter => subFilter.status)}-${filterGroup.subFilters.every(subFilter => subFilter.status)}`}
                  control={
                    <Checkbox
                      key={`${filterGroup.subFilters.every(subFilter => subFilter.status)}-${filterGroup.subFilters.every(subFilter => subFilter.status)}`}
                      checked={filterGroup.subFilters.every(subFilter => subFilter.status)}
                      indeterminate={filterGroup.subFilters.some(subFilter => subFilter.status) && !filterGroup.subFilters.every(subFilter => subFilter.status)}
                      onChange={() => {
                        const tmpFilters = [...filters];

                        if (tmpFilters[index].subFilters.every(subFilter => subFilter.status)) {
                          tmpFilters[index].subFilters.forEach((tmpSubFilter, j) => {
                            tmpFilters[index].subFilters[j].status = false;
                          });
                        } else {
                          tmpFilters[index].subFilters.forEach((tmpSubFilter, j) => {
                            tmpFilters[index].subFilters[j].status = true;
                          });
                        }
                        handleChange(tmpFilters);
                      }}
                    />
                  }
                  label={`${filterGroup.value} (${
                    filterGroup.subFilters.every(subFilter => subFilter.status) ?
                      (getParsedData(props.datas, filters).length - getParsedData(props.parsedData, filters, index).length) :
                      filterGroup.subFilters.some(subFilter => subFilter.status) ?
                        (getParsedData(props.datas, filters).length - getParsedData(props.parsedData, baseFilters, index).length) :
                        (getParsedData(props.datas, filters, index).length - getParsedData(props.parsedData, filters).length)
                  })`}
                />
              </FormGroup>
            }
            {open === filterGroup.value ?
              <IconChevronUp size={16} style={{
                marginLeft: filterGroup.value !== 'years' ? '-8px' : '8px',
                marginRight: '12px',
                cursor: 'pointer'
              }}/> :
              <IconChevronDown size={16} style={{
                marginLeft: filterGroup.value !== 'years' ? '-8px' : '8px',
                marginRight: '12px',
                cursor: 'pointer'
              }}/>
            }
            <Popper
              open={open === filterGroup.value}
              anchorEl={anchorEl}
              sx={{
                zIndex: 999,
                bgcolor: 'background.paper',
                pt: 2,
                pb: 2,
                borderRadius: 1,
                boxShadow: 4,
                maxHeight: '320px',
                overflowY: 'auto'
              }}
            >
              {filterGroup.subFilters.map((subFilter, jIndex) => (
                <Box key={subFilter.value} pl={4} pr={4}>
                  <FormGroup aria-orientation='horizontal'>
                    <FormControlLabel
                      key={`label-${subFilter.value}`}
                      control={
                        <Checkbox
                          key={`${subFilter.value}-${subFilter.status}`}
                          checked={subFilter.status}
                          onChange={() => {
                            const tmpFilters = [...filters];

                            tmpFilters[index].subFilters[jIndex].status = !subFilter.status;
                            handleChange(tmpFilters);
                          }}
                        />
                      }
                      label={`${subFilter.value} (${
                        subFilter.status ?
                          getParsedData(props.datas, filters).length - getParsedData(props.parsedData, filters, index, jIndex).length :
                          getParsedData(props.datas, filters, index, jIndex).length - getParsedData(props.parsedData, filters, index, jIndex).length
                      })`}
                    />
                  </FormGroup>
                </Box>
              ))}
            </Popper>
          </Stack>
          :
          <Box key={filterGroup.key}>
            {filterGroup.subFilters.map((subFilter, jIndex) => (
              <FormControlLabel
                key={subFilter.value}
                sx={{ ml: 2 }}
                control={
                  <Checkbox
                    key={`${subFilter.value}-${subFilter.status}`}
                    checked={subFilter.status}
                    onChange={() => {
                      const tmpFilters = [...filters];

                      tmpFilters[index].subFilters[jIndex].status = !subFilter.status;
                      handleChange(tmpFilters, true);
                    }}
                  />
                }
                label={`${subFilter.value} (${
                  subFilter.status ?
                    getParsedData(props.datas, filters).length - getParsedData(props.parsedData, filters, index, jIndex).length :
                    getParsedData(props.datas, filters, index, jIndex).length - getParsedData(props.parsedData, filters, index, jIndex).length
                })`}
              />
            ))}
          </Box>
        ))}
      </Stack>
    </Box>
  );
}

export default BodaccFilters;
