import { upperFirst } from 'lodash';
import { BaseEntitiesService, BaseEntitiesServiceProps } from '@/api';
import dnaConfig from '../../../../config/dna.config.json';
import axiosInstance from '../../../../api';

export interface BaseDnaEntitiesServiceProps extends Pick<BaseEntitiesServiceProps, 'entityPathUrl'> {
  entityType?: string
}

class BaseDnaRncsEntitiesService<Entity> extends BaseEntitiesService<Entity> {
  constructor(props: BaseDnaEntitiesServiceProps) {
    super({
      entityPathUrl: props.entityType?.includes('rncs') ? props.entityType : `rncs${upperFirst(props.entityType) || ''}`,
      // entityPathUrl: props.entityType,
      entityPrefixUrl: dnaConfig.api.url.entitiesPrefix,
      axiosInstance, ...props
    });
  }
}

export default BaseDnaRncsEntitiesService;
