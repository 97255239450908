import React, { ReactElement, useEffect, useState } from 'react';
import Stack from '@mui/material/Stack';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';

function FallbackComponent(): ReactElement {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    setProgress(0);

    const timer = setInterval(() => {
      setProgress(prevProgress => (prevProgress < 100 ? prevProgress + 10 : 100));
    }, 200);
    
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <Stack
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
        alignItems: 'center'
      }}
    >
      <Typography variant='body1' style={{ marginBottom: '4px' }}>Loading...</Typography>
      <LinearProgress
        variant={progress >= 100 ? 'indeterminate' : 'determinate'}
        value={progress}
        color='primary'
        style={{ width: '400px', borderRadius: '12px' }}
      />
    </Stack>
  );
}

export default FallbackComponent;
