import BooleanFilterTypeForm from './boolean';
// eslint-disable-next-line import/no-cycle
import ComboFilterTypesForm from './combo';
import RadioFilterTypeForm from './radio';
import SelectFilterTypeForm from './select';
import TextFilterTypeForm from './text';

const filterTypesMap = {
  'boolean': BooleanFilterTypeForm,
  'combo': ComboFilterTypesForm,
  'radio': RadioFilterTypeForm,
  'select': SelectFilterTypeForm,
  'text': TextFilterTypeForm
};

export default filterTypesMap;
