import { Params } from 'react-router-dom';
import { ApiResponse, ApiArrayResponse } from '@deecision/deecision-interfaces';
import { BaseEntitiesService, CustomBaseService } from '@/api';
import ServiceError from '@/api/services/error';
import { makeFindOptions } from '@/utils';

export interface LoaderProps<T> {
  service?: BaseEntitiesService<T>,
  params?: Params<string>,
  request?: Request,
  idGetAll?: true,
  forceGetId?: true
}

export interface CustomGetterLoaderProps<T, U extends CustomBaseService<T>> {
  service?: U,
  params?: Params<string>,
  request?: Request,
  idGetAll?: true,
  forceGetId?: true
}

async function loader<T>(props: LoaderProps<T>): Promise<ApiResponse<T> | ApiArrayResponse<T>> {
  let res;
  const url = new URL(props.request?.url || '');

  if ((props.service && props.params?.id && props.forceGetId) || (props.service && (props.params?.id && !props.idGetAll))) {
    res = await props.service.get(props.params.id, props.params);
  } else if (props.service) {
    res = await props.service.getAll(makeFindOptions(url));
  }

  return (
    res ||
    new Promise((r, reject) => {
      reject(new ServiceError(999, 'No Service Found'));
    })
  );
}

export async function customGetterLoader<T, U extends CustomBaseService<T>>(props: CustomGetterLoaderProps<T, U>): Promise<ApiResponse<T> | ApiArrayResponse<T>> {
  let res;
  const url = new URL(props.request?.url || '');

  if ((props.service && props.params?.id && props.forceGetId) || (props.service && (props.params?.id && !props.idGetAll))) {
    res = await props.service.get(props.params.id, makeFindOptions(url), props.params);
  } else if (props.service) {
    res = await props.service.getAll(makeFindOptions(url));
  }

  return (
    res ||
    new Promise((r, reject) => {
      reject(new ServiceError(999, 'No Service Found'));
    })
  );
}

export default loader;
