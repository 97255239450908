import React, { ReactElement, useEffect, useState } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { IconChartCircles, IconFilterSearch, IconRotate, IconUsers } from '@tabler/icons-react';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { CompositeItemSpec, SegmentationData } from '@deecision/dna-interfaces';
import IconBuildings from '@/assets/custom/IconBuildings';
import ChipFilters from '@/components/filters/chip';
import { BaseSegmentationProps } from '../../types.segmentations';

export interface FiltersSegmentationsProps extends BaseSegmentationProps {
  segmentationData?: SegmentationData,
  reset: () => void,
  listRef?: HTMLDivElement | null
}

function FiltersCriteriasSegmentations(props: FiltersSegmentationsProps): ReactElement {
  const theme = useTheme();
  const { t } = useTranslation();
  const [disableReset, setDisableReset] = useState(true);

  const handleScroll = () => {
    if (props.listRef) {
      props.listRef?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    setDisableReset(false);
  }, [props.filters]);

  return (
    <Grid container spacing={4} alignItems='center'>
      <Grid>
        <Paper
          onClick={handleScroll}
          sx={{ cursor: props.listRef ? 'pointer' : 'default' }}
        >
          <Stack direction='row' spacing={2} p={1} alignItems='center'>
            {props.segmentationData?.objectType === 'deecPerson' ? <IconUsers /> : props.segmentationData?.objectType === 'deecCompany' ? <IconBuildings /> : <IconChartCircles />}
            <Typography variant='h4'>{props.segmentationData?.totalCount || 0}</Typography>
            <Typography variant='h4'>{`${(props.segmentationData?.totalCount || 0) > 0 ?
              t(`entities.${props.segmentationData?.objectType === 'deecPerson' ? 'person' : props.segmentationData?.objectType === 'deecCompany' ? 'company' : 'links'}.multiple`) :
              t(`entities.${props.segmentationData?.objectType === 'deecPerson' ? 'person' : props.segmentationData?.objectType === 'deecCompany' ? 'company' : 'links'}.label`)
            } ${t('segmentation.render.segmentationCriterias.matching').replace(' x ', ` ${props.filters.length.toString()} `)}`}</Typography>
          </Stack>
        </Paper>
      </Grid>
      <Grid xs>
        <Paper sx={{ width: '100%' }}>
          <Grid container spacing={2} p={props.filters.length < 1 ? 1 : 0} alignItems='center'>
            <Grid mr={2}>
              <Box height={24}>
                <IconFilterSearch />
              </Box>
            </Grid>
            {props.filters.length > 0 ?
              [...props.filters.map(filter => (
                <Grid key={filter.id}>
                  <ChipFilters
                    color='primary'
                    value={
                      <Stack direction='row' spacing={2}>
                        <Typography>
                          {`${filter.label} ${props.filters.filter(f => f.id !== filter.id).find(f => f.filterId === filter.filterId) ? `(${t(
                            `segmentation.builder.config.advanced.on.${filter.on}`
                          ).split(':')[0]})` : ''}`}
                        </Typography>
                        {filter.values?.map(value => (
                          <Chip
                            key={value.toString()}
                            sx={{ bgcolor: theme.palette.primary.main, color: theme.palette.primary.contrastText }}
                            label={typeof value === 'boolean' ? value ? 'Yes' : 'No' : value.toString()} // TODO: Format Value before .toString()
                            size='small'
                          />
                        ))}
                        {filter.ranges?.map(range => (
                          <Chip
                            key={`${range.min} - ${range.max}`}
                            sx={{ bgcolor: theme.palette.primary.main, color: theme.palette.primary.contrastText }}
                            label={`${range.min} - ${range.max}`} // TODO: Format Value before displaying it
                            size='small'
                          />
                        ))}
                        {(filter as CompositeItemSpec).subItemSpecs &&
                          <Tooltip title={(filter as CompositeItemSpec).subItemSpecs?.map(subItem => subItem.id).join(', ') || t('common.utils.unknown')} placement='top' arrow>
                            <Chip
                              sx={{ bgcolor: theme.palette.primary.main, color: theme.palette.primary.contrastText }}
                              label={`${((filter as CompositeItemSpec).subItemSpecs?.length || 0)} ${((filter as CompositeItemSpec).subItemSpecs?.length || 0) > 1 ? t('filters.label') : t('filters.unique')}`}
                              size='small'
                            />
                          </Tooltip>
                        }
                      </Stack>
                    }
                    onDelete={() => filter.id && props.removeFilter(filter.id)}
                    onClick={() => undefined}
                  />
                </Grid>
              )),
              <Grid>
                <Tooltip title={t('common.utils.reset')} placement='top' arrow>
                  <IconButton
                    onClick={() => {
                      setDisableReset(true);
                      props.reset();
                    }}
                    size='small'
                    disabled={disableReset}
                  >
                    <IconRotate size={20} style={{ transform: 'rotate(180deg)' }} />
                  </IconButton>
                </Tooltip>
              </Grid>] :
              <Grid>
                <Typography>
                  {t('segmentation.render.segmentationCriterias.filters.noFilters')}
                </Typography>
              </Grid>
            }
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default FiltersCriteriasSegmentations;
