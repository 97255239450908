import { BodaccDataBlock, IDataBlock } from '@deecision/dna-interfaces';
import { LocalDate } from '@js-joda/core';
// eslint-disable-next-line no-restricted-imports
import { deepClone } from '@mui/x-data-grid/utils/utils';
import { get, sortBy, upperFirst } from 'lodash';
import { FilterGroupChoices } from './types';

export function bodaccDataSort( a: IDataBlock, b: IDataBlock ) {
  if (!a.dataInfo.dataTimestamp) {
    return -1;
  }
  if (!b.dataInfo.dataTimestamp) {
    return 1;
  }

  const d1 = LocalDate.parse(a.dataInfo.dataTimestamp);
  const d2 = LocalDate.parse(b.dataInfo.dataTimestamp);

  if ( d1.isAfter(d2) ) {
    return -1;
  }
  if ( d2.isAfter(d1) ) {
    return 1;
  }

  return 0;
}

export function getParsedData(data: IDataBlock<BodaccDataBlock>[], filters: FilterGroupChoices<BodaccDataBlock>[], index?: number, jIndex?: number): IDataBlock<BodaccDataBlock>[] {
  let dataTmp: IDataBlock<BodaccDataBlock>[] = deepClone(data);
  const filtersTmp: FilterGroupChoices<BodaccDataBlock>[] = deepClone(filters);

  if (filtersTmp.length > 0) {
    if (index !== undefined && jIndex === undefined) {
      if (filtersTmp[index].subFilters.every(subFilter => subFilter.status)) {
        filtersTmp[index].subFilters.forEach((tmpSubFilter, j) => {
          filtersTmp[index].subFilters[j].status = false;
        });
      } else {
        filtersTmp[index].subFilters.forEach((tmpSubFilter, j) => {
          filtersTmp[index].subFilters[j].status = true;
        });
      }
    } else if (index !== undefined && jIndex !== undefined) {
      filtersTmp[index].subFilters[jIndex].status = !filtersTmp[index].subFilters[jIndex].status;
    }

    filtersTmp.forEach((filter) => {
      if (filter.value === 'years') {
        filter.subFilters.forEach((subFilter) => {
          if (!subFilter.status) {
            dataTmp = dataTmp.filter(dTmps => !(LocalDate.parse(get(dTmps, subFilter.key)).year().toString() === subFilter.value));
          }
        });
      } else if (filter.value === '') {
        filter.subFilters.forEach((subFilter) => {
          if (!subFilter.status) {
            dataTmp = dataTmp.filter(dTmps => !(upperFirst(get(dTmps.data, subFilter.key)) === subFilter.value));
          }
        });
      } else {
        filter.subFilters.forEach((subFilter) => {
          if (!subFilter.status) {
            dataTmp = dataTmp.filter(dTmps => !(upperFirst(get(dTmps.data, subFilter.key)).replaceAll('.', '') === subFilter.value));
          }
        });
      }
    });
  }

  return dataTmp;
}

export function getYearsBodacc(datas: IDataBlock<BodaccDataBlock>[], sort?: 'asc' | 'dsc') {

  const yearsTmp: string[] = [];

  datas.forEach((d) => {
    if (d.dataInfo.dataTimestamp && !yearsTmp.find(year => d.dataInfo.dataTimestamp && year === LocalDate.parse(d.dataInfo.dataTimestamp).year().toString())) {
      yearsTmp.push(LocalDate.parse(d.dataInfo.dataTimestamp).year().toString());
    }
  });

  return (!sort || sort === 'asc') ? sortBy(yearsTmp).reverse() : sortBy(yearsTmp);
}
