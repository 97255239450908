import { EntityLinksAggregation } from '@deecision/dna-interfaces';

export const calculateLowestYAndLengthAtX = (previousState: any[], parentNode: { position: { x: number, y: number } }, offsetXParentChildren: number) => {
  let lowestYPosition = 0;
  let highestYPosition = 0;
  let nodeCount = 0;
  let initialized = false;

  previousState.forEach((node: any) => {
    if (node.position.x === parentNode.position.x + offsetXParentChildren) {
      nodeCount++;
      if (node.position.y < lowestYPosition) {
        lowestYPosition = node.position.y;
        initialized = true;
      }
      if (node.position.y > highestYPosition) {
        highestYPosition = node.position.y;
      }
    }
  });
  if (lowestYPosition === 0 && !initialized) lowestYPosition = parentNode.position.y;

  return ({ lowestYPosition, highestYPosition, nodeCount });
};

export const countNodes = (nodes: any[]) =>
  nodes.reduce((count, node) => ((node.links[0].type === 'has.representative' || node.links[0].type === 'has.shareholder')
    ? count + 1
    : count), 0);

export const getHighestPercentageShare = (elements: EntityLinksAggregation) => {
  let actualSharePercentage = -1;

  elements.links.forEach((link) => {
    const sharesPercentage = link.details?.sharesPercentage;

    if (sharesPercentage !== undefined && sharesPercentage > actualSharePercentage) {
      actualSharePercentage = link.details?.sharesPercentage || 0;
    }
  });

  return (actualSharePercentage);
};

export const getTotalPercentageShare = (elementsPerson: EntityLinksAggregation[], elementsCompanies: EntityLinksAggregation[]) => {
  let shareholderTotalPercentage = 0;

  elementsPerson.forEach((person) => {
    // if (( person.relations[0].type === 'has.shareholder' || person.relations[0].details?.positionTypes?.includes('sha'))) {
    shareholderTotalPercentage += getHighestPercentageShare(person);
    // }
  });

  elementsCompanies.forEach((companie) => {
    const isParent = companie.links[0].type === 'has.representative' || companie.links[0].type === 'has.shareholder';
    if (isParent && companie.links[0].type === 'has.shareholder') {
      shareholderTotalPercentage += getHighestPercentageShare(companie);;
    }
  });

  return (shareholderTotalPercentage);
};
