import React, { ReactElement, useEffect, useLayoutEffect, useState } from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import { AxiosInstance } from 'axios';

export interface LoaderLayoutProps {
  axiosInstance: AxiosInstance
}

function LoaderLayout(props: LoaderLayoutProps): ReactElement {
  const [progress, setProgress] = useState(0);
  const [callState, setCallState] = useState<'sent' | 'received' | null>(null);

  useLayoutEffect(() => {
    setProgress(100);
  }, []);

  useEffect(() => {
    setProgress(0);
    props.axiosInstance.interceptors.request.use((config) => {
      if (!config.params?.autocomplete) {
        setCallState('sent');
      }

      return config;
    }, ((error) => {
      setCallState('received');

      return Promise.reject(error);
    }));

    props.axiosInstance.interceptors.response.use((res) => {
      if (!res.config?.params?.autocomplete) {
        setCallState('received');
      }

      return res;
    }, ((error) => {
      setCallState('received');

      return Promise.reject(error);
    }));
  }, []);

  useEffect(() => {
    if (callState === 'sent' && progress < 100) {
      setTimeout(() => {
        if (progress < 1) {
          setProgress(1);
        } else {
          setProgress(old => old + 5);
        }
      }, 40);
    } else if (callState === 'received') {
      setTimeout(() => {
        if (progress < 100) {
          setProgress(old => old + 20);
        } else {
          setCallState(null);
          setProgress(0);
        }
      }, 80);
    } else if (!callState) {
      setProgress(0);
    }
  }, [callState, progress]);

  return (
    <LinearProgress
      variant={progress < 100 ? 'determinate' : 'indeterminate'}
      value={progress}
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        display: progress === 0 ? 'none' : undefined
      }}
    />
  );
}

export default LoaderLayout;
