import React, { useEffect, useState } from "react";
import { Grid, Stack, Typography, useTheme } from "@mui/material";
import { upperFirst } from "lodash";
import KeyValueWithCard from "@/components/keyvalue/keyvaluewithcard";
import { DataElementsQueryService } from "../../../../../api/services/query";

interface DependenciesProps {
  dependencies: Dependencies[]
}

type Dependencies = {
  type: string,
  entityRef: {
      entityType: string,
      entityId: string,
      name?: string | undefined
  },
  available: boolean,
  dataElementIds: string[],
  lastModified: string,
  featureId: string,
  needAvailableRecalc: boolean
}

enum STATUS {
  done,
  failed,
  requested,
  waiting_for_dependencies,
  created,
  updateNeeded,
  inProgress,
  startable
}

function DependenciesHeaderCard(dependencies: DependenciesProps) {
  const theme = useTheme();
  const initDependenciesStatusObjValue = [
    {
      label: 'done',
      count: 0,
      bgColor: theme.palette.success.light as string,
      color: 'grey'
    },
    {
      label: 'failed',
      count: 0,
      bgColor: theme.palette.error.light as string,
      color: 'grey'
    },
    {
      label: 'requested',
      count: 0,
      bgColor: theme.palette.info.light as string,
      color: 'grey'
    },
    {
      label: 'waiting',
      count: 0,
      bgColor: theme.palette.secondary.light as string,
      color: 'grey'
    },
    {
      label: 'created',
      count: 0,
      bgColor: 'grey',
      color: 'black'
    },
    {
      label: 'updateNeeded',
      count: 0,
      bgColor: 'grey',
      color: 'black'
    },
    {
      label: 'inProgress',
      count: 0,
      bgColor: 'grey',
      color: 'black'
    },
    {
      label: 'startable',
      count: 0,
      bgColor: 'grey',
      color: 'black'
    }
  ];
  const dataElementService = new DataElementsQueryService({ entityType: 'deecCompany' });
  const [dependenciesStatusObj, setDependenciesStatusObj] = useState<typeof initDependenciesStatusObjValue>(initDependenciesStatusObjValue);

  useEffect(() => {
    const tmpObj = initDependenciesStatusObjValue;

    dependencies.dependencies.forEach((dependency: Dependencies) => {
      dependency.dataElementIds.forEach((id: string) => {
        const query = {
          '_id': id
        };
        dataElementService.findInAllEntities(query).then((res) => {
          if (res.data) {
            tmpObj[STATUS[res.data[0].scheduling.status]].count += 1;
            setDependenciesStatusObj(tmpObj);
          }
        });
      });
    });
  }, []);

  return (
    <Grid key='criticity' container spacing={2} width='257px' flexDirection='column'>
      <Grid container flexDirection='column'>
        <Stack width='100%' flexDirection='row'>
          <Typography width='90%' color='#949494'>partialSets</Typography>
          <Typography fontWeight={500}>x%</Typography>
        </Stack>
        <Stack width='100%' flexDirection='row'>
          <Typography width='90%' color='#949494'>percentageDone</Typography>
          <Typography fontWeight={500}>{dependenciesStatusObj.length > 0 ? dependenciesStatusObj[STATUS.done].count : '0'}</Typography>
        </Stack>
      </Grid>
      <Grid container width='100%' flexDirection='row' ml='4px'>
        {dependenciesStatusObj.map((status, index) => (
          index > 3 ? <></> :
            <Grid key={status.label} padding={1}>
              <KeyValueWithCard
                keyValueCard={{
                  bgColor: status.bgColor,
                  color: status.color,
                  Key: upperFirst(status.label),
                  width: '120px',
                  height: '42px',
                  padding: '10px 8px',
                  size: 'small',
                  value: status.count
                }}
              />
            </Grid>
        ))}
      </Grid>
    </Grid>
  );
}

export default DependenciesHeaderCard;
