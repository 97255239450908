import { TabFunction } from "@/layouts/tabs";
import dnaConfig from "../../../../../../config/dna.config.json";
import { newsFunction, veegilenzFunction, weasseFunction } from "../../../components/tabs/tabsfunctions.tabs";
import { veegilenzPersonPaths } from "../../../../../containers/veegilenz/veegilenz";

export const tabsFunctions: Record<string, TabFunction> = {
  [dnaConfig.routes.persons.tabs.news.baseUrl]: newsFunction,
  [dnaConfig.routes.persons.tabs.weasse.baseUrl]: weasseFunction,
  [dnaConfig.routes.persons.tabs.veegilenz.baseUrl]: (props: { id: string, data?: unknown }) => veegilenzFunction({ ...props, paths: veegilenzPersonPaths })
};
