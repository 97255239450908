import React, { ReactElement } from 'react';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import Link from '@mui/material/Link';
import { IconTrash } from '@tabler/icons-react';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import { useNavigate } from 'react-router-dom';
import { startCase } from 'lodash';
import { CurrencyFormatterProps } from '@/utils';
import EvenOddTable from '@/components/tables/evenodd/table';
import { ListProps } from '../../lists/types';
import {
  BaseCustomUserData,
  CustomSegmentationData,
  CustomUserData,
  CustomUserDataAccess
} from '../types.segmentations';
import { Column } from '@/components/tables/types';
import dnaConfig from '../../../../config/dna.config.json';
import {
  GroupDataCustomSegmentationService,
  UserDataCustomSegmentationService
} from '../services/segmentations.services';

const getDataService = (accessTmp: CustomUserDataAccess, userId: string) => (accessTmp === 'me' ?
  new UserDataCustomSegmentationService<BaseCustomUserData<CustomSegmentationData>>({ userId }) :
  new GroupDataCustomSegmentationService<BaseCustomUserData<CustomSegmentationData>>({ groupId: accessTmp }));

function ListSegmentations(props: ListProps<CustomUserData<unknown>> & { updateList?: () => void }): ReactElement {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const columns: Column<CurrencyFormatterProps>[] = [
    {
      id: 'name',
      label: t('common.identity.name')
    },
    {
      id: 'segmentationCriterias',
      label: t('segmentation.render.segmentationCriterias.label')
    },
    {
      id: 'creationDate',
      label: t('common.dates.creationDate')
    },
    {
      id: 'lastUpdate',
      label: t('common.dates.lastUpdate')
    },
    {
      id: 'visibleFor',
      label: t('common.utils.visibleFor')
    },
    {
      id: 'actions',
      label: '',
      align: 'right'
    }
  ];

  const rows = props.data.map(customUserData => ({
    id: customUserData._id,
    name: <Link href={`${dnaConfig.routes.admin.factory.segmentation.baseUrl}/${customUserData.groups?.[0]}/${customUserData._id as string}`}>{customUserData.title}</Link>,
    segmentationCriterias: (customUserData.data as CustomSegmentationData)?.potentialSegmentationCriterias?.length,
    creationDate: customUserData.date ? new Date(customUserData.date).toLocaleDateString() : undefined,
    lastUpdate: customUserData.lastUpdate ? new Date(customUserData.lastUpdate).toLocaleDateString() : undefined,
    visibleFor: customUserData.groups?.[0] === 'me' ? startCase(customUserData.ownerId.split('.').join(' ')) : customUserData.groups?.map(group => t(`segmentation.builder.config.access.${group}`))?.join(', '),
    actions: <Stack direction='row' justifyContent='flex-end'>
      <IconButton>
        <IconTrash
          onClick={customUserData.groups?.[0] ?
            () => {
              getDataService(customUserData.groups?.[0] as CustomUserDataAccess, customUserData.ownerId)?.delete?.(customUserData._id)
                .then(() => (props.updateList ? props.updateList() : navigate(0)));
            } :
            undefined
          }
        />
      </IconButton>
    </Stack>
  }));

  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <EvenOddTable<CurrencyFormatterProps> label='CustomSegmentation' {...props} columns={columns} rows={rows} localSort />
    </Box>
  );
}

export default ListSegmentations;
