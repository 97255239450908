import { BaseServiceProps } from './base.api';
import { BaseElementsService } from './elements.api';

export interface BaseEntitiesServiceProps extends Omit<BaseServiceProps, 'elementPrefixUrl'> {
  entityPrefixUrl: string,
  entityPathUrl?: string
}

export abstract class BaseEntitiesService<Entity> extends BaseElementsService<Entity> {
  protected constructor(props: BaseEntitiesServiceProps) {
    super({
      elementPrefixUrl: props.entityPathUrl ?
        `${props.entityPrefixUrl}${props.entityPathUrl.charAt(0) === '/' ? props.entityPathUrl : `/${props.entityPathUrl}`}` :
        props.entityPrefixUrl,
      ...props
    });
  }
}
