import { Tooltip } from '@mui/material';
import React from 'react';

export type ProgressBarPropsType = {
  done: number, // Percentage for the first color
  failed: number, // Percentage for the second color
  requested: number, // Percentage for the third color
  waitingForDependencies: number
};

function ProgressBar(props: ProgressBarPropsType) {
  const { done, failed, requested, waitingForDependencies } = props;
  const totalCompletion = done + failed + requested;

  const containerStyles = {
    height: 20,
    width: '100%',
    backgroundColor: "#e0e0de",
    borderRadius: 50,
    marginTop: 10,
    marginLeft: 50,
    marginBottom: 50
  };

  const fillerStyles = {
    height: '100%',
    width: `${totalCompletion}%`,
    backgroundColor: 'transparent',
    borderRadius: 'inherit',
    display: 'flex'
    // flexDirection: 'row',
  };

  const subFillerStyles = (leftRounded: boolean, percentage: number, color: string) => ({
    width: `${percentage}%`,
    borderRadius: (leftRounded ? 'inherit' : 0),
    backgroundColor: color
  });

  const percentageStyles = {
    marginTop: 20
  };

  return (
    <div style={containerStyles}>

      <div style={fillerStyles}>
        <Tooltip title={`Done: ${done}%`}>
          <div style={subFillerStyles(true, done, 'rgb(190, 234, 193)')}>
            <div style={percentageStyles}>
              {`${done}%`}
            </div>
          </div>
        </Tooltip>

        <Tooltip title={`Failed: ${failed}%`}>
          <div style={subFillerStyles(false, failed, 'rgb(255, 181, 175)')}>
            <div style={percentageStyles}>
              {`${failed}%`}
            </div>
          </div>
        </Tooltip>
        { requested !== 0 &&
          <Tooltip title={`Requested: ${requested}%`}>
            <div style={subFillerStyles(false, requested, 'rgb(196, 216, 246)')}>
              <div style={{ marginTop: 20, marginLeft: 40 }}>
                {`${requested}%`}
              </div>
            </div>
          </Tooltip>
        }
        <Tooltip title={`Waiting for dependencies: ${waitingForDependencies}%`}>
          <div style={subFillerStyles(false, waitingForDependencies, 'rgb(177, 242, 237)')}>
            <div style={{ marginTop: 20, marginLeft: 60 }}>
              {`${waitingForDependencies}%`}
            </div>
          </div>
        </Tooltip>
      </div>
    </div>
  );
}

export default ProgressBar;