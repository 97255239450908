import React, { ReactElement, useEffect, useState } from "react";
import { IDataElement } from "@deecision/dna-interfaces";
import { Chip, Grid, Stack } from "@mui/material";
import { IconBrandDatabricks } from "@tabler/icons-react";
import { upperFirst } from "lodash";
import TitleComponent from "@/components/title";
import KeyValueList, { KeyValue } from "@/components/keyvalue/keyvaluelist";
import HeaderComponents from "../../../../components/header";
import { DataElementsQueryService } from "../../../../api/services/query";
import DependenciesHeaderCard from "./components/dataElement.components.DependenciesHeaderCard";
import { WEBAPP_NAME } from "../../../../env/env";
import ActionPanel from "../Dashboard/components/actionPanel";

type Dependencies = {
  type: string,
  entityRef: {
      entityType: string,
      entityId: string,
      name?: string | undefined
  },
  available: boolean,
  dataElementIds: string[],
  lastModified: string,
  featureId: string,
  needAvailableRecalc: boolean
}

function DataElementDetailsHeader(): ReactElement {
  const dataElementId = window.location.pathname.split('/')[4];
  const localStorageParams = localStorage.getItem(`entityType${dataElementId}`);
  const entityType = (localStorageParams != null ?  localStorageParams : '');

  const dataElementServices = new DataElementsQueryService({ entityType });
  const [dataElement, setDataElement] = useState<IDataElement>();
  const [identity, setIdentity] = useState<KeyValue[]>([]);
  const [scheduling, setScheduling] = useState<KeyValue[]>([]);
  const [dataInfo, setDataInfo] = useState<KeyValue[]>([]);
  const [dependenciesId, setDependenciesId] = useState<Dependencies[]>([]);
  const [chips, setChips] = useState<ReactElement[]>([]);

  useEffect(() => {
    dataElementServices.findInAllEntities({ _id: dataElementId })
      .then((res : any) => {
        if (res.data) {
          setDataElement(res.data[0]);
          setIdentity([{ key: 'DataElement id', value: res.data[0]._id },
            { key: 'Type', value: res.data[0].type },
            { key: 'EntityName', value: res.data[0].entityRef.name, redirectTo: `/${WEBAPP_NAME}/entities/${res.data[0].entityRef.entityId as string}/summary` },
            { key: 'EntityType', value: res.data[0].entityRef.entityType },
            { key: 'EntityId', value: res.data[0].entityRef.entityId, redirectTo: `/${WEBAPP_NAME}/entities/${res.data[0].entityRef.entityId as string}/summary` },
            { key: 'Provider', value: res.data[0].elementInfo.provider.label }]);
          setScheduling([{ key: 'status', value: res.data[0].scheduling.status },
            { key: 'nbDependents', value: res.data[0].dependencies.length },
            { key: 'priority', value: res.data[0].scheduling.priority },
            { key: 'effectivePriority', value: res.data[0].scheduling.effectivePriority },
            { key: 'partialDependencies', value: res.data[0].scheduling.partialDependencies.toString() },
            { key: 'partialSets', value: res.data[0].scheduling.partialSets.toString() }]);
          setDataInfo([ { key: 'status', value: res.data[0].dataInfo.status },
            { key: 'lastUpdate', value: res.data[0].dataInfo.lastUpdate }]);
          setDependenciesId(res.data[0].dependencies);
          setChips([
            <Chip key='state'
              label={upperFirst(res.data[0].scheduling.status)}
              color={res.data[0].scheduling.status === 'done' ? 'success' : 'error'}
            />
          ]);
        }
      });
  }, []);

  return (
    <Grid container spacing={2} alignItems='center'>
      <Stack flexDirection='row'>
        {/* See placement here where to place the action Panel */}
        <TitleComponent title={(dataElement?.entityRef.name ? `${dataElement?.entityRef.entityType} - ${dataElement?.entityRef.name}`  : 'title')} icon={IconBrandDatabricks} chips={chips} />
        <ActionPanel />
      </Stack>
      <Grid xs={12}>
        <HeaderComponents
          height={180}
          selects={[]}
          keys={[
            'Identity',
            'Scheduling',
            'DataInfo',
            'Dependencie'
          ]}
          links={[
            '', '', '', ''
          ]}
        >
          {[
            <KeyValueList key='Identity' values={[identity]}  />,
            <KeyValueList key='Scheduling' values={[scheduling]} />,
            <KeyValueList key='DataInfo' values={[dataInfo]} />,
            <DependenciesHeaderCard dependencies={dependenciesId}/>
          ]}
        </HeaderComponents>
      </Grid>
    </Grid>
  );
}

export default DataElementDetailsHeader;
