import React, { ReactElement } from 'react';
import Menu from '@mui/material/Menu';
import { IconAdjustmentsHorizontal } from '@tabler/icons-react';
import MenuItem from '@mui/material/MenuItem';
import { Stack } from '@mui/system';
import { IconButton, Switch, Typography } from '@mui/material';

export type SettingsProps = {
  label: string,
  id: string,
  status: boolean
}

function SimpleDisplaySwitch(props: { settingsList: SettingsProps[], setSettingsList: (settingsList: SettingsProps[] & {status: boolean}[]) => void, settingsLabel?: string }): ReactElement {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSwitchChange = (value: string) => {
    console.log('switch change');
    props.setSettingsList(props.settingsList.map(s => (
      {
        ...s,
        status: s.label === value ? !s.status : s.status
      }
    )));
  };

  return (
    <Stack direction='row' alignItems='center' spacing={2}>
      {/* <IconAdjustmentsHorizontal /> */}
      <IconButton
        size='small'
        onClick={handleClick}
        sx={{
          border: 1,
          borderColor: '#C0C0C1'
        }}
      >
        <IconAdjustmentsHorizontal />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {props.settingsList.map(setting => (
          <MenuItem
            key={setting.id}
            onClick={() => {
              handleSwitchChange(setting.label);
            }}
            sx={{
              // minWidth: '80px'
            }}
          >
            <Stack width='100%' direction='row' justifyContent='space-between' alignItems='center'>
              <Typography>
                {setting.label}
              </Typography>
              <Switch
                checked={setting.status}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </Stack>
          </MenuItem>
        ))}
      </Menu>
    </Stack>
  );
}

export default SimpleDisplaySwitch;
