import React, { ReactElement } from 'react';
import { useLoaderData } from 'react-router-dom';
import { CompanyEntity } from '@deecision/dna-interfaces';
import { ApiArrayResponse } from '@deecision/deecision-interfaces';
import CompanyList from '../../../../containers/lists/company';

function CompaniesComponent(): ReactElement {
  const data = useLoaderData() as ApiArrayResponse<CompanyEntity>;

  return (
    <CompanyList data={Array.isArray(data.data) ? data.data : []} totalCount={data.totalCount} />
  );
}

export default CompaniesComponent;
