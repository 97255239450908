import React, { ReactElement, useEffect, useState } from "react";
import { IDataBlock } from "@deecision/dna-interfaces";
import { Grid } from "@mui/material";
import { IconBrandDatabricks } from "@tabler/icons-react";
import TitleComponent from "@/components/title";
import KeyValueList, { KeyValue } from "@/components/keyvalue/keyvaluelist";
import HeaderComponents from "../../../../components/header";
import { DataBlocksQueryService } from "../../../../api/services/query";

function DataBlocksDetailsHeader(): ReactElement {
  const dataBlockId = window.location.pathname.split('/')[4];

  const dataBlockService = new DataBlocksQueryService({ entityType: 'deecCompany' });
  const [dataBlocks, setDataBlocks] = useState<IDataBlock>();
  const [identity, setIdentity] = useState<KeyValue[]>([]);
  const [dataInfo, setDataInfo] = useState<KeyValue[]>([]);
  const [source, setSource] = useState<KeyValue[]>([]);

  useEffect(() => {
    dataBlockService.findByCustomQuery({
      _id: dataBlockId
    })
      .then((res : any) => {
        if (res.data) {
          console.log(res.data);
          setDataBlocks(res.data[0]);
          setIdentity([{ key: 'DataBlock id', value: res.data[0]._id },
            { key: 'EntityType', value: res.data[0].entityRef.entityType },
            { key: 'EntityId', value: res.data[0].entityRef.entityId }]);
          setDataInfo([
            { key: 'Path', value: res.data[0].dataInfo.path },
            { key: 'DataType', value: res.data[0].dataInfo.dataType }
          ]);
          setSource([
            { key: 'dataElementId', value: res.data[0]._source?.dataElementId },
            { key: 'originDataBlockId', value: res.data[0]._source?.originDataBlockId },
            { key: 'providerId', value: res.data[0]._source?.provider.id },
            { key: 'providerOther', value: res.data[0]._source?.provider.other }
          ]);
        }
      });
  }, []);

  return (
    <Grid container spacing={2} alignItems='center'>
      <Grid>
        <TitleComponent title={(dataBlocks ? `${dataBlocks?.entityRef.entityType} - ${dataBlocks?.dataInfo.path}`  : 'title')} icon={IconBrandDatabricks} />
      </Grid>
      <Grid xs={12}>
        <HeaderComponents
          height={180}
          selects={[]}
          keys={[
            'Identity',
            'DataInfo',
            'Source'
          ]}
          links={[
            '',
            '',
            ''
          ]}
        >
          {[
            <KeyValueList key='Identity' values={[identity]}  />,
            <KeyValueList key='DataInfo' values={[dataInfo]} />,
            <KeyValueList key='Source' values={[source]} />
          ]}
        </HeaderComponents>
      </Grid>
    </Grid>
  );
}

export default DataBlocksDetailsHeader;
