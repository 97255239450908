import React from 'react';
import { IDataBlock, PersonEntity, zPersonEntity } from '@deecision/dna-interfaces';
import { IconUsers } from '@tabler/icons-react';
import createElementRoutes from '@/router/create';
import { customGetterLoader } from '@/router/loader';
import dnaConfig from '../../../../config/dna.config.json';
import PersonsComponent from './components/component.persons';
import PersonComponent from './components/id.component.persons';
import BaseDnaEntitiesService from '../services/entities.services';
import DashboardTabsComponents from './components/tabs/dashboard.tabs.components';
import DeveloperTabsComponents from '../components/tabs/developer.tabs.components';
import CompaniesTabsComponents from './components/tabs/companies.tabs.components';
import NetworkTabsComponents from '../components/tabs/network.tabs.components';
import PrioreezTabsComponents from './components/tabs/prioreez.tabs.components';
import OrigeenTabsComponents from "../companies/components/tabs/origeen.tabs.components";
// import OrigeenTabsComponents from './custom/tabs/origeen.tabs.custom';
import RelationsTabsComponents from './components/tabs/relations.tabs.components';
import NewsTabsComponents, { newsDataPaths } from '../components/tabs/news.tabs.components';
import { DataBlocksQueryService, DataElementsQueryService } from '../../../../api/services/query';
import WeasseTabsComponents, { weasseDataPaths } from './components/tabs/weasse.tabs.components';
import Veegilenz, { veegilenzPersonPaths } from "../../../containers/veegilenz/veegilenz";
import { tabsFunctions } from "./components/tabs/functions.tabs.components";
import { CustomRouteObject } from "@/router/types";

const tabsPersons: CustomRouteObject[] = [
  {
    id: dnaConfig.routes.persons.tabs.dashboard.label,
    i18nKey: dnaConfig.routes.persons.tabs.dashboard.i18nKey,
    path: dnaConfig.routes.persons.tabs.dashboard.baseUrl,
    element: <DashboardTabsComponents />
  },
  {
    id: dnaConfig.routes.persons.tabs.network.label,
    i18nKey: dnaConfig.routes.persons.tabs.network.i18nKey,
    path: dnaConfig.routes.persons.tabs.network.baseUrl,
    element: <NetworkTabsComponents<PersonEntity> />
  },
  {
    id: dnaConfig.routes.persons.tabs.companies.label,
    i18nKey: dnaConfig.routes.persons.tabs.companies.i18nKey,
    path: dnaConfig.routes.persons.tabs.companies.baseUrl,
    element: <CompaniesTabsComponents />
  },
  {
    id: dnaConfig.routes.persons.tabs.relations.label,
    i18nKey: dnaConfig.routes.persons.tabs.relations.i18nKey,
    path: dnaConfig.routes.persons.tabs.relations.baseUrl,
    element: <RelationsTabsComponents />
  },
  {
    id: dnaConfig.routes.persons.tabs.news.label,
    i18nKey: dnaConfig.routes.persons.tabs.news.i18nKey,
    path: dnaConfig.routes.persons.tabs.news.baseUrl,
    element: <NewsTabsComponents />,
    loader: ({ request, params }) => customGetterLoader({
      service: new DataBlocksQueryService<IDataBlock>({ dataPath: newsDataPaths, entityType: 'deecPerson' }),
      params,
      request
    })
  },
  {
    id: dnaConfig.routes.persons.tabs.veegilenz.label,
    i18nKey: 'Veegilenz',
    path: dnaConfig.routes.persons.tabs.veegilenz.baseUrl,
    element: <Veegilenz entityType='deecPerson' />,
    loader: ({ request, params }) => customGetterLoader({
      service: new DataBlocksQueryService<IDataBlock>({ dataPath: veegilenzPersonPaths, entityType: 'deecPerson' }),
      params,
      request
    })
  },
  {
    id: dnaConfig.routes.persons.tabs.prioreez.label,
    i18nKey: 'Prioreez',
    path: dnaConfig.routes.persons.tabs.prioreez.baseUrl,
    element: <PrioreezTabsComponents />
  },
  {
    id: dnaConfig.routes.persons.tabs.weasse.label,
    i18nKey: dnaConfig.routes.persons.tabs.weasse.i18nKey,
    path: dnaConfig.routes.persons.tabs.weasse.baseUrl,
    element: <WeasseTabsComponents />,
    loader: ({ request, params }) => customGetterLoader({
      service: new DataBlocksQueryService<IDataBlock>({ dataPath: weasseDataPaths, entityType: 'deecPerson' }),
      params,
      request
    })
  },
  {
    id: dnaConfig.routes.persons.tabs.origeen.label,
    i18nKey: 'Origeen',
    path: dnaConfig.routes.persons.tabs.origeen.baseUrl,
    element: <OrigeenTabsComponents entityType='deecPerson' />
  },
  {
    id: dnaConfig.routes.persons.tabs.developer.label,
    i18nKey: dnaConfig.routes.persons.tabs.developer.i18nKey,
    path: dnaConfig.routes.persons.tabs.developer.baseUrl,
    element: <DeveloperTabsComponents<PersonEntity> zodSchema={zPersonEntity} />
  }
];

const routesPersons: CustomRouteObject = createElementRoutes<PersonEntity>({
  i18nKey: dnaConfig.routes.persons.i18nKey,
  path: dnaConfig.routes.persons.baseUrl,
  icon: IconUsers,
  component: <PersonsComponent />,
  idComponent: <PersonComponent />,
  Service: new BaseDnaEntitiesService<PersonEntity>({ entityType: 'person' }),
  displayIndex: true,
  tabs: tabsPersons,
  tabsDisplayService: new DataElementsQueryService({ entityType: 'deecPerson' }),
  tabsFunctions
});

export default routesPersons;
