function computeString(array: Array<string>) {
  let response = '';

  array.forEach((name, idx) => {
    response += `${name}${(idx === array.length - 1 ? '' : ',')}`;
  });
  
  return (response);
}

export default computeString;