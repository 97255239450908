import React, { ReactElement } from 'react';
import { useRouteError } from 'react-router-dom';
import { AxiosError } from 'axios';
import ErrorPageManager from '../components/error';
import ServiceError from '@/api/services/error';
import { ErrorManagerProps } from '@/components/error';

export type ErrorRouterProps = Pick<ErrorManagerProps, 'app'>;

function ErrorRouter(props: ErrorRouterProps): ReactElement {
  const error = useRouteError() as AxiosError | ServiceError;

  return <ErrorPageManager error={ServiceError.ensureServiceError(error)} {...props} />;
}

export default ErrorRouter;
