import React, { ReactElement } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import no_data from '@/assets/images/no_data.svg';

function NoData(props: { title?: string, subtitle?: string }): ReactElement {
  const imgNoData = no_data;

  return (
    <Grid container spacing={4} direction='column' justifyContent='center' sx={{ width: '100%', height: '100%', textAlign: 'center' }}>
      <Grid>
        <img alt='no data' src={imgNoData} width={320} style={{ margin: 'auto' }} />
      </Grid>
      <Grid>
        <Typography variant='h1'>
          {props.title || 'No Data'}
        </Typography>
      </Grid>
      <Grid>
        <Typography variant='subtitle1'>
          {props.subtitle || 'Adjust your filters or start a new search'}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default NoData;
