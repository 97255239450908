import React, { ReactElement, useEffect, useState } from "react";
import { IDataElement } from "@deecision/dna-interfaces";
import { DataElementsQueryService } from "@/api/services/query";
import DisplayJson from "@/components/displayjson";

function JsonBrutTabs(): ReactElement {
  const dataElementId = window.location.pathname.split('/')[4];
  const localStorageParams = localStorage.getItem(`entityType${dataElementId}`);
  const entityType = (localStorageParams != null ?  localStorageParams : '');

  const dataElementServices = new DataElementsQueryService({ entityType });
  const [dataElement, setDataElement] = useState<IDataElement[] | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      const data = await dataElementServices.findInAllEntities({ _id: dataElementId });
      const res = Array.isArray(data.data) ? data.data : [];
      setDataElement(res);
    };

    fetchData();
  }, []);

  return (
    <DisplayJson data={dataElement} />
  );
}

export default JsonBrutTabs;
