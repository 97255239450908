import { QueryFilter } from '@deecision/deecision-interfaces/dist/api';

export function parseFilter({ key, value }: { key: string, value: string }): QueryFilter {
  let scope;
  let id;

  switch (key.split('_')[0]) {
  case 'fe' :
    scope = 'entity';
    id = key.replace('fe_', '');
    break;
  case 'fdv' :
    scope = 'dataViews';
    id = key.replace('fdv_', '');
    break;
  case 'fa' :
    scope = 'attributes';
    id = key.replace('fa_', '');
    break;
  default:
    scope = 'dataViews';
    id = key.replace('f_', '');
  }

  return ({
    scope,
    id,
    value
  });
}
