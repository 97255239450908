import { routes as routesAssistedSegmentations } from './assisted';
import { routes as routesCustomSegmentations } from './custom';

function routesSegmentations(id: string) {
  return [
    routesAssistedSegmentations(id),
    routesCustomSegmentations(id)
  ];
}

export default routesSegmentations;
