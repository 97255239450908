import React, { ReactElement } from 'react';
import { CompanyEntity } from '@deecision/dna-interfaces';
import { useTranslation } from 'react-i18next';
import { IconClipboardText } from '@tabler/icons-react';
import Grid from '@mui/material/Unstable_Grid2';
import { useOutletContext } from 'react-router-dom';
import { useTheme } from '@mui/material';
import Stack from '@mui/material/Stack';
import KeyValueCardComponent from '../../../../../../components/cards/keyvalue.cards';
import FinancialWidget from '@/main/containers/widgets/financial';
import NewsWidget from '@/main/containers/widgets/news';
import RadarWidget from '@/main/containers/widgets/radar';
import { RadarChartDatas } from "../../../../../../components/charts/radar";
import { CompanyGetter } from "../../../../../providers/getter";

function Informations(props: { data: CompanyEntity }): ReactElement {
  const { t } = useTranslation();
  const companyGetter = new CompanyGetter({
    data: props.data,
    errorMsg: t('common.utils.unknown')
  });

  return (
    <KeyValueCardComponent
      grid
      icon={IconClipboardText}
      title='Informations'
      cardParts={
        {
          mainValue:
            { key: t('entities.companies.company.keys.companyName'), value: companyGetter.getName() },
          values: [
            { key: t('common.codes.siren'), value: companyGetter.getSiren() },
            { key: t('common.dates.creationDate'), value: companyGetter.getCreationDate() },
            { key: t('common.localisation.address'), value: companyGetter.getAddress() },
            { key: t('common.localisation.city'), value: `${companyGetter.getCityZipCode()} ${companyGetter.getCity()}` },
            { key: t('common.localisation.country'), value: companyGetter.getCountry() }
          ]
        }
      }
    />
  );
}

interface IdentityWidgetsProps {
  data: CompanyEntity
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function CompaniesRadarWidget(props: Pick<IdentityWidgetsProps, 'data'>): ReactElement {
  const { t } = useTranslation();
  const theme = useTheme();

  const radarSubjects = [
    t('entities.persons.person.charts.radar.completeness'),
    t('entities.persons.person.charts.radar.alerts'),
    t('entities.persons.person.charts.radar.compliance'),
    t('entities.persons.person.charts.radar.opportunities'),
    t('entities.persons.person.charts.radar.trust')
  ];

  const radarDatas: RadarChartDatas[] = [
    {
      label: '2022',
      dataKey: 'A' as const,
      color: theme.palette.secondary.main,
      datas: {}
    },
    {
      label: '2023',
      dataKey: 'B' as const,
      color: theme.palette.primary.main,
      datas: {}
    }
  ];

  radarDatas.forEach((radarData, index) => {
    radarSubjects.forEach((radarSubject) => {
      radarDatas[index].datas = { ...radarData.datas, [radarSubject]: (Math.floor(Math.random() * 10) + 1) };
    });
  });

  return (
    <RadarWidget subjects={radarSubjects} datas={radarDatas} maxValue={10} />
  );
}

function DashboardTabsComponents(): ReactElement {
  const data = useOutletContext() as { data: CompanyEntity };
  const { t } = useTranslation();

  return (
    <Grid container spacing={4} p={2}>
      <Grid>
        <Stack spacing={2}>
          <NewsWidget
            data={data.data}
            link={data.data.dataViews?.data?.news && data.data.dataViews?.data?.news.length > 0 ? '../news' : undefined}
            linkLabel={data.data.dataViews?.data?.news && data.data.dataViews?.data?.news.length > 0 ? `${t('news.allNews')}` : undefined}
          />
          <CompaniesRadarWidget data={data.data} />
        </Stack>
      </Grid>
      <Informations data={data.data} />
      <FinancialWidget data={data.data} />
    </Grid>
  );
}

export default DashboardTabsComponents;
