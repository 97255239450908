import React, { ReactElement, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import { BaseItemFormProps } from '../types.form';

function TextFilterTypeForm(props: BaseItemFormProps): ReactElement {
  const [value, setValue] = useState(props.filter.value);

  useEffect(() => {
    setValue(props.filter.value);
  }, [props.filter.value]);

  return (
    <TextField
      fullWidth
      placeholder={props.filter.label || ''}
      variant='outlined'
      value={value}
      onChange={e => props.onChange(e.target.value)}
    />
  );
}

export default TextFilterTypeForm;
