import React, { ReactElement } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import { PersonEntity } from '@deecision/dna-interfaces';
import { useNavigate } from "react-router-dom";
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';
import { getEntityPath, PersonGetter } from '../../providers/getter';
import KeyValueList from '@/components/keyvalue/keyvaluelist';

export interface SmallPersonCardEntity {
  status: string,
  person: PersonEntity
}

interface SmallPersonCardProps {
  entity: SmallPersonCardEntity
}

function PersonSmallCards(props: SmallPersonCardProps): ReactElement {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const path = getEntityPath({ ...props.entity.person });
  const personGetter = new PersonGetter({
    data: props.entity.person,
    errorMsg: t('common.utils.unknown')
  });

  return (
    <Grid
      container
      width='100%'
      sx={{ cursor: 'pointer' }}
      onClick={() => navigate(path)}
      direction='row'
      flexWrap='nowrap'
      spacing={4}
    >
      <Grid
        xs
        width='max-content'
        whiteSpace='nowrap'
        sx={{
          mr: 'auto',
          overflowX: 'auto',
          msOverflowStyle: 'none',
          scrollbarWidth: 'none',
          '& ::-webkit-scrollbar': {
            display: 'none'
          }
        }}
      >
        <Stack spacing={0}>
          <Grid container spacing={2} flexWrap='nowrap' sx={{ width: 'max-content !important' }}>
            <Grid>
              <Typography variant='subtitle2'>
                {personGetter.getName()}
              </Typography>
            </Grid>
          </Grid>
        </Stack>
      </Grid>
      <Grid>
        <Divider orientation='vertical' />
      </Grid>
      <Grid>
        <KeyValueList values={[[
          {
            key: t('common.financial.turnover.sum'),
            value: personGetter.getTurnoverSum(),
            align: 'right' as const
          },
          {
            key: t('common.financial.capital.sum'),
            value: personGetter.getCapitalSum(),
            align: 'right' as const
          },
          {
            key: t('common.financial.ebitda.sum'),
            value: personGetter.getEbitdaSum(),
            align: 'right' as const
          }
        ]]}
        />
      </Grid>
    </Grid>
  );
}

export default PersonSmallCards;
