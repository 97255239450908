import React, { Dispatch, SetStateAction } from 'react';
import { SegmentationPossibleOutputEntityTypes } from "@deecision/dna-interfaces";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import { Tooltip } from "@mui/material";
import { CustomSegmentationData } from "../../types.segmentations";
import { possibleDataSetIds } from "../../builder/workflows";

interface SelectorLevelsRenderSegmentationsProps {
  possibleOutputEntities: CustomSegmentationData['possibleOutputEntities'],
  outputEntities: SegmentationPossibleOutputEntityTypes | undefined,
  setOutputEntities: Dispatch<SetStateAction<SegmentationPossibleOutputEntityTypes>>,
  setDataSetId: Dispatch<SetStateAction<CustomSegmentationData['possibleDataSetIds'][0]>>,
  first?: boolean
}

function SelectorLevelsRenderSegmentations(props: SelectorLevelsRenderSegmentationsProps) {
  const { t } = useTranslation();

  return (
    <Paper
      sx={{
        p: props.first ? 0 : 2,
        border: props.first ? 'none' : undefined,
        borderRadius: 0,
        borderBottom: 'none',
        bgcolor: props.first ? 'transparent' : 'background.default',
        width: '100%',
        height: '100%',
        overflow: 'auto'
      }}
    >
      <Paper
        sx={{
          p: 1,
          borderRadius: 1,
          bgcolor: props.first ? 'background.default' : 'background.paper'
        }}
      >
        <Stack
          direction='row'
          spacing={2}
          alignItems='center'
          width='100%'
        >
          {props.possibleOutputEntities.map(possibleOutputEntity => (
            <Tooltip key={possibleOutputEntity} title={t(`segmentation.builder.config.advanced.on.${possibleOutputEntity}`).split(':')[1]} arrow placement='top'>
              <Button
                variant='contained'
                onClick={() => {
                  props.setDataSetId(
                    possibleDataSetIds([possibleOutputEntity])[0]
                  );
                  props.setOutputEntities(possibleOutputEntity);
                }}
                sx={{
                  bgcolor: possibleOutputEntity === props.outputEntities ? props.first ? 'background.paper' : 'background.default' : props.first ? 'background.default' : 'background.paper',
                  color: 'text.primary',
                  border: 'none',
                  borderRadius: 1,
                  boxShadow: 'none',
                  '&:hover': {
                    bgcolor: props.first ? 'background.paper' : 'background.default',
                    boxShadow: 'none'
                  }
                }}
                size='small'
              >
                <Typography variant='h4'>
                  {t(`segmentation.builder.config.advanced.on.${possibleOutputEntity}`).split(':')[0]}
                </Typography>
              </Button>
            </Tooltip>

          ))}
        </Stack>
      </Paper>
    </Paper>
  );
}

export default SelectorLevelsRenderSegmentations;
