import React, { ReactElement } from 'react';
import { Chip } from '@mui/material';
import Stack from '@mui/material/Stack';
import { IconCalendarEvent } from '@tabler/icons-react';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

function KeyValueDate(props: { date?: Date, size?: 'small' | 'medium' }): ReactElement {
  const theme = useTheme();
  const ageDate = Math.abs(new Date(Date.now() - (props.date?.getTime() || 0)).getUTCFullYear() - 1970);

  return (
    props.date ?
      <Chip
        size={props.size || 'small'}
        sx={{
          width: props.size !== 'medium' ? 96 : undefined,
          height: props.size !== 'medium' ? 20 : undefined,
          bgcolor: ageDate < 1 ? theme.palette.success.light : ageDate < 2 ? theme.palette.warning.light : theme.palette.error.light,
          color: ageDate < 1 ? theme.palette.success.dark : ageDate < 2 ? theme.palette.warning.dark : theme.palette.error.dark,
          mt: props.size !== 'medium' ? '1px !important' : undefined,
          '& .MuiChip-labelSmall': {
            pl: 1,
            pr: 1
          }
        }}
        label={
          <Stack direction='row' alignItems='center' spacing={1}>
            {props.size !== 'small' && <IconCalendarEvent size={props.size === 'medium' ? 22 : 16} />}
            <Typography
              variant={props.size !== 'medium' ? 'body2' : 'body1'}
              textAlign='center'
              sx={{
                width: props.size !== 'medium' ? '64px' : undefined,
                fontWeight: 500
              }}
            >
              {props.date.toLocaleDateString()}
            </Typography>
          </Stack>
        }
      /> :
      <></>
  );
}

export default KeyValueDate;
