import React from 'react';
import { IconChartInfographic, IconPlus } from '@tabler/icons-react';
import { Navigate } from 'react-router-dom';
import createElementRoutes from '@/router/create';
import VennDiagramServices from '../../../../containers/vennDiagram/services/vennDiagram.services';
import VennDiagramBuilder from '../../../../containers/vennDiagram/vennDiagramBuilder';

const routesVennDiagramFactory = createElementRoutes({
  id: 'Venn Diagram', // dnaConfig.routes.admin.factory.vennDiagram.label,
  path: 'venn-diagram', // dnaConfig.routes.admin.factory.vennDiagram.baseUrl,
  icon: IconChartInfographic,
  component: <Navigate to='create' />, // dnaConfig.routes.admin.factory.vennDiagram.create.baseUrl
  idComponent: <VennDiagramBuilder />,
  IdService: new VennDiagramServices(),
  childrens: [
    createElementRoutes({
      id: 'Venn Diagram Create', // dnaConfig.routes.admin.factory.vennDiagram.create.label,
      path: 'create', // dnaConfig.routes.admin.factory.vennDiagram.create.baseUrl,
      icon: IconPlus,
      component: <VennDiagramBuilder />
    })
  ]
});

export default routesVennDiagramFactory;
