import React, { Dispatch, ReactElement, SetStateAction, useContext, useEffect, useState } from 'react';
import { useLoaderData, useParams, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Chip, CircularProgress, darken, lighten, Stack, Typography } from "@mui/material";
import { IconChartCircles } from '@tabler/icons-react';
import { SegmentationFilter, SegmentationPossibleOutputEntityTypes } from '@deecision/dna-interfaces';
import { cloneDeep, lowerCase } from "lodash";
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2';
import { SegmentationCriteriaSpec } from '@deecision/dna-interfaces/dist/segmentation/segmentationFilters';
import { ChipOwnProps } from '@mui/material/Chip';
import TitleComponent from '@/components/title';
import { VennDiagramConfig, VennDiagramData, VennDiagramResult } from "./types.vennDiagram";
import VennDiagramServices from "./services/vennDiagram.services";
import CustomAccordion from '../../../components/accordion';
import VennDiagramChart from "./components/vennDiagram.charts";
import {
  BaseCustomUserData,
  CustomSegmentationData
} from '../segmentations/types.segmentations';
import getDynamicGroupsRequest from "../../modules/deetect/portfolios/utils/dynamicgroups";
import { SegmentationContext } from '../segmentations/wrappers/wrapper.segmentations';
import { possibleCriteriaTypes } from '../segmentations/builder/workflows';
import { getEntityTypeColor, getEntityTypeI18nKey } from "../../providers/getter";
import RenderSegmentations from "../segmentations/render/render.segmentations";

interface VennDiagramTitleProps {
  diagram?: VennDiagramConfig,
  isDraft: boolean,
  setIsDraft: Dispatch<SetStateAction<boolean>>,
  isEditMode: boolean,
  setIsEditMode: Dispatch<SetStateAction<boolean>>,
  setDiagram: Dispatch<SetStateAction<VennDiagramConfig | undefined>>
}

function VennDiagramTitle(props: VennDiagramTitleProps): ReactElement {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Grid xs={12} container spacing={2} alignItems='center' sx={{ position: 'sticky', top: 0, bgcolor: theme.palette.grey['50'], zIndex: 3 }}>
      <Grid>
        <TitleComponent title={props.diagram?.label || ''} icon={IconChartCircles} />
      </Grid>
      <Grid>
        <Chip
          label={t(getEntityTypeI18nKey(props.diagram?.objectType))}
          size='small'
          color={getEntityTypeColor(props.diagram?.objectType) !== 'secondary' ? getEntityTypeColor(props.diagram?.objectType) as ChipOwnProps['color'] : undefined}
          sx={{
            bgcolor: getEntityTypeColor(props.diagram?.objectType) === 'secondary' ? theme.palette.secondary.light : undefined,
            color: getEntityTypeColor(props.diagram?.objectType) === 'secondary' ? theme.palette.secondary.dark : undefined
          }}
        />
      </Grid>
      {props.diagram?.segments.map(segment => (
        <Grid>
          <Chip
            key={segment.id}
            label={<Typography color={darken(segment.color, 0.5)}>{segment.label}</Typography>}
            style={{ backgroundColor: segment.color ? lighten(segment.color, 0.5) : undefined }}
            size='small'
          />
        </Grid>
      ))}
    </Grid>
  );
}

function RenderVennDiagram() {
  const { t } = useTranslation();
  const theme = useTheme();
  const segmentationContext = useContext(SegmentationContext);
  const { segmentationId, id } = useParams();
  const [searchParams] = useSearchParams();
  const [diagram, setDiagram] = useState<VennDiagramConfig>();
  const [result, setResult] = useState<VennDiagramResult[]>();
  const [loading, setLoading] = useState<boolean>(true);
  const [filters, setFilters] = useState<SegmentationFilter[]>([]);
  const vennServices = new VennDiagramServices<VennDiagramConfig>();
  const userData = useLoaderData() as { data?: BaseCustomUserData };
  const dataParams = userData?.data? cloneDeep(userData?.data) : undefined;
  const entityType = searchParams.get('entityType');
  const groupId = searchParams.get('groupId');
  const customGroupId = searchParams.get('customGroupId');
  const filterIds = searchParams.get('filterIds')?.split(',');
  const filterValues = searchParams.get('filterValues')?.split(',');
  const [isDraft, setIsDraft] = useState<boolean>(false);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);

  const dataSetId = (lowerCase(entityType || '')?.includes('person') || dataParams?.data.on?.includes('person') || diagram?.objectType?.includes('Person'))
    ? 'deecPersons'
    : 'deecCompanies';

  const baseFilterGroupMember: SegmentationFilter[] = id ? (customGroupId === 'true' && groupId) ? getDynamicGroupsRequest(groupId, id)?.filters || [] : [
    {
      id: dataSetId === 'deecPersons' ? 'person_groupMember' : 'company_groupMember',
      filterId: dataSetId === 'deecPersons' ? 'person_groupMember' : 'company_groupMember',
      type: 'filter',
      on: dataSetId === 'deecPersons' ? 'person1' : 'company',
      values: groupId && groupId.split(',').length > 1 ? groupId.split(',').map(uniqGroupId => `${id}/${uniqGroupId}`) : [`${id}/${groupId || (dataSetId === 'deecPersons' ? 'persons' : 'companies')}`]
    }
  ] : [];

  const baseFiltersFromUrlParams: SegmentationFilter[] = filterIds && filterValues ? filterIds.map((filterId, index) => ({
    id: filterId,
    filterId,
    type: 'filter',
    on: dataSetId === 'deecPersons' ? 'person1' : 'company',
    values: [filterValues[index] === "true" ? true : filterValues[index] === "false" ? false : filterValues[index]]
  })) : [];

  const getCriterias = (outputEntitiesTmp?: SegmentationPossibleOutputEntityTypes) => segmentationContext?.segmentation?.data.potentialSegmentationCriterias.filter(criteria => possibleCriteriaTypes(undefined, outputEntitiesTmp ? [outputEntitiesTmp] : undefined).includes(criteria.on)) || [];

  const makeSegmentationCriterias = (criterias: CustomSegmentationData['potentialSegmentationCriterias']) => criterias.map(criteria => ({
    ...criteria,
    type: 'segmentationCriteria',
    on: (criteria.on === 'person' ? `person${(diagram?.objectType === 'deecPerson' ? 'person1' : 'company') === 'person1' ? '1' : '2'}` : criteria.on === 'company' ? `company${(diagram?.objectType === 'deecPerson' ? 'person1' : 'company') === 'person1' ? '1' : '2'}` : criteria.on)
  })).filter(criteria => !filters.find(filter => filter.filterId === criteria.filterId));

  const addFilter = (filter: SegmentationFilter) => {
    setFilters([filter]);
  };

  const removeFilter = () => {
    setFilters([]);
  };

  useEffect(() => {
    if (segmentationId) {
      vennServices.get(segmentationId).then((res) => {
        if (res.data) {
          setDiagram(res.data);
          setLoading(false);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (diagram) {
      const outputEntities = diagram?.objectType === 'deecPerson' ? 'person1' : 'company';

      const contextFromParams = {
        context: {
          dataSetId,
          outputEntities: dataSetId === 'deecPersons' ? 'person1' : 'company',
          filters: [
            ...baseFilterGroupMember,
            ...baseFiltersFromUrlParams
          ],
          globalFilteringItems: [],
          entitiesSettings: {
            includeEntities: false,
            findOptions: {
              pagingParams: {},
              queryFilters: [],
              sortOptions: []
            }
          },
          segmentationCriterias: makeSegmentationCriterias(getCriterias(outputEntities)) as SegmentationCriteriaSpec[]
        }
      };

      if (segmentationId) {
        vennServices.execute<VennDiagramData>(segmentationId, contextFromParams)
          .then((res) => {
            if (res.data) {
              setResult(res.data.result);
              setLoading(false);
            }
          });
      }
    }
  }, [diagram]);

  return (
    <>
      {!loading && diagram ?
        <Grid container spacing={4}>
          {/* Header */}
          <VennDiagramTitle
            diagram={diagram}
            isDraft={isDraft}
            setIsDraft={setIsDraft}
            isEditMode={isEditMode}
            setIsEditMode={setIsEditMode}
            setDiagram={setDiagram}
          />
          <Grid xs={12}>
            <RenderSegmentations
              filters={filters}
              removeFilter={removeFilter}
              reset={() => setFilters([])}
            >
              <CustomAccordion
                title={t('segmentation.vennDiagram.label')}
                bgcolor={theme.palette.background.default}
                defaultOpen
              >
                {(result && result.length > 0) ?
                  <Stack
                    id='venn-diagram'
                    display='flex'
                    height='min-content'
                    width='100%'
                    justifyContent='center'
                    minHeight='calc(100vh - 400px)'
                  >
                    <VennDiagramChart
                      diagram={diagram}
                      result={result}
                      filters={filters}
                      setFilters={setFilters}
                      addFilter={addFilter}
                      removeFilter={removeFilter}
                    />
                  </Stack>
                  :
                  <Stack
                    id='venn-diagram-empty-result'
                    display='flex'
                    height='200px'
                    width='100%'
                    alignItems='center'
                    justifyContent='center'
                  >
                    <Typography fontSize={16}
                      color={theme.palette.error.main}>{t('segmentation.vennDiagram.display.onSegmentEmpty')}</Typography>
                  </Stack>
                }
              </CustomAccordion>
            </RenderSegmentations>
          </Grid>
        </Grid>
        :
        <Stack height='100%' alignContent='center' alignItems='center'>
          <CircularProgress/>
        </Stack>
      }
    </>
  );
}

export default RenderVennDiagram;
