import React from 'react';
import { CompanyEntity, IDataBlock, zCompanyEntity } from '@deecision/dna-interfaces';
import IconBuildings from '@/assets/custom/IconBuildings';
import createElementRoutes from '@/router/create';
import { customGetterLoader } from '@/router/loader';
import CompaniesComponent from './components/component.companies';
import CompanyComponent from './components/id.component.companies';
import BaseDnaEntitiesService from '../services/entities.services';
import DashboardTabsComponents from './components/tabs/dashboard.tabs.components';
import DeveloperTabsComponents from '../components/tabs/developer.tabs.components';
import IaTabsComponents from './components/tabs/ia.tabs.components';
import BodaccTabsComponents from './components/tabs/bodacc.tabs.components';
import { DataBlocksQueryService, DataElementsQueryService } from '../../../../api/services/query';
import FinancialTabsComponents from './components/tabs/financial.tabs.components';
import dnaConfig from '../../../../config/dna.config.json';
import NewsTabsComponents, { newsDataPaths } from '../components/tabs/news.tabs.components';
import OrigeenTabsComponents from './components/tabs/origeen.tabs.components';
import NetworkTabsComponents from '../components/tabs/network.tabs.components';
import RelationsTabsComponents from './components/tabs/relations.tabs.components';
import Veegilenz, { veegilenzCompanyPaths } from "../../../containers/veegilenz/veegilenz";
import { tabsFunctions } from "./components/tabs/functions.tabs.components";
import { CustomRouteObject } from "@/router/types";

const tabsCompanies: CustomRouteObject[] = [
  {
    id: dnaConfig.routes.companies.tabs.dashboard.label,
    i18nKey: dnaConfig.routes.companies.tabs.dashboard.i18nKey,
    path: dnaConfig.routes.companies.tabs.dashboard.baseUrl,
    element: <DashboardTabsComponents />
  },
  {
    id: dnaConfig.routes.companies.tabs.network.label,
    i18nKey: dnaConfig.routes.companies.tabs.network.i18nKey,
    path: dnaConfig.routes.companies.tabs.network.baseUrl,
    element: <NetworkTabsComponents<CompanyEntity> />
  },
  {
    id: dnaConfig.routes.companies.tabs.relations.label,
    i18nKey: dnaConfig.routes.companies.tabs.relations.i18nKey,
    path: dnaConfig.routes.companies.tabs.relations.baseUrl,
    element: <RelationsTabsComponents />
  },
  {
    id: dnaConfig.routes.companies.tabs.news.label,
    i18nKey: dnaConfig.routes.companies.tabs.news.i18nKey,
    path: dnaConfig.routes.companies.tabs.news.baseUrl,
    element: <NewsTabsComponents />,
    loader: ({ request, params }) => customGetterLoader({
      service: new DataBlocksQueryService<IDataBlock>({ dataPath: newsDataPaths, entityType: 'deecCompany' }),
      params,
      request
    })
  },
  {
    id: dnaConfig.routes.companies.tabs.veegilenz.label,
    i18nKey: 'Veegilenz',
    path: dnaConfig.routes.companies.tabs.veegilenz.baseUrl,
    element: <Veegilenz entityType='deecCompany' />,
    loader: ({ request, params }) => customGetterLoader({
      service: new DataBlocksQueryService<IDataBlock>({ dataPath: veegilenzCompanyPaths, entityType: 'deecCompany' }),
      params,
      request
    })
  },
  {
    id: dnaConfig.routes.companies.tabs.financial.label,
    i18nKey: dnaConfig.routes.companies.tabs.financial.i18nKey,
    path: dnaConfig.routes.companies.tabs.financial.baseUrl,
    element: <FinancialTabsComponents />,
    loader: ({ request, params }) => customGetterLoader({
      service: new DataBlocksQueryService<IDataBlock>({ dataPath: 'company.financialReportDetails', entityType: 'deecCompany' }),
      params,
      request
    })
  },
  {
    id: dnaConfig.routes.companies.tabs.officialInformations.label,
    i18nKey: dnaConfig.routes.companies.tabs.officialInformations.i18nKey,
    path: dnaConfig.routes.companies.tabs.officialInformations.baseUrl,
    element: <BodaccTabsComponents />,
    loader: ({ request, params }) => customGetterLoader({
      service: new DataBlocksQueryService<IDataBlock>({ dataPath: 'company.bodacc.publication', entityType: 'deecCompany' }),
      params,
      request
    })
  },
  {
    id: dnaConfig.routes.companies.tabs.ai.label,
    i18nKey: 'AI',
    path: dnaConfig.routes.companies.tabs.ai.baseUrl,
    handle: {
      tabChips: [
        'Beta'
      ]
    },
    element: <IaTabsComponents />
  },
  {
    id: dnaConfig.routes.companies.tabs.origeen.label,
    i18nKey: 'Origeen',
    path: dnaConfig.routes.companies.tabs.origeen.baseUrl,
    element: <OrigeenTabsComponents entityType='deecCompany'/>
  },
  {
    id: dnaConfig.routes.companies.tabs.developer.label,
    i18nKey: dnaConfig.routes.companies.tabs.developer.i18nKey,
    path: dnaConfig.routes.companies.tabs.developer.baseUrl,
    element: <DeveloperTabsComponents<CompanyEntity> zodSchema={zCompanyEntity} />
  }
];

const routesCompanies: CustomRouteObject = createElementRoutes<CompanyEntity>({
  i18nKey: dnaConfig.routes.companies.i18nKey,
  path: dnaConfig.routes.companies.baseUrl,
  icon: IconBuildings,
  component: <CompaniesComponent />,
  idComponent: <CompanyComponent />,
  Service: new BaseDnaEntitiesService<CompanyEntity>({ entityType: 'company' }),
  displayIndex: true,
  tabs: tabsCompanies,
  tabsDisplayService: new DataElementsQueryService({ entityType: 'deecCompany' }),
  tabsFunctions
});

export default routesCompanies;
