import React, { ReactElement } from 'react';
import { useOutletContext } from 'react-router-dom';
import { PersonEntity } from '@deecision/dna-interfaces';
import DisplayRelationsComponents from '@/main/containers/network/list';

function CompaniesTabsComponents(): ReactElement {
  const { data } = useOutletContext() as { data: PersonEntity };

  return (<DisplayRelationsComponents data={data} to='deecCompany' />);
}

export default CompaniesTabsComponents;
