import {
  ApiArrayResponse,
  ApiResponse
} from '@deecision/deecision-interfaces';
import { Params } from 'react-router-dom';
import { BaseService, BaseServiceProps } from './base.api';
import makeParams, { MakeParamsProps } from './makeparams';

export class BaseElementsService<T> extends BaseService {
  constructor(props: BaseServiceProps) {
    super({ ...props });
  }

  search(query: string): Promise<ApiArrayResponse<T>> {

    return this.axiosInstance.get(this.constructUrl('search'), { params: { name: query } })
      .then(res => this.handleArrayResponse<T>(res));
  }

  getAll(props: MakeParamsProps): Promise<ApiArrayResponse<T>> {

    return this.axiosInstance.get(this.constructUrl(), {
      params: makeParams({ ...props })
    })
      .then(res => this.handleArrayResponse<T>(res));
  }

  get(id: string, params?: Params<string>): Promise<ApiResponse<T>> {
    return this.axiosInstance.get(this.constructUrl(id))
      .then(res => this.handleResponse<T>(res));
  }

  post?(data: T): Promise<ApiResponse<T>> {
    return this.axiosInstance.post(this.constructUrl(), data)
      .then(res => this.handleResponse(res));
  }

  put?(id: string, data: T): Promise<ApiResponse<T>> {
    return this.axiosInstance.put(this.constructUrl(id), data)
      .then(res => this.handleResponse(res));
  }

  delete?(id: string): Promise<ApiResponse<T>> {
    return this.axiosInstance.delete(this.constructUrl(id))
      .then(res => this.handleResponse<T>(res));
  }
}
