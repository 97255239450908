import React, { ReactElement, useEffect, useState } from 'react';
import { GroupAllValues, GroupBaseValues } from '@deecision/dna-interfaces';
import { CircularProgress } from '@mui/material';
import { darken, lighten, useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { uniq } from 'lodash';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import Stack from '@mui/material/Stack';
import { IconCornerDownRight, IconTopologyRing2, IconUsers } from '@tabler/icons-react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import { currencyFormatter } from '@/utils';
import GroupsServices from '../../../modules/deetect/portfolios/services';
import KeyValue, { KeyValueProps } from '@/components/keyvalue/keyvalue';
import PROXEMEE from '../../../../assets/PRXMEE.svg';
import {
  basePaperSx,
  BasePortfolioDashboardProps,
  CustomGroupValues,
  dashboardSpacing
} from './types';
import KeyValueList from '@/components/keyvalue/keyvaluelist';

export function getProxemeeSubGroups(group: CustomGroupValues): CustomGroupValues[] {
  return group.subGroups?.filter(subGroup => (subGroup.subGroupId?.includes('personal') || subGroup.subGroupId?.includes('business') || subGroup.subGroupId?.includes('extended'))) || [];
}

function PortfolioDashboardProxemeeSubGroup(props: { label?: string, full?: boolean, color?: string, baseGroupId: string, groupId?: string, size: KeyValueProps['size'] } & Pick<BasePortfolioDashboardProps, 'callSegmentation'>): ReactElement {
  const { t } = useTranslation();
  const groupsServices = new GroupsServices();
  const [values, setValues] = useState<GroupAllValues>();

  useEffect(() => {
    if (props.groupId) {
      groupsServices.getGroupValues(props.baseGroupId, props.groupId)
        .then(res => setValues(res.data));
    }
  }, []);

  return (values ?
    <Box
      onClick={() => props.callSegmentation?.(values.groupDesc.objectType, props.groupId)}
      sx={{
        cursor: 'pointer',
        '&:hover': {
          color: 'black'
        }
      }}
    >
      {props.full ?
        <Stack spacing={dashboardSpacing}>
          <KeyValue
            Key={props.label}
            value={values?.values?.main.count || 0}
            size='big'
            keySize='big'
          />
          <KeyValueList
            values={[[
              {
                key: t('common.financial.weasse.sum'),
                keyColor: props.color,
                value: currencyFormatter({ value: values?.values?.main.weasse_sum, currency: 'EUR' })
              },
              {
                key: t('common.financial.weasseMax.sum'),
                keyColor: props.color,
                value: currencyFormatter({ value: values?.values?.main.weasseMax_sum, currency: 'EUR' })
              }
            ]]}
          />
          <Paper sx={{ width: '100%', bgcolor: 'transparent', borderColor: props.color ? lighten(props.color, 0.2) : undefined, color: props.color, fontWeight: 500 }}>
            <KeyValueList
              values={[
                (values?.values && Object.keys(values?.values).filter(value => value !== 'main' && value !== 'totalCount').length > 0 &&
                  Object.keys(values?.values).filter(value => value !== 'main' && value !== 'totalCount').map(value => ([
                    {
                      key: t(`portfolios.dashboard.groups.labels.${value}.label`),
                      keyColor: props.color,
                      value: (values.values[value as keyof typeof values.values] as Record<string, number>).count || 0
                    },
                    {
                      key: t('common.financial.valuation.sum'),
                      keyColor: props.color,
                      value: currencyFormatter({ value: (values.values[value as keyof typeof values.values] as GroupBaseValues)?.valuationSum, currency: 'EUR' })
                    },
                    {
                      key: t('common.financial.valuation.sumOfSum'),
                      keyColor: props.color,
                      value: currencyFormatter({ value: (values.values[value as keyof typeof values.values] as GroupBaseValues)?.valuationSum_sum, currency: 'EUR' })
                    },
                    {
                      key: t('common.financial.ebitda.sum'),
                      keyColor: props.color,
                      value: currencyFormatter({ value: (values.values[value as keyof typeof values.values] as GroupBaseValues)?.ebitdaSum, currency: 'EUR' })
                    },
                    {
                      key: t('common.financial.capital.sum'),
                      keyColor: props.color,
                      value: currencyFormatter({ value: (values.values[value as keyof typeof values.values] as GroupBaseValues)?.capitalSum, currency: 'EUR' })
                    },
                    {
                      key: t('common.financial.turnover.sum'),
                      keyColor: props.color,
                      value: currencyFormatter({ value: (values.values[value as keyof typeof values.values] as GroupBaseValues)?.turnoverSum, currency: 'EUR' })
                    }
                  ])).flat(1) || [])
              ]}
            />
          </Paper>
        </Stack> :
        <KeyValue
          value={values.values.totalCount || 0}
          size={props.size}
        />
      }
    </Box> : <CircularProgress size={props.size === 'big' ? 28 : props.size === 'normal' ? 20 : 16} color='inherit' />
  );
}

function PortfolioDashboardProxemee(props: { full?: boolean } & BasePortfolioDashboardProps): ReactElement {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [possibleGroups, setPossibleGroups] = useState<(string | undefined)[]>([]);
  const [possibleSubGroups, setPossibleSubGroups] = useState<{ id?: string, subIds: (string | undefined)[]}[]>([]);

  const colors = [
    {
      color: theme.palette.success.dark,
      bgColor: theme.palette.success.light
    },
    {
      color: theme.palette.warning.dark,
      bgColor: theme.palette.warning.light
    },
    {
      color: theme.palette.error.dark,
      bgColor: theme.palette.error.light
    }
  ];

  useEffect(() => {
    setPossibleGroups(uniq(getProxemeeSubGroups(props.group).map(group => group.subGroups?.map(subGroup => subGroup.subGroupId.split('.').at(-1))).flat(1)));
  }, [props.group]);

  useEffect(() => {
    setPossibleSubGroups(possibleGroups.map(possibleGroup => ({
      id: possibleGroup,
      subIds: uniq(getProxemeeSubGroups(props.group)
        .filter(group => (group.subGroups?.find(subGroup => subGroup.subGroupId.split('.').at(-1) === possibleGroup)?.subGroups?.length || 0) > 0)
        .map(group => (group.subGroups?.find(subGroup => subGroup.subGroupId.split('.').at(-1) === possibleGroup)?.subGroups?.map(subGroup => subGroup.subGroupId.split('.').at(-1))))
        .flat(1))
    })));
  }, [possibleGroups]);

  return (
    <Paper
      variant='hoverElevation2'
      sx={{ minWidth: 'min-content', width: 'fit-content', bgcolor: theme.palette.grey['200'], p: dashboardSpacing }}
    >
      <Grid container spacing={2} minWidth='fit-content' minHeight='100%'>
        <Grid xs={12} height='min-content' onClick={!props.full ? () => navigate('../proxemee') : undefined} sx={{ cursor: !props.full ? 'pointer' : undefined }}>
          <Stack direction='row' alignItems='center' spacing={2}>
            <Stack direction='row' alignItems='center'>
              <IconUsers />
              <IconTopologyRing2 />
            </Stack>
            <img src={PROXEMEE} alt='PROXEMEE' height={24} width={96}/>
            <Typography variant='h4'>{t('proxemee.desc')}</Typography>
          </Stack>
        </Grid>
        <Grid container spacing={dashboardSpacing} xs={12} minWidth='max-content'>
          {!props.full &&
            <Grid container minWidth={120} width='min-content' alignContent='flex-start'>
              <Grid xs={12} height={30} />
              <Grid xs={12}>
                <Paper sx={{ ...basePaperSx, bgcolor: 'transparent', borderColor: 'transparent' }}>
                  <Box width='100%'>
                    <Grid container spacing={dashboardSpacing}>
                      <Grid xs={12}>
                        <Box height={23} />
                      </Grid>
                      {possibleGroups.map(possibleGroup => (
                        <Grid key={`label-${possibleGroup}`} xs={12}>
                          <Paper sx={{ ...basePaperSx, bgcolor: 'transparent', borderColor: 'transparent' }}>
                            <Stack spacing={dashboardSpacing / 2} height={(possibleSubGroups.find(subGroup => subGroup.id === possibleGroup)?.subIds.length || 0) > 0 ? 60 : 33} width='100%' alignItems='center' justifyContent='center'>
                              <Typography width='100%' variant='h4'>
                                {t(`portfolios.dashboard.groups.labels.${possibleGroup}.label`)}
                              </Typography>
                              {possibleSubGroups.find(subGroup => subGroup.id === possibleGroup)?.subIds.map(subId => (
                                <Stack key={subId} spacing={dashboardSpacing} direction='row' alignItems='center'>
                                  <IconCornerDownRight size={20} />
                                  <Typography noWrap mt='4px !important'>
                                    {t(`portfolios.dashboard.groups.labels.${subId}.label`)}
                                  </Typography>
                                </Stack>
                              ))}
                            </Stack>
                          </Paper>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          }
          {getProxemeeSubGroups(props.group).map((group, index) => (
            <Grid key={group.subGroupId} container spacing={dashboardSpacing} width='min-content'>
              {!props.full &&
                <Grid xs={12}>
                  <Typography textAlign='center' variant={props.full ? 'h3' : 'h4'}>
                    {t(`portfolios.dashboard.groups.labels.${group.subGroupId.split('.').at(-1)}.label`)}
                  </Typography>
                </Grid>
              }
              <Grid xs={12}>
                <Paper variant='hoverElevation1' sx={{ ...basePaperSx, bgcolor: theme.palette.grey['300'] }}>
                  <Box width='100%'>
                    <Grid container spacing={dashboardSpacing}>
                      <Grid xs={12} display='flex' justifyContent='center'>
                        <PortfolioDashboardProxemeeSubGroup {...props} label={props.full ? t(`portfolios.dashboard.groups.labels.${group.subGroupId.split('.').at(-1)}.label`) : undefined} groupId={group.subGroupId} size='normal' />
                      </Grid>
                      {possibleGroups.map(possibleGroup => (
                        <Grid key={`${group.subGroupId}-${possibleGroup}`} xs={12}>
                          <Paper
                            variant='hoverElevation1'
                            sx={{
                              ...basePaperSx,
                              bgcolor: colors[index].bgColor,
                              borderColor: darken(colors[index].bgColor, 0.2),
                              pl: 4,
                              pr: 4
                            }}
                          >
                            <Stack spacing={dashboardSpacing / 2} justifyContent='center' width='100%'>
                              <Box
                                sx={{ color: colors[index].color }}
                                display='flex'
                                justifyContent='center'
                              >
                                <PortfolioDashboardProxemeeSubGroup {...props} label={t(`portfolios.dashboard.groups.labels.${possibleGroup}.label`)} color={colors[index].color} groupId={group.subGroups?.find(subGroup => subGroup.subGroupId.split('.').at(-1) === possibleGroup)?.subGroupId} size='big' />
                              </Box>
                              {group.subGroups?.find(subGroup => subGroup.subGroupId.split('.').at(-1) === possibleGroup)?.subGroups?.map(subGrp => (
                                <Stack
                                  key={subGrp.subGroupId}
                                  spacing={dashboardSpacing / 2}
                                  direction='row'
                                  alignItems={props.full ? 'flex-start' : 'center'}
                                  justifyContent='center'
                                  width='100%'
                                  sx={{ color: colors[index].color }}
                                >
                                  <IconCornerDownRight size={20} />
                                  <Typography noWrap mt='4px !important'>
                                    <PortfolioDashboardProxemeeSubGroup {...props} label={t(`portfolios.dashboard.groups.labels.${subGrp.subGroupId.split('.').at(-1)}.label`)} color={colors[index].color} groupId={subGrp.subGroupId} size='small' />
                                  </Typography>
                                </Stack>
                              ))}
                            </Stack>
                          </Paper>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Paper>
  );
}

export default PortfolioDashboardProxemee;
