import { ThemeOptions } from '@mui/material';

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    menu: true,
    menuContrasted: true,
    menuActive: true,
    menuUser: true,
    menuUserActive: true,
    menuIcon: true,
    menuIconActive: true,
    appIcon: true,
    appIconActive: true,
    icon: true,
    topbarIcon: true,
    sidebarChevron: true,
    tabContained: true,
    tabText: true,
    outlinedIcon: true,
    containedIcon: true,
    textIcon: true
  }
}

declare module '@mui/material/Paper' {
  interface PaperPropsVariantOverrides {
    hoverElevation1: true,
    hoverElevation2: true,
    hoverElevation3: true,
    hoverElevation4: true
  }
}

declare module '@mui/material/Chip' {
  interface ChipPropsVariantOverrides {
    env: true
  }
}

const customThemeComponents: ThemeOptions = {
  components: {
    MuiPaper: {
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            width: 'fit-content',
            boxShadow: 0,
            p: 2,
            bgcolor: 'background.default',
            border: 'solid 1px',
            borderColor: 'grey.400',
            transition: 'all 0.2s ease-in-out'
          })
      },
      variants: [
        {
          props: { variant: 'hoverElevation1' },
          style: ({ theme }) => theme.unstable_sx({
            boxShadow: 0,
            transition: 'all 0.2s ease-in-out',
            '&:hover': {
              boxShadow: 1,
              transition: 'all 0.2s ease-in-out'
            }
          })
        },
        {
          props: { variant: 'hoverElevation2' },
          style: ({ theme }) => theme.unstable_sx({
            boxShadow: 0,
            transition: 'all 0.2s ease-in-out',
            '&:hover': {
              boxShadow: 2,
              transition: 'all 0.2s ease-in-out'
            }
          })
        },
        {
          props: { variant: 'hoverElevation3' },
          style: ({ theme }) => theme.unstable_sx({
            boxShadow: 0,
            transition: 'all 0.2s ease-in-out',
            '&:hover': {
              boxShadow: 3,
              transition: 'all 0.2s ease-in-out'
            }
          })
        },
        {
          props: { variant: 'hoverElevation4' },
          style: ({ theme }) => theme.unstable_sx({
            boxShadow: 0,
            transition: 'all 0.2s ease-in-out',
            '&:hover': {
              boxShadow: 4,
              transition: 'all 0.2s ease-in-out'
            }
          })
        }
      ]
    },
    MuiPopover: {
      styleOverrides: {
        paper: ({ theme }) =>
          theme.unstable_sx({
            bgcolor: 'grey.50'
          })
      }
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'icon' },
          style: ({ theme }) => theme.unstable_sx({
            borderRadius: 1,
            display: 'block',
            textTransform: 'none',
            height: '40px',
            width: '40px',
            minWidth: 0,
            p: 2,
            backgroundColor: 'white',
            border: '1px black',
            justifyContent: 'flex-start',
            transition: 'all 0.2s ease-in-out',
            '&:hover': {
              backgroundColor: 'primary.main',
              color: 'primary.contrastText',
              transition: 'all 0.2s ease-in-out'
            }
          })
        },
        {
          props: { variant: 'containedIcon' },
          style: ({ theme }) => theme.unstable_sx({
            borderRadius: 2,
            display: 'block',
            textTransform: 'none',
            height: '40px',
            width: '40px',
            minWidth: 0,
            p: 2,
            boxShadow: 0,
            border: 'solid 1px',
            borderColor: theme.primaryColors?.darkest,
            backgroundColor: 'primary.main',
            color: 'primary.contrastText',
            justifyContent: 'flex-start',
            transition: 'all 0.2s ease-in-out',
            '&:hover': {
              boxShadow: 2,
              backgroundColor: theme.primaryColors?.darkest,
              borderColor: 'primary.main',
              color: 'primary.contrastText',
              transition: 'all 0.2s ease-in-out'
            }
          })
        },
        {
          props: { variant: 'outlinedIcon' },
          style: ({ theme }) => theme.unstable_sx({
            borderRadius: 2,
            display: 'block',
            textTransform: 'none',
            height: '40px',
            width: '40px',
            minWidth: 0,
            p: 2,
            backgroundColor: 'transparent',
            border: 'solid 1px',
            borderColor: theme.palette.grey['400'],
            justifyContent: 'flex-start',
            transition: 'all 0.2s ease-in-out',
            '&:hover': {
              backgroundColor: 'transparent',
              borderColor: theme.palette.primary.main,
              color: theme.palette.primary.main,
              transition: 'all 0.2s ease-in-out'
            }
          })
        },
        {
          props: { variant: 'textIcon' },
          style: ({ theme }) => theme.unstable_sx({
            borderRadius: 2,
            display: 'block',
            textTransform: 'none',
            height: '40px',
            width: '40px',
            minWidth: 0,
            p: 2,
            backgroundColor: 'transparent',
            justifyContent: 'flex-start',
            transition: 'all 0.2s ease-in-out',
            '&:hover': {
              backgroundColor: 'grey.400',
              transition: 'all 0.2s ease-in-out'
            }
          })
        },
        {
          props: { variant: 'containedIcon', size: 'small' },
          style: ({ theme }) => theme.unstable_sx({
            borderRadius: 2,
            display: 'block',
            textTransform: 'none',
            height: '28px',
            width: '28px',
            minWidth: 0,
            p: 1,
            boxShadow: 0,
            border: 'solid 1px',
            borderColor: theme.primaryColors?.darkest,
            backgroundColor: 'primary.main',
            color: 'primary.contrastText',
            justifyContent: 'flex-start',
            transition: 'all 0.2s ease-in-out',
            '&:hover': {
              boxShadow: 2,
              backgroundColor: theme.primaryColors?.darkest,
              borderColor: 'primary.main',
              color: 'primary.contrastText',
              transition: 'all 0.2s ease-in-out'
            }
          })
        },
        {
          props: { variant: 'outlinedIcon', size: 'small' },
          style: ({ theme }) => theme.unstable_sx({
            borderRadius: 2,
            display: 'block',
            textTransform: 'none',
            height: '28px',
            width: '28px',
            minWidth: 0,
            p: 1,
            backgroundColor: 'transparent',
            border: 'solid 1px',
            borderColor: theme.palette.grey['400'],
            justifyContent: 'flex-start',
            transition: 'all 0.2s ease-in-out',
            '&:hover': {
              backgroundColor: 'transparent',
              borderColor: theme.palette.primary.main,
              color: theme.palette.primary.main,
              transition: 'all 0.2s ease-in-out'
            }
          })
        },
        {
          props: { variant: 'textIcon', size: 'small' },
          style: ({ theme }) => theme.unstable_sx({
            borderRadius: 2,
            display: 'block',
            textTransform: 'none',
            height: '28px',
            width: '28px',
            minWidth: 0,
            p: 1,
            backgroundColor: 'transparent',
            justifyContent: 'flex-start',
            transition: 'all 0.2s ease-in-out',
            '&:hover': {
              backgroundColor: 'grey.400',
              transition: 'all 0.2s ease-in-out'
            }
          })
        },
        {
          props: { variant: 'sidebarChevron' },
          style: ({ theme }) => theme.unstable_sx({
            borderRadius: 2,
            display: 'block',
            textTransform: 'none',
            height: '40px',
            width: '40px',
            minWidth: 0,
            p: 2,
            ml: -2,
            boxShadow: 2,
            transform: 'scale(0.6)',
            backgroundColor: 'grey.50',
            transition: 'all 0.2s ease-in-out',
            '&:hover': {
              backgroundColor: 'primary.main',
              color: 'primary.contrastText',
              transition: 'all 0.2s ease-in-out'
            }
          })
        },
        {
          props: { variant: 'icon', size: 'small' },
          style: ({ theme }) => theme.unstable_sx({
            borderRadius: 2,
            display: 'block',
            textTransform: 'none',
            height: '40px',
            width: '40px',
            minWidth: 0,
            p: 2,
            transform: 'scale(0.6)',
            backgroundColor: 'transparent',
            justifyContent: 'flex-start',
            transition: 'all 0.2s ease-in-out',
            '&:hover': {
              backgroundColor: 'primary.main',
              color: 'primary.contrastText',
              transition: 'all 0.2s ease-in-out'
            }
          })
        },
        {
          props: { variant: 'topbarIcon' },
          style: ({ theme }) => theme.unstable_sx({
            borderRadius: 2,
            display: 'block',
            textTransform: 'none',
            height: '40px',
            width: '40px',
            minWidth: 0,
            p: 2,
            mt: -1,
            transform: 'scale(0.8)',
            backgroundColor: 'primary.light',
            color: 'primary.main',
            border: 'solid 1px',
            borderColor: 'grey.100',
            justifyContent: 'flex-start',
            transition: 'all 0.2s ease-in-out',
            '&:hover': {
              backgroundColor: 'primary.main',
              color: 'primary.contrastText',
              transition: 'all 0.2s ease-in-out'
            }
          })
        },
        {
          props: { variant: 'menu' },
          style: ({ theme }) => theme.unstable_sx({
            borderRadius: 1,
            textTransform: 'none',
            height: '48px',
            width: '100%',
            pl: 5,
            backgroundColor: 'transparent',
            justifyContent: 'flex-start',
            transition: 'all 0.2s ease-in-out',
            '&:hover': {
              backgroundColor: theme.primaryColors?.light || 'primary.light',
              color: theme.primaryColors?.darkest || 'primary.main',
              transition: 'all 0.2s ease-in-out'
            }
          })
        },
        {
          props: { variant: 'menuActive' },
          style: ({ theme }) => theme.unstable_sx({
            borderRadius: 1,
            textTransform: 'none',
            height: '48px',
            width: '100%',
            pl: 5,
            color: theme.primaryColors?.darkest || 'primary.main',
            backgroundColor: theme.primaryColors?.light || 'primary.light',
            justifyContent: 'flex-start',
            transition: 'all 0.2s ease-in-out',
            '&:hover': {
              backgroundColor: theme.primaryColors?.light || 'primary.main',
              color: 'primary.dark',
              transition: 'all 0.2s ease-in-out'
            }
          })
        },
        {
          props: { variant: 'menuContrasted' },
          style: ({ theme }) => theme.unstable_sx({
            borderRadius: 1,
            textTransform: 'none',
            height: '48px',
            width: '100%',
            pl: 5,
            backgroundColor: 'transparent',
            justifyContent: 'flex-start',
            transition: 'all 0.2s ease-in-out',
            '&:hover': {
              backgroundColor: theme.primaryColors?.lightest,
              color: theme.primaryColors?.darkest,
              transition: 'all 0.2s ease-in-out'
            }
          })
        },
        {
          props: { variant: 'menuIcon' },
          style: ({ theme }) => theme.unstable_sx({
            borderRadius: 1,
            display: 'block',
            minWidth: 0,
            width: '48px',
            height: '48px',
            p: '12px',
            transform: 'scale(0.75)',
            textTransform: 'none',
            color: 'primary.main',
            backgroundColor: 'primary.light',
            transition: 'all 0.2s ease-in-out',
            '&:hover': {
              backgroundColor: 'primary.main',
              color: 'primary.contrastText',
              transition: 'all 0.2s ease-in-out'
            }
          })
        },
        {
          props: { variant: 'appIcon', size: 'large' },
          style: ({ theme }) => theme.unstable_sx({
            borderRadius: 1,
            display: 'block',
            minWidth: 0,
            width: '48px',
            height: '48px',
            p: '12px',
            transform: 'scale(1)',
            textTransform: 'none',
            backgroundColor: theme.primaryColors?.light || 'primary.light',
            color: theme.primaryColors?.darkest || 'primary.main',
            transition: 'all 0.2s ease-in-out',
            '&:hover': {
              backgroundColor: 'primary.main',
              color: 'primary.contrastText',
              transition: 'all 0.2s ease-in-out'
            }
          })
        },
        {
          props: { variant: 'menuIcon', size: 'large' },
          style: ({ theme }) => theme.unstable_sx({
            borderRadius: 1,
            display: 'block',
            minWidth: 0,
            width: '48px',
            height: '48px',
            p: '12px',
            transform: 'scale(1)',
            textTransform: 'none',
            color: 'primary.main',
            backgroundColor: 'transparent',
            transition: 'all 0.2s ease-in-out',
            '&:hover': {
              backgroundColor: theme.primaryColors?.light || 'primary.light',
              color: `${theme.primaryColors?.darkest} !important` || 'primary.main',
              transition: 'all 0.2s ease-in-out'
            }
          })
        },
        {
          props: { variant: 'appIconActive', size: 'large' },
          style: ({ theme }) => theme.unstable_sx({
            borderRadius: 1,
            display: 'block',
            minWidth: 0,
            width: '48px',
            height: '48px',
            p: '12px',
            transform: 'scale(1)',
            textTransform: 'none',
            color: 'primary.light',
            backgroundColor: 'primary.main',
            transition: 'all 0.2s ease-in-out'
          })
        },
        {
          props: { variant: 'menuIconActive', size: 'large' },
          style: ({ theme }) => theme.unstable_sx({
            borderRadius: 1,
            display: 'block',
            minWidth: 0,
            width: '48px',
            height: '48px',
            p: '12px',
            transform: 'scale(1)',
            textTransform: 'none',
            color: theme.primaryColors?.darkest || 'primary.main',
            backgroundColor: theme.primaryColors?.light || 'primary.light',
            transition: 'all 0.2s ease-in-out',
            '&:hover': {
              backgroundColor: theme.primaryColors?.light || 'primary.light',
              color: 'primary.dark !important',
              transition: 'all 0.2s ease-in-out'
            }
          })
        },
        {
          props: { variant: 'menuUser' },
          style: ({ theme }) => theme.unstable_sx({
            borderRadius: 2,
            minWidth: 0,
            height: '48px',
            p: '12px',
            textTransform: 'none',
            backgroundColor: theme.primaryColors?.light ||'primary.light',
            color: theme.primaryColors?.darkest || 'primary.main',
            transition: 'all 0.2s ease-in-out',
            '&:hover': {
              backgroundColor: 'primary.main',
              color: 'primary.contrastText',
              transition: 'all 0.2s ease-in-out'
            }
          })
        },
        {
          props: { variant: 'menuUserActive' },
          style: ({ theme }) => theme.unstable_sx({
            borderRadius: 2,
            minWidth: 0,
            height: '48px',
            p: '12px',
            textTransform: 'none',
            color: 'primary.light',
            backgroundColor: 'primary.main'
          })
        },
        {
          props: { variant: 'tabText', size: 'small' },
          style: ({ theme }) => theme.unstable_sx({
            pl: 2,
            pr: 2,
            pt: 0,
            pb: 0,
            textTransform: 'none',
            color: 'text.primary',
            fontSize: '12px',
            letterSpacing: '0.2px',
            backgroundColor: 'transparent',
            boxShadow: 0,
            transition: 'all 0.2s ease-in-out',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.1)',
              color: 'primary.main',
              boxShadow: 0,
              transition: 'all 0.2s ease-in-out'
            }
          })
        },
        {
          props: { variant: 'tabContained', size: 'small' },
          style: ({ theme }) => theme.unstable_sx({
            pl: 2,
            pr: 2,
            pt: 0,
            pb: 0,
            textTransform: 'none',
            color: 'primary.contrastText',
            fontSize: '12px',
            letterSpacing: '0.2px',
            backgroundColor: 'primary.main',
            boxShadow: 0,
            transition: 'all 0.2s ease-in-out',
            '&:hover': {
              backgroundColor: 'primary.main',
              boxShadow: 0,
              transition: 'all 0.2s ease-in-out'
            }
          })
        },
        {
          props: { variant: 'contained' },
          style: ({ theme }) => theme.unstable_sx({
            color: 'primary.contrastText',
            backgroundColor: 'primary.main',
            boxShadow: 0,
            border: 'solid 1px',
            borderColor: theme.primaryColors?.darkest,
            transition: 'all 0.2s ease-in-out',
            '&:hover': {
              backgroundColor: theme.primaryColors?.darkest,
              borderColor: 'primary.main',
              boxShadow: 2,
              transition: 'all 0.2s ease-in-out'
            }
          })
        },
        {
          props: { variant: 'contained', disabled: true },
          style: ({ theme }) => theme.unstable_sx({
            color: 'primary.contrastText',
            backgroundColor: 'primary.main',
            boxShadow: 0,
            border: 'none',
            transition: 'all 0.2s ease-in-out',
            '&:hover': {
              backgroundColor: theme.primaryColors?.darkest,
              boxShadow: 2,
              transition: 'all 0.2s ease-in-out'
            }
          })
        },
        {
          props: { variant: 'contained', size: 'small' },
          style: ({ theme }) => theme.unstable_sx({
            pt: 1,
            pb: 1,
            pl: 3,
            pr: 3,
            color: 'primary.contrastText',
            backgroundColor: 'primary.main',
            boxShadow: 0,
            border: 'solid 1px',
            borderColor: theme.primaryColors?.darkest,
            transition: 'all 0.2s ease-in-out',
            '&:hover': {
              backgroundColor: theme.primaryColors?.darkest,
              borderColor: 'primary.main',
              boxShadow: 2,
              transition: 'all 0.2s ease-in-out'
            }
          })
        },
        {
          props: { variant: 'outlined' },
          style: ({ theme }) => theme.unstable_sx({
            pt: 1,
            pb: 1,
            pl: 3,
            pr: 3,
            color: 'text.primary',
            border: 'solid 1.5px',
            borderColor: 'grey.400',
            boxShadow: 0,
            transition: 'all 0.2s ease-in-out',
            '&:hover': {
              color: 'primary.main',
              border: 'solid 1.5px',
              borderColor: 'primary.main',
              backgroundColor: 'transparent',
              boxShadow: 0,
              transition: 'all 0.2s ease-in-out'
            }
          })
        },
        {
          props: { variant: 'outlined', size: 'small' },
          style: ({ theme }) => theme.unstable_sx({
            pt: 1,
            pb: 1,
            pl: 3,
            pr: 3,
            color: 'text.primary',
            border: 'solid 1.5px',
            borderColor: 'grey.400',
            boxShadow: 0,
            transition: 'all 0.2s ease-in-out',
            '&:hover': {
              color: 'primary.main',
              border: 'solid 1.5px',
              borderColor: 'primary.main',
              backgroundColor: 'transparent',
              boxShadow: 0,
              transition: 'all 0.2s ease-in-out'
            }
          })
        },
        {
          props: { variant: 'text' },
          style: ({ theme }) => theme.unstable_sx({
            color: 'text.primary',
            textDecoration: 'underline',
            boxShadow: 0,
            transition: 'all 0.2s ease-in-out',
            '&:hover': {
              color: 'primary.main',
              textDecoration: 'underline',
              backgroundColor: 'transparent',
              boxShadow: 0,
              transition: 'all 0.2s ease-in-out'
            }
          })
        },
        {
          props: { variant: 'text', size: 'small' },
          style: ({ theme }) => theme.unstable_sx({
            pt: 1,
            pb: 1,
            pl: 3,
            pr: 3,
            color: 'text.primary',
            textDecoration: 'underline',
            boxShadow: 0,
            transition: 'all 0.2s ease-in-out',
            '&:hover': {
              color: 'primary.main',
              textDecoration: 'underline',
              backgroundColor: 'transparent',
              boxShadow: 0,
              transition: 'all 0.2s ease-in-out'
            }
          })
        }
      ],
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            borderRadius: 2,
            letterSpacing: '0.35px',
            textTransform: 'none',
            transition: 'all 0.2s ease-in-out',
            '&:hover': {
              bgcolor: theme.primaryColors?.darkest,
              transition: 'all 0.2s ease-in-out'
            }
          }),
        startIcon: ({ theme, ownerState }) =>
          theme.unstable_sx({
            ...(ownerState.variant?.includes('menu') && {
              pr: 1,
              pl: 1
            })
          }),
        endIcon: ({ theme, ownerState }) =>
          theme.unstable_sx({
            ...(ownerState.variant?.includes('menu') && {
              ml: 'auto'
            })
          }),
        focusVisible: ({ theme, ownerState }) =>
          theme.unstable_sx({
            ...(ownerState.variant === 'menu' && {
              backgroundColor: 'rgba(0, 0, 0, 0.1)'
            })
          })
      }
    },
    MuiChip: {
      styleOverrides: {
        label: ({ theme }) => theme.unstable_sx({
          pl: 3,
          pr: 3
        })
      },
      variants: [
        {
          props: { variant: 'env', color: 'secondary' },
          style: ({ theme }) => theme.unstable_sx({
            backgroundColor: 'secondary.light',
            color: 'secondary.dark'
          })
        },
        {
          props: { color: 'primary' },
          style: ({ theme }) => theme.unstable_sx({
            backgroundColor: 'primary.light',
            color: 'primary.dark'
          })
        },
        {
          props: { color: 'error' },
          style: ({ theme }) => theme.unstable_sx({
            backgroundColor: 'error.light',
            color: 'error.dark'
          })
        },
        {
          props: { color: 'success' },
          style: ({ theme }) => theme.unstable_sx({
            backgroundColor: 'success.light',
            color: 'success.dark'
          })
        },
        {
          props: { color: 'warning' },
          style: ({ theme }) => theme.unstable_sx({
            backgroundColor: 'warning.light',
            color: 'warning.dark'
          })
        },
        {
          props: { color: 'info' },
          style: ({ theme }) => theme.unstable_sx({
            backgroundColor: 'info.light',
            color: 'info.dark'
          })
        }
      ]
    },
    MuiMenu: {
      styleOverrides: {
        paper: ({ theme }) =>
          theme.unstable_sx({
            mt: 2,
            p: 0,
            boxShadow: '0px 12px 24px rgba(0, 0, 0 , 0.32)'
          })
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            p: 2,
            pt: 1,
            pb: 1
          })
      }
    },
    MuiList: {
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            pt: 1,
            pb: 1
          })
      }
    },
    MuiBadge: {
      styleOverrides: {
        badge: ({ theme }) =>
          theme.unstable_sx({
            mt: 1,
            mr: 2
          })
      }
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            width: 'fit-content',
            height: '20px',
            pl: 1,
            pr: 1
          }),
        ol: ({ theme }) =>
          theme.unstable_sx({
            height: '20px'
          }),
        li: ({ theme }) =>
          theme.unstable_sx({
            height: '20px'
          })
      }
    },
    MuiTabs: {
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            width: '100%'
          }),
        flexContainer: ({ theme }) =>
          theme.unstable_sx({
            gap: '16px'
          }),
        scrollButtons: ({ theme }) =>
          theme.unstable_sx({
            borderRadius: 2
          })
      }
    },
    MuiTab: {
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            position: 'relative',
            zIndex: 3,
            minWidth: '0px',
            paddingRight: 2,
            paddingLeft: 2,
            fontSize: '14px',
            letterSpacing: '0.2px',
            textTransform: 'none',
            color: 'inherit',
            transition: 'all 0.2s ease-in-out',
            '&.Mui-selected': {
              color: 'primary',
              transition: 'all 0.2s ease-in-out'
            }
          })
      }
    },
    MuiTableContainer: {
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            borderRadius: 1,
            border: 'none'
          })
      }
    }
  }
};

export default customThemeComponents;
