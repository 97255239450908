import React, { ReactElement } from 'react';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import { darken } from '@mui/material/styles';
import KeyValueList, { KeyValueListProps } from './keyvaluelist';
import KeyValue, { KeyValueProps } from './keyvalue';

interface KeyValueWithCardProps {
  values?: Omit<KeyValueListProps['values'], 'card'>,
  keyValueCard: Omit<KeyValueProps, 'card'> & {
    bgColor?: string,
    color?: string,
    width?: string,
    minWidth?: string,
    height?: string,
    action?: () => void,
    selected?: true,
    disabled?: true,
    padding?: string,
    link?: string,
    params?: string
  },
  padding?: string,
  alignItems?: string
}

function KeyValueWithCard(props: KeyValueWithCardProps): ReactElement {
  const navigate = useNavigate();

  return (
    <Stack spacing={4} direction='row' height='100%' p={props.padding}>
      <Box
        onClick={(e) => {
          props.keyValueCard.action && props.keyValueCard.action();
          e.preventDefault();
          e.stopPropagation();
          props.keyValueCard.link && navigate({
            pathname: props.keyValueCard.link,
            search: props.keyValueCard.params && `?${props.keyValueCard.params}`
          });
        }}
        width={props.keyValueCard.width}
        minWidth={props.keyValueCard.minWidth}
      >
        <Paper
          elevation={0}
          variant={(props.keyValueCard.action || props.keyValueCard.link) ? 'hoverElevation2' : undefined}
          sx={{
            p: props.keyValueCard.padding,
            bgcolor: props.keyValueCard.bgColor,
            height: props.keyValueCard.height || '100%',
            color: props.keyValueCard.color,
            width: '100%',
            minWidth: props.keyValueCard.minWidth,
            cursor: (props.keyValueCard.action || props.keyValueCard.link) ? 'pointer' : undefined,
            border: props.keyValueCard.selected ? `solid 1.5px ${props.keyValueCard.color}` : undefined,
            opacity: props.keyValueCard.disabled ? 0.6 : undefined,
            borderColor: props.keyValueCard.bgColor ? darken(props.keyValueCard.bgColor, 0.2) : undefined
          }}
        >
          <KeyValue card {...props.keyValueCard} />
        </Paper>
      </Box>
      {props.values && <KeyValueList card {...props} values={props.values} />}
    </Stack>
  );
}

export default KeyValueWithCard;
