import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import backend from "i18next-http-backend";
import { initReactI18next } from 'react-i18next';

export interface I18nPossibleLanguageType {
  full: string,
  global: string,
  version: string
}

export const i18nPossibleLanguages: I18nPossibleLanguageType[] = [
  { full: "English", global: "en", version: "en-GB" },
  { full: "English (US)", global: "en/us", version: "en-US" },
  { full: "Français", global: "fr", version: "fr-FR" }
];

// translations are already at
// '../public/locales/en/translation.json'
// which is the default for the xhr backend to load from

i18n
  .use(detector)
  .use(backend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    ns: [],
    backend: {
      loadPath: `/{{ns}}/locales/{{lng}}/translation.json`,
      requestOptions: {
        cache: 'no-store'
      }
    },
    lng: i18nPossibleLanguages[0].global,
    fallbackLng: "en", // use en if detected lng is not available
    returnNull: false,
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  }).then(() => undefined);

export default i18n;
