import { SegmentationCriteria } from '@deecision/dna-interfaces';
import { currencyFormatter } from '../../../utils';

export type DataType = Required<SegmentationCriteria>['values'][0] & {
  name?: string
};

export type SerieDataType = {
  value: number,
  range: {min: number, max: number} | undefined | null,
  soloValue: string | number | undefined | null
}
export type FlagType = {
  'code': string,
  'emoji': string,
  'unicode': string,
  'name': string,
  'title': string,
  'dialCode': string
}

const colorOrder = ['green', 'orange', 'red', 'black'];
export const colorTableForVeeligenz: { [key: string]: string } = {
  'green': '#70B877',
  'orange': '#F7C368',
  'red': '#F0827A'
};

export const jetBlack = '#333333';

export const getRange = (element: DataType) => {
  if (element.range !== undefined && element.range.min !== undefined && element.range.max === undefined) {

    return (`${element.range.min}-Max`);
  }
  if (element.range !== undefined && element.range.min !== undefined && element.range.max !== undefined) {

    return (`${element.range.min}-${element.range.max}`);
  }

  return undefined;
};

export const getValue = (element: DataType) => {
  if (element.value !== undefined) return element.value;

  return undefined;
};

export function formatNumber(num: number, precision?: number | 0, currency?: string) {
  if (currency) {
    return currencyFormatter({ value: num, currency });
  }

  const map = [
    { suffix: 'T', threshold: 1e12 },
    { suffix: 'B', threshold: 1e9 },
    { suffix: 'M', threshold: 1e6 },
    { suffix: 'K', threshold: 1e3 },
    { suffix: '', threshold: 1 }
  ];

  const found = map.find(x => Math.abs(num) >= x.threshold);

  if (found) {
    return (num / found.threshold).toFixed(precision) + found.suffix;
  }

  return (num === undefined ? 'Max' : num?.toString());

}

export function interpolateColors(color1: any, color2: any, steps: number) {
  const hexToRgb = (hex: { match: (arg0: RegExp) => any[] }) => hex.match(/\w\w/g).map(str => parseInt(str, 16));
  const rgb1 = hexToRgb(color1);
  const rgb2 = hexToRgb(color2);

  const stepSize = [
    (rgb2[0] - rgb1[0]) / steps,
    (rgb2[1] - rgb1[1]) / steps,
    (rgb2[2] - rgb1[2]) / steps
  ];

  const colors = Array.from({ length: steps }, (_, i) => {
    const r = Math.round(rgb1[0] + stepSize[0] * i);
    const g = Math.round(rgb1[1] + stepSize[1] * i);
    const b = Math.round(rgb1[2] + stepSize[2] * i);

    return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
  });

  return colors;
}

export const sortVeegilenzColor = (a: DataType, b: DataType) => {
  const indexA = colorOrder.indexOf(a.value);
  const indexB = colorOrder.indexOf(b.value);

  if (indexA === -1 && indexB === -1) {
    return 0;
  }
  if (indexA === -1) {
    return 1;
  }
  if (indexB === -1) {
    return -1;
  }

  return indexA - indexB;
};

export function getFlagEmoji(countryCode: string | undefined | null) {
  if (countryCode === undefined || countryCode === null || countryCode === '') return '';

  return [...countryCode.toUpperCase()].map(char =>
    String.fromCodePoint(127397 + char.charCodeAt(0))
  ).reduce((a, b) => `${a}${b}`) || '';
}
