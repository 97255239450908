import React, { ReactElement, ReactNode } from 'react';
import Tooltip from '@mui/material/Tooltip';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { Icon, IconDatabase, IconProps } from '@tabler/icons-react';

export interface CompletenessProps {
  completeness?: ReactNode | unknown, // todo: define type
  size?: 'small' | 'medium',
  title?: string,
  icon?: React.ForwardRefExoticComponent<Omit<IconProps, "ref"> & React.RefAttributes<Icon>>
}

function Completeness(props: CompletenessProps): ReactElement {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <>
      {props.completeness !== undefined &&
        <Tooltip
          title={
            `${t('common.utils.completeness')}: ${typeof props.completeness === 'number' ? `${(props.completeness * 100).toFixed(2)}%` : props.completeness === null ? t('veegilenz.errorResponse.notCalculated') : props.completeness }`
          }
          arrow
          placement='top'
        >
          <Chip
            size={props.size || 'small'}
            sx={{
              width: props.size !== 'medium' ? 68 : undefined,
              height: props.size !== 'medium' ? 20 : undefined,
              bgcolor: typeof props.completeness === 'number' ? (props.completeness < 0.33 && theme.palette.error.light ||
                props.completeness >= 0.33 && props.completeness < 0.75 && theme.palette.warning.light ||
                props.completeness >= 0.75 && theme.palette.success.light || undefined) : undefined,
              color: typeof props.completeness === 'number' ? (props.completeness < 0.33 && theme.palette.error.dark ||
                props.completeness >= 0.33 && props.completeness < 0.75 && theme.palette.warning.dark ||
                props.completeness >= 0.75 && theme.palette.success.dark || undefined) : undefined,
              mt: props.size !== 'medium' ? '1px !important' : undefined,
              '& .MuiChip-labelSmall': {
                pl: 1,
                pr: 1
              },
              '& .MuiChip-label': {
                pl: 0,
                pr: 0
              },
              '& .MuiChip-labelMedium': {
                pl: 2,
                pr: 2
              }
            }}
            label={
              <Stack direction='row' alignItems='center' spacing={1}>
                {props.size !== 'small' && <IconDatabase size={props.size === 'medium' ? 22 : 16} />}
                {props.title &&
                  <Typography
                    variant={props.size !== 'medium' ? 'body2' : 'body1'}
                    textAlign='center'
                    sx={{
                      width: props.size !== 'medium' ? '40px' : undefined,
                      fontWeight: 400,
                      mt: '1px'
                    }}
                  >
                    {`${props.title}:`}
                  </Typography>
                }
                <Typography
                  variant={props.size !== 'medium' ? 'body2' : 'body1'}
                  textAlign='center'
                  sx={{
                    width: props.size !== 'medium' ? '40px' : undefined,
                    fontWeight: 500
                  }}
                >
                  {`${typeof props.completeness === 'number' ? `${props.completeness === 1 ? props.completeness * 100 : (props.completeness * 100).toFixed(2)}%` : '?'}`}
                </Typography>
              </Stack>
            }
          />
          {
            /*
            (props.completeness < 0.33 &&
              <IconCircleLetterC size={16} style={{ color: theme.palette.error.main }}/> ||
              props.completeness >= 0.33 && props.completeness < 0.66 &&
              <IconCircleLetterC size={16} style={{ color: theme.palette.warning.main }}/> ||
              props.completeness >= 0.66 &&
              <IconCircleLetterC size={16} style={{ color: theme.palette.success.main }}/>) ||
            <></>
            */
          }
        </Tooltip>
      }
    </>
  );
}

export default Completeness;
