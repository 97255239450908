import React, { useEffect, useMemo, useState } from "react";
import { CompanyEntity, EntityLinksAggregation } from "@deecision/dna-interfaces";
import { Button, Chip, lighten, Paper, Stack, Tooltip, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Handle, Position, useStore } from "reactflow";
import { IconArrowBackUp, IconArrowBigLeftLines, IconArrowBigRightLines, IconChartHistogram, IconChartLine, IconCoins, IconCopy, IconId, IconUsers, IconX } from "@tabler/icons-react";
import IconBuildings from "@/assets/custom/IconBuildings";
import Flags from '@/components/flags';
import { CompanyGetter, getEntity } from "../../../providers/getter";
import { EntityLinksQueryAggregatorService } from "../../../../api/services/query";
import { NodeType } from "../types";
import { deployHandling, undeployHandling } from "./miscellaneous/deploy.undeploy.nodes";
import { countNodes } from "../utils";
import { SortNetworkArrayByCriteria } from './miscellaneous/sortNetworkArrayByCriteria';
import { SettingsProps } from "@/components/filters/simple/switch";

export function CompaniesCard(data: any) {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const sourcePosition = data.data.source as keyof typeof Position;
  const targetPosition = data.data.target as keyof typeof Position;
  const [copied, setCopied] = useState(false);
  const [companies, setCompanies] = useState<EntityLinksAggregation[]>([]);
  const [relatedPersons, setRelatedPersons] = useState<EntityLinksAggregation[]>([]);
  const [relatedCompanies, setRelatedCompanies] = useState<EntityLinksAggregation[]>([]);
  const [parsedCompanies, setParsedCompanies] = useState<EntityLinksAggregation[]>([]);
  const [deployChildrenRelation, setDeployChildrenRelation] = useState<boolean | undefined>();
  const [deployParentRelation, setDeployParentRelation] = useState<boolean | undefined>();
  const [lastDeployAction, setLastDeployAction] = useState<'parent' | 'children'>();
  const [deployableNodesNumber, setDeployableNodesNumber] = useState<{ parent: number, children: number }>({ parent: 0, children: 0 });
  const zoomLevel = useStore((state: { transform: any[] }) => state.transform[2]);
  const linksServiceToCompany = new EntityLinksQueryAggregatorService<EntityLinksAggregation>({
    entityType: data.data.entitie.entity2.entityType,
    toEntityType: 'deecCompany'
  });
  const linksServiceToPerson = new EntityLinksQueryAggregatorService<EntityLinksAggregation>({
    entityType: data.data.entitie.entity2.entityType,
    toEntityType: 'deecPerson'
  });
  const companyGetter = useMemo(() => new CompanyGetter({
    data: companies as unknown as CompanyEntity,
    errorMsg: t('common.utils.unknown')
  }), [companies]);
  const [entity, setEntity] = useState<any[]>([]);
  const companiesGetter = useMemo(() => entity.map(node => (
    node ?
      new CompanyGetter({
        data: node as unknown as CompanyEntity,
        errorMsg: t('common.utils.unknown')
      }):
      undefined
  )
  ), [entity]);

  useEffect(() => {
    const sortObj = data.data.sortList.current.find((sort: { direction: any }) => sort.direction);
    const sortingType = data.data.sortList.current.find((sort: { direction: any }) => sort.direction)?.label;

    getEntity({ entityType: data.data.entitie.entity2?.entityType, entityId: data.data.entitie.entity2?.entityId }).then((res: any) => {
      if (res) {
        setCompanies(res);
      }
    }).catch(e => console.log(e));
    linksServiceToPerson.get(data.data.entitie.entity2.entityId)
      .then((res) => {
        if (res.data) {
          setRelatedPersons(SortNetworkArrayByCriteria(res.data, sortingType || 'Alphabet', sortObj?.direction === 'asc'));
        }
      })
      .catch(e => console.error(e));
    linksServiceToCompany.get(data.data.entitie.entity2.entityId)
      .then((res) => {
        if (res.data) {
          setRelatedCompanies(SortNetworkArrayByCriteria(res.data, sortingType || 'Alphabet', sortObj?.direction === 'asc'));
        }
      })
      .catch(e => console.error(e));
  }, [data.data.entitie.entity2]);

  useEffect(() => {
    const mainEntitieNode = data.data.nodes.current[0];
    const parentNodes =  data.data.nodes.current?.find((n: NodeType) => n.id === data.id);
    const parsedDuplicateCompanies = relatedCompanies.filter((companie: EntityLinksAggregation) => {
      const { linkedNotes, companieLinkedNodes, entitie } = parentNodes.data;

      return (
        (linkedNotes || entitie.entity2.name !== companie.entity2.name) &&
        (!companieLinkedNodes || !companieLinkedNodes.includes(companie.entity2.name)) &&
        (companie.entity2.name !== mainEntitieNode.data.label)
      );
    });
    const parentCompaniesNodesNumber = countNodes(parsedDuplicateCompanies);
    const parentPersonsNodesNumber = countNodes(relatedPersons);
    const shareholderTotalPercentage = 0;
    parsedDuplicateCompanies.forEach((company: EntityLinksAggregation) => {
      getEntity({ entityType: company.entity2?.entityType, entityId: company.entity2?.entityId }).then((res: any) => {
        if (res) {
          setEntity(previousState => [...previousState, res]);
        }
      }).catch(e => console.log(e));
    });

    setDeployableNodesNumber({
      parent: parentCompaniesNodesNumber + parentPersonsNodesNumber,
      children: (parsedDuplicateCompanies.length - parentCompaniesNodesNumber) + (relatedPersons.length - parentPersonsNodesNumber)
    });
    setParsedCompanies(parsedDuplicateCompanies);

    if ((deployChildrenRelation && lastDeployAction === 'children') || (deployParentRelation && lastDeployAction === 'parent')) {
      deployHandling(data, relatedPersons, shareholderTotalPercentage, lastDeployAction, parsedDuplicateCompanies, parentNodes, companiesGetter);
    } else if ((deployChildrenRelation === false && lastDeployAction === 'children') || (deployParentRelation === false && lastDeployAction === 'parent')) {
      undeployHandling(deployParentRelation, deployChildrenRelation, lastDeployAction, data, parentNodes, setDeployParentRelation, setDeployChildrenRelation);
    }
  }, [lastDeployAction, deployChildrenRelation, deployParentRelation, relatedPersons, relatedCompanies]);

  const Company = useMemo(() => (
    <Paper variant='hoverElevation2' sx={{
      boxShadow: 1,
      cursor: 'pointer',
      border: data.data.isAdvisor ? 'dashed' : '',
      borderColor: data.data.isAdvisor ? lighten(theme.palette.secondary.dark, 0.3) : lighten(theme.palette.primary.main, 0.4),
      backgroundColor: data.data.isAdvisor ? lighten(theme.palette.secondary.main, 0.45) : lighten(theme.palette.primary.main, 0.75),
      width: '500px',
      height: '108px',
      p: 1,
      pl: 4,
      pr: 4
    }}>
      <Stack spacing={1} height='100%' justifyContent='space-between' pb={1} onClick={() => {
        navigate(`../../../companies/${data.data.entitie.entity2.entityId}/network`);
      }}>
        <Stack height={24} spacing={1} direction='row' alignItems='center' width='calc(100% + 16px)' pt={zoomLevel < 0.5 ? 4 : 0} sx={{ ml: '-8px !important' }}>
          {(parsedCompanies.length > 0 || relatedPersons.length > 0) && deployableNodesNumber.parent > 0 &&
          <Stack alignItems='center' height='min-content' position='absolute' left={ -20 } top='32%'>
            <Button
              variant='containedIcon'
              size='large'
              sx={{
                transform: `${zoomLevel < 0.5 ? 'scale(1.5)' : 'scale(1.2)'}`
              }}
              onClick={(e) => {
                setDeployParentRelation((previousState) => {
                  if (previousState === undefined) {
                    return true;
                  }
                  if (previousState === true) {
                    return false;
                  }

                  return undefined;
                });
                setLastDeployAction('parent');
                e.stopPropagation();
              }}
            >
              {deployParentRelation === true ? <IconX style={{ marginBottom: 2 }} size={24} /> : <IconArrowBigLeftLines style={{ marginBottom: 2 }} size={24} />}
            </Button>
          </Stack>
          }
          <Stack alignItems='center' height='min-content' position='absolute' right={ -35 } top='-13px' >
            <Chip color='primary' label={
              <Stack display='flex' flexDirection='row' alignItems='center'>
                <IconUsers size={zoomLevel < 0.5 ? 35 : 15} />
                <Typography fontSize={zoomLevel < 0.5 ? 40 : 20}>
                  {relatedPersons.length}
                </Typography>
              </Stack>
            } />
          </Stack>
          {zoomLevel > 0.5 ? data.id : <Stack width={8} /> }
          {(parsedCompanies.length > 0 || relatedPersons.length > 0) && deployableNodesNumber.children > 0 &&
          <Stack alignItems='center' position='absolute' right={ -20 } top='32%'>
            <Button
              variant='containedIcon'
              size='large'
              sx={{
                transform: `${zoomLevel < 0.5 ? 'scale(1.5)' : 'scale(1.2)'}`
              }}
              onClick={(e) => {
                setDeployChildrenRelation((previousState) => {
                  if (previousState === undefined) {
                    return true;
                  }
                  if (previousState === true) {
                    return false;
                  }

                  return undefined;
                });
                setLastDeployAction('children');
                e.stopPropagation();
              }}
            >
              {deployChildrenRelation === true ? <IconX style={{ marginBottom: 2 }} size={24} /> : <IconArrowBigRightLines style={{ marginBottom: 2 }} size={24} />}
            </Button>
          </Stack>
          }
          <Stack alignItems='center' height='min-content' position='absolute' right={ -35 } top='90px' >
            <Chip color='primary' label={
              <Stack display='flex' flexDirection='row' alignItems='center'>
                <IconBuildings size={zoomLevel < 0.5 ? 35 : 15} />
                <Typography fontSize={zoomLevel < 0.5 ? 40 : 20}>
                  {parsedCompanies.length}
                </Typography>
              </Stack>} />
          </Stack>

          {zoomLevel > 0.5 &&
            <>
              <IconBuildings size={14}color={theme.palette.grey['100']}/>
              <Typography variant='body1' fontSize={zoomLevel > 0.5 ? 22 : 35} mr={2} fontWeight={500} noWrap>
                {data.data.label}
              </Typography><Tooltip
                title={copied ? t('common.utils.copied') : `${t('common.utils.copy')}`}
                arrow
                placement='top'
                onOpen={() => setCopied(false)}
              >
                <IconCopy
                  style={{
                    marginLeft: '2px',
                    marginRight: '6px'
                  }}
                  color={theme.palette.grey['600']}
                  size={zoomLevel <= 0.5 ? 26 : 18}
                  onClick={(e: { stopPropagation: () => void, preventDefault: () => void }) => {
                    navigator.clipboard.writeText(data.data.label)
                      .then(() => {
                        setCopied(true);
                      });
                    e.stopPropagation();
                    e.preventDefault();
                  } } />
              </Tooltip>
            </>
          }

          {zoomLevel <= 0.5 &&
            <IconBuildings size={40} color={theme.palette.grey['100']} style={{ paddingTop: '10px' }}/>
          }
          {companies && data.data.settingsList.current.find((setting: SettingsProps) => setting.id === 'nationalities')?.status &&
            <Chip
              label={
                <Stack display='flex' flexDirection='row' justifyContent='center' alignItems='center'>
                  <IconId />
                  {Flags({ countries: [`${(companyGetter.getCountryCode(true) || '')[0]}${(companyGetter.getCountryCode(true) || '')[1]}`], size: zoomLevel <= 0.5 ? 'large' : 'small' })}
                </Stack>
              }
              style={{ marginLeft: '20px', height: zoomLevel < 0.5 ? '35px' : '20px', paddingTop: zoomLevel < 0.5 ? 5 : 0 }}
            />
          }
          { data.data.isListedCompany &&
            data.data.settingsList.current.some((setting: SettingsProps) => setting.id === 'listedCompany' && setting.status) &&
          // !data.data.filters.current.some(
          //   (element: { id: string, active: boolean }) => element.id === 'listedCompany' && element.active
          // ) &&
              <Stack alignItems='center' height={zoomLevel < 0.5 ? 'min-content' : '20px'}>
                <Chip
                  color='error'
                  sx={{ border: '0.5px solid grey' }}
                  label={
                    <Stack display='flex' direction='row' alignContent='center' alignItems='center'>
                      {/* <IconCirclesRelation size={zoomLevel < 0.5 ? 25 : 16} color='black'/> */}
                      <Typography fontSize={zoomLevel < 0.5 ? 25 : 15} color='black'>
                        L
                      </Typography>
                    </Stack>
                  }
                />
              </Stack>
          }
          {companies && zoomLevel > 0.5 &&
            <>
              <Chip
                sx={{
                  transform: 'scale(0.8)'
                }}
                label={companyGetter.getState()}
                color={companyGetter.getState() === 'open' ? 'success' : 'error'}
                size='small'
              />
            </>
          }
        </Stack>
        {zoomLevel <= 0.5 &&
          <Typography variant='body1' align='center' fontSize={38} pl={2} mr={2} pb={6} fontWeight={500} noWrap>
            {data.data.label}
          </Typography>
        }

        {companies && zoomLevel > 0.5 &&
          <>
            <Typography variant='body2' fontSize={zoomLevel > 0.5 ? 15 : 20} noWrap pl={4} pr={4}>
              {companyGetter.getLegalForm()}
            </Typography>
            <Typography variant='body2' fontSize={zoomLevel > 0.5 ? 15 : 20} noWrap pl={4} pr={4}>
              {companyGetter.getActivitySector()}
            </Typography>
            <Stack spacing={2} direction='row' alignItems='center' width='calc(100% + 16px)' sx={{ ml: '-8px !important' }}>
              <Tooltip title={t('common.financial.turnover.label')} arrow placement='top'>
                <Chip
                  sx={{ width: '115px' }}
                  label={
                    <Stack spacing={1} direction='row' alignItems='center'>
                      <IconArrowBackUp size={14} />
                      <Typography>
                        {companyGetter.getTurnover()}
                      </Typography>
                    </Stack>
                  }
                  size='small'
                />
              </Tooltip>
              <Tooltip title={t('common.financial.capital.label')} arrow placement='top'>
                <Chip
                  sx={{ width: '115px' }}
                  label={
                    <Stack spacing={1} direction='row' alignItems='center'>
                      <IconCoins size={14} />
                      <Typography>
                        {companyGetter.getCapital()}
                      </Typography>
                    </Stack>
                  }
                  size='small'
                />
              </Tooltip>
              <Tooltip title={t('common.financial.ebitda.label')} arrow placement='top'>
                <Chip
                  sx={{ width: '115px' }}
                  label={
                    <Stack spacing={1} direction='row' alignItems='center'>
                      <IconChartHistogram size={14} />
                      <Typography>
                        {companyGetter.getEbitda()}
                      </Typography>
                    </Stack>
                  }
                  size='small'
                />
              </Tooltip>
              <Tooltip title={t('common.financial.valuation.label')} arrow placement='top'>
                <Chip
                  sx={{ width: '115px', mr: '-8px' }}
                  label={
                    <Stack spacing={1} direction='row' alignItems='center'>
                      <IconChartLine size={14} />
                      <Typography>
                        {companyGetter.getValuation()}
                      </Typography>
                    </Stack>
                  }
                  size='small'
                />
              </Tooltip>
            </Stack>
          </>
        }
      </Stack>
    </Paper>
  ), [
    companies,
    copied,
    lastDeployAction,
    deployChildrenRelation,
    deployParentRelation,
    parsedCompanies,
    zoomLevel,
    data.data.settingsList.current.find((setting: SettingsProps) => setting.id === 'listedCompany')?.status,
    data.data.settingsList.current.find((setting: SettingsProps) => setting.id === 'nationalities')?.status
  ]);

  return (
    <>
      <Handle type='source' position={Position[sourcePosition]} isConnectable style={{ visibility: 'hidden' }} />
      {Company}
      <Handle type='target' position={Position[targetPosition]} isConnectable style={{ visibility: 'hidden' }} />
    </>
  );
}
