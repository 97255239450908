import React, { ReactElement, useEffect } from 'react';
import { AxiosInstance } from 'axios';
import MaintenanceBanner from '../components/maintenance/banner';
import MaintenancePage from '../components/maintenance';

export interface MaintenanceWrapperProps {
  app: string,
  axiosInstance: AxiosInstance
}

function MaintenanceWrapper(props: MaintenanceWrapperProps & { children: ReactElement }): ReactElement {

  useEffect(() => {
    props.axiosInstance.interceptors.response.use(res => res, (error) => {
      if (error.response && (error.response.status === 503 || (error.response.status > 500 && error.response.status < 600))) {
        if (window.location.pathname.split('/')[2] !== 'maintenance') {
          window.location.assign(`${window.location.origin}/${props.app}/maintenance`);
        }
      }

      return Promise.reject(error);
    });
  }, []);

  return (
    <>
      {window.location.pathname.includes('maintenance') ?
        <MaintenancePage {...props} /> :
        <MaintenanceBanner {...props}>
          {props.children}
        </MaintenanceBanner>
      }
    </>
  );
}

export default MaintenanceWrapper;
