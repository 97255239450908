import React, { ReactElement, ReactNode } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import { DefaultTReturn } from 'i18next';
import type { TOptions } from 'i18next/typescript/options';
import { Icon, IconProps } from '@tabler/icons-react';
import CompletenessAndAccuracy from '../../main/containers/completenessandaccuracy';

export interface KeyValueProps {
  Key?: string | DefaultTReturn<TOptions>,
  value: React.ReactNode,
  icon?: React.ForwardRefExoticComponent<Omit<IconProps, "ref"> & React.RefAttributes<Icon>>,
  valueFontSize?: string,
  size?: 'small' | 'big' | 'normal',
  keySize?: 'small' | 'big' | 'normal',
  color?: string,
  card?: true,
  completeness?: ReactNode | unknown, // todo: define type
  accuracy?: ReactNode,
  children?: ReactNode,
  width?: string
}

function KeyValue(props: KeyValueProps): ReactElement {
  const theme = useTheme();

  return (
    <Stack
      key={props.Key}
      spacing={props.size && (props.size === 'big' || props.size === 'normal') ? 1 : 2}
      sx={{
        width: props.width || '100%',
        minWidth: 'min-content',
        height: props.card ? '100%' : undefined
      }}
      direction={props.size && (props.size === 'big' || props.size === 'normal') ? 'column' : 'row'}
      justifyContent={props.size && (props.size === 'big' || props.size === 'normal') ? 'center' : 'space-between'}
      alignItems='center'
    >
      <Stack spacing={2} direction='row' alignItems='center'>
        {props.icon && (
          (props.Key?.includes('Companies') || props.Key?.includes('companies') || props.Key?.toLowerCase().includes('legal persons') || props.Key?.toLowerCase().includes('Legal person') || props.Key?.toLowerCase().includes('personnes physiques') || props.Key?.toLowerCase().includes('personne physique')) ?
            <props.icon size={16} stroke={props.Key?.includes('Active') && theme.palette.success.main || props.Key?.includes('Closed') && theme.palette.error.main || props.color || theme.palette.text.primary} />
            :
            <props.icon size={16} />
        )}
        {props.Key &&
          <Typography
            variant={props.keySize && props.keySize === 'big' ? 'h3' : 'body1'}
            width='max-content'
            noWrap
          >
            {props.Key}
          </Typography>
        }
      </Stack>
      {typeof props.value === 'string' || typeof props.value === 'number' ?
        <Typography
          variant={
            props.size && (props.size === 'big' || props.size === 'normal') ?
              props.size === 'big' ? 'h2' : 'h5'
              : 'body1'
          }
          color={props.color || props.Key?.includes('Active') && theme.palette.success.main || props.Key?.includes('Closed') && theme.palette.error.main || undefined}
          fontWeight={600}
          fontSize={props.valueFontSize}
          mt={props.size === 'normal' ? '-6px' : undefined}
          noWrap={props.size === 'normal' ? undefined : true}
        >
          {props.value}
        </Typography> :
        props.value
      }
      {props.children || props.accuracy || props.completeness as ReactNode &&
        <Grid
          xs={props.size && (props.size === 'big' || props.size === 'normal') ?  12 : undefined}
          width={props.size === 'normal' ? 'fit-content' : 'max-content'}
          ml={props.card ? 'auto' : undefined}
          mr={props.card ? 'auto' : undefined}
        >
          {
            (props.completeness || props.accuracy) ?
              <CompletenessAndAccuracy completeness={parseFloat(props.completeness ? props.completeness.toString() : '')} accuracy={parseFloat(props.accuracy ? props.accuracy.toString() : '')} onlyAccuracy={!props.completeness} onlyCompleteness={!props.accuracy} /> :
              props.children
          }
        </Grid>
      }
    </Stack>
  );
}

export default KeyValue;
