import React, { ReactElement, useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';
import SimpleDisplayFilters, { SimpleFilter } from '@/components/filters/simple';
import SimpleSegmentationList from "../../../../../containers/segmentations/render/lists/simple.lists.segmentations";

function ListTabsPortfolios(): ReactElement {
  const { t } = useTranslation();
  const { tab } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [personFilters, setPersonFilters] = useState<SimpleFilter[]>([
    {
      id: 'persons.withCompanies',
      label: t('portfolios.dashboard.groups.labels.persons.withCompanies.filter'),
      active: false,
      unique: true
    }
  ]);

  useEffect(() => {
    personFilters.filter(filter => filter.active).forEach((filter) => {
      searchParams.set('groupId', filter.id);
    });
    setSearchParams(searchParams);
  }, [personFilters]);

  return (
    <Stack spacing={4}>
      {tab === 'persons' && <SimpleDisplayFilters filters={personFilters} setFilters={setPersonFilters} />}
      <SimpleSegmentationList entityType={tab === 'persons' ? 'deecPerson' : 'deecCompany'} noAccordion />
    </Stack>
  );
}

export default ListTabsPortfolios;
