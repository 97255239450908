import { AxiosInstance } from 'axios';

class AxiosInstanceModifier {
  private axiosInstance: AxiosInstance;

  constructor(axiosInstance: AxiosInstance) {
    this.axiosInstance = axiosInstance;
  }

  changeBaseUrl(url: string): void {
    this.axiosInstance.defaults.baseURL = url;
  }

  addHeader(headerKey: string, headerValue: string ): void {
    this.axiosInstance.defaults.headers.common[headerKey] = headerValue;
  }

  deleteHeader(headerKey: string): void {
    delete this.axiosInstance.defaults.headers.common[headerKey];
  }

  addLanguageHeader(value: string) {
    this.addHeader('Accept-Language', value);
  }

  addAuthorizationHeader(token: string) {
    this.addHeader('Authorization', `bearer ${token}`);
  }
}

export default AxiosInstanceModifier;
