import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {  Button, Modal, Paper } from "@mui/material";
import Grid from "@mui/system/Unstable_Grid";
import SelectFiltersToAddModalHeader from "./builder.select.filters.modal.header";
import SelectFiltersToAddModalBody from "./builder.select.filters.modal.body";
import { SelectFiltersToAddModalProps } from "./types.filter.modal";
import { VennDiagramSegment } from "../../types.vennDiagram";

function SelectFiltersToAddModal(props: SelectFiltersToAddModalProps) {
  const { t } = useTranslation();
  const segmentWithBaseValue: VennDiagramSegment = {
    groupIds: [],
    label: '',
    color: '',
    behavior: 'intersection',
    id: ''
  };
  const [segment, setSegment] = useState<VennDiagramSegment>(props.segmentToUpdate || segmentWithBaseValue);
  const segmentRefColor = props.segmentToUpdate?.color;
  const addDisable = ((segment?.label?.length || 0) < 1) || ((segment?.groupIds?.length || 0) < 1) || ((segment?.color?.length || 0) < 1);

  const onClose = () => {
    setSegment(segmentWithBaseValue);
    props.setModalOpen(false);
  };

  const onAdd = () => {
    props.updateSegment(segment);
    setSegment(segmentWithBaseValue);
  };

  const handleSegmentChange = (propertyLabel: keyof VennDiagramSegment, value: string, addAllArray?: boolean) => {
    setSegment((prevState) => {
      let groupIdsValue: string[] | undefined = [];

      if (propertyLabel === 'groupIds') {
        const subGroupId = value.split('/')[1];

        if (prevState && prevState?.groupIds.includes(value) && !addAllArray) {
          groupIdsValue = prevState?.groupIds.filter(selected => !selected.includes(subGroupId));
        } else {
          groupIdsValue = [...(prevState?.groupIds || []), value];
        }
      }

      const newState = { ...prevState, [propertyLabel]: propertyLabel === 'groupIds' ? groupIdsValue : value };

      return newState;
    });
  };

  useEffect(() => {
    if (props.segmentToUpdate) {
      setSegment(props.segmentToUpdate as VennDiagramSegment);
    }
  }, [props.segmentToUpdate]);

  useEffect(() => {
    const groupIdLabel = segment.groupIds.length === 1 ? segment.groupIds[0].split('/')[1] : segment.label;
    const isLabelIncludedInGroups = props.possibleFilters.some(filter =>
      filter.subGroupId === segment.label
    );

    if (segment.groupIds.length === 1 && segment.label === '') {
      setSegment(prevState => ({ ...prevState, label: groupIdLabel }));
    } else if (segment.groupIds.length > 1 && segment.groupIds.length > 1 && segment.label === groupIdLabel && isLabelIncludedInGroups) {
      setSegment(prevState => ({ ...prevState, label: '' }));
    } else if (segment.groupIds.length === 0 && segment.label !== '' && isLabelIncludedInGroups) {
      setSegment(prevState => ({ ...prevState, label: '' }));
    }
  }, [segment]);

  return (
    <Modal
      open={props.modalOpen}
      onClose={onClose}
    >
      <Paper
        variant='hoverElevation2'
        sx={{
          boxShadow: 1,
          height: '90vh',
          width: '80vw',
          p: 4,
          marginLeft: '10vw',
          marginTop: '5vh',
          backgroundColor: 'white',
          border: 1,
          borderColor: 'grey.400',
          borderRadius: 2,
          overflow: 'auto'
        }}
      >
        <Grid container xs={12} height='100%' width='100%'>
          <SelectFiltersToAddModalHeader {...props} onClose={onClose} segment={segment} handleSegmentChange={handleSegmentChange} segmentRefColor={segmentRefColor}/>
          <SelectFiltersToAddModalBody {...props} selectedFilters={segment?.groupIds || []} handleSegmentChange={handleSegmentChange} setSegment={setSegment}/>
          {/* MODAL FOOTER */}
          <Grid xs={12} pt={2} height='5%' display='flex' flexDirection='row' justifyContent='flex-end' alignItems='center'>
            <Button variant='text' sx={{ marginRight: '8px' }} onClick={onClose}>
              {t('segmentation.filters.cancel')}
            </Button>
            <Button variant='contained' onClick={onAdd} disabled={addDisable}>
              {t('segmentation.filters.add')}
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Modal>
  );
}

export default SelectFiltersToAddModal;
