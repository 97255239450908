import React, { ReactElement } from 'react';
import { useNavigate, useOutletContext } from "react-router-dom";
import { BaseGroup } from "@deecision/dna-interfaces";
import Grid from "@mui/material/Unstable_Grid2";
import { IconBuildings, IconUsers } from "@tabler/icons-react";
import VeegilenzPortfolioDashboard, {
  VeegilenzPortfolioDashboardConfig
} from "@/main/containers/dashboards/portfolio/veegilenz";
import dnaConfig from "@/config/dna.config.json";

const veegilenzConfig: VeegilenzPortfolioDashboardConfig[] = [
  {
    id: 'persons',
    entityType: 'deecPerson',
    icon: IconUsers,
    groupId: 'persons'
  },
  {
    id: 'companies',
    entityType: 'deecCompany',
    icon: IconBuildings,
    groupId: 'companies'
  }
];

function VeegilenzTabsPortfolios(): ReactElement {
  const data = useOutletContext() as { data: BaseGroup };
  const navigate = useNavigate();

  const callSegmentation = (entityType?: string, groupId?: string, workflowId?: string, filterIds?: string[], filterValues?: string[]) => {
    if (groupId && entityType) {
      navigate(`../${dnaConfig.routes.portfolios.childrens.augment.baseUrl}?entityType=${entityType}&groupId=${groupId}${workflowId ? `&workflowId=${workflowId}` : ''}${filterIds ? `&filterIds=${filterIds.join(',')}` : ''}${filterValues ? `&filterValues=${filterValues.join(',')}` : ''}`);
    }
  };

  return (
    <Grid container alignItems='stretch' spacing={6}>
      {veegilenzConfig.map(config => (
        <Grid key={config.id}>
          <VeegilenzPortfolioDashboard
            baseGroupId={data.data.baseGroupId}
            config={config}
            callSegmentation={callSegmentation}
          />
        </Grid>
      ))}
    </Grid>
  );
}

export default VeegilenzTabsPortfolios;
