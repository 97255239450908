import React, { ReactElement, useEffect, useState } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import { CompanyEntity } from '@deecision/dna-interfaces';
import { useOutletContext } from 'react-router-dom';
import FormGroup from '@mui/material/FormGroup';
import BaseDnaEntitiesService from '../../../services/entities.services';

function IaTabsComponents(): ReactElement {
  const data = useOutletContext() as { data: CompanyEntity };
  const service = new BaseDnaEntitiesService<CompanyEntity>({ entityType: 'company' });
  const [aiResume, setAiResume] = useState<string>('');
  const [augmentedAiResume, setAugmentedAiResume] = useState<string>('');
  const [aiSanctions, setAiSanctions] = useState<string>('');
  const [aiAcquisitions, setAiAcquisitions] = useState<string>('');
  const [augmented, setAugmented] = useState<boolean>(true);

  useEffect(() => {
    if (data.data.name) {
      service.getAiResume(data.data.name)
        .then((res) => {
          setAiResume(res.data.description);
        })
        .catch((err) => {
          setAiResume('- error -');
          console.log(err);
        });
      service.getCompanyAiResumeWithData(data.data)
        .then((res) => {
          setAugmentedAiResume(res.data.description);
        })
        .catch((err) => {
          setAugmentedAiResume('- error -');
          console.log(err);
        });
      service.getAiSanctions(data.data.name)
        .then((res) => {
          setAiSanctions(res.data.answer);
        })
        .catch((err) => {
          setAiSanctions('- error -');
          console.log(err);
        });
      service.getAiAcquisitions(data.data.name)
        .then((res) => {
          setAiAcquisitions(res.data.answer);
        })
        .catch((err) => {
          setAiAcquisitions('- error -');
          console.log(err);
        });
    }
  }, []);

  return (
    <Grid container spacing={4}>
      <Grid minWidth='400px' height='100%'>
        <Paper sx={{ width: '100%', height: '100%' }}>
          <Grid container>
            <Grid p={2} xs={12}>
              <Stack direction='row' spacing={2}>
                <Typography variant='h3'>AI Completion</Typography>
              </Stack>
            </Grid>
            <Grid xs={12}>
              <Grid container spacing={2} p={2} alignItems='center'>
                <Grid>
                  <Typography variant='h4'>Augmented with our data</Typography>
                </Grid>
                <Grid xs>
                  <FormGroup>
                    <Switch defaultChecked value={augmented} onChange={() => setAugmented(!augmented)} />
                  </FormGroup>
                </Grid>
                <Grid xs={12} width='100%'>
                  <Grid container spacing={2} alignItems='baseline' width='100%'>
                    {!augmented ?
                      (aiResume !== '' ?
                        <Grid xs>
                          <Typography variant='body1'>{aiResume}</Typography>
                        </Grid> :
                        <Grid xs={12} width='100%'>
                          <LinearProgress sx={{ borderRadius: '12px' }} />
                        </Grid>
                      ) : (augmentedAiResume !== '' ?
                        <Grid xs>
                          <Typography variant='body1'>{augmentedAiResume}</Typography>
                        </Grid> :
                        <Grid xs={12} width='100%'>
                          <LinearProgress sx={{ borderRadius: '12px' }} variant='indeterminate' />
                        </Grid>
                      )
                    }
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid minWidth='400px' height='100%'>
        <Paper sx={{ width: '100%', height: '100%' }}>
          <Grid container>
            <Grid p={2} xs={12}>
              <Stack direction='row' spacing={2}>
                <Typography variant='h3'>AI Sanctions</Typography>
              </Stack>
            </Grid>
            <Grid xs={12}>
              <Grid container spacing={2} p={2} alignItems='center'>
                <Grid xs={12} width='100%'>
                  <Grid container spacing={2} alignItems='baseline' width='100%'>
                    {aiSanctions !== '' ?
                      <Grid xs>
                        <Typography variant='body1'>{aiSanctions}</Typography>
                      </Grid> :
                      <Grid xs={12} width='100%'>
                        <LinearProgress sx={{ borderRadius: '12px' }} />
                      </Grid>
                    }
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid minWidth='400px' height='100%'>
        <Paper sx={{ width: '100%', height: '100%' }}>
          <Grid container>
            <Grid p={2} xs={12}>
              <Stack direction='row' spacing={2}>
                <Typography variant='h3'>AI Acquisitions</Typography>
              </Stack>
            </Grid>
            <Grid xs={12}>
              <Grid container spacing={2} p={2} alignItems='center'>
                <Grid xs={12} width='100%'>
                  <Grid container spacing={2} alignItems='baseline' width='100%'>
                    {aiAcquisitions !== '' ?
                      <Grid xs>
                        <Typography variant='body1'>{aiAcquisitions}</Typography>
                      </Grid> :
                      <Grid xs={12} width='100%'>
                        <LinearProgress sx={{ borderRadius: '12px' }} />
                      </Grid>
                    }
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default IaTabsComponents;
