import React from 'react';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { darken } from '@mui/material/styles';
import Modal from '@mui/material/Modal';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';

function DeleteModalsRenderSegmentations(props: { openConfirmDelete: boolean, handleCloseOpenConfirmDelete: () => void, handleDeleteSegmentation: () => void }) {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Modal
      open={props.openConfirmDelete}
      onClose={props.handleCloseOpenConfirmDelete}
      aria-labelledby='child-modal-title'
      aria-describedby='child-modal-description'
    >
      <Paper
        sx={{
          width: 500,
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          p: 4
        }}
      >
        <Stack spacing={4} alignItems='center'>
          <Typography variant='h3'>{t('segmentation.render.settings.confirmDelete')}</Typography>
          <Stack direction='row' spacing={4}>
            <Button
              variant='text'
              onClick={(e) => {
                e.stopPropagation();
                props.handleCloseOpenConfirmDelete();
              }}
            >
              {t('common.utils.cancel')}
            </Button>
            <Button
              variant='contained'
              sx={{
                bgcolor: theme.palette.error.main,
                borderColor: theme.palette.error.main,
                '&:hover': {
                  bgcolor: darken(theme.palette.error.main, 0.2),
                  borderColor: darken(theme.palette.error.main, 0.2)
                }
              }}
              onClick={(e) => {
                e.stopPropagation();
                props.handleDeleteSegmentation();
                props.handleCloseOpenConfirmDelete();
              }}
            >
              {t('common.utils.delete')}
            </Button>
          </Stack>
        </Stack>
      </Paper>
    </Modal>
  );
}

export default DeleteModalsRenderSegmentations;
