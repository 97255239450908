import React, { ReactElement, ReactNode } from 'react';
import { useTheme } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import { IconX } from '@tabler/icons-react';
import { DNAFilterValue } from '@/main/containers/form/types.form';

function ChipFilters(props: { value: DNAFilterValue | ReactNode, onDelete: () => void, readonly?: boolean, onClick?: () => void, color?: 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' }): ReactElement {
  const theme = useTheme();

  return (
    <Chip
      color={!props.readonly ? props.color || 'primary' : undefined}
      label={props.value}
      deleteIcon={!props.readonly ? <IconX size={16} color={props.color ? (theme.palette[props.color as keyof typeof theme.palette] as typeof theme.palette.primary).dark : theme.palette.primary.dark} /> : undefined}
      onDelete={!props.readonly ? props.onDelete : undefined}
      sx={!props.readonly ? {
        cursor: props.onClick ? 'pointer' : undefined,
        '& .MuiChip-deleteIcon': {
          height: '100%',
          borderBottomRightRadius: 24,
          borderTopRightRadius: 24,
          width: 28,
          mr: 0,
          pl: 1,
          pr: 2,
          '&:hover': {
            bgcolor: props.color && props.color !== 'primary' ? (theme.palette[props.color as keyof typeof theme.palette] as typeof theme.palette.primary).main : theme.primaryColors?.lightest
          }
        }
      } : {
        cursor: 'default'
      }}
    />
  );
}

export default ChipFilters;
