import React, { ReactElement, useEffect } from 'react';
import Stack from '@mui/material/Stack';
import { IconFilter, IconPlus } from '@tabler/icons-react';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import { darken, Palette, useTheme } from '@mui/material/styles';
import ChipFilters from '../chip';

export interface SimpleFilter {
  id: string,
  label: string,
  value?: number | string,
  active: boolean,
  family?: string,
  unique?: boolean,
  main?: boolean,
  color?: keyof Pick<Palette, 'primary' | 'secondary' | 'success' | 'warning' | 'error'>
}

function SimpleDisplayFilters(props: { filters: SimpleFilter[], setFilters: (filters: SimpleFilter[]) => void, mandatory?: boolean, showIcon?: boolean, buttonLabel? : string}): ReactElement {
  const { t } = useTranslation();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAddFilter = (filter: SimpleFilter) => {
    handleClose();
    props.setFilters(props.filters.map((f) => {
      if (f.family === filter.family) {
        if (f.id === filter.id) {
          return ({
            ...f,
            active: true
          });
        }
        if (f.unique) {
          return ({
            ...f,
            active: false
          });
        }
      }

      return f;
    }));
  };

  const getFamilies = (filters: SimpleFilter[]) => {
    const families: string[] = [];

    filters.forEach((filter) => {
      if (filter.family && !families.includes(filter.family)) {
        families.push(filter.family);
      }
    });

    return families;
  };

  useEffect(() => {
    if (props.mandatory && !props.filters.some(filter => filter.active)) {
      props.setFilters(props.filters.map((filter, index) => (index === 0 ? { ...filter, active: true } : filter)));
    }
  }, [props.filters]);

  return (
    <Stack direction='row' spacing={2} alignItems='center'>
      {props.showIcon === undefined || props.showIcon === true ?
        <IconFilter /> : <></>
      }
      {props.filters.filter(filter => filter.active).map(filter => (
        <ChipFilters
          value={filter.label}
          color={filter.color}
          onDelete={() => props.setFilters(props.filters.map(p => (p.id === filter.id ? { ...p, active: !p.active } : p)))}
        />
      ))}
      {props.filters.filter(filter => !filter.active).length > 0 &&
        <Button variant='outlined' startIcon={<IconPlus size={18} />} size='small' onClick={handleClick} sx={{
          borderWidth: 1,
          borderColor: '#BFBFC0',
          '&:hover': {
            color: 'inherit',
            borderColor: darken('#BFBFC0', 0.3),
            borderWidth: 1
          }
        }}>
          {props.buttonLabel ? props.buttonLabel : t('filters.addFilter')}
        </Button>
      }
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {getFamilies(props.filters).map((family, index) => (
          <Stack>
            <Typography sx={{ p: 2, pl: 4, pr: 4 }} variant='body2'>{family}</Typography>
            {props.filters.filter(filter => filter.family === family && !filter.active).map(filter => (
              <MenuItem key={filter.id} sx={{ pl: 6, pr: 2 }} onClick={() => handleAddFilter(filter)}>
                <Stack direction='row' spacing={4} alignItems='center' width='100%'>
                  <Box sx={{ marginRight: '16px !important' }}>
                    {filter.color ?
                      <Typography color={theme.palette[filter.color].main}>
                        {`${filter.label} ${filter.unique ? `(${t('filters.unique')})` : ''}`}
                      </Typography> :
                      `${filter.label} ${filter.unique ? `(${t('filters.unique')})` : ''}`
                    }
                  </Box>
                  <IconPlus size={14} style={{ marginLeft: 'auto' }} />
                </Stack>
              </MenuItem>
            ))}
            {(getFamilies(props.filters).length > index + 1 || props.filters.filter(filter => !filter.family && !filter.active).length > 0) && <Divider />}
          </Stack>
        ))}
        {props.filters.filter(filter => !filter.family && !filter.active).map(filter => (
          <MenuItem key={filter.id} sx={{ pl: 2, pr: 2 }} onClick={() => handleAddFilter(filter)}>
            <Stack direction='row' spacing={2} alignItems='center' width='100%'>
              <Box sx={{ marginRight: '16px !important' }}>
                {filter.color ?
                  <Typography color={theme.palette[filter.color].main}>{`${filter.label}`}</Typography>
                  : `${filter.label} ${filter.unique ? `(${t('filters.unique')})` : ''}`
                }
              </Box>
              <IconPlus size={14} />
            </Stack>
          </MenuItem>
        ))}
      </Menu>
    </Stack>
  );
}

export default SimpleDisplayFilters;
