import React, { ReactElement, RefObject } from 'react';
import Button from '@mui/material/Button';
import { DNA } from 'react-loader-spinner';
import { IconArrowRight, IconCheck } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';

export interface SearchSubmitBtnProps {
  type: string,
  btnRef: RefObject<HTMLButtonElement>,
  query: string | undefined,
  loading?: boolean,
  onSubmit: (value?: string) => void,
  hideSubmit?: boolean,
  disabled?: boolean,
  forceWithoutQuery?: boolean
}

function SearchSubmitBtn(props: SearchSubmitBtnProps): ReactElement {
  const { t } = useTranslation();

  return (
    <Button
      ref={props.btnRef}
      onClick={() => (props.query || props.forceWithoutQuery ? props.onSubmit(props.query) : undefined)}
      variant='contained'
      sx={{
        pl: 4,
        whiteSpace: 'nowrap',
        minWidth: 'auto',
        letterSpacing: 0.1,
        mr: props.hideSubmit && props.btnRef.current ? `-${props.btnRef.current.clientWidth + 8}px !important` : undefined,
        opacity: props.hideSubmit ? 0 : 1,
        transition: 'opacity 0.2s ease-in-out, margin-right 0.2s ease-in-out'
      }}
      endIcon={
        props.loading ?
          <DNA
            height='22'
            width='22'
            wrapperStyle={{}}
            visible
          /> :
          props.disabled && props.query && props.query.length > 0 ?
            <IconCheck size={22} /> :
            <IconArrowRight size={22} />
      }
      disabled={(!props.query && !props.forceWithoutQuery) || props.disabled || props.hideSubmit}
    >
      {props.type === 'deecPerson' && t('search.person.search') || props.type === 'deecCompany' && t('search.company.search') || t('search.entity.search') || 'Search'}
    </Button>
  );
}

export default SearchSubmitBtn;
