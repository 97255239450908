import React, { ReactElement } from 'react';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { BaseItemFormProps } from '../types.form';

function BooleanFilterTypeForm(props: BaseItemFormProps): ReactElement {

  return (
    <FormControl fullWidth>
      <FormControlLabel
        control={
          <Switch
            color='primary'
            checked={props.filter.value as boolean}
            onChange={() => props.onChange(!(props.filter.value as boolean))}
          />
        }
        label={props.filter.label}
        labelPlacement='start'
      />
    </FormControl>
  );
}

export default BooleanFilterTypeForm;
