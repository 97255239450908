import React, { ReactElement } from 'react';
import {
  IconAlertTriangleFilled
} from '@tabler/icons-react';
import { useTheme } from '@mui/material/styles';

export interface StatusProps {
  status?: 'success' | 'warning' | 'error',
  statusAsInt?: number,
  size?: number,
  color?: string,
  dark?: boolean
}

function Status(props: StatusProps): ReactElement {
  const theme = useTheme();

  return (
    <>
      {(props.status !== undefined || props.statusAsInt !== undefined) &&
        ((props.status === 'success' || props.statusAsInt === 0)  &&
          <IconAlertTriangleFilled size={props.size || 16} style={{ color: props.color ?? (props.dark ? theme.palette.success.dark : theme.palette.success.main) }}/> ||
          (props.status === 'warning' || props.statusAsInt === 1) &&
          <IconAlertTriangleFilled size={props.size || 16} style={{ color: props.color ?? (props.dark ? theme.palette.warning.dark : theme.palette.warning.main) }}/> ||
          (props.status === 'error' || props.statusAsInt === 2) &&
          <IconAlertTriangleFilled size={props.size || 16} style={{ color: props.color ?? (props.dark ? theme.palette.error.dark : theme.palette.error.main) }}/>) ||
          (props.statusAsInt === 3 && <IconAlertTriangleFilled size={props.size || 16} style={{ color: props.color || props.dark ? theme.palette.grey[200] : theme.palette.grey[800] }}/>) ||
        <></>
      }
    </>
  );
}

export default Status;
