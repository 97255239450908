import React, { ReactElement } from 'react';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';

function DndWrapper(props: { children: ReactElement }): ReactElement {

  return (
    <DndProvider
      backend={HTML5Backend}
    >
      {props.children}
    </DndProvider>
  );
}

export default DndWrapper;
