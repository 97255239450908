import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material";
import { IconEyeSearch } from "@tabler/icons-react";
import { DataElementsQueryService } from "../../../../../api/services/query";
import KeyValueCardComponent from "../../../../../components/cards/keyvalue.cards";

interface DependenciesProps {
  dependencies: Dependencies[]
}

type Dependencies = {
  type: string,
  entityRef: {
      entityType: string,
      entityId: string,
      name?: string | undefined
  },
  available: boolean,
  dataElementIds: string[],
  lastModified: string,
  featureId: string,
  needAvailableRecalc: boolean
}

enum STATUS {
  done,
  failed,
  requested,
  waiting_for_dependencies,
  created,
  updateNeeded,
  inProgress,
  startable
}

// const computeDependenciesStatus = async (initDependenciesStatusObjValue: any, dependencies: DependenciesProps) => {
//   const dataElementService = new DataElementsQueryService({ entityType: 'deecCompany' });
//   let tmpObj = initDependenciesStatusObjValue;

//   const promises = dependencies.dependencies.flatMap((dependency: Dependencies) => {
//     return dependency.dataElementIds.map((id: string) => {
//       const query = {
//         '_id': id
//       };
//       return dataElementService.findInAllEntities(query).then((res) => {
//         if (res.data) {
//           tmpObj[STATUS[res.data[0].scheduling.status]].count += 1;
//         }
//       });
//     });
//   });

//   await Promise.all(promises);

//   return tmpObj;
// };

function DependenciesSummaryWidget(dependencies: DependenciesProps) {
  const theme = useTheme();
  const initDependenciesStatusObjValue = [
    {
      label: 'done',
      count: 0,
      bgColor: theme.palette.success.light as string,
      color: 'grey'
    },
    {
      label: 'failed',
      count: 0,
      bgColor: theme.palette.error.light as string,
      color: 'grey'
    },
    {
      label: 'requested',
      count: 0,
      bgColor: theme.palette.info.light as string,
      color: 'grey'
    },
    {
      label: 'waiting',
      count: 0,
      bgColor: theme.palette.secondary.light as string,
      color: 'grey'
    },
    {
      label: 'created',
      count: 0,
      bgColor: 'grey',
      color: 'black'
    },
    {
      label: 'updateNeeded',
      count: 0,
      bgColor: 'grey',
      color: 'black'
    },
    {
      label: 'inProgress',
      count: 0,
      bgColor: 'grey',
      color: 'black'
    },
    {
      label: 'startable',
      count: 0,
      bgColor: 'grey',
      color: 'black'
    }
  ];
  const dataElementService = new DataElementsQueryService({ entityType: 'deecCompany' });
  const [dependenciesStatusObj, setDependenciesStatusObj] = useState<typeof initDependenciesStatusObjValue>(initDependenciesStatusObjValue);
  const [total, setTotal] = useState<number>(0);

  useEffect(() => {
    const tmpObj = initDependenciesStatusObjValue;
    let tmpTotal = 0;

    dependencies.dependencies.forEach((dependency: Dependencies) => {
      dependency.dataElementIds.forEach((id: string) => {
        const query = {
          '_id': id
        };
        dataElementService.findInAllEntities(query).then((res) => {
          if (res.data) {
            tmpObj[STATUS[res.data[0].scheduling.status]].count += 1;
            tmpTotal += 1;
            setDependenciesStatusObj(tmpObj);
            setTotal(tmpTotal);
          }
        });
      });
    });
  }, []);

  return (
    <KeyValueCardComponent
      grid
      icon={IconEyeSearch}
      title='Dependencies'
      cardParts={
        {
          mainValue:
          { key: 'Dependencies Done', value: `${dependenciesStatusObj[STATUS.done].count.toString()} / ${total}` },
          values: [
            { key: 'Done', value: dependenciesStatusObj[STATUS.done].count.toString() },
            { key: 'Failed', value: dependenciesStatusObj[STATUS.failed].count.toString() },
            { key: 'Waiting', value: dependenciesStatusObj[STATUS.waiting_for_dependencies].count.toString() },
            { key: 'Requested', value: dependenciesStatusObj[STATUS.requested].count.toString() }
          ]
        }
      }/>
  );
}

export default DependenciesSummaryWidget;
