import React, { ReactElement } from 'react';
import { z } from 'zod';
import { useOutletContext } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import { CompanyEntity, EntityLinksAggregation, PersonEntity } from '@deecision/dna-interfaces';
import Button from '@mui/material/Button';
import Displayjson from '@/components/displayjson';
import { API_BASE_URL } from '../../../../../env/env';

function DeveloperTabsComponents<T extends PersonEntity | CompanyEntity | EntityLinksAggregation[]>(props: { zodSchema?: z.ZodTypeAny }): ReactElement {
  const data = useOutletContext() as { data: T };

  return (
    <Stack>
      <Displayjson<T> data={data.data} zodSchema={props.zodSchema} />
      <Button
        href={`${API_BASE_URL}/dna-server/admin/entities/${(data.data as PersonEntity).entityType}/${(data.data as PersonEntity).entityId}`}
        target='_blank'
        sx={{
          mt: 2,
          whiteSpace: 'nowrap'
        }}
      >
        Access Admin Backend
      </Button>
    </Stack>
  );
}

export default DeveloperTabsComponents;
