import React, { ReactElement, useEffect, useState } from 'react';
import { AxiosInstance } from 'axios';
import { Alert, Snackbar, Typography } from '@mui/material';
import ServiceError from '@/api/services/error';

export interface ErrorWrapperProps {
  axiosInstance: AxiosInstance
}

function ErrorWrapper(props: ErrorWrapperProps & { children: ReactElement }): ReactElement {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  useEffect(() => {
    props.axiosInstance.interceptors.response.use(res => res, (error) => {
      setErrorMsg(ServiceError.getErrorMsg(error));
      setSnackbarOpen(true);

      return Promise.reject(error);
    });
  }, []);

  return (
    <>
      {props.children}
      <Snackbar onClose={() => setSnackbarOpen(false)} autoHideDuration={6000} open={snackbarOpen} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={() => setSnackbarOpen(false)} variant='filled' sx={{ with: '100%' }} severity='error'>
          <Typography variant='h5'>{errorMsg}</Typography>
        </Alert>
      </Snackbar>
    </>
  );
}

export default ErrorWrapper;
