import React, { ReactElement, useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { BodaccDataBlock, IDataBlock, PersonEntity } from '@deecision/dna-interfaces';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import SimpleDisplaySort, { SimpleSort } from '@/components/sort';
import NoData from '@/components/nodata';
import { PersonGetter } from '../../../../../providers/getter';
import SimpleDisplayFilters, { SimpleFilter } from '@/components/filters/simple';
import BodaccAccordions from '../../../../../containers/bodacc/accordions';
import prioreezProviders from '@/main/providers/prioreez';

function PrioreezTabsComponents(): ReactElement {
  const { t } = useTranslation();
  const { data } = useOutletContext() as { data: PersonEntity };
  const getterProps = new PersonGetter({
    data,
    errorMsg: t('common.utils.unknown')
  });
  const criticities = ['alert', 'opportunity', 'neutral', 'ambiguous'];
  const [filters, setFilters] = useState<SimpleFilter[]>([
    {
      id: 'lastThreeMonth',
      label: t('filters.last3Months'),
      value: 3,
      active: true,
      family: t('filters.time') || undefined,
      unique: true
    },
    {
      id: 'lastYear',
      label: t('filters.lastYear'),
      value: 12,
      active: false,
      family: t('filters.time') || undefined,
      unique: true
    },
    ...criticities.map(criticity => ({
      id: criticity,
      label: criticity,
      value: criticity,
      active: criticity === 'opportunity',
      color: (criticity === 'alert' && 'error' as const) || (criticity === 'ambiguous' && 'warning' as const) || (criticity === 'opportunity' && 'success' as const) || (criticity === 'neutral' && 'primary' as const) || undefined,
      family: t('filters.criticity')
    }))
  ]);
  const [sortList, setSortList] = useState<SimpleSort[]>([
    {
      id: 'time',
      label: t('sort.date')
    }
    /* ,
    {
      id: 'criticity',
      label: t('filters.criticity')
    } */
  ]);
  const [prioreezData, setPrioreezData] = useState<IDataBlock<BodaccDataBlock>[]>([]);

  useEffect(() => {
    prioreezProviders(
      getterProps.getAllCompaniesInfo().map(companyInfo => companyInfo.entityRef.entityId) || [],
      filters.filter(filter => filter.family === t('filters.criticity') && filter.active).map(filter => filter.value) as string[],
      parseFloat(filters.filter(filter => filter.family === t('filters.time')).find(filter => filter.active)?.value?.toString() || '0')
    )
      .then(res => setPrioreezData(res));
  }, [filters]);

  return (
    <Stack spacing={4}>
      <Stack spacing={4} direction='row' alignItems='center' justifyContent='space-between'>
        <SimpleDisplayFilters
          filters={filters}
          setFilters={setFilters}
        />
        <SimpleDisplaySort
          sortList={sortList}
          setSortList={setSortList}
        />
      </Stack>
      {prioreezData.length > 0 ?
        <BodaccAccordions
          datas={prioreezData}
          selectedId=''
          sort={sortList.find(sort => sort.id === 'time')?.direction}
        /> :
        <NoData />
      }
    </Stack>
  );
}

export default PrioreezTabsComponents;
