import { FindOptions } from '@deecision/deecision-interfaces';

export interface MakeParamsProps {
  pagingParams?: FindOptions['pagingParams'],
  filters?: FindOptions['queryFilters'],
  queryFilters?: FindOptions['queryFilters'],
  sortOptions?: FindOptions['sortOptions'],
  post?: boolean
}

function makeParams(props: MakeParamsProps): FindOptions {
  const params : FindOptions = { pagingParams: {}, queryFilters: [], sortOptions: [] };

  if (params) {
    params.pagingParams = props.pagingParams;
  }
  if (props.filters) {
    params.queryFilters = props.filters;
  }
  if (props.queryFilters) {
    params.queryFilters = props.queryFilters;
  }
  if (props.sortOptions) {
    params.sortOptions = props.sortOptions.map(sort => ({ ...sort, name: sort.name.substring(props.post ? 2 : 0) }));
  }

  return ({
    ...(params.queryFilters ?
      !props.post ?
        Object.fromEntries(params.queryFilters.map(filter => ([filter.scope, filter.value]))) :
        {
          queryFilters: params.queryFilters
        } : {}
    ),
    ...(params.sortOptions ?
      !props.post ?
        Object.fromEntries(params.sortOptions.map(sort => ([sort.name, sort.dir]))) :
        {
          sortOptions: params.sortOptions
        } : {}
    ),
    ...(params.pagingParams ?
      !props.post ?
        params.pagingParams :
        {
          pagingParams: params.pagingParams
        } : {}
    )
  });
}

export default makeParams;
