import React, { ReactElement } from 'react';
import { useOutletContext } from 'react-router-dom';
import { EntityLinksAggregation } from '@deecision/dna-interfaces';
import DisplayLinksRelations from '../../../../../containers/relations';

function DashboardTabsComponents(): ReactElement {
  const data = useOutletContext() as { data : EntityLinksAggregation[] };

  return (
    <>
      {data.data[0] && <DisplayLinksRelations data={data.data[0]} />}
    </>
  );
}

export default DashboardTabsComponents;
