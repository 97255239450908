import axiosInstance from './axios/instance.axios';

export * from './services/base.api';
export * from './services/elements.api';
export * from './services/entities.api';
export * from './services/upload.api';
export * from './services/makeparams';

export { default as makeParams } from './services/makeparams';
export { default as AxiosInstanceModifier } from './axios/axiosinstancemodifier.api';

export default axiosInstance;
