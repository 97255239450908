import { IconPresentationAnalytics } from '@tabler/icons-react';
import React from 'react';
import { CustomRouteObject } from "@/router/types";
import createElementRoutes from '@/router/create';
import dnaConfig from '../../../../../config/dna.config.json';
import SimpleSegmentationList from '../../../../containers/segmentations/render/lists/simple.lists.segmentations';
import ComponentAugment from './components';
import SelectComponentSegmentations from '../../segmentation/components/select.component.segmentations';
import SelectComponentVennDiagram from '../../vennDiagram/components/select.component.vennDiagram';
import { routes as routesSegmentations } from '../../segmentation';
import { routes as routesVennDiagram } from '../../vennDiagram';

const tabs: CustomRouteObject[] = [
  {
    id: dnaConfig.routes.portfolios.childrens.augment.tabs.list.label,
    i18nKey: 'portfolios.augment.list.label',
    path: dnaConfig.routes.portfolios.childrens.augment.tabs.list.baseUrl,
    element: <SimpleSegmentationList noAccordion />,
    handle: {
      keepParams: true
    }
  },
  {
    id: dnaConfig.childRoutes.segmentations.label,
    i18nKey: 'segmentation.multiple',
    path: dnaConfig.childRoutes.segmentations.baseUrl,
    element: <SelectComponentSegmentations />,
    handle: {
      keepParams: true
    }
  },
  {
    id: dnaConfig.childRoutes.vennDiagrams.label,
    i18nKey: 'segmentation.vennDiagram.labels',
    path: dnaConfig.childRoutes.vennDiagrams.baseUrl,
    element: <SelectComponentVennDiagram />,
    handle: {
      keepParams: true
    }
  }
];

const routesAugment: CustomRouteObject = createElementRoutes({
  i18nKey: dnaConfig.routes.portfolios.childrens.augment.i18nKey,
  path: dnaConfig.routes.portfolios.childrens.augment.baseUrl,
  icon: IconPresentationAnalytics,
  sectionTitle: 'deetect',
  displayIndex: true,
  component: <ComponentAugment />,
  tabs,
  childrens: [
    ...routesSegmentations('augment'),
    routesVennDiagram('augment')
  ]
});

export default routesAugment;
