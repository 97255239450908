import React, { ReactElement, useEffect, useState } from 'react';
import { CompanyEntity } from '@deecision/dna-interfaces';
import { useTranslation } from 'react-i18next';
import { Grid, Link } from '@mui/material';
import { toNumber } from 'lodash';
import { CurrencyFormatterProps } from '@/utils';
import EvenOddTable from '@/components/tables/evenodd/table';
import BaseDnaEntitiesService from '../../entities/services/entities.services';
import BaseDnaRncsEntitiesService from '../../entities/services/rncsEntities.services';
import { Column } from "@/components/tables/types";
import { CompanyGetter } from "@/main/providers/getter";

function EntitiesScreen(): ReactElement {
  const { t } = useTranslation();

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const params = urlParams.get('entitie');
  const paramsPage = urlParams.get('page');
  const [page] = useState(paramsPage == null ? 0 : toNumber(paramsPage));
  const entity = (params == null ? 'deecCompany' : params);

  const [totalCount, setTotalCount] = useState(0);
  const entitieService = (entity.includes('deec') ? new BaseDnaEntitiesService<CompanyEntity >({ entityType: entity })
    : new BaseDnaRncsEntitiesService<CompanyEntity >({ entityType: entity }));
  const [entitiesList, setEntitiesList] = useState<CompanyEntity[] | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      const tmp = await entitieService.getAll({ });
      setTotalCount(tmp.totalCount);
      const res = Array.isArray(tmp.data) ? tmp.data : [];
      setEntitiesList(res);
    };

    fetchData();
  }, [page]);

  const columns: Column<CurrencyFormatterProps>[] = [
    {
      id: 'entityType',
      label: 'Entity Type'
      // sort: !props.disableSort ? 'name' : undefined
    },
    {
      id: 'entityId',
      label: 'Entity Id'
    },
    {
      id: 'name',
      label: 'Name'
    },
    {
      id: 'State',
      label: 'State'
      // sort: !props.disableSort ? 'legalForm' : undefined
    },
    {
      id: 'codeApe',
      label: 'codeApe'
      // sort: 'codeApe'
    },
    {
      id: 'legalStatus',
      label: 'Legal Form Code'
      // sort: !props.disableSort ? 'legalForm' : undefined
    },
    {
      id: 'turnover',
      label: 'Turnover',
      align: 'right'
      // sort: !props.disableSort ? 'dv_comp_turnover' : undefined
    },
    {
      id: 'ebitda',
      label: 'EBITDA',
      align: 'right'
      // sort: !props.disableSort ? 'dv_comp_ebitda' : undefined
    },
    {
      id: 'capital',
      label: 'Capital',
      align: 'right'
      // sort: !props.disableSort ? 'dv_comp_capital' : undefined
    },
    {
      id: 'valuation',
      label: 'Valuation',
      align: 'right'
      // sort: !props.disableSort ? 'dv_comp_valuation' : undefined
    }
  ];

  const rows = entitiesList?.map(company => ({
    entityType: company.entityType,
    id: company.entityId,
    entityId: <Link href={`${company.entityId as string}`}
      onClick={() => {
        localStorage.setItem(`entityType${company.entityId}`, company.entityType);
      }}>
      {company.entityId}
    </Link>,
    name: new CompanyGetter({ data: company, errorMsg: t('common.utils.unknown') }).getName(),
    state: new CompanyGetter({ data: company, errorMsg: t('common.utils.unknown') }).getState(),
    codeApe: new CompanyGetter({ data: company, errorMsg: t('common.utils.unknown') }).getActivitySector(),
    legalStatus: `${new CompanyGetter({ data: company, errorMsg: t('common.utils.unknown') }).getLegalForm()} (${new CompanyGetter({ data: company, errorMsg: t('common.utils.unknown') }).getLegalForm(true)})`,
    turnover: new CompanyGetter({ data: company, errorMsg: t('common.utils.unknown') }).getTurnover(),
    ebitda: new CompanyGetter({ data: company, errorMsg: t('common.utils.unknown') }).getEbitda(),
    capital: new CompanyGetter({ data: company, errorMsg: t('common.utils.unknown') }).getCapital(),
    valuation: new CompanyGetter({ data: company, errorMsg: t('common.utils.unknown') }).getValuation()
  }));

  return (
    <Grid xs={10}>
      <EvenOddTable<CurrencyFormatterProps> label='entities' columns={columns} rows={rows || []} totalCount={totalCount}/>
    </Grid>
  );
}

export default EntitiesScreen;
