import { IconFolders } from '@tabler/icons-react';
import React from 'react';
import { BaseGroup, GroupAllValues } from '@deecision/dna-interfaces';
import createElementRoutes from '@/router/create';
import { routes as routesCreate } from './create';
import { routes as routesAugment } from './augment';
import dnaConfig from '@/config/dna.config.json';
import ComponentPortfolios from './components';
import GroupsServices from './services';
import IdComponentPortfolios from './components/id';
import DashboardTabsPortfolios from './components/tabs/dashboard';
import ProxemeeTabsPortfolios from './components/tabs/proxemee';
import ListTabsPortfolios from './components/tabs/list';
import UsecasesTabsPortfolios from './components/tabs/usecases';
import { CustomRouteObject } from "@/router/types";
import VeegilenzTabsPortfolios from "@/main/modules/deetect/portfolios/components/tabs/veegilenz";

const tabs: CustomRouteObject[] = [
  {
    id: dnaConfig.routes.portfolios.tabs.dashboard.label,
    i18nKey: 'Dashboard',
    path: dnaConfig.routes.portfolios.tabs.dashboard.baseUrl,
    element: <DashboardTabsPortfolios />,
    handle: {
      keepParams: true
    }
  },
  {
    id: dnaConfig.routes.portfolios.tabs.proxemee.label,
    i18nKey: dnaConfig.routes.portfolios.tabs.proxemee.i18nKey,
    path: dnaConfig.routes.portfolios.tabs.proxemee.baseUrl,
    element: <ProxemeeTabsPortfolios />,
    handle: {
      keepParams: true
    }
  },
  {
    id: dnaConfig.routes.portfolios.tabs.useCases.label,
    i18nKey: dnaConfig.routes.portfolios.tabs.useCases.i18nKey,
    path: dnaConfig.routes.portfolios.tabs.useCases.baseUrl,
    element: <UsecasesTabsPortfolios />
  },
  {
    id: dnaConfig.routes.portfolios.tabs.persons.label,
    i18nKey: dnaConfig.routes.portfolios.tabs.persons.i18nKey,
    path: dnaConfig.routes.portfolios.tabs.persons.baseUrl,
    element: <ListTabsPortfolios />
  },
  {
    id: dnaConfig.routes.portfolios.tabs.companies.label,
    i18nKey: dnaConfig.routes.portfolios.tabs.companies.i18nKey,
    path: dnaConfig.routes.portfolios.tabs.companies.baseUrl,
    element: <ListTabsPortfolios />
  },
  {
    id: dnaConfig.routes.portfolios.tabs.veegilenz.label,
    i18nKey: dnaConfig.routes.portfolios.tabs.veegilenz.i18nKey,
    path: dnaConfig.routes.portfolios.tabs.veegilenz.baseUrl,
    element: <VeegilenzTabsPortfolios />
  }
];

const routesPortfolios: CustomRouteObject = createElementRoutes<BaseGroup, GroupAllValues>({
  i18nKey: dnaConfig.routes.portfolios.i18nKey,
  path: dnaConfig.routes.portfolios.baseUrl,
  icon: IconFolders,
  displayIndex: true,
  sectionTitle: 'deetect',
  component: <ComponentPortfolios />,
  idComponent: <IdComponentPortfolios />,
  Service: new GroupsServices(),
  tabs,
  childrens: [
    routesCreate
  ],
  idChildrens: [
    routesAugment
  ]
});

export default routesPortfolios;
