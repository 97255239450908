import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
// eslint-disable-next-line no-restricted-imports
import { deepClone } from '@mui/x-data-grid/utils/utils';
import Typography from '@mui/material/Typography';
import { APE_CODE_MAPPING } from '@deecision/dna-interfaces';
import { DNAFilter } from '@/main/containers/form/types.form';
import Form, { ListFormProps } from '@/main/containers/form';

export type FiltersManagerProps = ListFormProps & {
  searchEntityType: 'deecPerson' | 'deecCompany',
  resetting?: boolean,
  children?: React.ReactNode
}

function FiltersManager(props: FiltersManagerProps): ReactElement {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [ready, setReady] = useState(false);
  const commonFilters: DNAFilter[] = [
    {
      scope: 'entity',
      id: 'tags',
      label: t('search.advancedSearch.filters.tags.label'),
      labelCombo: [
        t('search.advancedSearch.filters.tags.label2'),
        t('search.advancedSearch.filters.tags.label')
      ],
      desc: t('search.advancedSearch.filters.tags.desc'),
      type: 'combo',
      combo: ['select', 'text'],
      possibleComboValues: [
        [
          {
            id: 'riskType',
            label: t('search.advancedSearch.filters.tags.values.riskType')
          },
          {
            id: 'companyType',
            label: t('search.advancedSearch.filters.tags.values.companyType')
          },
          {
            id: 'activitySector',
            label: t('search.advancedSearch.filters.tags.values.activitySector')
          }
        ],
        []
      ],
      possibleValues: []
    }
  ];
  const deecPersonFilters: DNAFilter[] = [
    ...commonFilters
  ];
  const deecCompanyFilters: DNAFilter[] = [
    ...commonFilters,
    {
      scope: 'dataViews',
      id: 'company_state_state',
      label: t('search.advancedSearch.filters.companyState.label'),
      desc: t('search.advancedSearch.filters.companyState.desc'),
      type: 'radio',
      possibleValues: [
        {
          id: 'open',
          label: t('search.advancedSearch.filters.companyState.values.open')
        },
        {
          id: 'closed',
          label: t('search.advancedSearch.filters.companyState.values.closed')
        }
      ]
    },
    {
      scope: 'dataViews',
      id: 'company_identity_codeApe',
      label: t('search.advancedSearch.filters.codeAPE.label'),
      desc: t('search.advancedSearch.filters.codeAPE.desc'),
      type: 'select',
      possibleValues: Object.entries(APE_CODE_MAPPING).map(([code, label]) => ({ id: code, label: `${code} - ${label}` }))
    }
  ];
  const [baseFilters] = useState<DNAFilter[]>(props.searchEntityType === 'deecPerson' ? deecPersonFilters : deecCompanyFilters);

  useEffect(() => {
    props.setFilters(props.searchEntityType === 'deecPerson' ? deecPersonFilters : deecCompanyFilters);
    setReady(false);
  }, [props.searchEntityType]);

  useEffect(() => {
    if (ready) {
      props.filters.forEach((filter) => {
        let prefix;

        switch (filter.scope) {
        case 'entity':
          prefix = 'fe_';
          break;
        case 'dataViews':
          prefix = 'fdv_';
          break;
        case 'attributes':
          prefix = 'fa_';
          break;
        default:
          prefix = 'f_';
          break;
        }

        const filterName = prefix + filter.id;

        if (filter.value) {
          searchParams.set(filterName, filter.value.toString());
        } else {
          searchParams.delete(filterName);
        }

        setSearchParams(searchParams);
      });
    } else {
      setReady(true);
    }
  }, [props.filters]);

  useEffect(() => {
    if (props.resetting) {
      props.setFilters(props.searchEntityType === 'deecPerson' ? deecPersonFilters : deecCompanyFilters);
    }
  }, [props.resetting]);

  useEffect(() => {
    const tmpFilters: typeof props.filters = baseFilters.map((filter) => {
      const tmpFilter: DNAFilter = deepClone(filter);

      searchParams.forEach((value, key) => {
        if (key.startsWith('f') && key.includes('_')) {
          if (key.includes(tmpFilter.id)) {
            tmpFilter.value = value;
          }
        }
      });

      return tmpFilter;
    });

    props.setFilters(tmpFilters);
  }, []);

  return (
    <>
      {props.filters.length > 0 ?
        <Form {...props} />
        :
        <Typography variant='h4' textAlign='center'>{t('search.advancedSearch.noFilter')}</Typography>
      }
    </>
  );
}

export default FiltersManager;
