import React, { ReactElement } from 'react';
import Paper, { PaperTypeMap } from '@mui/material/Paper';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { useTheme } from '@mui/material/styles';

function CardContainer(props: {paper?: OverridableComponent<PaperTypeMap>, color?: 'primary' | 'secondary', children: React.ReactNode}): ReactElement {
  const theme = useTheme();

  const getBgColor = (color: typeof props.color) => {
    switch (color) {
    case 'primary':
      return theme.primaryColors?.light;
    case 'secondary':
      return theme.palette.secondary.light;
    default:
      return theme.palette.background.default;
    }
  };

  const getBorderColor = (color: typeof props.color) => {
    switch (color) {
    case 'primary':
      return theme.palette.primary.main;
    case 'secondary':
      return theme.palette.secondary.main;
    default:
      return theme.palette.grey['400'];
    }
  };

  return (
    <Paper
      variant='hoverElevation2'
      elevation={0}
      sx={{
        p: 2,
        bgcolor: getBgColor(props.color),
        border: 'solid 1px',
        borderColor: getBorderColor(props.color)
      }}
      {...props.paper}
    >
      {props.children}
    </Paper>
  );
}

export default CardContainer;
