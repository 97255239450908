import React, { ReactElement } from 'react';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import { useTranslation } from 'react-i18next';
import Stack from "@mui/material/Stack";
import VEEGILENZ from '../../../assets/VEEGILENZ.svg';

function VeegilenzHeader(): ReactElement {
  const { t } = useTranslation();

  return (
    <Paper elevation={1} sx={{ width: '100%', overflowX: 'auto' }} >
      <Grid
        container
        spacing={4}
        direction='row'
        width='100%'
        pl={2}
        wrap='nowrap'
      >
        <Grid display='flex' alignItems='center'>
          <img src={VEEGILENZ} alt='Veegilenz' height={32} />
        </Grid>
        <Grid height='64px'>
          <Divider orientation='vertical' />
        </Grid>
        <Grid xs>
          <Stack spacing={1}>
            <Typography>
              {t('veegilenz.fullDesc1')}
            </Typography>
            <Typography>
              {t('veegilenz.fullDesc2')}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default VeegilenzHeader;
