import { EntityLinksAggregation } from "@deecision/dna-interfaces";
import { calculateLowestYAndLengthAtX } from "../../utils";
import { cardHeight, labelEdgeColor, NodeType } from "../../types";
import { CompanyGetter } from "../../../../providers/getter";

export const deployHandling = (data: any, relatedPersons: EntityLinksAggregation[], shareholderTotalPercentage: number, lastDeployAction: 'parent' | 'children', parsedDuplicateCompanies: EntityLinksAggregation[], parentNodes: NodeType, companyGetter: (CompanyGetter | undefined)[]) => {
  const numberOfNewPersonNode = (relatedPersons || []).filter((person: EntityLinksAggregation) => {
    const isParent = person.links[0].type === 'has.representative' || person.links[0].type === 'has.shareholder';
    const AlreadyExistingNode = data.data.nodes.current.find((n: NodeType) => n.data.entitie?.entity2?.entityId === person.entity2.entityId);

    return !(AlreadyExistingNode || ((lastDeployAction === 'children' && isParent) || (lastDeployAction === 'parent' && !isParent)));
  }).length;
  const numberOfNewCompaniesNode = (parsedDuplicateCompanies || []).filter((companie: EntityLinksAggregation) => {
    const isParent = companie.links[0].type === 'has.representative';
    const AlreadyExistingNode = data.data.nodes.current.find((n: NodeType) => n.data.entitie?.entity2?.entityId === companie.entity2.entityId);

    return !(AlreadyExistingNode || ((lastDeployAction === 'children' && isParent) || (lastDeployAction === 'parent' && !isParent)));
  }).length;
  /// SETTING NEW NODES
  data.data.setNodes((previousState: any) => {
    const offsetXParentChildren = 800;
    const offsetYBetweenChildrenBranches = 75;
    const parentNode = previousState.find((n: NodeType) => n.id === data.id);
    const upwardDirection = parentNode.position.y <= parentNode.data.entitieBasePoint.y;
    let customPersonIndex = -1;
    let customCompanieIndex = -1;

    const defaultDataProperties = {
      ...data.data,
      parentNode,
      branchNodeIds: parentNode.data.branchNodeIds ? parentNode.data.branchNodeIds.concat(parentNode.id) : [parentNode.id],
      companieLinkedNodes: parentNode.data.companieLinkedNodes ? parentNode.data.companieLinkedNodes.concat(parentNode.data.label) : [ parentNode.data.label],
      entityData: companyGetter
    };
    /// SETTING NEW PERSON
    const childrenPersonsNewNodes = (relatedPersons || []).map((person: EntityLinksAggregation) => {
      const isParent = person.links[0].type === 'has.representative' || person.links[0].type === 'has.shareholder';
      const AlreadyExistingNode = data.data.nodes.current.find((n: NodeType) => n.data.label === person.entity2.name);
      const existingNodeAtX = calculateLowestYAndLengthAtX(previousState, parentNode, offsetXParentChildren);
      const existingNodeAtXParent = calculateLowestYAndLengthAtX(previousState, parentNode, -offsetXParentChildren);
      const startingYUpwardPersonsPoint = (isParent ? existingNodeAtXParent : existingNodeAtX).lowestYPosition < parentNode.position.y ? (isParent ? existingNodeAtXParent : existingNodeAtX).lowestYPosition - offsetYBetweenChildrenBranches : parentNode.position.y;
      const startingYDownwardPersonsPoint = (isParent ? existingNodeAtXParent : existingNodeAtX).highestYPosition > parentNode.position.y ? (isParent ? existingNodeAtXParent : existingNodeAtX).highestYPosition + offsetYBetweenChildrenBranches * 2 : parentNode.position.y + offsetYBetweenChildrenBranches;

      const yUpwardPosition = startingYUpwardPersonsPoint - (numberOfNewCompaniesNode * cardHeight) - (numberOfNewPersonNode * cardHeight) + (customPersonIndex * cardHeight) + cardHeight;
      const yDownwardPosition = startingYDownwardPersonsPoint + cardHeight + (customPersonIndex * cardHeight);

      if (isParent &&( person.links[0].type === 'has.shareholder' || person.links[0].details?.positionTypes?.includes('sha'))) {
        let highestSharePercentage = 0;

        person.links.forEach((link: any) => {
          if (link.details?.sharesPercentage > highestSharePercentage) {
            highestSharePercentage = link.details?.sharesPercentage;
          }
        });
        shareholderTotalPercentage += highestSharePercentage;
      }
      if (AlreadyExistingNode || (((lastDeployAction === 'children' && isParent) || (lastDeployAction === 'parent' && !isParent)))) return null;
      customPersonIndex += 1;

      return ({
        id: String(customPersonIndex + previousState.length + 1),
        type: 'personEntitie',
        position: {
          x: parentNode.position.x + (isParent ? -offsetXParentChildren : offsetXParentChildren),
          y: upwardDirection ? yUpwardPosition : yDownwardPosition
        },
        nodeFrom: parentNode.id,
        nodeFromType: lastDeployAction,
        data: {
          ...defaultDataProperties,
          hidden: data.filtersId?.includes('parent') ? data.filters?.current.find((filter: { id: string }) => filter.id === 'parent')?.active : false,
          label: person.entity2.name || '' as string,
          entitie: person,
          source: 'Right',
          target: 'Left',
          category: 'person',
          filtersId: [
            ...data.data.filtersId,
            (person.links[0].type === 'has.shareholder' || person.links[0].details?.positionTypes?.includes('sha') ? 'shareholders' : null),
            (person.links[0].details?.positionTypes?.includes('exe') ? 'executive' : null)
          ].filter(Boolean)
        }
      });
    }).filter(Boolean);

    /// SETTING NEW COMPANIES
    const childrenCompaniesNewNodes = (parsedDuplicateCompanies || []).map((companie: any) => { // EntityLinksAggregation but there is no positionTypes in role
      const isParent = companie.links[0].type === 'has.representative';
      const isAdv = companie.links[0].details?.positionTypes?.includes('adv') || companie.links[0].details?.role?.positionTypes?.includes('adv');
      // Checking advisors but it positionTypes should be in the root of details and not in role
      const AlreadyExistingNode = data.data.nodes.current.find((n: NodeType) => n.data.entitie?.entity2?.entityId === companie.entity2.entityId);
      const existingNodeAtX = calculateLowestYAndLengthAtX(previousState, parentNode, offsetXParentChildren);
      const existingNodeAtXParent = calculateLowestYAndLengthAtX(previousState, parentNode, -offsetXParentChildren);
      const startingYUpwardCompaniesPoint = (isParent ? existingNodeAtXParent : existingNodeAtX).lowestYPosition < parentNode.position.y ?
        (isParent ? existingNodeAtXParent : existingNodeAtX).lowestYPosition - offsetYBetweenChildrenBranches
        : parentNode.position.y;
      const startingYDownwardCompaniesPoint = (isParent ? existingNodeAtXParent : existingNodeAtX).lowestYPosition > parentNode.position.y ||
      (isParent ? existingNodeAtXParent : existingNodeAtX).highestYPosition > parentNode.position.y ?
        (isParent ? existingNodeAtXParent : existingNodeAtX).highestYPosition + 2 * offsetYBetweenChildrenBranches
        : parentNode.position.y + offsetYBetweenChildrenBranches;

      if (isParent && companie.links[0].type === 'has.shareholder') {
        let highestSharePercentage = 0;

        companie.links.forEach((link: any) => {
          if (link.details?.sharesPercentage > highestSharePercentage) {
            highestSharePercentage = link.details?.sharesPercentage;
          }
        });
        shareholderTotalPercentage += highestSharePercentage;
      }
      // console.log(companie.entity1.name, companie.relations[0].type, companie.relations[0].details?.positionTypes, companie.entity2.name);

      if (AlreadyExistingNode || (((lastDeployAction === 'children' && isParent) || (lastDeployAction === 'parent' && !isParent)))) return null;
      customCompanieIndex += 1;
      const yUpwardPosition = startingYUpwardCompaniesPoint - (numberOfNewCompaniesNode * cardHeight) + ((customCompanieIndex) * cardHeight);
      const yDownwardPosition = startingYDownwardCompaniesPoint + (numberOfNewPersonNode * cardHeight) + ((customCompanieIndex * cardHeight));

      return ({
        id: String(customCompanieIndex + childrenPersonsNewNodes.length + previousState.length + 1),
        type: 'companieEntitie',
        position: {
          x: parentNode.position.x + (isParent ? -offsetXParentChildren : offsetXParentChildren),
          y: upwardDirection ? yUpwardPosition : yDownwardPosition
        },
        nodeFrom: parentNode.id,
        nodeFromType: lastDeployAction,
        hidden: data.filtersId?.length > 0 ? data.filters?.current.find((filter: { id: string }) => filter.id === (isAdv ? 'advisors' : isParent ? 'parent' : 'children'))?.active : false,
        data: {
          ...defaultDataProperties,
          isAdvisor: isAdv && isParent,
          label: companie.entity2.name || '',
          entitie: companie,
          source: 'Right',
          target: 'Left',
          category: 'companie',
          isListedCompany: companie?.tags?.some((elem: { value: string }) => elem.value === 'listedCompany') || false,
          filtersId: [
            ...data.data.filtersId,
            isAdv ? 'advisors' : null,
            (companie.links[0].type === 'has.shareholder' || companie.links[0].details?.positionTypes?.includes('sha') ? 'shareholders' : null),
            (companie.links[0].details?.positionTypes?.includes('exe') ? 'executive' : null)
          ].filter(Boolean)
        }
      });
    }).filter(Boolean);

    const updatedNodes = previousState.map((node: NodeType) => {
      if (node.id === data.id) {
        return {
          ...node, data: { ...node.data, deploy: true }
        };
      }

      return node;
    });

    // SETTING WARNING SHA NODES
    const existingNodeAtXParent = calculateLowestYAndLengthAtX(previousState, parentNode, -offsetXParentChildren);
    const startingYUpwardCompaniesPoint = existingNodeAtXParent.lowestYPosition < parentNode.position.y ? (existingNodeAtXParent.lowestYPosition - offsetYBetweenChildrenBranches) : parentNode.position.y;
    const startingYDownwardCompaniesPoint = existingNodeAtXParent.highestYPosition > parentNode.position.y ? (existingNodeAtXParent.highestYPosition + (2 * offsetYBetweenChildrenBranches)) : (parentNode.position.y + offsetYBetweenChildrenBranches);
    const yUpwardPosition = startingYUpwardCompaniesPoint - (numberOfNewPersonNode * cardHeight) - (numberOfNewCompaniesNode * cardHeight) - cardHeight;
    const yDownwardPosition = startingYDownwardCompaniesPoint + (numberOfNewPersonNode * cardHeight) + (numberOfNewCompaniesNode * cardHeight);
    let highestSharePercentage = -1;

    relatedPersons.forEach((person) => {
      person.links.forEach((link: any) => {
        if (link.details?.sharesPercentage > highestSharePercentage && link.type === 'has.shareholder' || link.details?.positionTypes?.includes('sha')) {
          highestSharePercentage = link.details?.sharesPercentage;
        }
      });
    });
    const warningShaNode = (lastDeployAction === 'parent' && shareholderTotalPercentage < 99.5 && (highestSharePercentage === -1 || highestSharePercentage > 0.5)) ?
      {
        id: String(previousState.length + numberOfNewPersonNode + numberOfNewCompaniesNode + 1),
        type: 'warningShaParent',
        position: {
          x: parentNode.position.x - offsetXParentChildren,
          y: upwardDirection ? yUpwardPosition : yDownwardPosition
        },
        nodeFrom: parentNode.id,
        nodeFromType: lastDeployAction,
        data: {
          ...defaultDataProperties,
          shareholderTotalPercentage,
          source: 'Right',
          target: 'Left'
        },
        category: 'companie'
      }
      : null;

    return [...updatedNodes, ...childrenPersonsNewNodes, ...childrenCompaniesNewNodes, warningShaNode].filter(Boolean);
  });

  /// SETTING NEW EDGES
  data.data.setEdges((previousState: any[]) => {
    let customPersonIndex = -1;
    let customCompanieIndex = -1;

    const newPersonEdges = (relatedPersons ?? []).map((person: EntityLinksAggregation, index: number) => {
      const isParent = person.links[0].type === 'has.representative' || person.links[0].type === 'has.shareholder';
      const AlreadyExistingNode = data.data.nodes.current.find((n: NodeType) => n.data.entitie?.entity2?.entityId === person.entity2.entityId);
      const defaultEdgeProperty = {
        id: `e${isParent ? String(index + data.data.nodes.current.length + 1) : parentNodes.id}-${isParent ? parentNodes.id : String(index + data.data.nodes.current.length + 1)}`,
        type: 'custom',
        edgeFrom: parentNodes.id,
        edgeFromType: lastDeployAction,
        label: person.links[0]?.details?.positionTypes || [],
        branchNodeIds: parentNodes.data.branchNodeIds ? parentNodes.data.branchNodeIds.concat(parentNodes.id) : [parentNodes.id],
        style: {
          strokeWidth: 3
        }
      };
      let actualSharePercentage = -1;

      person.links.forEach((link: any) => {
        if (link.details?.sharesPercentage > actualSharePercentage) {
          actualSharePercentage = link.details?.sharesPercentage;
        }
      });

      if (AlreadyExistingNode) {

        return (
          {
            ...defaultEdgeProperty,
            source: isParent ? AlreadyExistingNode.id : parentNodes.id,
            target: isParent ? parentNodes.id : AlreadyExistingNode.id,
            data: {
              display: 'right',
              parentOrChildrenDirection: lastDeployAction,
              sourceType: parentNodes.data.entitie?.entity2.entityType.includes('Person') ? 'person' : 'companie',
              targetType: person.entity2.entityType.includes('Person') ? 'person' : 'companie',
              arrowAt: parentNodes.data.source === 'Left' && isParent ? 'start' : 'end',
              sharesPercentage: actualSharePercentage,
              sharesPercentageWarningLabel: !(actualSharePercentage || (actualSharePercentage && actualSharePercentage < 0.5 && shareholderTotalPercentage > 99.5)),
              labelEdgeColor:
              person.links[0]?.details?.positionTypes?.includes('sha') && person.links[0]?.details?.positionTypes?.includes('exe') ?
                labelEdgeColor.both :
                person.links[0]?.details?.positionTypes?.includes('sha') ?
                  labelEdgeColor.sha : person.links[0]?.details?.positionTypes?.includes('exe') ?
                    labelEdgeColor.exe : undefined
            },
            style: {
              strokeWidth: 3
            }
          }
        );
      }
      if (AlreadyExistingNode || (((lastDeployAction === 'children' && isParent) || (lastDeployAction === 'parent' && !isParent)))) {
        return null;
      }
      customPersonIndex += 1;

      return ({
        ...defaultEdgeProperty,
        source: isParent ? String(customPersonIndex + data.data.nodes.current.length + 1) : parentNodes.id,
        target: isParent ? parentNodes.id : String(customPersonIndex + data.data.nodes.current.length + 1),
        data: {
          mainEntitie: previousState[0].data.mainEntitie,
          display: 'right',
          parentOrChildrenDirection: lastDeployAction,
          arrowAt: parentNodes.data.source === 'Left' && isParent ? 'start' : 'end',
          sharesPercentage: actualSharePercentage,
          sharesPercentageWarningLabel: !(actualSharePercentage || (actualSharePercentage && actualSharePercentage < 0.5 && shareholderTotalPercentage > 99.5)),
          labelEdgeColor:
              person.links[0]?.details?.positionTypes?.includes('sha') && person.links[0]?.details?.positionTypes?.includes('exe') ?
                labelEdgeColor.both :
                person.links[0]?.details?.positionTypes?.includes('sha') ?
                  labelEdgeColor.sha : person.links[0]?.details?.positionTypes?.includes('exe') ?
                    labelEdgeColor.exe : undefined
        },
        edgeFromType: lastDeployAction,
        branchNodeIds: parentNodes.data.branchNodeIds ? parentNodes.data.branchNodeIds.concat(parentNodes.id) : [parentNodes.id]
      });
    });

    const newCompanieEdges = (parsedDuplicateCompanies ?? []).map((companie: EntityLinksAggregation) => {
      const isParent = companie.links[0].type === 'has.representative';
      const AlreadyExistingNode = data.data.nodes.current.find((n: NodeType) => n.data.entitie?.entity2?.entityId === companie.entity2.entityId);
      const defaultEdgeProperty = {
        type: 'custom',
        edgeFrom: parentNodes.id,
        edgeFromType: lastDeployAction,
        label: companie.links[0]?.details?.positionTypes || [],
        branchNodeIds: parentNodes.data.branchNodeIds ? parentNodes.data.branchNodeIds.concat(parentNodes.id) : [parentNodes.id],
        data: {
          entitie: parentNodes.data.entitie,
          display: 'right',
          parentOrChildrenDirection: lastDeployAction,
          sourceType: parentNodes.data.entitie?.entity2.entityType.includes('Person') ? 'person' : 'companie',
          targetType: companie.entity2.entityType.includes('Person') ? 'person' : 'companie',
          arrowAt: parentNodes.data.source === 'Left' && isParent ? 'start' : 'end',
          labelEdgeColor:
              companie.links[0]?.details?.positionTypes?.includes('sha') && companie.links[0]?.details?.positionTypes?.includes('exe') ?
                labelEdgeColor.both :
                companie.links[0]?.details?.positionTypes?.includes('sha') ?
                  labelEdgeColor.sha : companie.links[0]?.details?.positionTypes?.includes('exe') ?
                    labelEdgeColor.exe : undefined
        },
        style: {
          strokeWidth: 3
        }
      };
      // console.log(companie.entity1.name, companie.relations[0].type, companie.relations[0].details?.positionTypes, companie.entity2.name);

      if (AlreadyExistingNode && ((lastDeployAction === 'children' && !isParent) || (lastDeployAction === 'parent' && isParent))) {

        return (
          {
            id: `e${isParent ? AlreadyExistingNode.id : parentNodes.id}-${isParent ? parentNodes.id : AlreadyExistingNode.id}`,
            source: isParent ? AlreadyExistingNode.id : parentNodes.id,
            target: isParent ? parentNodes.id : AlreadyExistingNode.id,
            ...defaultEdgeProperty,
            data: {
              ...defaultEdgeProperty.data,
              arrowAt: parentNodes.data.source === 'Left' ? 'start' : 'end'
            }
          }
        );
      }

      if (AlreadyExistingNode || (((lastDeployAction === 'children' && isParent) || (lastDeployAction === 'parent' && !isParent)))) {
        return null;
      }
      customCompanieIndex += 1;

      return ({
        id: `e${isParent ? String(customCompanieIndex + data.data.nodes.current.length + 1 + (numberOfNewPersonNode ?? 0)) : parentNodes.id}-${isParent ? parentNodes.id : String(customCompanieIndex + data.data.nodes.current.length + 1 + (numberOfNewPersonNode ?? 0))}`,
        source: isParent ? String(customCompanieIndex + data.data.nodes.current.length + 1 + (numberOfNewPersonNode ?? 0)) : parentNodes.id,
        target: isParent ? parentNodes.id : String(customCompanieIndex + data.data.nodes.current.length + 1 + (numberOfNewPersonNode ?? 0)),
        ...defaultEdgeProperty
      });
    }).filter(Boolean);

    let highestSharePercentage = -1;

    relatedPersons.forEach((person) => {
      person.links.forEach((link: any) => {
        if (link.details?.sharesPercentage > highestSharePercentage && link.type === 'has.shareholder' || link.details?.positionTypes?.includes('sha')) {
          highestSharePercentage = link.details?.sharesPercentage;
        }
      });
    });
    const warningShaEdge = (shareholderTotalPercentage < 99.5 && lastDeployAction === 'parent' && (highestSharePercentage === -1 || highestSharePercentage > 0.5)) ? {
      id: (`e${data.data.nodes.current.length + numberOfNewPersonNode + numberOfNewCompaniesNode + 1}-${parentNodes.id}`),
      type: 'custom',
      source: String(data.data.nodes.current.length + numberOfNewPersonNode + numberOfNewCompaniesNode + 1),
      target: parentNodes.id,
      edgeFrom: parentNodes.id,
      edgeFromType: lastDeployAction,
      data: {
        arrowAt: 'end'
      },
      branchNodeIds: parentNodes.data.branchNodeIds ? parentNodes.data.branchNodeIds.concat(parentNodes.id) : [parentNodes.id],
      style: {
        strokeWidth: 3
      }
    } : null;

    return ([...previousState, ...newPersonEdges, ...newCompanieEdges, warningShaEdge].filter(Boolean));
  });
};

export const undeployHandling = (deployParentRelation: boolean | undefined, deployChildrenRelation: boolean | undefined, lastDeployAction: 'parent' | 'children', data: any, parentNodes: NodeType, setDeployParentRelation: any, setDeployChildrenRelation: any) => {
  const deletedNodeId: string[] = [];

  data.data.setNodes((previousState: NodeType[]) => previousState.map((node: any) => {
    // This condition should be able to work but exclude parent if we are undeploying children

    if (node.nodeFrom === data.id &&
        ((deployParentRelation === false) || (deployChildrenRelation === false)) &&
        node.nodeFromType === lastDeployAction) {
      return null;
    }

    // Find a way to exclude parent or chidren with lastDeployAction if we are undeploying children and that is from node.nodeFrom the data.id
    if (node.data.branchNodeIds?.includes(data.id) && node.nodeFrom !== data.id) {
      deletedNodeId.push(node.id);

      return null;
    }

    // Add a condition to check if parents and children are undeployed
    return node.id === data.id && ((deployChildrenRelation === undefined && deployParentRelation === false) || (deployChildrenRelation === false && deployParentRelation === undefined))? {
      ...node,
      data: {
        ...node.data,
        deploy: false
      }
    } : node;
  }).filter(Boolean));
  data.data.setEdges((previousState: any[]) =>
    previousState.filter((edge: { branchNodeIds: string | string[], edgeFromType: string | undefined, edgeFrom: string }) => !((deletedNodeId.includes(edge.edgeFrom)) ||
        (((edge.branchNodeIds?.includes(data.id) || edge.edgeFrom === parentNodes.id) && edge.edgeFromType === lastDeployAction))))
  );
  lastDeployAction === 'parent' ?
    setDeployParentRelation(undefined) :
    setDeployChildrenRelation(undefined);
};
