import { z } from 'zod';

const languageStorageSchema = z.object({
  lang: z.string()
});

export type LanguageStorage = z.infer<typeof languageStorageSchema>;

export function getLanguageStorage(): LanguageStorage | undefined {
  const languageStorage = localStorage.getItem('languageStorage');
  const validatedLanguageStorage = languageStorageSchema.safeParse(languageStorage ? JSON.parse(languageStorage) : '');

  return validatedLanguageStorage.success ? validatedLanguageStorage.data : undefined;
}

export function setLanguageStorage(languageStorage: LanguageStorage): void {
  localStorage.setItem('languageStorage', JSON.stringify(languageStorage));
}
