import React, { ReactElement } from 'react';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { BaseItemFormProps, DNABaseFilterValue } from '../types.form';

function RadioFilterTypeForm(props: BaseItemFormProps): ReactElement {

  return (
    <FormControl fullWidth>
      <RadioGroup
        row
        name='form-filter-row-radio-buttons-group'
      >
        {(props.filter.possibleValues as DNABaseFilterValue[]).map(possibleValue => (
          <FormControlLabel
            key={possibleValue.id}
            value={possibleValue.id}
            control={
              <Radio
                checked={possibleValue.id === props.filter.value}
                onClick={() => props.onChange(possibleValue.id)}
              />
            }
            label={possibleValue.label}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}

export default RadioFilterTypeForm;
