import React, { ReactElement } from 'react';
import { useTheme } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button';

interface CustomSwitchIconButtonProps extends ButtonProps {
  label1: string,
  label2: string,
  label1Icon: ReactElement,
  label2Icon: ReactElement,
  setValue: (value: string) => void,
  small?: true,
  noBackground?: true
}

function CustomSwitchIconButton(props: CustomSwitchIconButtonProps): ReactElement {
  const { label1, label2, label1Icon, label2Icon, value, setValue, small, noBackground, ...others } = props;
  const theme = useTheme();

  const handleClick = () => {
    value === label1 ? setValue(label2) : setValue(label1);
  };

  return (
    <Button
      disableElevation
      variant='contained'
      sx={small ? {
        height: '38px',
        borderRadius: theme.shape.borderRadius * 2,
        textTransform: 'none',
        minWidth: '72px',
        padding: '4px',
        boxShadow: 0,
        backgroundColor: noBackground ? 'transparent' : theme.palette.background.paper,
        border: 'none',
        color: theme.palette.text.primary,
        transition: 'all 0.2s ease-in-out',
        '&:hover': {
          boxShadow: noBackground ? 0 : undefined,
          backgroundColor: noBackground ? 'transparent' : theme.palette.background.paper,
          transition: 'all 0.2s ease-in-out'
        }
      } : {
        height: '50px',
        borderRadius: theme.shape.borderRadius * 2,
        textTransform: 'none',
        padding: '4px',
        boxShadow: 0,
        backgroundColor: noBackground ? 'transparent' : theme.palette.background.paper,
        border: 'none',
        color: theme.palette.text.primary,
        transition: 'all 0.2s ease-in-out',
        '&:hover': {
          boxShadow: noBackground ? 0 : undefined,
          backgroundColor: noBackground ? 'transparent' : theme.palette.background.paper,
          transition: 'all 0.2s ease-in-out'
        }
      }}
      onClick={handleClick}
      disableRipple
      size='small'
      {...others}
    >
      <div
        style={small ? {
          position: 'relative',
          height: '32px',
          display: 'flex'
        } : {
          position: 'relative',
          height: '42px',
          display: 'flex'
        }}
      >
        <div
          style={
            value === label1 ? (small ? {
              zIndex: 98,
              position: 'absolute',
              backgroundColor: theme.palette.primary.main,
              height: '32px',
              borderRadius: theme.shape.borderRadius * 2,
              transition: 'all 0.2s ease-out',
              width: small ? '32px' : '42px',
              padding: '0px'
            } : {
              zIndex: 98,
              position: 'absolute',
              backgroundColor: theme.palette.primary.main,
              height: '42px',
              borderRadius: theme.shape.borderRadius * 2,
              transition: 'all 0.2s ease-out',
              width: small ? '32px' : '42px',
              padding: '0px'
            }) :
              (small ? {
                zIndex: 98,
                position: 'absolute',
                marginLeft: '50%',
                backgroundColor: theme.palette.primary.main,
                height: '32px',
                borderRadius: theme.shape.borderRadius * 2,
                transition: 'all 0.2s ease-out',
                width: small ? '32px' : '42px',
                padding: '0px'
              } : {
                zIndex: 98,
                position: 'absolute',
                marginLeft: '50%',
                backgroundColor: theme.palette.primary.main,
                height: '42px',
                borderRadius: theme.shape.borderRadius * 2,
                transition: 'all 0.2s ease-out',
                width: small ? '32px' : '42px',
                padding: '0px'
              })
          }
        />
        <div
          style={value === label1 ? {
            zIndex: 99,
            margin: 'auto',
            transition: 'all 0.2s ease-out',
            color: theme.palette.primary.contrastText,
            width: small ? '32px' : '42px',
            padding: '0px'
          } : {
            zIndex: 99,
            margin: 'auto',
            transition: 'all 0.2s ease-out',
            width: small ? '32px' : '42px',
            padding: '0px'
          }}
        >
          <div style={{ marginTop: '5.5px' }}>{label1Icon}</div>
        </div>
        <div
          style={value === label2 ? {
            zIndex: 99,
            margin: 'auto',
            transition: 'all 0.2s ease-out',
            color: theme.palette.primary.contrastText,
            width: small ? '32px' : '42px',
            padding: '0px'
          } : {
            zIndex: 99,
            margin: 'auto',
            transition: 'all 0.2s ease-out',
            width: small ? '32px' : '42px',
            padding: '0px'
          }}
        >
          <div style={{ marginTop: '5.5px' }}>{label2Icon}</div>
        </div>
      </div>
    </Button>
  );
}

export default CustomSwitchIconButton;
