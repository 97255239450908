import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { PersonEntity } from '@deecision/dna-interfaces';
import { useTranslation } from 'react-i18next';
import Tooltip from '@mui/material/Tooltip';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import { IconDatabase, IconGrave, IconHome, IconId, IconRefresh } from '@tabler/icons-react';
import { Typography } from '@mui/material';
import { startCase } from "lodash";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import Flags from '@/components/flags';
import { PersonGetter } from '../../providers/getter';
import Completeness from '../../../components/chips/completeness';
import Accuracy from '../../../components/chips/accuracy';
import Status from "../../../components/status";

function PersonEntitiesChips(props: { entity: PersonEntity, size?: 'small' | 'medium' }): ReactElement[] {
  const { t } = useTranslation();
  const theme = useTheme();
  const [entity, setEntity] = useState<PersonEntity>(props.entity as PersonEntity);
  const personGetter = useMemo(() => new PersonGetter({
    data: entity,
    errorMsg: t('common.utils.unknown')
  }), [entity]);

  const completenessArray = [
    parseFloat(personGetter.getWeasseCompleteness().toString()),
    parseFloat(personGetter.getWeasseMaxCompleteness().toString()),
    parseFloat(personGetter.getTurnoverSumCompleteness().toString()),
    parseFloat(personGetter.getCapitalSumCompleteness().toString()),
    parseFloat(personGetter.getEbitdaSumCompleteness().toString()),
    parseFloat(personGetter.getValuationSumCompleteness().toString())
  ];

  useEffect(() => {
    setEntity(props.entity as PersonEntity);
  }, [props.entity]);

  return ([
    <Tooltip
      key='common.identity.nationality'
      title={`${personGetter.getNationalitiesCodes().length < 2 ? t('common.identity.nationality') : t('common.identity.nationalities')}: ${personGetter.getNationalities().map(country => startCase(country.toLowerCase())).join(', ')}`}
      arrow
      placement='top'
    >
      <Chip
        label={
          <Stack direction='row' height='20px' spacing={1} alignItems='center' divider={<Divider orientation='vertical' />} sx={{ mr: 1 }}>
            {Flags({ countries: personGetter.getNationalitiesCodes(), size: props.size })}
          </Stack>
        }
        icon={<IconId size={props.size === 'small' ? 18 : 22} style={{ marginLeft: props.size === 'small' ? '8px' : '12px', marginRight: '-4px' }} />}
        size={props.size}
      />
    </Tooltip>,
    <Tooltip
      key='common.identity.country'
      title={`${personGetter.getCountriesCodes().length < 2 ? t('common.identity.country') : t('common.identity.countries')}: ${personGetter.getCountries().map(country => startCase(country.toLowerCase())).join(', ')}`}
      arrow
      placement='top'
    >
      <Chip
        label={
          <Stack direction='row' height='20px' spacing={1} alignItems='center' divider={<Divider orientation='vertical' />} sx={{ mr: 1 }}>
            {Flags({ countries: personGetter.getCountriesCodes(), size: props.size })}
          </Stack>
        }
        icon={<IconHome size={props.size === 'small' ? 18 : 22} style={{ marginLeft: props.size === 'small' ? '8px' : '12px', marginRight: '-4px' }} />}
        size={props.size}
      />
    </Tooltip>,
    <Completeness
      key='completenessTitleChip'
      completeness={completenessArray.reduce((a, b) => (Number.isNaN(a) ? 0 : a) + (Number.isNaN(b) ? 0 : b), 0) / completenessArray.length}
      size={props.size || 'medium'}
      icon={IconDatabase}
    />,
    personGetter.getIsDead() ?
      <Tooltip
        title={
          <Typography>
            {t('common.utils.deceased')} ({personGetter.getDeathDate()}) <Accuracy accuracy={personGetter.getDeathMatchAccuracy()}/>
          </Typography>
        }
        arrow
        placement='top'
      >
        <Chip
          label={t('common.utils.deceased')}
          icon={<IconGrave size={props.size === 'small' ? 18 : 22} style={{ marginLeft: props.size === 'small' ? '8px' : '12px', marginRight: '-4px' }} />}
          color='error'
          size={props.size}
        />
      </Tooltip>
      : <></>,
    ...personGetter.getTags().map(tag => (
      <Tooltip key={`${tag.value}-${tag.family}`} title={t(`tags.families.${tag.family}`)} arrow placement='top'>
        <Chip label={t(`tags.values.${tag.value}`)} size={props.size} />
      </Tooltip>
    )),
    <Tooltip
      title={
        <Stack direction='row' spacing={1} alignItems='center'>
          <Typography variant='body2'>{personGetter.getVeegilenzReason() !== t('common.utils.unknown') ? t(`veegilenz.reason.${personGetter.getVeegilenzReason() || 'noReason'}`) : t('veegilenz.errorResponse.notSearched')}</Typography>
          {personGetter.getVeegilenzReason() !== t('common.utils.unknown') &&
            <Status
              statusAsInt={
                personGetter.getVeegilenzReason() === 'atLeastOneBlackFact' && 3 ||
                  personGetter.getVeegilenzReason() === 'atLeastOneRedFact' && 2 ||
                  personGetter.getVeegilenzReason() === 'atLeastOneOrangeFact' && 1 ||
                  personGetter.getVeegilenzReason() === 'moreThanTreeOrangeFacts' && 1 ||
                  personGetter.getVeegilenzReason() === 'onlyGreenFacts' && 0 ||
                  0
              }
              size={16}
            />
          }
        </Stack>
      }
      arrow
      placement='top'
    >
      <Chip
        label={t('veegilenz.label')}
        icon={<Box pl={1} display='flex' justifyContent='center'>
          <Status statusAsInt={personGetter.getVeegilenzLevel(true) as number} size={24} dark color={personGetter.getVeegilenzLevel() === t('common.utils.unknown') ? theme.palette.grey[700] : undefined} />
        </Box>}
        color={personGetter.getVeegilenzLevel(true) === 2 ? 'error' : personGetter.getVeegilenzLevel(true) === 1 ? 'warning' : personGetter.getVeegilenzLevel() === t('common.utils.unknown') ? undefined : personGetter.getVeegilenzLevel(true) === 0 ? 'success' : undefined}
        sx={{
          bgcolor: personGetter.getVeegilenzLevel(true) === 3 ? theme.palette.grey[800] : undefined,
          '& .MuiChip-label': {
            color: personGetter.getVeegilenzLevel(true) === 3 ? theme.palette.grey[200] : undefined
          }
        }}
        size={props.size}
      />
    </Tooltip>,
    <Tooltip
      key='common.dates.lastUpdate'
      title={t('common.dates.lastUpdate')}
      arrow
      placement='top'
    >
      <Chip
        label={personGetter.getDataDate()}
        icon={<IconRefresh size={props.size === 'small' ? 18 : 22} style={{ marginLeft: props.size === 'small' ? '8px' : '12px', marginRight: '-4px' }} />}
        size={props.size}
      />
    </Tooltip>
  ]
  );
}

export default PersonEntitiesChips;
