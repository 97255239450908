import React, { ReactElement, useState } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import { CompanyEntity, PersonEntity } from '@deecision/dna-interfaces';
import { IconAlertCircle, IconBinaryTree, IconBinaryTree2, IconChartArcs, IconUserDown, IconUserUp } from '@tabler/icons-react';
import Paper from '@mui/material/Paper';
import { ReactFlowProvider } from 'reactflow';
import Grid from '@mui/system/Unstable_Grid';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { Chip, Stack, Tooltip } from '@mui/material';
import Button from "@mui/material/Button";
import LinksTrees from '@/main/containers/network/trees/simple';
import CirclesNetwork from '../../../../containers/network/circles';
import RelationsTree from '../../../../containers/network/trees/vectorial';
import { DataElementChipType } from '@/main/containers/network/types';

type NetworkType = {
  id: number,
  icon: JSX.Element,
  label: string,
  value: string
}

function DataElementChipComponent(props: { dataElementChips: DataElementChipType }) {
  return (
    <Stack direction='row' width='88px' ml='8px'>
      <Tooltip arrow title={`Parent DataElement: ${props.dataElementChips?.parent.dataInfo ?
        props.dataElementChips?.parent.dataInfo === 'missing' ? 'data can be missing and will be updated' :
          props.dataElementChips?.parent.dataInfo === 'incomplete' ? 'Ran into issues when identifying represented/representatives' :
            props.dataElementChips?.parent.dataInfo : ' Due to nature of entity, parents are not scheduled to be fetched'}
              ${props.dataElementChips?.parent.scheduling || ''}`}
      >
        <Chip
          sx={{
            width: 45,
            height: 24
          }}
          color={
            props.dataElementChips?.parent.dataInfo ?
              props.dataElementChips?.parent.dataInfo === 'available' ?
                'success' :
                props.dataElementChips?.parent.dataInfo === 'incomplete' ? 'error' : 'warning' :
              'warning'
          }
          label={
            <Stack style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%' }}>
              <IconUserUp size={20} />
            </Stack>
          }
        />
      </Tooltip>
      <Tooltip arrow title={`Subsidiary DataElement: ${props.dataElementChips?.children.dataInfo ?
        props.dataElementChips?.children.dataInfo === 'missing' ? 'data can be missing and will be updated' :
          props.dataElementChips?.children.dataInfo === 'incomplete' ? 'Ran into issues when identifying represented/representatives' :
            props.dataElementChips?.children.dataInfo : ' Due to nature of entity, subsidiary are not scheduled to be fetched'}
              ${props.dataElementChips?.children.scheduling || ''}`}
      >
        <Chip
          color={
            props.dataElementChips?.children.dataInfo ?
              props.dataElementChips?.children.dataInfo === 'available' ?
                'success' :
                props.dataElementChips?.children.dataInfo === 'incomplete' ? 'error' : 'warning' :
              'warning'
          }
          label={
            <Stack style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%' }}>
              <IconUserDown size={20} />
            </Stack>
          }
          style={{
            marginLeft: '8px',
            width: 45,
            height: 24
          }}
        />
      </Tooltip>
    </Stack>
  );
}

function NetworkTabsComponents<T extends CompanyEntity | PersonEntity>(): ReactElement {
  const { t } = useTranslation();
  const theme = useTheme();
  const entity = useOutletContext() as { data: T };
  const { id } = useParams();
  const networkTypes = [
    {
      id: 0,
      icon: <IconBinaryTree size={18} style={{ transform: 'rotate(-90deg)' }}/>,
      label: t('network.tree.label'),
      value: 'Tree View'
    },
    {
      id: 1,
      icon: <IconBinaryTree2 size={18} style={{ transform: 'rotate(-90deg)' }}/>,
      label: t('network.tree.extended.label'),
      value: 'Tree Chart'
    },
    {
      id: 2,
      icon: <IconChartArcs size={18}/>,
      label: t('network.circles.label'),
      value: 'Circles Chart'
    }
  ];
  const [selected, setSelected] = useState<NetworkType>(networkTypes[0]);
  const [dataElementChips, setDataElementChips] = useState<DataElementChipType>();

  return (
    <Grid container spacing={2}>
      <Grid xs={12} container display='flex' alignItems='center' pb={2}>
        <Grid>
          <Paper
            sx={{ p: 1 }}
          >
            <Stack direction='row' spacing={2} alignItems='center'>
              {networkTypes.map(networkType => (
                <Button
                  key={networkType.id}
                  variant='contained'
                  startIcon={networkType.icon}
                  onClick={() => setSelected(networkType)}
                  sx={{
                    borderRadius: '8px',
                    border: 'none',
                    bgcolor: selected.id === networkType.id ? theme.palette.background.paper : 'transparent',
                    color: theme.palette.text.primary,
                    '&:hover': {
                      boxShadow: 'none',
                      backgroundColor: theme.palette.background.paper
                    }
                  }}
                  size='small'
                >
                  {networkType.label}
                </Button>
              ))}
            </Stack>
          </Paper>
        </Grid>
        <Grid>
          {dataElementChips && selected.value === 'Tree Chart' &&
            <Grid xs display='flex' alignItems='center'>
              <DataElementChipComponent dataElementChips={dataElementChips} />
            </Grid>
          }
          {!entity.data.entityType.includes('Person') && selected.value === 'Circles Chart' &&
            <Grid xs display='flex' height='49px' alignItems='center'>
              <Stack direction='row' width='88px' ml='8px'>
                <Tooltip arrow title={t('charts.chip.prxmeeNotAvailable.label')}>
                  <Chip
                    sx={{
                      width: 45,
                      height: 24
                    }}
                    color='warning'
                    label={
                      <Stack style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%' }}>
                        <IconAlertCircle size={20} />
                      </Stack>
                    }
                  />
                </Tooltip>
              </Stack>
            </Grid>
          }
        </Grid>
      </Grid>
      <Grid xs={12}>
        {id && selected.value === 'Tree View' &&
          <LinksTrees entity={entity.data} id={id} />
        }
        {id && selected.value === 'Tree Chart' &&
          <ReactFlowProvider>
            <RelationsTree entity={entity.data} id={id} setDataElementsChips={setDataElementChips}/>
          </ReactFlowProvider>
        }
        {id && selected.value === 'Circles Chart' &&
          <CirclesNetwork entity={entity.data} id={id} />
        }
      </Grid>
    </Grid>
  );
}

export default NetworkTabsComponents;
