import React, { ReactElement, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { CompanyEntity, EntityRef, PersonEntity } from '@deecision/dna-interfaces';
import Stack from '@mui/material/Stack';
import { IconCirclesRelation, IconExchange, IconUsers } from '@tabler/icons-react';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import IconBuildings from '@/assets/custom/IconBuildings';
import CustomSwitchIconButton from '@/components/switch';
import DisplayRelationsComponents from '@/main/containers/network/list';
import dnaConfig from '../../../../../../config/dna.config.json';
import { getEntity } from '../../../../../providers/getter';
import EntitiesCards from '@/main/containers/cards/dispatch';

function Step2Relations(): ReactElement {
  const [searchParams] = useSearchParams();
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const searchEntityTypeFromParams = searchParams.get('entity1Type');
  const [searchEntityType, setSearchEntityType] = useState<'deecPerson' | 'deecCompany'>( (searchEntityTypeFromParams === 'deecPerson' || searchEntityTypeFromParams === 'deecCompany') ? searchEntityTypeFromParams : 'deecPerson');
  const [entity1, setEntity1] = useState<PersonEntity | CompanyEntity>();

  const getEntity1 = (entity: EntityRef) => {
    getEntity<PersonEntity | CompanyEntity>(entity)
      .then((res) => {
        setEntity1(res);
      });
  };

  useEffect(() => {
    const entity1Id = searchParams.get('entity1');
    const entity1Type = searchParams.get('entity1Type');

    if (entity1Id && entity1Type) {
      getEntity1({
        entityId: entity1Id,
        entityType: entity1Type
      });
    }
  }, [searchParams]);

  return (
    <Stack spacing={4} width='100%' alignItems='center'>
      <Stack direction='row' spacing={2} mr='48px !important'>
        <Tooltip title={t('entities.relations.stepper.step2.changeEntity')} placement='top' arrow>
          <Button variant='textIcon' onClick={() => navigate({ pathname: dnaConfig.routes.relations.relativeUrl, search: '?step=1' })}>
            <IconExchange size={22} />
          </Button>
        </Tooltip>
        {entity1 && <EntitiesCards entity={entity1} />}
      </Stack>
      <Stack direction='row' spacing={4} width='100%' justifyContent='center' alignItems='center'>
        <Divider orientation='horizontal' sx={{ width: 280 }} />
        <IconCirclesRelation size={28} color={theme.palette.grey['600']} />
        <Divider orientation='horizontal' sx={{ width: 280 }} />
      </Stack>
      <Paper sx={{ borderRadius: 24, p: 1 }}>
        <CustomSwitchIconButton
          style={{
            marginRight: '4px',
            border: 'none'
          }}
          label1='deecPerson'
          label2='deecCompany'
          label1Icon={<IconUsers size={20} />}
          label2Icon={
            <Stack direction='row' width={20}>
              <Box
                sx={{
                  opacity: searchEntityType === 'deecCompany' ? 1 : 0,
                  ml: '10px !important',
                  transition: 'opacity 0.2s ease-out'
                }}
              >
                <IconBuildings stroke={theme.palette.primary.contrastText} size={20} />
              </Box>
              <Box
                sx={{
                  opacity: searchEntityType === 'deecCompany' ? 0 : 1,
                  ml: '-20px !important',
                  transition: 'opacity 0.2s ease-out'
                }}
              >
                <IconBuildings stroke={theme.palette.grey['700']} size={20} />
              </Box>
            </Stack>
          }
          value={searchEntityType}
          setValue={value => setSearchEntityType((value === 'deecPerson' || value === 'deecCompany') ? value : 'deecPerson')}
          noBackground
        />
      </Paper>
      {entity1 &&
        <DisplayRelationsComponents data={entity1} to={searchEntityType} link={(id: string, entityType: string) => `?step=3&entity1=${entity1?.entityId}&entity1Type=${entity1?.entityType}&entity2=${id}&entity2Type=${entityType}`} />
      }
    </Stack>
  );
}

export default Step2Relations;
