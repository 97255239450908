import { GroupDesc, SegmentationCriteriaSpec, SegmentationRequest } from '@deecision/dna-interfaces';
import { Icon, IconProps } from '@tabler/icons-react';
import React from 'react';

export interface BasePortfolioDashboardProps {
  baseGroupId: string,
  group: CustomGroupValues,
  callSegmentation: (entityType?: string, groupId?: string, workflowId?: SegmentationRequest['workflowId'], filterIds?: string[], filterValues?: string[]) => void
}

export interface CustomGroupValues extends Pick<GroupDesc, 'subGroupId'> {
  augmented?: boolean,
  type?: 'select' | 'segmentation' | 'parent' | 'custom',
  icon?: React.ForwardRefExoticComponent<Omit<IconProps, "ref"> & React.RefAttributes<Icon>>,
  img?: string,
  description?: boolean,
  link?: string,
  options?: string[],
  segmentationCriterias?: SegmentationCriteriaSpec[],
  subGroups?: CustomGroupValues[]
}

export interface DashboardSection {
  id: string,
  type: 'full' | 'compact',
  noLabel?: boolean,
  icon?: React.ForwardRefExoticComponent<Omit<IconProps, "ref"> & React.RefAttributes<Icon>>,
  groups?: CustomGroupValues[]
}

export interface DashboardConfig {
  sections: DashboardSection[]
}

export const basePaperSx = {
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'flex-start',
  p: 1
};

export const dashboardSpacing = 2;
