import React, { ReactElement } from 'react';
import { IconChartRadar } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import RadarCharts, { RadarChartsProps } from '../../../components/charts/radar';
import MakeWidget from './make';
import CONFEEDENZ from '../../../assets/CONFEEDEENZ.svg';

interface RadarWidgetProps extends RadarChartsProps {
  size?: string
}

function RadarWidget(props: RadarWidgetProps): ReactElement {
  const { t } = useTranslation();

  return (
    <MakeWidget
      icon={<IconChartRadar size={18} />}
      title={t('entities.persons.person.charts.radar.decisionRadar')}
      logo={<img src={CONFEEDENZ} alt='Veegilenz' height={12} />}
      width='320px'
      childWidth='100%'
      childHeight='288px'
    >
      <RadarCharts {...props} />
    </MakeWidget>
  );
}

export default RadarWidget;
