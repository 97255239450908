import React, { ReactElement, Suspense } from 'react';
import {
  createBrowserRouter,
  Outlet,
  RouterProvider
} from 'react-router-dom';
import { IconHome } from '@tabler/icons-react';
import ErrorRouter, { ErrorRouterProps } from './error';
import Layout, { LayoutProps } from '../layouts';
import { CustomRouteObject } from './types';
import FallbackComponent from './fallback';
import { ErrorPage } from '@/components/error';

export interface IndexRouterProps extends Omit<LayoutProps, 'children'>, ErrorRouterProps {
  routeObjects: CustomRouteObject[][] | CustomRouteObject[],
  fallbackElement?: ReactElement
}

function Router(props: IndexRouterProps): ReactElement {
  const router = createBrowserRouter([
    {
      path: '/',
      element:
        <Layout {...props}>
          <Outlet />
        </Layout>,
      errorElement:
        <Layout {...props}>
          <ErrorRouter {...props} />
        </Layout>,
      handle: {
        crumb: Array.isArray(props.routeObjects[0]) ?
          props.routeObjects[0][0].index && props.routeObjects[0][0].handle?.icon ? props.routeObjects[0][0].handle.icon : IconHome :
          props.routeObjects[0].index && props.routeObjects[0].handle?.icon ? props.routeObjects[0].handle.icon : IconHome
      },
      children: [
        {
          path: '*',
          element: <ErrorPage errTitle='404' errMessage='Page Not Found !' { ...props } />
        },
        ...props.routeObjects.flat()
      ]
    }
  ], {
    basename: `/${props.app}`,
    future: {
      // Normalize `useNavigation()`/`useFetcher()` `formMethod` to uppercase
      v7_normalizeFormMethod: true
    }
  });

  return (
    <Suspense fallback={props.fallbackElement || <FallbackComponent />}>
      <RouterProvider router={router} fallbackElement={props.fallbackElement || <FallbackComponent />} />
    </Suspense>
  );
}

export default Router;
