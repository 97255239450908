import React from "react";
import { useTranslation } from "react-i18next";
import {  Button, Chip, Divider, Stack, Typography, useTheme } from "@mui/material";
import Grid from "@mui/system/Unstable_Grid";
import { IconMinus, IconPlus } from "@tabler/icons-react";
import { uniq, upperFirst } from "lodash";
import { GroupDesc } from "@deecision/dna-interfaces";
import { SelectFiltersToAddModalProps } from "./types.filter.modal";
import { VennDiagramSegment } from "../../types.vennDiagram";

type SelectFiltersToAddModalBodyProps = {
  handleSegmentChange: (propertyLabel: keyof VennDiagramSegment, value: string, addAllArray?: boolean) => void,
  selectedFilters: string[],
  setSegment: React.Dispatch<React.SetStateAction<VennDiagramSegment>>
}

function SelectFiltersToAddModalBody(props: SelectFiltersToAddModalProps & SelectFiltersToAddModalBodyProps) {
  const { t } = useTranslation();
  const theme = useTheme();

  const FilterChipSx =  {
    cursor: 'pointer',
    '&:hover': {
      bgcolor: theme.palette.primary.light
    },
    '& .MuiChip-label': {
      pr: 1
    },
    '& .MuiChip-deleteIcon': {
      height: '100%',
      borderBottomRightRadius: 24,
      borderTopRightRadius: 24,
      width: 28,
      mr: 0,
      pl: 1,
      pr: 2,
      '&:hover': {
        bgcolor: theme.palette.primary.light
      }
    },
    '& .iconBar': {
      height: '105%',
      width: 28,
      mr: 0,
      pl: 1,
      pr: 2,
      '&:hover': {
        bgcolor: theme.palette.primary.light
      }
    },
    '& .iconPie': {
      height: '105%',
      width: 28,
      mr: 0,
      pl: 1,
      pr: 2,
      '&:hover': {
        bgcolor: theme.palette.primary.light
      }
    }
  };

  return (
    <Grid maxHeight='65%' xs={12} spacing={4} p={2} pl={3} pr={3} pb={0} overflow='scroll'>
      {uniq(props.possibleFilters?.map(possibleFilter => possibleFilter.categories).flat(1))
        .map((category) => {
          const atLeastOneFilterActiveInCategory = props.possibleFilters.find(grpDesc => props.selectedFilters.some(selectedFilter => selectedFilter === `${grpDesc.baseGroupId}/${grpDesc.subGroupId}` && grpDesc.categories?.includes(category || '')));

          return ([
            <Stack sx={{ px: 4, pt: 0, width: '100%' }}>
              <Divider orientation='horizontal' />
              <Stack width='100%' pt={2}>
                {/* CARD HEADER - Display Categories with category select button */}
                <Stack direction='row' alignItems='center' width={140}>
                  <Typography variant='h4'>{upperFirst(category)}</Typography>
                  <Button
                    variant='outlined'
                    endIcon={
                      props.selectedFilters.length > 0 && atLeastOneFilterActiveInCategory ?
                        <IconMinus size={15} color='#666666'/> :
                        <IconPlus size={15} color='#666666'/>
                    }
                    onClick={() => {
                      props.selectedFilters.length > 0 && atLeastOneFilterActiveInCategory
                        ?
                        // TODO: Type this
                        props.setSegment((prevState: any) => (
                          {
                            ...prevState,
                            groupIds: prevState?.groupIds?.filter((groupId: string) =>
                              !props.possibleFilters.find(grpDesc => groupId === `${grpDesc.baseGroupId}/${grpDesc.subGroupId}`)?.categories?.includes(category || ''))
                          }
                        ))
                        :
                        props.possibleFilters
                          .filter(possibleFilter => possibleFilter.objectType === props.objectType && possibleFilter.categories?.includes(category || ''))
                          .forEach(groupDesc => props.handleSegmentChange('groupIds', `${groupDesc.baseGroupId}/${groupDesc.subGroupId}`, true));
                    }}
                    sx={{
                      maxWidth: 'max-content', maxHeight: '25px', minWidth: 'max-content', minHeight: '25px', marginLeft: '10px', border: 1,
                      '&:hover': {
                        border: 1, borderColor: '#666666', backgroundColor: theme.palette.grey[100]
                      }
                    }}>
                    <Typography fontSize={14} color='#666666'>
                      {props.selectedFilters.length > 0 && atLeastOneFilterActiveInCategory ?
                        t('common.utils.removeAll') :  t('common.utils.addAll')}
                    </Typography>
                  </Button>
                </Stack>
              </Stack>

              {/* CARD BODY - Display all filter within his category */}
              <Grid container direction='row' display='flex' alignItems='center' borderRadius={1} pt={2}>
                {props.possibleFilters
                  .filter(possibleFilter => possibleFilter.objectType === props.objectType && possibleFilter.categories?.includes(category || ''))
                  .map((possibleFilter: GroupDesc) => {
                    const findGroupId = props.selectedFilters.find(selected => selected.includes(possibleFilter.subGroupId));

                    return (
                      <Grid
                        container
                        p={2}
                        spacing={2}
                        width='min-content'
                      >
                        <Chip
                          color={findGroupId ? 'primary' : undefined}
                          label={
                            <Stack display='flex' flexDirection='row' alignItems='center'>
                              <Typography>{typeof possibleFilter.label === 'string' ? possibleFilter.label : ''}</Typography>
                            </Stack>
                          }
                          deleteIcon={
                            findGroupId ?
                              <IconMinus size={16} color={theme.palette.grey[800]} /> :
                              <IconPlus size={16} color={theme.palette.grey[800]} />
                          }
                          onDelete={() => props.handleSegmentChange('groupIds', `${possibleFilter.baseGroupId}/${possibleFilter.subGroupId}`)}
                          onClick={!findGroupId ? () => props.handleSegmentChange('groupIds', `${possibleFilter.baseGroupId}/${possibleFilter.subGroupId}`) : undefined}
                          sx={
                            findGroupId ? FilterChipSx : { cursor: 'pointer' }
                          }
                        />
                      </Grid>
                    );
                  })}
              </Grid>
            </Stack>
          ]);
        })}
    </Grid>
  );
}

export default SelectFiltersToAddModalBody;
