import { RNCS_FORME_JURIDIQUE_MAPPING } from './capfi-api-client.constants';
import { APE_CODE_MAPPING } from '@deecision/dna-interfaces';

export function getRncsLabel(code?: string) {
  const parsedCode = code?.replace('rncs:', '');

  return code ? RNCS_FORME_JURIDIQUE_MAPPING[parsedCode as keyof typeof RNCS_FORME_JURIDIQUE_MAPPING] : 'unknown';
}

export function getApeLabel(code?: string) {

  return code ? APE_CODE_MAPPING[code as keyof typeof APE_CODE_MAPPING] : 'unknown';
}
