import React, { ReactElement } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';

export interface MainerContainerProps {
  elevation?: number,
  breadcrumbs?: ReactElement,
  children: ReactElement
}

function Container(props: MainerContainerProps): ReactElement {

  return (
    <Grid xs>
      <Box
        bgcolor='grey.50'
        width='calc(100% - 12px)'
        height='calc(100% - 12px)'
        borderRadius={1}
        sx={{
          boxShadow: (props.elevation && props.elevation * -1 < 0) ?
            'inset 0px 0px 4px rgba(0, 0, 0, 0.02), inset 1px 1px 5px rgba(0, 0, 0, 0.05)' :
            undefined
        }}
      >
        <Grid container direction='column' height='100%' pt={2} pb={0}>
          {props.breadcrumbs &&
            <>
              <Grid mb={2} mt={2} pl={4} pr={4}>
                {props.breadcrumbs}
              </Grid>
            </>
          }
          <Grid xs sx={{ overflowY: 'auto', overflowX: 'hidden' }} p={4} pt={0}>
            {props.children}
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
}

export default Container;
