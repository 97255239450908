import React, { ReactElement, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { IconDeviceFloppy, IconRefresh, IconTimelineEventPlus } from '@tabler/icons-react';
import Typography from '@mui/material/Typography';
import { Tooltip, useTheme } from '@mui/material';
import Chip from '@mui/material/Chip';
import { CustomSegmentationData } from '../types.segmentations';
import ConfigBuilderSegmentation from './config/config.builder.segmentation';
import PreFilteringBuilderSegmentation from './prefiltering/prefiltering.builder.segmentation';
import CriteriasBuilderSegmentation from './criterias/criterias.builder.segmentation';
import { SegmentationContext } from '../wrappers/wrapper.segmentations';

const defaultNewCustomSegmentationTitle = 'My Segmentation';

function SegmentationBuilder(): ReactElement {
  const { t } = useTranslation();
  const theme = useTheme();
  const segmentationContext = useContext(SegmentationContext);
  const [isPrefiltersEnabled, setIsPrefiltersEnabled] = useState(false);

  const setCustomSegmentationData = (segmentationDataTmp: CustomSegmentationData) => segmentationContext?.setSegmentation(prevState => (prevState ? { ...prevState, data: segmentationDataTmp } : undefined));

  return (
    segmentationContext?.segmentation ?
      <div key={segmentationContext.segmentation?._id}>
        <Stack
          spacing={4}
          direction='row'
          alignItems='center'
          justifyContent='space-between'
          sx={{
            p: 2,
            overflow: 'auto',
            width: '100%',
            position: 'sticky',
            left: 0,
            top: 0,
            zIndex: 1,
            bgcolor: theme.palette.grey[50]
          }}
        >
          <Stack direction='row' spacing={4} alignItems='center'>
            <Typography variant='h2' sx={{ minWidth: 'max-content' }}>
              {segmentationContext.segmentation?.title || defaultNewCustomSegmentationTitle}
            </Typography>
            <Chip
              label={t(`segmentation.builder.config.access.${segmentationContext.segmentation?.groups?.[0] || 'me'}`)}
              color='primary'
              size='small'
            />
            <Tooltip title={t('common.dates.creationDate')} arrow placement='top'>
              <Chip
                label={
                  <Stack direction='row' spacing={1} alignItems='center'>
                    <IconTimelineEventPlus size={16} />
                    <Typography variant='body2'>
                      {segmentationContext.segmentation?.date ? `${new Date(segmentationContext.segmentation.date || '').toLocaleDateString()} ${new Date(segmentationContext.segmentation.date || '').toLocaleTimeString()}` : ''}
                    </Typography>
                  </Stack>
                }
                color='success'
                size='small'
              />
            </Tooltip>
            <Tooltip title={t('common.dates.lastUpdate')} arrow placement='top'>
              <Chip
                label={
                  <Stack direction='row' spacing={1} alignItems='center'>
                    <IconRefresh size={16} />
                    <Typography variant='body2'>
                      {segmentationContext.segmentation?.lastUpdate ? `${new Date(segmentationContext.segmentation.lastUpdate).toLocaleDateString()} ${new Date(segmentationContext.segmentation.lastUpdate).toLocaleTimeString()}` : ''}
                    </Typography>
                  </Stack>
                }
                sx={{ bgcolor: theme.palette.secondary.light, color: theme.palette.secondary.dark }}
                size='small'
              />
            </Tooltip>
          </Stack>
          <Button variant='contained' startIcon={<IconDeviceFloppy />} sx={{ minWidth: 'max-content' }} onClick={() => segmentationContext.save(segmentationContext?.segmentation?.groups?.[0] || 'me')}>
            {t('common.utils.save')}
          </Button>
        </Stack>
        {segmentationContext.segmentation &&
        <Stack alignItems='center' pt={4}>
          <ConfigBuilderSegmentation
            customSegmentation={segmentationContext.segmentation}
            setCustomSegmentation={segmentationContext.setSegmentation}
            isPrefiltersEnabled={isPrefiltersEnabled}
            setIsPrefiltersEnabled={setIsPrefiltersEnabled}
            access={segmentationContext.segmentation.groups?.[0] || 'me'}
            setAccess={segmentationContext?.updateAccess}
          />
          {isPrefiltersEnabled && <PreFilteringBuilderSegmentation customSegmentationData={segmentationContext.segmentation.data} setCustomSegmentationData={setCustomSegmentationData}/>}
          <CriteriasBuilderSegmentation customSegmentationData={segmentationContext.segmentation.data} setCustomSegmentationData={setCustomSegmentationData} />
        </Stack>
        }
      </div>
      : <></>
  );
}

export default SegmentationBuilder;
