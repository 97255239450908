import React, { ReactElement, useEffect, useState } from 'react';
import { Box, Chip, IconButton, Paper, Stack, Tooltip, Typography } from '@mui/material';
import { useOutletContext, useSearchParams } from 'react-router-dom';
import { ApiArrayResponse } from '@deecision/deecision-interfaces';
import { IDataBlock, IDataElement } from '@deecision/dna-interfaces';
import { IconDatabase, IconListDetails, IconMinus, IconPlus } from '@tabler/icons-react';
import Grid from '@mui/system/Unstable_Grid';
import { lighten, darken, useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { upperFirst } from 'lodash';
import { CurrencyFormatterProps } from '../../../../../../utils';
import EvenOddTable from '@/components/tables/evenodd/table';
import { interpolateColors } from "../../../../../../components/charts/utils";
import { DataBlocksQueryService, DataElementsQueryService } from "../../../../../../api/services/query";
import { Column } from "@/components/tables/types";

export enum DataElementRequestStatus {
  CREATED = 'created',
  UPDATE_NEEDED = 'updateNeeded',
  WAITING_DEPENDENCIES = 'waiting_for_dependencies',
  REQUESTED = 'requested',
  IN_PROGRESS = 'inProgress',
  DONE = 'done',
  FAILED = 'failed',
  STARTABLE = 'startable',
}
export enum DataElementDataStatus {
  MISSING = 'missing',
  AVAILABLE = 'available',
  INCOMPLETE = 'incomplete',
  // TODO: maybe add OUT_OF_DATE ?
}

const principalProviders = ['rncs', 'bodacc', 'bodacc2', 'deecision'];

const colorAssociatedToScheduling: Record<DataElementRequestStatus, 'info' | 'warning' | 'success' | 'error' | 'default'> = {
  [DataElementRequestStatus.CREATED]: 'info',
  [DataElementRequestStatus.UPDATE_NEEDED]: 'warning',
  [DataElementRequestStatus.WAITING_DEPENDENCIES]: 'warning',
  [DataElementRequestStatus.REQUESTED]: 'info',
  [DataElementRequestStatus.IN_PROGRESS]: 'info',
  [DataElementRequestStatus.DONE]: 'success',
  [DataElementRequestStatus.FAILED]: 'error',
  [DataElementRequestStatus.STARTABLE]: 'info'
};

const colorAssociatedToStatus: Record<DataElementDataStatus, 'info' | 'warning' | 'success' | 'error' | 'default'> = {
  [DataElementDataStatus.MISSING]: 'error',
  [DataElementDataStatus.AVAILABLE]: 'info',
  [DataElementDataStatus.INCOMPLETE]: 'warning'
};

const colorAssociatedToProviders: Record<string, 'success' | 'warning' | 'error'> = {
  'official': 'success',
  'credible': 'warning',
  'noise': 'error'
};

const colorAssociatedToCredibilitySource: Record<string, 'secondary' | 'info' | 'warning' | 'success' | 'error' | 'default'>  = {
  'abcbourse': 'warning',
  'bing': 'warning',
  'bodacc': 'success',
  'bodacc2': 'success',
  'deecision': 'success',
  'googleCse': 'warning',
  'newsApiOrg': 'warning',
  'rncs': 'success',
  'yahooFinance': 'warning',
  'yahoo': 'warning'
};

type ProviderCountType = {
  count: number,
  selectedView: string,
  value: string,
  credibility: string
}

type ThirdLevelDataType = {
  dataBlock? :  {
    [key: string]: {
        path: string,
        count: number
    }[]
  } | undefined,
  dataElement?: {countStatus: {
    [key: string]: {
      [key: string]: number
  }}, dElement: IDataElement} | undefined
}

function LinksWithPercentageChip(props: {percentage: string}) {
  const theme = useTheme();

  return (
    <Stack width='120px' maxWidth='120px' alignItems='center'>
      <Box sx={{ background: theme.palette.grey['500'], width: 2, height: '25px', borderRadius: 2 }}/>
      <Box minWidth='70%' display='flex' alignItems='center' justifyContent='center'>
        <Chip
          label={`${props.percentage === 'NaN' ? '0' : props.percentage}%`}
          sx={{
            fontWeight: 700,
            border: 1,
            borderColor: darken(theme.palette.info.light, 0.05),
            '&:hover': {
              boxShadow: 1
            }
          }}
          color='info'
          size='small'
        />
      </Box>
      <Box sx={{ background: theme.palette.grey['500'], width: 2, height: '25px', borderRadius: 2 }}/>
    </Stack>
  );
}

function CustomPaper(props: {children: ReactElement, height?: string | number,  width?: string | number, background?: string, borderColor?: string, onClick?: () => void}) {
  return (
    <Paper
      variant='hoverElevation2'
      onClick={props.onClick}
      sx={{
        '&:hover': {
          boxShadow: 1
        },
        width: props.width ? props.width : 'auto',
        minWidth: '110px',
        height: props.height ? props.height : '30px',
        p: 1,
        px: 2,
        background: props.background,
        borderColor: props.borderColor,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center'
      }}
    >
      {props.children}
    </Paper>
  );
}

const sortByProviderThenCount = (a: { count: number, value: string}, b: { count: number, value: string }) => {
  const isAInPrincipalProviders = principalProviders.includes(a.value);
  const isBInPrincipalProviders = principalProviders.includes(b.value);

  if ((isAInPrincipalProviders && isBInPrincipalProviders) || (!isAInPrincipalProviders && !isBInPrincipalProviders)) {
    return b.count - a.count;
  }

  return isAInPrincipalProviders ? -1 : 1;
};

function ProvidersTreeDataBlockLevel3(props: { data: ProviderCountType, selectedProvider: string, thirdLevelData: ThirdLevelDataType }): ReactElement {
  const theme = useTheme();
  const dataPathAndCount = props.thirdLevelData.dataBlock?.[props.selectedProvider];
  const dataPathAndCountLength = (dataPathAndCount ? dataPathAndCount.length : 1);
  const baseColor = interpolateColors('#8fcee4', '#cef2ff', dataPathAndCountLength);
  const color = dataPathAndCount?.map((_, index: number) => baseColor[index % baseColor.length]);
  const totalCount = dataPathAndCount?.reduce((acc: number, curr: { count: number }) => acc + curr.count, 0) || 0;
  const widthLevel3HorizontalBarTree = 200 * (dataPathAndCountLength - 1) + 100;

  return (
    <Stack>
      {props.data.value === props.selectedProvider &&
      <>
        <Box sx={{ bgcolor: theme.palette.grey['500'], width: 2, height: '60px', borderRadius: 2, alignSelf: 'center' }} />
        {dataPathAndCountLength > 1 &&
          <Box sx={{ bgcolor: theme.palette.grey['500'], marginLeft: '60px', width: `${widthLevel3HorizontalBarTree}px`, height: 2, borderRadius: 2 }} />
        }
        <Stack flexDirection='row' ml={dataPathAndCountLength > 1 ? '60px' : '-40px'} pb={8} width={dataPathAndCountLength > 1 ? widthLevel3HorizontalBarTree : '500px'} justifyContent='space-between' height='100%' >
          {dataPathAndCount?.map((data: {
              path: string,
              count: number
          }, index: number) => (
            <Stack alignItems='center' minWidth='200px' width={widthLevel3HorizontalBarTree}>
              <LinksWithPercentageChip percentage={(data.count / totalCount * 100).toFixed(2)} />
              <CustomPaper background={color?.[index] || 'lightblue'} borderColor={darken(color?.[index] || 'lightblue', 0.05)}>
                <>
                  <Typography>{data.path.split('.').slice(1)}</Typography>
                  <Chip
                    label={`${data.count}`}
                    sx={{
                      marginLeft: 2,
                      fontWeight: 700
                    }}
                    size='small'
                  />
                </>
              </CustomPaper>
            </Stack>
          ))}
        </Stack>
      </>
      }
    </Stack>
  );
}

function ProvidersCredibility(props: { providerCount: ProviderCountType[], view: string, name: string, thirdLevelData: ThirdLevelDataType, totalCount: number, setSelectedProvider: React.Dispatch<React.SetStateAction<string>>, selectedProvider: string }): ReactElement {
  const theme = useTheme();
  const { t } = useTranslation();
  const credibilityCount = props.providerCount.reduce((count: { official: number, credible: number, noise: number }, data: ProviderCountType) => {
    const updatedCount = { ...count };
    if (principalProviders.includes(data.value)) {
      updatedCount.official += 1;
    } else {
      updatedCount.credible += 1;
      // updatedCount.noise += 1;
    }

    return updatedCount;
  }, { official: 0, credible: 0, noise: 0 });

  return (
    <>
      {Object.keys(credibilityCount).map((key) => {
        const value = credibilityCount[key as keyof typeof credibilityCount];
        const totalCredibility = props.providerCount.reduce((sum: number, data: ProviderCountType) => ((data.credibility === key) ? sum + data.count : sum), 0);

        return (
          <Stack width='150px' alignItems='center'>
            <LinksWithPercentageChip percentage={((totalCredibility / props.totalCount) * 100).toFixed(2)} />
            <CustomPaper background={theme.palette[colorAssociatedToProviders[key]].light} borderColor={darken(theme.palette[colorAssociatedToProviders[key]].light, 0.05)}>
              <Typography>{t(`common.trust.${key}`)}</Typography>
            </CustomPaper>
            {value > 0 &&
              <Box sx={{ bgcolor: theme.palette.grey['500'], width: 2, height: '30px', borderRadius: 2, alignSelf: 'center' }}/>
            }
          </Stack>
        );
      })}
    </>
  );
}

function ProvidersTree(props: { providerCount: ProviderCountType[], view: string, name: string, thirdLevelData: ThirdLevelDataType, totalCount: number, setSelectedProvider: React.Dispatch<React.SetStateAction<string>>, selectedProvider: string }): ReactElement {
  const theme = useTheme();
  const baseColor = interpolateColors('#8fcee4', '#cef2ff', props.providerCount.length);
  const color = props.providerCount.map((_, index: number) => baseColor[index % baseColor.length]);
  const totalProviderCount = Object.keys(props.providerCount).length;
  const credibilityCount = props.providerCount.reduce((count: { official: number, credible: number, noise: number }, data: ProviderCountType) => {
    const updatedCount = { ...count };
    if (principalProviders.includes(data.value)) {
      updatedCount.official += 1;
    } else {
      updatedCount.credible += 1;
      updatedCount.noise += 0; // set to 1 when back is done
    }

    return updatedCount;
  }, { official: 0, credible: 0, noise: 0 });
  const highestCredibilityCount = Object.values(credibilityCount).reduce((acc, curr) => Math.max(acc, curr), 0);
  const widthCredibility = 116.6 * totalProviderCount < 700 ? 700 : 116.6 * totalProviderCount;
  const sizeOfDataBox = 120;
  const minWidthLinkStack = `${widthCredibility * 1.13}px`;
  const minWidthDataStack = `${widthCredibility * (highestCredibilityCount < 2 ? 0.957 : 1.3)}px`;
  const widthLinkStack2ndLevel = (sizeOfDataBox * totalProviderCount) + (widthCredibility * (totalProviderCount > 6 ? 0.0521 : 0.074) * totalProviderCount);
  const widthStackData2ndLevel = (sizeOfDataBox * totalProviderCount) + (widthCredibility * (totalProviderCount > 6 ? 0.0567 : 0.102) * totalProviderCount);
  const offsetLinkLeft = totalProviderCount > 6 ? `${sizeOfDataBox * 0.7}px` : '0px';

  return (
    <Stack width='100%' pl={totalProviderCount > 6 ? '100px' : '0px'} pb={4} overflow='auto' minHeight='650px'>
      <Typography align='center'>Providers</Typography>
      <Stack width='100%' justifyContent='center' height='100%' alignItems='center'>
        <CustomPaper height='50px' width='250px' background={theme.palette.primary.main} borderColor={darken(theme.palette.primary.main, 0.05)}>
          <Typography color='white'>{props.name}</Typography>
        </CustomPaper>
        <Box sx={{ bgcolor: theme.palette.grey['500'], width: 2, height: 70, borderRadius: 2 }}/>
        <Box sx={{ bgcolor: theme.palette.grey['500'], width: `${widthCredibility - 150}px`, height: 2, borderRadius: 2, alignSelf: 'center' }}/>
        <Stack flexDirection='row' width={`${widthCredibility}px`} justifyContent='space-between'>
          <ProvidersCredibility {...props} />
        </Stack>

        <Stack id='SecondLevelHorizontalBar' flexDirection='row' ml={offsetLinkLeft} width={`${widthLinkStack2ndLevel}px`} minWidth={minWidthLinkStack} justifyContent='space-between'>
          {Object.keys(credibilityCount).map(key => (credibilityCount[key as keyof typeof credibilityCount] > 1 ?
            <Box sx={{ bgcolor: theme.palette.grey['500'], width: 120 * (credibilityCount[key as keyof typeof credibilityCount] - 1), height: 2, borderRadius: 2 }}/>
            : <Box color='transparent' height='1px' width={`${40 * totalProviderCount}px`} />))
          }
        </Stack>
        <Stack id='ProvidersView' flexDirection='row' width={`${widthStackData2ndLevel}px`} minWidth={minWidthDataStack} justifyContent='space-between'>
          {Object.keys(credibilityCount).map(key => (
            <Stack flexDirection='row' minWidth={highestCredibilityCount < 4 ? `${120 * highestCredibilityCount}px` : '0px'} width={credibilityCount[key as keyof typeof credibilityCount] > 0 ? 'auto' :  `${120 * credibilityCount.official}px`}  justifyContent='center'>
              {props.providerCount.map((data: ProviderCountType, index: number) => {
                const percentage = ((data.count / props.totalCount) * 100).toFixed(2);
                const isSelected = data.value === props.selectedProvider;
                if (key !== data.credibility) return (<></>);

                return (
                  <Stack maxWidth='120px'>
                    <LinksWithPercentageChip percentage={percentage} />
                    <Stack
                      maxWidth='150px'
                      maxHeight={30}
                      display='flex'
                      position='relative'
                    >
                      <CustomPaper
                        // background={darken(theme.palette[colorAssociatedToProviders[data.credibility]].light, isSelected ? 0.1 : 0.0 + (data.count / props.totalCount) / 5 )}
                        background={principalProviders.includes(data.value) ? lighten(theme.palette.secondary.main, isSelected ? 0.1 : 0.3) : darken(color[index], isSelected ? 0.1 : 0)}
                        borderColor={isSelected ? darken(theme.palette[colorAssociatedToProviders[data.credibility]].light, isSelected ? 0.15 : 0.05 + (data.count / props.totalCount) / 5 ) : 'transparent'}
                        // borderColor={isSelected ? principalProviders.includes(data.value) ? theme.palette.secondary.main : darken(color[index], 0.1) : 'transparent'}
                        onClick={() => {
                          data.value === props.selectedProvider ?
                            props.setSelectedProvider('')
                            :
                            props.setSelectedProvider(data.value);
                        }}
                      >
                        <Tooltip title={principalProviders.includes(data.value) ? 'Official' : 'Credible / Noise'} placement='top'>
                          <Typography>{upperFirst(data.value)}</Typography>
                        </Tooltip>
                      </CustomPaper>
                      <IconButton
                        onClick={() => {
                          data.value === props.selectedProvider ?
                            props.setSelectedProvider('') : props.setSelectedProvider(data.value);
                        }}
                        sx={{ borderRadius: 50, height: '15px', width: '15px', alignSelf: 'center', position: 'absolute', left: '39%', top: '85%', backgroundColor: 'lightgrey', aspectRatio: '1 / 1' }}>
                        {props.selectedProvider === data.value ?
                          <IconMinus size={10} color='black'/>
                          :
                          <IconPlus size={10} color='black'/>
                        }
                      </IconButton>
                      {props.thirdLevelData.dataBlock && props.selectedProvider &&
                        <ProvidersTreeDataBlockLevel3 data={data} {...props}/>
                      }
                    </Stack>
                  </Stack>
                );
              })}
            </Stack>
          ))
          }
        </Stack>
      </Stack>
    </Stack>
  );
}

function OrigeenTabsComponents(props: {entityType: string}): ReactElement {
  const theme = useTheme();
  const { t } = useTranslation();
  const data = useOutletContext() as { data: any };
  const dataElementsService = new DataElementsQueryService({ entityType: props.entityType });
  const dataBlocksService =  new DataBlocksQueryService<IDataBlock>({ entityType: props.entityType });
  const [dataElements, setDataElements] = useState<IDataElement[]>();
  const [dataBlocks, setDataBlocks] = useState<IDataBlock[]>();
  const [component, setComponent] = useState('dataBlock');
  const [providerCount, setProviderCount] = useState<ProviderCountType[]>([]);
  const [selectedProvider, setSelectedProvider] = useState<string>('');
  const [totalCount, setTotalCount] = useState<number>(0);
  const [thirdLevelData, setThirdLevelData] = useState<ThirdLevelDataType>({});
  const [urlParams, setUrlParams] = useSearchParams();

  const columns: Column<CurrencyFormatterProps>[] = [
    {
      id: 'type',
      label: 'Type'
    },
    {
      id: 'provider',
      label: 'Provider'
    },
    {
      id: 'status',
      label: 'Status'
    },
    {
      id: 'scheduling',
      label: 'Scheduling'
    },
    {
      id: 'lastUpdate',
      label: 'Last Update'
    }
  ];

  const rows = ((dataElements || []).map((dataElement: IDataElement) => ({
    type: dataElement.type,
    provider: <Tooltip title={principalProviders.includes(dataElement.type.split(/:|\./)[1]) ? 'Official' : 'Credible / Noise'} placement='top'>
      <Chip label={upperFirst(dataElement.type.split(/:|\./)[1])} color={colorAssociatedToCredibilitySource[dataElement.type.split(/:|\./)[1]]} />
    </Tooltip>,
    id: 'TODO',
    status: <Chip label={upperFirst(dataElement.dataInfo.status)} color={colorAssociatedToStatus[dataElement.dataInfo.status]} />,
    scheduling: <Chip label={upperFirst(dataElement.scheduling.status)} color={colorAssociatedToScheduling[dataElement.scheduling.status]} />,
    lastUpdate: dataElement.dataInfo.lastUpdate?.split('T')[0] || t('common.utils.notFound')
  }))).sort((a, b) => a.type.localeCompare(b.type));

  useEffect(() => {
    urlParams.set('pageSize', '100');
    setUrlParams(urlParams);
  }, []);

  useEffect(() => {
    dataBlocksService.findByQuery({ entityId: data.data.entityId }).then((response: ApiArrayResponse<IDataBlock>) => {
      setDataBlocks(response.data);
    }).catch((error: Error) => {
      console.error('error', error);
    });
    dataElementsService.findByQuery({ entityId: data.data.entityId }).then((response: ApiArrayResponse<IDataElement>) => {
      setDataElements(response.data);
    }).catch((error: Error) => {
      console.error('error', error);
    });
  }, [data]);

  useEffect(() => {
    const viewToMap = component === 'dataElement' ? dataElements || [] : dataBlocks || [];
    const dataBlock = (dataBlocks || []).reduce((countPath: { [key: string]: {path: string, count: number}[] }, dBlock: IDataBlock) => {
      const provider = dBlock._source?.provider?.id || t('common.utils.notFound');

      if (countPath[provider]) {
        const existingPathIndex = countPath[provider].findIndex((innerData: { path: string }) => innerData.path === dBlock.dataInfo.path);
        existingPathIndex !== -1 ?
          countPath[provider][existingPathIndex].count += 1
          :
          countPath[provider].push({ path: dBlock.dataInfo.path, count: 1 });
      } else {
        countPath[provider] = [{ path: dBlock.dataInfo.path, count: 1 }];
      }

      return countPath;
    }, {});
    const dataElement = (dataElements || []).reduce((countStatus: any, dElement: IDataElement) => {
      const provider = dElement.type.split(/:|\./)[1];

      if (!countStatus[provider]) {
        countStatus[provider] = {};
      }
      countStatus[provider][dElement.scheduling.status] = (countStatus[provider][dElement.scheduling.status] || 0) + 1;

      return countStatus;
    }, {});

    setTotalCount(viewToMap.length);
    const providerCounts = viewToMap.reduce((counts: { [key: string]: number }, d) => {
      const provider = component === 'dataElement' ? (d as IDataElement).type.split(/:|\./)[1] : (d as IDataBlock)._source?.provider?.id || t('common.utils.notFound');

      counts[provider] = (counts[provider] || 0) + 1;

      return counts;
    }, {});
    setProviderCount(Object.keys(providerCounts).map((provider: string) => ({
      count: providerCounts[provider],
      selectedView: component,
      value: provider,
      credibility: principalProviders.includes(provider) ? 'official' : 'credible'
    })));
    setThirdLevelData({
      dataBlock: component === 'dataBlock' ? dataBlock : undefined,
      dataElement: component === 'dataElement' ? dataElement : undefined
    });
  }, [dataElements, dataBlocks, component]);

  return (
    <>
      <Grid container alignItems='center'>
        <Paper sx={{ borderRadius: 24, p: 2 }}>
          <Grid container alignItems='center'>
            <Grid
              p={2}
              borderRadius={15}
              bgcolor={component === 'dataBlock' ? theme.palette.primary.main : 'transparent'}
              height={40}
              onClick={() => setComponent('dataBlock')}
              sx={{
                aspectRatio: '1 / 1'
              }}
            >
              {/* TODO: add translation */}
              <Tooltip title={`${t('common.utils.dataOrigin')}`}>
                <IconDatabase size={25} color={component === 'dataBlock' ? 'white' : 'grey'} />
              </Tooltip>
            </Grid>
            <Grid
              p={2}
              borderRadius={15}
              bgcolor={component === 'dataElement' ? theme.palette.primary.main : 'transparent'}
              height={40}
              onClick={() => setComponent('dataElement')}
              sx={{
                aspectRatio: '1 / 1'
              }}
            >
              {/* TODO: add translation */}
              <Tooltip title={`${t('common.utils.elements')} ${t('common.utils.processed').toLocaleLowerCase()}`}>
                <IconListDetails size={25} color={component === 'dataElement' ? 'white' : 'grey'}/>
              </Tooltip>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      {component === 'dataElement' &&
        <EvenOddTable<CurrencyFormatterProps> rows={rows} columns={columns} totalCount={rows.length} label='' labelFound={`${t('common.utils.elements')} ${t('common.utils.processed').toLowerCase()}`} /> // TODO: add translation
      }
      {component === 'dataBlock' &&
        <ProvidersTree
          providerCount={providerCount.sort(sortByProviderThenCount)}
          view={component}
          name={data.data.name}
          totalCount={totalCount}
          thirdLevelData={thirdLevelData}
          setSelectedProvider={setSelectedProvider}
          selectedProvider={selectedProvider}
        />
      }
    </>
  );
}

export default OrigeenTabsComponents;
