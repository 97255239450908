import React, { ReactElement, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { IconSearch, IconX } from '@tabler/icons-react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import InputBase from '@mui/material/InputBase';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import SearchSubmitBtn, { SearchSubmitBtnProps } from './searchsubmitbtn.components';

type SearchBarProps = Omit<SearchSubmitBtnProps, 'btnRef'> & {
  focus: boolean,
  setFocus: (focus: boolean) => void,
  setQuery: (query: string | undefined) => void,
  selectedFromAutocomplete: string | undefined
}

function SearchBar(props: SearchBarProps): ReactElement {
  const { t } = useTranslation();
  const theme = useTheme();
  const btnRef = useRef<HTMLButtonElement>(null);

  return (
    <Box
      minWidth={200}
      width='100%'
    >
      <Stack direction='row' spacing={2} height='100%' alignItems='center'>
        <Box height={22}>
          <IconSearch size={22} />
        </Box>
        <InputBase
          placeholder={t('search.placeholder') || 'Type a name ...'}
          value={props.query || ''}
          onChange={e => props.setQuery(e.target.value)}
          onFocus={() => props.setFocus(true)}
          onBlur={() => props.setFocus(false)}
          onKeyDown={(e) => {
            if (!props.hideSubmit) {
              if ((e.code === 'Enter' || e.code === 'NumpadEnter') && !props.disabled) {
                if (props.selectedFromAutocomplete) {
                  props.onSubmit(props.selectedFromAutocomplete);
                  props.setQuery(props.selectedFromAutocomplete);
                } else {
                  props.query && props.query.length > 0 && props.onSubmit(props.query);
                }
              }
            } else if (props.selectedFromAutocomplete) {
              props.setQuery(props.selectedFromAutocomplete);
            }
          }}
          sx={{
            fontSize: 16
          }}
          fullWidth
        />
        {props.query && props.query.length > 0 &&
          <Box
            sx={{
              ml: '0px !important'
            }}
          >
            <Button
              variant='textIcon'
              size='small'
              sx={{
                '&:hover': {
                  color: theme.palette.error.main
                }
              }}
              onClick={() => props.setQuery(undefined)}
            >
              <IconX size={18} />
            </Button>
          </Box>
        }
        <SearchSubmitBtn
          {...props}
          btnRef={btnRef}
        />
      </Stack>
    </Box>
  );
}

export default SearchBar;
