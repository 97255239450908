import { routes as routesPortfolios } from './portfolios';
import { routes as routesFolders } from './folders';
import { CustomRouteObject } from "@/router/types";

const routesDeetect: CustomRouteObject[] = [
  routesPortfolios,
  routesFolders
];

export default routesDeetect;
