import { z } from 'zod';

const layoutStorageSchema = z.object({
  sidebar: z.boolean(),
  breadcrumbs: z.boolean(),
  dispLanguage: z.boolean()
});

export type LayoutStorage = z.infer<typeof layoutStorageSchema>;

export function getLayoutStorage(): LayoutStorage | undefined {
  const layoutStorage = localStorage.getItem('layoutStorage');
  const validatedLayoutStorage = layoutStorageSchema.safeParse(layoutStorage ? JSON.parse(layoutStorage) : '');

  return validatedLayoutStorage.success ? validatedLayoutStorage.data : undefined;
}

export function setLayoutStorage(layoutStorage: LayoutStorage): void {
  localStorage.setItem('layoutStorage', JSON.stringify(layoutStorage));
}
