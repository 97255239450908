import { ApiArrayResponse, ApiResponse } from '@deecision/deecision-interfaces';
import { BaseGroup, GroupAllValues, GroupDesc } from '@deecision/dna-interfaces';
import { DynamicGroupsData, DynamicGroupsRequest } from '@deecision/dna-interfaces/dist/groups/dynamicGroups';
import { BaseElementsService } from '@/api';
import axiosInstance from '../../../../../api';

class GroupsServices extends BaseElementsService<BaseGroup> {
  constructor(props?: { elementSuffixUrl?: string }) {
    super({ elementPrefixUrl: props?.elementSuffixUrl ? `dashboard-groups/${props.elementSuffixUrl}` : 'dashboard-groups', axiosInstance });
  }

  getAllGroupValues(id: string): Promise<ApiArrayResponse<GroupAllValues>> {
    return this.axiosInstance.get(this.constructUrl(`${id}/values`))
      .then(res => this.handleArrayResponse(res));
  }

  getGroupSubGroupDescs(id: string): Promise<ApiArrayResponse<GroupDesc>> {
    return this.axiosInstance.get(this.constructUrl(`${id}/subGroupDescs`))
      .then(res => this.handleArrayResponse(res));
  }

  getGroupValues(id: string, subGroupId: string): Promise<ApiResponse<GroupAllValues>> {
    return this.axiosInstance.get(this.constructUrl(`${id}/values/${subGroupId}`))
      .then(res => this.handleResponse(res));
  }
}

export class DynamicGroupsServices extends GroupsServices {
  constructor() {
    super({ elementSuffixUrl: 'dynamic' });
  }

  getDynamicGroupValues(request: DynamicGroupsRequest): Promise<ApiResponse<DynamicGroupsData>> {
    return this.axiosInstance.post(this.constructUrl(`values`), request)
      .then(res => this.handleResponse(res));
  }
}

export default GroupsServices;
