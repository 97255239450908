import React, { ReactElement } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { IconPlus } from '@tabler/icons-react';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import ItemCardsComponents, { SimpleCard } from './item.cards';

interface CardsListProps {
  rows: SimpleCard[],
  plusCard?: {
    link?: string
  }
}

function CardsList(props: CardsListProps): ReactElement {
  const theme = useTheme();

  return (
    <Grid container spacing={4}>
      {props.plusCard &&
        <Grid>
          <Link href={props.plusCard.link}>
            <Paper
              elevation={0}
              sx={{
                height: '100%',
                width: '280px',
                bgcolor: 'transparent',
                border: 'solid 2px',
                borderColor: theme.palette.grey['400'],
                color: theme.palette.grey['600'],
                verticalAlign: 'middle',
                cursor: 'pointer',
                '&:hover': {
                  borderColor: theme.palette.grey['600'],
                  color: theme.palette.grey['700']
                }
              }}
            >
              <Stack height='100%' justifyItems='center'>
                <Grid m='auto' width={78} height={78}>
                  <IconPlus size={64} stroke={1} />
                </Grid>
                <Typography />
              </Stack>
            </Paper>
          </Link>
        </Grid>
      }
      {props.rows.map(row => (
        <ItemCardsComponents
          key={row.id}
          grid
          {...row}
        />
      ))}
    </Grid>
  );
}

export default CardsList;
