import React, { ReactElement, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { useSearchParams } from 'react-router-dom';
import DataElementCard, { CountObj, progressBarColor } from './components/dataElementCard';
import Filter from './components/filter';
import AdminServices from './services/admin.services';
import computeString from '../components/computeString';

export type DataType =  {
  entityType: string,
  type: string,
  count: number,
  scheduling_status: string
}

export type FilterResponse = {
  title: string,
  count: number,
  data: [{
    type: string,
    entityType: string,
    scheduling_status: string,
    count: number
  }]
}

const baseCountStruct = {
  all: 0,
  done: 0,
  failed: 0,
  requested: 0,
  waiting_for_dependencies: 0
};

function AdminDashboard(): ReactElement {
  const [totalCount, setTotalCount] = useState<CountObj>(baseCountStruct);
  const [selected, setSelected] = useState<CountObj>(baseCountStruct);
  const [filterEntityType, setFilterEntityType] = useState<string[]>([]);
  const [filterType, setFilterType] = useState<string[]>([]);
  const [filterSchedulingStatus, setFilterSchedulingStatus] = useState<string[]>([]);
  const [filterEntityTypeName, setFilterEntityTypeName] = useState<string[]>([]);
  const [filterTypeName, setFilterTypeName] = useState<string[]>([]);
  const [filterSchedulingStatusName, setFilterSchedulingStatusName] = useState<string[]>([]);
  const [resetting, setResetting] = useState(false);
  const adminService = new AdminServices();
  const [filterParams, setFilterParams] = useSearchParams();
  const [res, setRes] = useState<FilterResponse>({
    title: 'All',
    count: 0,
    data: [{
      type: '',
      entityType: '',
      scheduling_status: '',
      count: 0
    }]
  });

  const handleSetters = (setter: string, value: string[]) => {
    switch (setter) {
    case 'filterEntityType':
      setFilterEntityType(value);
      setFilterEntityTypeName(value);
      break;
    case 'filterType':
      setFilterType(value);
      setFilterTypeName(value);
      break;
    case 'filterSchedulingStatus':
      setFilterSchedulingStatus(value);
      setFilterSchedulingStatusName(value);
      break;
    default:
      break;
    }
  };

  const handleRes = (data: any, valueEntityType: string, valueType: string, valueSchedulingStatus: string) => {
    const tmpSelected: CountObj = {
      all: 0,
      done: 0,
      failed: 0,
      requested: 0,
      waiting_for_dependencies: 0
    };
    const tmpResponse: FilterResponse = {
      title: `${valueEntityType.length === 0 ? 'All EntityTypes' : valueEntityType},
              ${valueType.length === 0 ? 'All Types' : valueType},
              ${valueSchedulingStatus.length === 0 ? 'All Status' : valueSchedulingStatus}`,
      count: 0,
      data: [{
        type: '',
        entityType: '',
        scheduling_status: '',
        count: 0
      }]
    };

    data?.forEach((value: DataType) => {
      type ObjectKey = keyof typeof progressBarColor;
      const status = value.scheduling_status as ObjectKey;

      tmpSelected[status] += value.count;
      if (valueEntityType !== '' || valueType !== '' || valueSchedulingStatus !== '') {
        tmpResponse.count += value.count;
        tmpResponse.data.push({
          type: value.type,
          entityType: value.entityType,
          scheduling_status: value.scheduling_status,
          count: value.count
        });
        setRes(tmpResponse);
        setSelected(tmpSelected);
      } else {
        // tmpCount.all += value.count;
        // setTotalCount(tmpCount);
      }
    });
  };

  const setUrlFilter = (valueType: string, valueEntityType: string, valueSchedulingStatus: string) => {
    filterTypeName.length !== 0 ?filterParams.set('type', valueType) : filterParams.delete('type');
    filterEntityTypeName.length !== 0 ? filterParams.set('entityType', valueEntityType) : filterParams.delete('entityType');
    filterSchedulingStatusName.length !== 0 ? filterParams.set('status', valueSchedulingStatus) : filterParams.delete('status');
    setFilterParams(filterParams);
  };

  useEffect(() => {
    const urlStatus = filterParams.getAll('status')[0];
    const urlEntityType = filterParams.getAll('entityType')[0];
    const urlType = filterParams.getAll('type')[0];

    urlStatus ? handleSetters('filterSchedulingStatus', urlStatus.replace(`'`, '').replace(/"/g, '').split(',')) : undefined;
    urlEntityType ? handleSetters('filterEntityType', urlEntityType.replace(`'`, '').replace(/"/g, '').split(',')) : undefined;
    urlType ? handleSetters('filterType', urlType.replace(`'`, '').replace(/"/g, '').split(',')) : undefined;
  }, []); // Set filter from URL params

  useEffect(() => {
    const valueEntityType = computeString(filterEntityType);
    const valueType = computeString(filterType);
    let valueSchedulingStatus = computeString(filterSchedulingStatus);

    if (filterSchedulingStatusName.length === 0) valueSchedulingStatus = `"done", "failed", "requested", "waiting_for_dependencies"`;

    setUrlFilter(valueType, valueEntityType, valueSchedulingStatus);

    adminService.getStats(filterSchedulingStatusName, filterSchedulingStatus, filterType, filterEntityType)
      .then((response) => {
        if (response.data) handleRes(response.data, valueEntityType, valueType, valueSchedulingStatus);
      });
  }, [filterEntityType, filterType, filterSchedulingStatus, totalCount]); // set filterData and set url + get data from backend

  useEffect(() => {
    adminService.getStats().then((response) => {
      const tmpCount: CountObj = {
        all: 0,
        done: 0,
        failed: 0,
        requested: 0,
        waiting_for_dependencies: 0
      };

      response.data.forEach((value: DataType) => {
        tmpCount.all += value.count;
        type ObjectKey = keyof typeof progressBarColor;
        const status = value.scheduling_status as ObjectKey;

        tmpCount[status] += value.count;
      });
      setTotalCount(tmpCount);
    });
  }, []);  // retrieve total count from all data

  useEffect(() => {
    if (resetting) {
      filterParams.delete('status');
      filterParams.delete('type');
      filterParams.delete('entityType');
      setFilterEntityType([]);
      setFilterType([]);
      setFilterSchedulingStatus([]);
      setFilterEntityTypeName([]);
      setFilterTypeName([]);
      setFilterSchedulingStatusName([]);
      setResetting(false);
    }
  }, [resetting]);  // reset filters value

  return (
    <Grid sx={{ flexGrow: 1, marginTop: '1%' }} container spacing={2}>
      <Filter
        filterEntityTypeName={filterEntityTypeName}
        filterTypeName={filterTypeName}
        filterSchedulingStatusName={filterSchedulingStatusName}
        handleSetters={handleSetters}
        setResetting={setResetting} />
      <Grid item xs={12}>
        <Grid container justifyContent='center' spacing='1%'>
          <Grid item>
            <DataElementCard
              title={res.title}
              status='done'
              count={res?.count}
              selectedCount={selected}
              totalCount={totalCount}
              dataElement={res.data}
              filterSchedulingStatusName={filterSchedulingStatusName}
              filterParams={filterParams}
              handleSetters={handleSetters} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default AdminDashboard;
