import { FindOptions, SortDirection } from '@deecision/deecision-interfaces';
import { parseFilter } from './filterParsing';

export function makeFindOptions(props: { searchParams?: URLSearchParams, filtersToAdd?: FindOptions['queryFilters'], post?: true }): FindOptions {
  const pagingParams: FindOptions['pagingParams'] = {};
  const sortOptions: FindOptions['sortOptions'] = [];
  const queryFilters: FindOptions['queryFilters'] = [];

  props.searchParams?.forEach((value, key) => {
    if (key.startsWith('s_')) {
      sortOptions.push({ name: props.post ? key.replace('s_', '') : key, dir: value as SortDirection });
    }
    if (key.startsWith('f') && key.includes('_')) {
      queryFilters.push(parseFilter({ key: props.post ? key.split('_').slice(1).join('_') : key, value }));
    }
    if (key === 'page') {
      pagingParams.page = parseInt(value, 10);
    }
    if (key === 'pageSize') {
      pagingParams.pageSize = parseInt(value, 10);
    }
  });

  props.filtersToAdd?.forEach(filterToAdd => queryFilters.push(filterToAdd));

  return ({ pagingParams, queryFilters, sortOptions });
}
