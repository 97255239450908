import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import SimpleDisplayFilters, { SimpleFilter } from '@/components/filters/simple';
import RelationsChart, { RelationsChartProps } from './chart';

function CirclesNetwork(props: Omit<RelationsChartProps, 'filters'>): ReactElement {
  const { t } = useTranslation();
  const [filters, setFilters] = useState<SimpleFilter[]>([
    {
      label: t('filters.showActive'),
      id: 'showActive',
      active: false
    },
    {
      label: t('filters.showInactive'),
      id: 'showInactive',
      active: false
    }
  ]);

  return (
    <Stack spacing={4} width='100%' height='max-content'>
      <SimpleDisplayFilters filters={filters} setFilters={setFilters} />
      <RelationsChart filters={filters} {...props} />
    </Stack>
  );
}

export default CirclesNetwork;
