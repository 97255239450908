import { Button, CircularProgress } from "@mui/material";
import { IconDownload } from "@tabler/icons-react";
import React, { useContext, useState } from "react";
import { useLocation, useOutletContext } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { BaseGroup } from "@deecision/dna-interfaces";
import BaseDnaEntitiesService from "../../../modules/entities/services/entities.services";
import { SegmentationDataContext } from "./abstract.segmentations";
import { SegmentationContext } from "../wrappers/wrapper.segmentations";
import { getEntityTypeEquivalent, getEntityTypeI18nKey } from "../../../providers/getter";

function ExportIconButton() {
  const { t } = useTranslation();
  const data = useOutletContext() as { data : BaseGroup };
  const segmentationContext = useContext(SegmentationContext);
  const segmentationData = useContext(SegmentationDataContext);
  const entitiesServices = new BaseDnaEntitiesService({ entityType: segmentationData?.objectType });
  const { pathname } = useLocation();
  const date = new Date();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const name = `DNA EntitiesList export ${date.toLocaleDateString().replaceAll('/', '-')} ${date.toLocaleTimeString().replace(':', 'h').replace(':', 'min')} ${data?.data.label || ''} ${(pathname.includes('venn') && 'Venn') || segmentationContext?.segmentation?.title || 'full'} ${t(getEntityTypeI18nKey(getEntityTypeEquivalent(segmentationData?.objectType)))}`;

  const exportCsv = () => {
    if (segmentationData?.request) {
      setIsLoading(true);

      entitiesServices.downloadCSV({
        name,
        format: 'csv',
        options: { separator: ';' }
      }, segmentationData.request)
        .then(() => setIsLoading(false));
    }
  };

  return (
    <Button
      variant='outlined'
      size='small'
      onClick={exportCsv}
      startIcon={<IconDownload size={18} />}
      endIcon={isLoading ? <CircularProgress size={18} /> : undefined}
      disabled={isLoading}
    >
      {t('common.actions.export.csv')}
    </Button>
  );
}

export default ExportIconButton;
