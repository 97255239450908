import React, { useState } from 'react';
import { darken } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Unstable_Grid2';
import { IconCheck, IconX } from "@tabler/icons-react";

type ColorPickerProps = {
  colors: { color: string, isDisabled: boolean }[],
  onSelect: (color: string) => void,
  selectedColor?: string
}

function ColorPicker(props: ColorPickerProps) {
  const [selectedColor, setSelectedColor] = useState<string | undefined>(props.selectedColor || undefined);

  const handleColorClick = (color: string, isDisabled: boolean) => {
    if (!isDisabled) {
      setSelectedColor(color);
      props.onSelect(color);
    }
  };

  return (
    <Grid container gap={2} alignItems='center'>
      {props.colors.map(colorObj => (
        <IconButton
          key={colorObj.color}
          onClick={() => handleColorClick(colorObj.color, colorObj.isDisabled)}
          sx={{
            width: selectedColor === colorObj.color ? 28 : 25,
            height: selectedColor === colorObj.color ? 28 : 25,
            bgcolor: colorObj.isDisabled ? 'grey' : colorObj.color,
            border: selectedColor === colorObj.color ? `2px solid ${darken(colorObj.color, 0.2)}` : '1px solid transparent',
            '&:hover': {
              transform: colorObj.isDisabled ? 'none' : 'scale(1.1)',
              bgcolor: colorObj.isDisabled ? 'grey' : darken(colorObj.color, 0.1)
            },
            transition: 'transform 0.2s, border-color 0.5s',
            cursor: colorObj.isDisabled ? 'not-allowed' : 'pointer'
          }}
        >
          {selectedColor === colorObj.color && !colorObj.isDisabled && <IconCheck size={20} style={{ color: 'white' }} />}
          {colorObj.isDisabled && <IconX size={20} style={{ color: 'white' }} />}
        </IconButton>
      ))}
    </Grid>
  );
}

export default ColorPicker;
