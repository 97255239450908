import React, { ReactElement } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Icon, IconProps } from '@tabler/icons-react';

interface TitleComponentProps {
  title: string,
  icon?: React.ForwardRefExoticComponent<Omit<IconProps, "ref"> & React.RefAttributes<Icon>>,
  chips?: ReactElement[],
  small?: true
}

function TitleComponent(props: TitleComponentProps): ReactElement {

  return (
    <Box>
      <Stack
        direction='row'
        alignItems='center'
        spacing={2}
        width='100%'
        p={props.small ? 1 : 2}
      >
        <Box>
          {props.icon && <props.icon size={props.small ? 24 : 28} />}
        </Box>
        <Box>
          <Typography variant={props.small ? 'h4' : 'h3'}>
            {props.title}
          </Typography>
        </Box>
        {props.chips &&
          <Stack direction='row' spacing={2} alignItems='center' mt='-4px'>
            {props.chips.map(chip => (
              <React.Fragment key={chip.key}>
                {chip}
              </React.Fragment>
            ))}
          </Stack>
        }
      </Stack>
    </Box>
  );
}

export default TitleComponent;
