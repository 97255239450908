import { EntityLinksAggregation, PersonEntity } from "@deecision/dna-interfaces";
import { Box, Chip, Paper, Stack, Tooltip, Typography, useTheme } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Handle, Position, useStore } from "reactflow";
import { IconArrowBackUp, IconChartHistogram, IconChartLine, IconCirclesRelation, IconCoins, IconCopy, IconGrave, IconHome, IconId, IconUser, IconUsers } from "@tabler/icons-react";
import Flags from '@/components/flags';
import IconBuildings from "@/assets/custom/IconBuildings";
import { getEntity, PersonGetter } from "../../../providers/getter";
import { proxemeeCategories } from "../../proxemee/proxemee";
import { SettingsProps } from "@/components/filters/simple/switch";
import Accuracy from "../../../../components/chips/accuracy";

export function PersonCard(data: any) {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const sourcePosition = data.data.source as keyof typeof Position;
  const targetPosition = data.data.target as keyof typeof Position;
  const [copied, setCopied] = useState(false);
  const [person, setPerson] = useState<EntityLinksAggregation[]>([]);
  const zoomLevel = useStore((state: { transform: any[] }) => state.transform[2]);
  const personGetter = useMemo(() => new PersonGetter({
    data: person as unknown as PersonEntity,
    errorMsg: t('common.utils.unknown')
  }), [person]);
  const sharesPercentage = data.data.entitie.links.find((link: { type: string }) => link.type === 'has.investedCompany' || link.type === 'has.shareholder')?.details?.sharesPercentage;
  const color = [
    'secondary',
    'success',
    'warning',
    'error'
  ];
  const prxmeeChipColor = color[proxemeeCategories.findIndex(category => data.data.prxmee >= category.min && data.data.prxmee < category.max)] as "secondary" | "success" | "warning" | "error" | "default" | "primary" | "info";

  useEffect(() => {
    getEntity({ entityType: data.data.entitie.entity2.entityType, entityId: data.data.entitie.entity2.entityId }).then((res: any) => {
      if (res) {
        setPerson(res);
      }
    }).catch(e => console.log(e));
  }, [data.data.entitie.entity2]);

  const Person = useMemo(() => (
    <Paper variant='hoverElevation2' sx={{ boxShadow: 1, cursor: 'pointer', width: '500px', height: '108px', p: 1, pl: 4, pr: 4 }}>
      <Stack spacing={1} height='100%' justifyContent='space-between' pb={1} onClick={() => {
        navigate(`../../../persons/${data.data.entitie.entity2.entityId}/network`);
      }}>
        <Stack alignItems='center' height='min-content' position='absolute' right={-35} top={-10}>
          { data.data.prxmee !== undefined &&
            data.data.prxmee !== 0 &&
            data.data.settingsList.current.some((setting: SettingsProps) => setting.id === 'prxmee' && setting.status) &&
            <Chip
              color={prxmeeChipColor}
              sx={{ border: '0.5px solid grey' }}
              label={
                <Stack display='flex' direction='row' alignContent='center' alignItems='center'>
                  <IconCirclesRelation size={zoomLevel < 0.5 ? 25 : 16} color='black'/>
                  <Typography fontSize={zoomLevel < 0.5 ? 30 : 18} color='black'>
                    {data.data.prxmee}
                  </Typography>
                </Stack>
              }
            />
          }
        </Stack>
        {sharesPercentage !== undefined &&
          <Stack alignItems='center' height='min-content' position='absolute' right={sharesPercentage > 10 ? -60 : -35} top='35%'>
            <Tooltip title={t('filters.shares')} arrow placement='top'>
              <Chip color={sharesPercentage > 0 ? 'info' : 'warning'} sx={{ border: '0.5px solid grey' }} label={<Typography fontSize={24}>{`${sharesPercentage}%`}</Typography>} />
            </Tooltip>
          </Stack>
        }

        {zoomLevel <= 0.5 &&
          <>
            <Stack height={24} pt={4} spacing={1} direction='row' alignItems='center' width='calc(100% + 16px)' sx={{ ml: '-8px !important' }}>
              {zoomLevel > 0.5 ? data.id : <Stack width={8} /> }
              <IconUser size={40} />
              { person &&
                data.data.settingsList.current.some((setting: SettingsProps) => setting.id === 'nationalities' && setting.status) &&
              personGetter.getNationalities().map((code: string) => (
                <Chip
                  label={
                    <Stack display='flex' flexDirection='row' justifyContent='center' alignItems='center'>
                      <IconId />
                      {Flags({ countries: [code.slice(0, 2)], size: zoomLevel <= 0.5 ? 'large' : 'small' })}
                    </Stack>
                  }
                  style={{ marginLeft: '20px' }}
                />
              ))}
              { person &&
                data.data.settingsList.current.some((setting: SettingsProps) => setting.id === 'residence' && setting.status) &&
                <Chip
                  label={
                    <Stack display='flex' flexDirection='row' justifyContent='center' alignItems='center'>
                      <IconHome />
                      {personGetter.getCountriesCodes().map((code: string) => (
                        Flags({ countries: [code], size: zoomLevel <= 0.5 ? 'large' : 'small' })
                      ))}
                    </Stack>
                  }
                  style={{ marginLeft: '20px' }}
                />
              }
              {person && personGetter.getIsDead() &&
                <Tooltip
                  title={
                    <Typography>
                      {t('common.utils.deceased')} ({personGetter.getDeathDate()}) <Accuracy accuracy={personGetter.getDeathMatchAccuracy()}/>
                    </Typography>
                  }
                  arrow
                  placement='top'
                >
                  <Chip
                    sx={{
                      ml: '20px !important'
                    }}
                    color='error'
                    label={
                      <Stack spacing={1} direction='row' alignItems='center'>
                        <IconGrave color={ theme.palette.error.dark}/>
                      </Stack>
                    }
                  />
                </Tooltip>
              }
            </Stack>
            <Typography variant='body1' align='center' fontSize={38} pl={2} mr={2} pb={6} fontWeight={500} noWrap>
              {data.data.label}
            </Typography>
          </>
        }
        {zoomLevel > 0.5 &&
        <Stack height={24} spacing={1} direction='row' alignItems='center' width='calc(100% + 16px)' sx={{ ml: '-8px !important' }}>
          <IconUser size={14} />

          <Typography variant='body1' mr={2} fontWeight={500} noWrap>
            {data.data.label}
          </Typography>
          <Tooltip
            title={copied ? t('common.utils.copied') : `${t('common.utils.copy')}`}
            arrow
            placement='top'
            onOpen={() => setCopied(false)}
          >
            <IconCopy
              style={{
                marginLeft: '2px',
                marginRight: '6px'
              }}
              color={theme.palette.grey['600']}
              size={12}
              onClick={(e) => {
                e.preventDefault();
                navigator.clipboard.writeText(data.data.label)
                  .then(() => {
                    setCopied(true);
                  });
              }}
            />
          </Tooltip>
          {person && personGetter.getCountriesCodes().map((code: string) => (
            <Tooltip title={code} placement='top' arrow>
              <Box minWidth='max-content' mt='2px !important' ml='0 !important' borderRadius={1} pl={zoomLevel <= 0.5 ? 10 : 0 }>
                {Flags({ countries: [code], size: zoomLevel <= 0.5 ? 'large' : 'small' })}
              </Box>
            </Tooltip>
          ))}
          {person &&
            <>
              <Tooltip
                title={`${t('entities.companies.label')}: ${t('common.utils.active')} / ${t('common.utils.total')}`}
                arrow
                placement='top'
              >
                <Chip
                  sx={{
                    transform: 'scale(0.8)'
                  }}
                  color={
                    ((personGetter.getNbCompanies(true) as number)
                      - (personGetter.getNbActiveCompanies(true) as number))
                    <= (personGetter.getNbActiveCompanies(true) as number) ?
                      'success' : 'error'
                  }
                  label={
                    <Stack spacing={1} direction='row' alignItems='center'>
                      <IconBuildings
                        size={14}
                        stroke={
                          ((personGetter.getNbCompanies(true) as number)
                            - (personGetter.getNbActiveCompanies(true) as number))
                          <= (personGetter.getNbActiveCompanies(true) as number) ?
                            theme.palette.success.dark : theme.palette.error.dark
                        }
                      />
                      <Typography>
                        {`${personGetter.getNbActiveCompanies(true)}/${personGetter.getNbCompanies(true)}`}
                      </Typography>
                    </Stack>
                  }
                  size='small'
                />
              </Tooltip>
              <Tooltip
                title={`${t('entities.persons.alterLabels.relations')}: ${t('common.utils.current')} / ${t('common.utils.total')}`}
                arrow
                placement='top'
              >
                <Chip
                  sx={{
                    ml: '-8px !important',
                    transform: 'scale(0.8)'
                  }}
                  color={
                    ((personGetter.getNbRelations() as number)
                      - (personGetter.getNbCurrentRelations() as number))
                    <= (personGetter.getNbCurrentRelations() as number) ?
                      'success' : 'error'
                  }
                  label={
                    <Stack spacing={1} direction='row' alignItems='center'>
                      <IconUsers
                        size={14}
                        color={
                          ((personGetter.getNbRelations() as number)
                            - (personGetter.getNbCurrentRelations() as number))
                          <= (personGetter.getNbCurrentRelations() as number) ?
                            theme.palette.success.dark : theme.palette.error.dark
                        }
                      />
                      <Typography>
                        {`${personGetter.getNbCurrentRelations()}/${personGetter.getNbRelations()}`}
                      </Typography>
                    </Stack>
                  }
                  size='small'
                />
              </Tooltip>
              {personGetter.getIsDead() &&
                <Tooltip
                  title={
                    <Typography>
                      {t('common.utils.deceased')} ({personGetter.getDeathDate()}) <Accuracy accuracy={personGetter.getDeathMatchAccuracy()}/>
                    </Typography>
                  }
                  arrow
                  placement='top'
                >
                  <Chip
                    sx={{
                      ml: '-4px !important',
                      transform: 'scale(0.8)'
                    }}
                    color='error'
                    label={
                      <Stack spacing={1} direction='row' alignItems='center'>
                        <IconGrave
                          size={14}
                          color={ theme.palette.error.dark}
                        />
                        {/* <Typography>{t('common.utils.deceased')}</Typography> */}
                      </Stack>
                    }
                    size='small'
                  />
                </Tooltip>
              }
            </>
          }
        </Stack>
        }

        {person && zoomLevel > 0.5 &&
          <>
            <Stack spacing={2} direction='row' alignItems='center'>
              <Tooltip title={t('common.financial.weasse.label')} arrow placement='top'>
                <Chip
                  sx={{ width: '160px' }}
                  label={
                    <Stack spacing={1} direction='row' alignItems='center'>
                      <IconCoins size={14} />
                      <Typography>
                        {personGetter.getWeasse()}
                      </Typography>
                    </Stack>
                  }
                  size='small'
                />
              </Tooltip>
              <Tooltip title={t('common.financial.weasseMax.label')} arrow placement='top'>
                <Chip
                  sx={{ width: '160px' }}
                  label={
                    <Stack spacing={1} direction='row' alignItems='center'>
                      <IconCoins size={14} />
                      <Typography>
                        {personGetter.getWeasseMax()}
                      </Typography>
                    </Stack>
                  }
                  size='small'
                />
              </Tooltip>
              <Tooltip title={t('common.financial.fortune.label')} arrow placement='top'>
                <Chip
                  sx={{ width: '160px' }}
                  label={
                    <Stack spacing={1} direction='row' alignItems='center'>
                      <IconChartHistogram size={14} />
                      <Typography>
                        {personGetter.getFortune()}
                      </Typography>
                    </Stack>
                  }
                  size='small'
                />
              </Tooltip>
            </Stack>
            <Stack spacing={2} direction='row' alignItems='center' width='calc(100% + 16px)' sx={{ ml: '-8px !important' }}>
              <Tooltip title={t('common.financial.turnover.sum')} arrow placement='top'>
                <Chip
                  sx={{ width: '115px' }}
                  label={
                    <Stack spacing={1} direction='row' alignItems='center'>
                      <IconArrowBackUp size={14} />
                      <Typography>
                        {personGetter.getTurnoverSum()}
                      </Typography>
                    </Stack>
                  }
                  size='small'
                />
              </Tooltip>
              <Tooltip title={t('common.financial.capital.sum')} arrow placement='top'>
                <Chip
                  sx={{ width: '115px' }}
                  label={
                    <Stack spacing={1} direction='row' alignItems='center'>
                      <IconCoins size={14} />
                      <Typography>
                        {personGetter.getCapitalSum()}
                      </Typography>
                    </Stack>
                  }
                  size='small'
                />
              </Tooltip>
              <Tooltip title={t('common.financial.ebitda.sum')} arrow placement='top'>
                <Chip
                  sx={{ width: '115px' }}
                  label={
                    <Stack spacing={1} direction='row' alignItems='center'>
                      <IconChartHistogram size={14} />
                      <Typography>
                        {personGetter.getEbitdaSum()}
                      </Typography>
                    </Stack>
                  }
                  size='small'
                />
              </Tooltip>
              <Tooltip title={t('common.financial.valuation.sum')} arrow placement='top'>
                <Chip
                  sx={{ width: '115px' }}
                  label={
                    <Stack spacing={1} direction='row' alignItems='center'>
                      <IconChartLine size={14} />
                      <Typography>
                        {personGetter.getValuationSum()}
                      </Typography>
                    </Stack>
                  }
                  size='small'
                />
              </Tooltip>
            </Stack>
          </>
        }
      </Stack>
    </Paper>
  ), [
    person,
    copied,
    zoomLevel,
    data.data.settingsList.current.some((setting: SettingsProps) => setting.id === 'prxmee' && setting.status),
    data.data.settingsList.current.some((setting: SettingsProps) => setting.id === 'nationalities' && setting.status),
    data.data.settingsList.current.some((setting: SettingsProps) => setting.id === 'residence' && setting.status)
  ]);

  return (
    <>
      <Handle type='source' position={Position[sourcePosition]} isConnectable style={{ visibility: 'hidden' }}/>
      {Person}
      <Handle type='target' position={Position[targetPosition]} isConnectable style={{ visibility: 'hidden' }}/>
    </>
  );
}
